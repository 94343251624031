import Head from 'next/head';

type PageTitleProps = {
  title: string;
};

export function PageTitle(props: PageTitleProps) {
  return (
    <Head>
      <title>{props.title} | HMBradley | Secure Online Banking</title>
    </Head>
  );
}
