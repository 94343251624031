import * as React from 'react';

type HMBradleyLogoProps = {
  className?: string;
};

export function HMBradleyLogo(props: HMBradleyLogoProps) {
  return (
    <svg {...props} viewBox="0 0 993.13 236.09">
      <title>HMBradley logo</title>
      <g>
        <path
          d="M484.79,88.71l-8.83,12.62l-0.01-25.93h-23.61h-1.96h-3.14v4.02c2.48,0.84,3.03,10.83,3.14,16.59v76.32h25.58v-60.15
					c1.87-5.98,8.7-12.87,18.18-12.87h15.37V76.15c-1.97,0-4.26,0-8.15,0C494.17,76.15,489.82,80.83,484.79,88.71z"
        />
        <path
          d="M679.77,31.24c2.96,1.01,3.17,15.01,3.17,19.35v28.36c-4.45-4.08-11.66-6.32-20.18-6.32c-26.5,0-47.09,19.26-47.09,54.29
					c0,31.52,17.85,47.27,40.96,47.27l26.31-10.88l0,9.03h25.76V27.22h-28.93V31.24z M682.94,158.88h-9.9
					c-24.02,0-31.98-18.25-31.98-40.86c0-23.17,8.92-37.42,24.48-37.42c11.09,0,17.4,8.51,17.4,23.84V158.88z"
        />
        <path d="M730.92,31.24c2.6,0.89,3.08,11.86,3.16,17.42v123.67h25.76V27.22h-28.92V31.24z" />
        <path
          d="M824.45,72.63c-26.23,0-45.78,22.03-45.78,53.19c0,28.54,17.98,48.37,44.85,48.37c2.72,0,5.42-0.67,8.29-1.85l26.88-11.36
					v-4.74c0,0-6.58,2.65-17.58,2.65c-30.56,0-40.01-17.68-39.26-43.29h60.42v-4.59C862.25,88.75,849.28,72.63,824.45,72.63z
					 M802.21,108.4c1.48-17.05,8.15-29.28,20.39-29.28c12.05,0,16.68,11.86,16.68,29.28H802.21z"
        />
        <path
          d="M560.66,72.59c-23.69,0-39.12,9.13-39.12,24.51v2.21h23.06v-5.56c0-8.9,4.91-14.64,13.81-14.64
					c9.64,0,12.49,6.67,12.49,17.24v15.42l-27.98,10.19c-13.9,5.19-25.39,12.01-25.39,27.95c0,14.83,11.8,24.28,24.83,24.28l3.05-0.07
					l25.65-10.75v8.96h25.64l-0.22-69.68C596.47,80.97,584.38,72.59,560.66,72.59z M570.9,158.88h-12.37
					c-11.41,0-16.17-6.37-16.17-16.01c0-10.38,4.82-14.48,12.6-17.44l15.94-6.49V158.88z"
        />
        <path
          d="M943.29,74.11v3.92c5.64,1.09,8.22,7.8,3.81,19.94l-19.84,48.57l-24.49-58.65c-2.42-6.35-3.68-9.65-4.55-13.78h-32.21
					v4.02c3.4,1.71,6.33,4.54,11.8,15.63l37.19,82.76l-2.99,7.3c-0.9,1.8-3.14,3.83-6.62,3.83l-26.62,0.12l0.1,21.1
					c0,0,6.93,0,14.11,0c11.82,0,17.83-6.98,21.8-15.25l42.4-99.41c2.66-6.66,6-13.35,8.74-15.6v-4.5H943.29z"
        />
        <path
          d="M394.39,104.6c-0.38-0.03-0.4-0.56-0.03-0.63c16.41-2.79,31.59-13.04,31.59-30.16c0-21.29-16.23-31.48-44.75-31.48H268.29
					c-0.64,4.56-1.82,7.72-3.08,11.63l-29.02,78.64l-29.16-78.98c-1.31-3.69-2.95-7.51-3.46-11.3h-26.68v4.02
					c3.08,1.05,2.34,9.35,1.23,16.63l-13.79,94.15c-0.82,5.33-3.46,6.81-8.38,6.81h-11.77c-4.98,0-6.93-2.19-6.93-6.81V65.16
					c0.02-4.84,0.32-17.84,3.17-18.81v-4.02h-30.2v59.4H54.46v-59.4H24.07v4.02c2.76,0.94,3.13,13.23,3.16,18.37v85.22
					c-0.04,5.14-0.4,17.43-3.16,18.37v4.02h30.38v-62.2h55.74v62.2h62.6c0-3.22,0.46-9.97,1.02-13.91l12.29-85.53l36.37,99.44h9.42
					l36.67-99.44c0,0,8.68,58.19,12.24,85.53c1.02,7.86,1.08,11.72,1.08,13.91h31.65v-4.02c-2.98-1.98-5.1-19.34-5.1-19.34
					c-4.66-33.13-13.05-91.57-13.05-91.57c-0.75-4.59,1.29-6.68,5.49-6.68h28.19c5.75,0,7.3,2.67,7.3,8.56l0.01,89.91
					c0,7.98-0.55,18.77-3.12,19.11v4.02h51.02c32.04,0,49.07-12.59,49.07-35C433.36,118.41,417.3,106.25,394.39,104.6z M363.55,50.73
					h8.89c17.11,0.21,27.04,7.72,27.04,24.2c0,17.41-8.7,26.31-26.85,26.31h-9.07V50.73z M380.77,164.01h-1.85
					c-11.11,0-15.37-2.93-15.37-12.92v-41.82h12.78c17.67,0.17,30,11.21,30,27.69C406.32,155.3,397.44,164.01,380.77,164.01z"
        />
      </g>
    </svg>
  );
}
