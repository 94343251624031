import * as React from 'react';
import CommandPalette from 'react-command-palette';
import { commandPaletteAtomTheme } from './helpers';
import { useCommandPaletteContext } from './hooks/use-command-palette-context';

export function HMBCommandPalette() {
  const controller = useCommandPaletteContext();

  return (
    <CommandPalette
      closeOnSelect
      hotKeys="command+k"
      resetInputOnClose
      commands={controller.commands}
      theme={commandPaletteAtomTheme}
    />
  );
}
