import currency from 'currency.js';

export function toAmountInCents(amount: string | number) {
  return currency(amount).intValue;
}

export function fromCents(x: number | string) {
  return currency(x, { fromCents: true }).value;
}

export function toAmountInDollarsFromCents(amount: string | number) {
  return currency(amount, { fromCents: true }).format();
}

export function toAmountInDollars(amount: string | number) {
  return currency(amount).format();
}

export function toAmountString(amount: string | number) {
  return currency(amount, { separator: '', symbol: '' }).format();
}
