import { byAccountId, isCreditAccount } from '@/helpers';
import { httpClient } from '@/transports/http';
import { AccountV4, CardV1 } from '@core/mainframe-js-sdk';
import { useGetCardV1Cards, useGetCardV1CardsBalances, useGetLedgerV4Accounts } from '@core/mainframe-react-query';
import { useRouter } from 'next/router';

type UseActiveDepositReturn = {
  hasAccount: boolean;
  id: string;
  account: AccountV4;
  isLoading: boolean;
  isError: boolean;
};

export function useActiveDepositAccount(): UseActiveDepositReturn {
  const router = useRouter();
  const id = router.query.depositAccountId as string;

  const getLedgerV4AccountsQuery = useGetLedgerV4Accounts(httpClient, {
    options: {
      select(data) {
        return data.data.find(byAccountId(id));
      },
    },
  });

  return {
    isLoading: getLedgerV4AccountsQuery.isLoading,
    isError: getLedgerV4AccountsQuery.isError,
    id,
    account: getLedgerV4AccountsQuery.data ?? undefined,
    hasAccount: Boolean(
      !getLedgerV4AccountsQuery.isLoading &&
        getLedgerV4AccountsQuery.data &&
        (router.query.sid || router.query.depositAccountId),
    ),
  };
}

export type UseActiveCreditReturn = {
  hasAccount: boolean;
  id: string;
  account: CardV1;
  isLoading: boolean;
  isError: boolean;
  isClosedWithNegativeBalance: boolean;
  isClosedWithBalanceLeft: boolean;
  authorizedUser?: CardV1;
  hasAuthUser: boolean;
};

export function useActiveCreditAccount(props?: { id?: string }): UseActiveCreditReturn {
  const router = useRouter();
  const id = props?.id || (router.query.cardId as string);

  const getCardV1CardsQuery = useGetCardV1Cards(httpClient, {
    options: {
      select(data) {
        const authorizedUser = data.data?.filter(isCreditAccount).find((card) => card.isAuthorizedUserCard);
        const account = data.data?.filter(isCreditAccount).find(byAccountId(id));
        return {
          account,
          authorizedUser,
          hasAuthUser: Boolean(authorizedUser),
        };
      },
    },
  });

  const getCardV1CardsBalances = useGetCardV1CardsBalances(httpClient, {
    params: {
      path: { card_id: id },
    },
    options: {
      enabled: Boolean(id),
      select(data) {
        return {
          remainingStatementBalance: data.remainingStatementBalance,
        };
      },
    },
  });

  return {
    isLoading: getCardV1CardsQuery.isLoading || getCardV1CardsBalances.isLoading,
    isError: getCardV1CardsQuery.isError || getCardV1CardsBalances.isError,
    id,
    account: getCardV1CardsQuery.data?.account,
    authorizedUser: getCardV1CardsQuery.data?.authorizedUser,
    hasAuthUser: getCardV1CardsQuery.data?.hasAuthUser,
    hasAccount:
      !getCardV1CardsQuery.isLoading && !!getCardV1CardsQuery.data?.account && Boolean(router.query.sid || id),
    isClosedWithNegativeBalance:
      getCardV1CardsQuery.data?.account?.isAccountClosed &&
      0 >= Number(getCardV1CardsBalances.data?.remainingStatementBalance),
    isClosedWithBalanceLeft:
      getCardV1CardsQuery.data?.account?.isAccountClosed &&
      Number(getCardV1CardsBalances.data?.remainingStatementBalance) > 0,
  };
}
