import { AuthenticatedUser } from '@/components/authenticated-user';
import { wrapDisplayName } from '@/helpers/recompose';
import * as React from 'react';

export function withAuthenticatedUser<T>(WrappedComponent: React.ComponentType<T>) {
  function WithAuthenticatedUser(props: React.PropsWithChildren<any>) {
    return (
      <AuthenticatedUser>
        <WrappedComponent {...props} />
      </AuthenticatedUser>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    WithAuthenticatedUser.displayName = wrapDisplayName(WrappedComponent, 'WithAuthenticatedUser');
  }

  return WithAuthenticatedUser;
}
