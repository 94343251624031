export const FORGOT_PW_PATH = '/forgot-password';
export const FORGOT_UN_PATH = '/forgot-username';
export const ACTIVATE_CARD_PATH = '/activate';
export const HOME_LOGGED_OUT_PATH = '/';

export const PATH_LOGIN = '/login';
export const PATH_ROOT = '/';
export const PATH_LOGIN_2FA = `${PATH_LOGIN}/2fa`;
export const PATH_LOGIN_2FA_SETUP = `${PATH_LOGIN}/2fa/setup`;
export const PATH_REGISTER_WEB_AUTHN = `${PATH_LOGIN}/web-authn-registration`;

export const PATH_500 = '/500';

export const PATH_ONBOARDING = '/onboarding';
export const PATH_ONBOARDING_TERMS = `${PATH_ONBOARDING}/terms`;
export const PATH_ONBOARDING_INFO = `${PATH_ONBOARDING}/info`;
export const PATH_ONBOARDING_VERIFY = `${PATH_ONBOARDING}/verify`;
export const PATH_ONBOARDING_PHONE_VERIFICATION = `${PATH_ONBOARDING}/phone-verification`;
const PATH_ONBOARDING_KYC = `${PATH_ONBOARDING}/kyc`;
export const PATH_ONBOARDING_KYC_PROCESSING = `${PATH_ONBOARDING_KYC}/processing`;
export const PATH_ONBOARDING_KYC_SUCCESS = `${PATH_ONBOARDING_KYC}/success`;
export const PATH_ONBOARDING_KYC_SORRY = `${PATH_ONBOARDING_KYC}/sorry`;
export const PATH_ONBOARDING_KYC_IDV = `${PATH_ONBOARDING_KYC}/idv`;
export const PATH_ONBOARDING_KYC_IDV_GATHER = `${PATH_ONBOARDING_KYC_IDV}/gather`;
export const PATH_ONBOARDING_KYC_IDV_COMPLETED = `${PATH_ONBOARDING_KYC_IDV}/completed`;
export const PATH_ONBOARDING_KYC_IDV_EXPIRED = `${PATH_ONBOARDING_KYC_IDV}/expired`;
export const PATH_ONBOARDING_ONE_CLICK_CREDIT = `${PATH_ONBOARDING}/one-click-credit`;

// DASHBOARD
export const PATH_ACCOUNT = '/account';
export const PATH_ACCOUNT_HOME = `${PATH_ACCOUNT}/home`;
export const PATH_ACCOUNT_ACCOUNTS = `${PATH_ACCOUNT}/accounts`;
const DEPOSIT_SUBPATH = 'deposit';
const CREDIT_SUBPATH = 'credit';
export const ACCOUNT_ACTIVITY = `${PATH_ACCOUNT}/activity`;
export const DEPOSIT_ACCOUNT_BASE_PATH = `${PATH_ACCOUNT}/${DEPOSIT_SUBPATH}`;
export const CREDIT_ACCOUNT_BASE_PATH = `${PATH_ACCOUNT}/${CREDIT_SUBPATH}`;
export const TAX_DOCUMENTS_PATH = `${PATH_ACCOUNT}/${DEPOSIT_SUBPATH}/:depositAccountId/tax-documents`;
export const HATCH_DEPOSIT_TRANSACTIONS_PATH = `${PATH_ACCOUNT}/${DEPOSIT_SUBPATH}/:depositAccountId/hatch/transactions`;
export const NYCB_TRANSACTIONS_PATH = `${PATH_ACCOUNT}/deposit/:depositAccountId/transactions`;
export const CREDIT_TRANSACTIONS_PATH = `${PATH_ACCOUNT}/credit/:cardId/transactions`;
export const NYCB_PLANS_PATH = `${PATH_ACCOUNT}/deposit/:depositAccountId/plans`;
export const NYCB_PLANS_DETAILS_PATH = `${PATH_ACCOUNT}/deposit/:depositAccountId/plans/:planId/details`;
export const CREDIT_STATEMENTS_PATH = `${PATH_ACCOUNT}/${CREDIT_SUBPATH}/:cardId/statements`;
export const PATH_ACCOUNT_DEPOSIT_DETAILS = `${PATH_ACCOUNT}/${DEPOSIT_SUBPATH}/:depositAccountId`;
export const PATH_ACCOUNT_CREDIT_DETAILS = `${PATH_ACCOUNT}/${CREDIT_SUBPATH}/:cardId`;

const MANAGE_ACCOUNT_PATH = `${PATH_ACCOUNT}/manage`;

export const ACCOUNT_MANAGE_CARD_PATH = `${MANAGE_ACCOUNT_PATH}/card`;
export const ACCOUNT_MANAGE_CARD_RESET_PIN_PATH = `${ACCOUNT_MANAGE_CARD_PATH}/:cardId/reset-pin`;
export const ACCOUNT_MANAGE_CARD_SET_PIN_PATH = `${ACCOUNT_MANAGE_CARD_PATH}/:cardId/set-pin`;

export const ACCOUNT_DETAILS_PATH = `${MANAGE_ACCOUNT_PATH}/account-details`;
export const ACCOUNT_DETAILS_UPDATE_ADDRESS_PATH = `${ACCOUNT_DETAILS_PATH}/update-address`;
export const ACCOUNT_DETAILS_UPDATE_PHONE_PATH = `${ACCOUNT_DETAILS_PATH}/update-phone`;
export const ACCOUNT_DETAILS_UPDATE_EMAIL_PATH = `${ACCOUNT_DETAILS_PATH}/update-email`;
export const ACCOUNT_DETAILS_UPDATE_NAME_PATH = `${ACCOUNT_DETAILS_PATH}/update-name`;

export const ACCOUNT_MANAGE_APP_CREDENTIALS_PATH = `${MANAGE_ACCOUNT_PATH}/app-credentials`;
export const ACCOUNT_MANAGE_APP_CREDENTIALS_NEW_PATH = `${ACCOUNT_MANAGE_APP_CREDENTIALS_PATH}/new`;
export const ACCOUNT_MANAGE_APP_CREDENTIALS_NEW_CONFIRMATION_PATH = `${ACCOUNT_MANAGE_APP_CREDENTIALS_PATH}/:appCredentials/new-confirmation`;
export const ACCOUNT_MANAGE_APP_CREDENTIALS_RESET_CONFIRMATION_PATH = `${ACCOUNT_MANAGE_APP_CREDENTIALS_PATH}/:appCredentials/reset-confirmation`;
export const ACCOUNT_MANAGE_APP_CREDENTIALS_DELETE_PATH = `${ACCOUNT_MANAGE_APP_CREDENTIALS_PATH}/:appCredentials/delete`;

export const ACCOUNT_MANAGE_LINKED_ACCOUNTS_PATH = `${MANAGE_ACCOUNT_PATH}/linkedaccounts`;
export const ACCOUNT_MANAGE_LINKED_ACCOUNTS_CONNECT_PATH = `${ACCOUNT_MANAGE_LINKED_ACCOUNTS_PATH}/connect`;
export const ACCOUNT_MANAGE_LINKED_ACCOUNTS_CONNECT_MANUALLY_PATH = `${ACCOUNT_MANAGE_LINKED_ACCOUNTS_CONNECT_PATH}/manually`;

export const MANAGE_ACCOUNT_LINK_ACCOUNTS_ACCOUNTS_PATH = `${ACCOUNT_MANAGE_LINKED_ACCOUNTS_PATH}/accounts/:accountId`;
export const ACCOUNT_MANAGE_LINKED_ACCOUNTS_EDIT_PATH = `${ACCOUNT_MANAGE_LINKED_ACCOUNTS_PATH}/edit`;
export const ACCOUNT_MANAGE_LINKED_ACCOUNTS_DELETE_PATH = `${ACCOUNT_MANAGE_LINKED_ACCOUNTS_PATH}/delete`;

export const CHANGE_PW_PATH = `${MANAGE_ACCOUNT_PATH}/change-password`;

//CREDIT
export const PATH_ACCOUNT_CREDIT_DIGITAL_ISSUANCE = `${PATH_ACCOUNT}/${CREDIT_SUBPATH}/digital-issuance`;

// OCC
const ONE_CLICK_CREDIT_PATH = `${PATH_ACCOUNT}/one-click-credit`;
export const FINANCIAL_INSIGHTS_PATH = `${ONE_CLICK_CREDIT_PATH}/financial-insights`;
export const CREDIT_MONITORING_PATH = `${ONE_CLICK_CREDIT_PATH}/credit-monitoring`;
export const CREDIT_MONITORING_VIEW_ALL_PATH = `${CREDIT_MONITORING_PATH}/account-history`;
export const ACCOUNT_ONE_CLICK_CREDIT_OFFERS_OFFER_ID_PATH = `${ONE_CLICK_CREDIT_PATH}/offers/:offerId`;

// LINK FOR USERS TO SHARE
export const BASE_REFERRAL_LINK = `hmb.to/signup?code=`;
export const CREDIT_PAYMENT_PATH = `${CREDIT_ACCOUNT_BASE_PATH}/:cardId/payment`;
export const CREDIT_REWARDS_PATH = `${CREDIT_ACCOUNT_BASE_PATH}/:cardId/rewards`;

export const ACCOUNT_REFERRALS_PATH = `${PATH_ACCOUNT}/referrals`;

const ACCOUNT_MANAGE_BENEFICIARY = `${MANAGE_ACCOUNT_PATH}/beneficiary-h/:ledgerId`;
export const ACCOUNT_MANAGE_BENEFICIARY_ADD = `${ACCOUNT_MANAGE_BENEFICIARY}/add`;

// NYCB
export const NYCB_PROMO = `https://hmb.to/NYCB-promo`;
export const ACCOUNT_MANAGE_BENEFICIARY_REMOVE = `${ACCOUNT_MANAGE_BENEFICIARY}/remove`;

const NYCB_ACCOUNT_MANAGE_BENEFICIARY = `${MANAGE_ACCOUNT_PATH}/beneficiary/:depositAccountId`;
export const NYCB_ACCOUNT_MANAGE_BENEFICIARY_ADD = `${NYCB_ACCOUNT_MANAGE_BENEFICIARY}/add`;
export const NYCB_ACCOUNT_MANAGE_BENEFICIARY_REMOVE = `${NYCB_ACCOUNT_MANAGE_BENEFICIARY}/remove/:beneficiaryId`;
export const NYCB_TRANSFER_PATH = `${DEPOSIT_ACCOUNT_BASE_PATH}/transfers`;

export const NYCB_DEPOSIT_STATEMENTS_PATH = `${PATH_ACCOUNT}/${DEPOSIT_SUBPATH}/:depositAccountId/statements`;
export const PATH_SUPPORT = 'https://hmb.to/support-topics';
export const PATH_APP_STORE = 'https://apps.apple.com/us/app/hmbradley-save-spend-borrow/id1513277120';

export const PATH_ROUTINES = `${PATH_ACCOUNT}/routines`;
export const PATH_ROUTINES_NEW = `${PATH_ACCOUNT}/routines/new`;
export const PATH_ROUTINES_NEW_TEMPLATE = `${PATH_ACCOUNT}/routines/new/:templateId`;
export const PATH_ROUTINES_EDIT = `${PATH_ACCOUNT}/routines/:routineId/edit`;
export const PATH_ROUTINES_LIST = `${PATH_ACCOUNT}/routines/list`;
export const PATH_ROUTINES_TERMS = `${PATH_ACCOUNT}/routines/terms-of-use`;

export const PATH_ADD_AUTHORIZED_USER = `${PATH_ACCOUNT}/add-authorized-user`;

// Deposit application
export const PATH_DEPOSIT_APPLICATION_ENTRY = `/account/deposit/open/entry`;
export const PATH_DEPOSIT_APPLICATION_INDIVIDUAL = `/account/deposit/open/nycb`;
export const PATH_DEPOSIT_APPLICATION_JOINT_INVITE = `/account/deposit/open/joint-invite`;
export const PATH_DEPOSIT_APPLICATION_JOINT_ACCEPT = `/account/deposit/open/joint-accept`;
export const PATH_ACCOUNT_ACTIVATION = `/account/deposit/:depositAccountId/account-activation`;
