import {
  OdfiTransferType,
  RoutineTemplateFragment,
  TemplateActionFragment,
  TemplateTriggerFragment,
  TriggerTypes,
} from '@/generated/graphql';
import { useSegmentAnalyticsContext } from '@/hooks/use-segment-analytics-context';
import { HardPull } from '@/routes/account/routes/one-click-credit/types';

// TODO: Remove `?` from the call.
// We need to find a way to wait for the analytics to be loaded first. This is a temporary fix. The downside of that
// is that forcing the user to wait for a spinner or something like for analytics to load is not ideal.
// Ideally, the 3rd party dependency should buffer the calls and only send them when the analytics is loaded,
// instead us doing all that logic in our app.

export function useIdentifyAnalytics() {
  const analyticsContext = useSegmentAnalyticsContext();
  return (userId: string, traits: any) => {
    return analyticsContext.analytics?.identify(userId, traits);
  };
}

export function useTrackAnalyticsPage() {
  const analyticsContext = useSegmentAnalyticsContext();
  return (url: string) => {
    return analyticsContext.analytics?.page(url);
  };
}

export function useResetAnalytics() {
  const analyticsContext = useSegmentAnalyticsContext();
  return () => {
    return analyticsContext.analytics?.reset();
  };
}

export function useTrackReferralCodeV2Copied() {
  const analyticsContext = useSegmentAnalyticsContext();
  return (properties: { referral_code: string }) => {
    return analyticsContext.analytics?.track('Referral Code V2 Copied', properties);
  };
}

export function useTrackOccFunnelEvents() {
  const analyticsContext = useSegmentAnalyticsContext();
  return (properties: { cta_id: string; position?: string }) => {
    return analyticsContext.analytics?.track('OCC Funnel CTA Clicked', properties);
  };
}

export function useTrackOnboardingEvents() {
  const analyticsContext = useSegmentAnalyticsContext();
  return (event: string, properties?: { email?: string; url?: string; utmTags?: {}; cta_id?: string }) => {
    return analyticsContext.analytics?.track(event, properties);
  };
}

export function useTrackSeeOfferClick() {
  const analyticsContext = useSegmentAnalyticsContext();
  return (properties: { location: string; creditLine: number; offerID: string; interestRate: string }) => {
    return analyticsContext.analytics?.track('Credit Offer Clicked', {
      location: properties.location,
      apr: properties.interestRate,
      limit: properties.creditLine,
      offerId: properties.offerID,
      category: 'creditOnboarding',
      action: 'creditOfferClicked',
    });
  };
}

export function useTrackCreditOptedIn() {
  const analyticsContext = useSegmentAnalyticsContext();
  return (properties: { category: string; action: string }) => {
    return analyticsContext.analytics?.track('Credit Opted In', properties);
  };
}

export function useTrackCreditOfferAccepted() {
  const analyticsContext = useSegmentAnalyticsContext();
  return (properties: {
    category: string;
    action: string;
    apr: number;
    limit: string;
    hardPull: HardPull;
    offerId: string;
    cardColorSelected: string;
  }) => {
    return analyticsContext.analytics?.track('Credit Offer Accepted', properties);
  };
}

export function useTrackPersonCreated() {
  const analyticsContext = useSegmentAnalyticsContext();
  return (properties: { category: string; action: string }) => {
    return analyticsContext.analytics?.track('Person Created', properties);
  };
}

export function useTrackRoutineDrafted() {
  const analyticsContext = useSegmentAnalyticsContext();
  return (properties: { template: RoutineTemplateFragment }) => {
    return analyticsContext.analytics?.track('Routine Drafted', mapTemplateToSegment(properties.template));
  };
}

export function useTrackRoutineDraftCompleted() {
  const analyticsContext = useSegmentAnalyticsContext();
  return (properties: { template: RoutineTemplateFragment }) => {
    return analyticsContext.analytics?.track('Routine Draft Completed', mapTemplateToSegment(properties.template));
  };
}

export function useTrackRoutineDraftDeleted() {
  const analyticsContext = useSegmentAnalyticsContext();
  return (properties: { template: RoutineTemplateFragment }) => {
    return analyticsContext.analytics?.track('Routine Draft Deleted', mapTemplateToSegment(properties.template));
  };
}

function mapTemplateToSegment(template: RoutineTemplateFragment) {
  return {
    templateID: template.id,
    name: template.title,
    description: template.description,
    actionType: getActionType(template.action),
    actionVersion: getActionVersion(template.action),
    amountInCents: getActionAmount(template.action),
    fromAccountType: getActionFromAccountType(template.action),
    toAccountType: getActionToAccountType(template.action),
    transactionCategory: getTriggerUserCategory(template.trigger),
    transactionDescription: getTriggerDescription(template.trigger),
    transactionType: getTriggerCategory(template.trigger),
    triggerType: getTriggerType(template.trigger),
    triggerVersion: getTriggerVersion(template.trigger),
  };
}

function getTriggerDescription(trigger: TemplateTriggerFragment) {
  if (trigger.__typename == 'DescriptionTrigger') return trigger.options.description;
  return null;
}

function getTriggerUserCategory(trigger: TemplateTriggerFragment) {
  if (trigger.__typename == 'UserCategoryTrigger') return trigger.options.userCategory;
  return null;
}

function getTriggerCategory(trigger: TemplateTriggerFragment) {
  if (trigger.__typename == 'CategoryTrigger') return trigger.options.category;
  return null;
}

function getTriggerVersion(trigger: TemplateTriggerFragment) {
  if ('version' in trigger) return trigger.version;
  return null;
}

function getTriggerType(trigger: TemplateTriggerFragment) {
  if ('type' in trigger) return trigger.type;
  return null;
}
function getActionVersion(action: TemplateActionFragment) {
  if ('version' in action) return action.version;
  return null;
}

function getActionType(action: TemplateActionFragment) {
  if ('type' in action) return action.type;
  return null;
}

function getActionAmount(action: TemplateActionFragment): number {
  if (action.__typename == 'PlanTransferAction') return action.planTransferActionOptions.amountInCents;
  if (action.__typename == 'InternalTransferAction') return action.internalTransferActionOptions.amountInCents;
  if (action.__typename == 'ExternalTransferAction') return action.externalTransferAction.amountInCents;
  if (action.__typename == 'MultiAction')
    return action.options.actions.reduce((acc, val) => getActionAmount(val) + acc, 0);
  return 0;
}

function getActionToAccountType(action: TemplateActionFragment): string {
  if (action.__typename == 'PlanTransferAction') return action.planTransferActionOptions.toAccountType;
  if (action.__typename == 'CashbackRedemptionAction') return 'Deposit';
  if (action.__typename == 'InternalTransferAction') return 'Deposit';
  if (action.__typename == 'ExternalTransferAction')
    return action.externalTransferAction.type == OdfiTransferType.Pull ? 'Deposit' : 'External';
  if (action.__typename == 'MultiAction')
    return action.options.actions.reduce((acc: string, val) => getActionToAccountType(val) ?? acc, null);
  return null;
}

function getActionFromAccountType(action: TemplateActionFragment): string {
  if (action.__typename == 'PlanTransferAction') return action.planTransferActionOptions.fromAccountType;
  if (action.__typename == 'CashbackRedemptionAction') return 'Cashback';
  if (action.__typename == 'InternalTransferAction') return 'Deposit';
  if (action.__typename == 'ExternalTransferAction')
    return action.externalTransferAction.type == OdfiTransferType.Pull ? 'External' : 'Deposit';
  if (action.__typename == 'MultiAction')
    return action.options.actions.reduce((acc: string, val) => getActionFromAccountType(val) ?? acc, null);
  return null;
}
