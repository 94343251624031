import clsx from 'clsx';
import React from 'react';

export function PlanTypeOption(props: { onClick: () => void; name: string; color: string; isSelected: boolean }) {
  const [isHover, setIsHover] = React.useState(false);

  function onMouseEnter() {
    setIsHover(true);
  }

  function onMouseLeave() {
    setIsHover(false);
  }

  const isActive = isHover || props.isSelected;

  const style = isActive
    ? {
        backgroundColor: props.color,
        borderColor: props.color,
        color: '#fff',
      }
    : undefined;

  return (
    <div
      role="button"
      onClick={props.onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={clsx('w-fit mt-3.5 mr-3.5 text-base rounded-md cursor-pointer border-1 py-3 px-4 duration-300', {
        'border-gray-200 text-gray-950': !isActive,
      })}
      style={style}
    >
      {props.name}
    </div>
  );
}
