export const CurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const CurrencyNoDecimalFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export const InterestRateNumberFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  useGrouping: false,
  minimumFractionDigits: 2,
  maximumFractionDigits: 10,
});

// Format: $0, $1, $200, $1K, $15K
export const CurrencyCompactFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  notation: 'compact',
});
