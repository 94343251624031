import { PageTitle } from '@/components/page-title';
import { HOME_LOGGED_OUT_PATH } from '@/constants/routes';
import Link from 'next/link';
import { BgLines } from './components/bg-lines';

const NOT_FOUND_ERROR = '404';

type BaseErrorProps = {
  type: string;
};

export function BaseError(props: BaseErrorProps) {
  const Desc404 = () => (
    <>
      The page that you’re looking for doesn’t exist.
      <Link
        href={HOME_LOGGED_OUT_PATH}
        className="my-0 mx-xs no-underline text-base font-medium text-blue hover:text-grey-dark"
      >
        Click here
      </Link>
      to return home.
    </>
  );

  const Desc500 = () => (
    <>
      Looks like we’re having some trouble with our application. We&apos;re hard at work to resolve this issue. Please
      check back in shortly!
    </>
  );
  const is404 = props.type === NOT_FOUND_ERROR;
  const title = is404 ? 'Page not found' : 'Server error';

  return (
    <div className="w-screen h-screen">
      <PageTitle title={title} />
      <div className="flex items-center justify-around w-full h-full overflow-hidden">
        <div className="relative flex flex-col justify-center w-3/5 h-full pl-[20px] sm:pl-20">
          <h2 className="relative font-medium font-serif text-[96px] mb-0 leading-tight w-fit">
            {is404 ? 404 : 500}
            <div className="absolute w-sm h-screen bg-red -top-[150%] origin-right hidden md:block falling-line" />
          </h2>

          <p className="font-medium text-lg mb-sm">{is404 ? 'Page not found' : 'Server error'}</p>
          <p className="text-base font-light max-w-[270px]">{is404 ? <Desc404 /> : <Desc500 />}</p>
        </div>
        <div className="w-2/5 pl-[70px] h-full hidden md:block">
          <BgLines
            className="flex h-full justify-end"
            color="#EB5F2D"
            lines={[
              ['15px', 67],
              ['36px', 52],
              ['52px', 36],
              ['67px', 15],
              ['88px', 7.8],
              ['104px', 0],
            ]}
          />
        </div>
      </div>
    </div>
  );
}
