import { Button, ButtonProps, BUTTON_COLORS } from '@/components/buttons-main/button';
import { FeatureFlag } from '@/components/feature-flag';
import { FEATURE_FLAG_GATED_REFERRALS } from '@/constants/features';
import {
  ACCOUNT_DETAILS_PATH,
  ACCOUNT_MANAGE_APP_CREDENTIALS_PATH,
  ACCOUNT_MANAGE_CARD_PATH,
  ACCOUNT_MANAGE_LINKED_ACCOUNTS_PATH,
  ACCOUNT_REFERRALS_PATH,
  CREDIT_STATEMENTS_PATH,
  NYCB_DEPOSIT_STATEMENTS_PATH,
} from '@/constants/routes';
import { useGetDepositAccountsQuery } from '@/generated/graphql';
import { byAccountId, isCreditAccount } from '@/helpers';
import { routeFor } from '@/helpers/router';
import { useRouteType } from '@/hooks/use-route-type';
import { useLogout } from '@/hooks/use-session';
import { useAuthenticatedSessionContext } from '@/hooks/use-session-context';
import { httpClient } from '@/transports/http';
import { useGetCardV1Cards, useGetLedgerV2Accounts } from '@core/mainframe-react-query';
import { Menu, Transition } from '@headlessui/react';
import { default as clsx, default as clx } from 'clsx';
import { useRouter } from 'next/router';
import * as React from 'react';

function ProfileSVG() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="9" stroke="black" strokeWidth="2" />
      <circle cx="12" cy="10" r="3" stroke="black" strokeWidth="2" />
      <path
        d="M18 18C18 18 16 16 12 16C8 16 6 18 6 18L7.5 19.5C7.5 19.5 9 18 12 18C15 18 16.5 19.5 16.5 19.5L18 18Z"
        fill="black"
      />
    </svg>
  );
}

export function UserMenuDropdown(props: { open: boolean }) {
  const sessionContext = useAuthenticatedSessionContext();
  return (
    <div className="m-2">
      <Menu.Button className="w-full px-4 py-2 text-sm font-medium text-black-pure border-1 border-gray-100 rounded-lg hover:bg-opacity-70 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        <div className="flex justify-between items-center">
          <div className="flex gap-3 items-center">
            <ProfileSVG />
            <span className="font-bold capitalize">
              {sessionContext.user.nameFirst} {sessionContext.user.nameLast}
            </span>
          </div>
          <div>
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 16"
              fill="none"
              className={clx('text-black-pure duration-300', {
                'rotate-180': props.open,
              })}
            >
              <path d="M5 14L5 2L11 8L5 14Z" fill="black" />
            </svg>
          </div>
        </div>
      </Menu.Button>
    </div>
  );
}

function AccountDetails() {
  return (
    <Menu.Item>
      {(props) => (
        <MenuItemButton active={props.active} toNext={ACCOUNT_DETAILS_PATH}>
          Account Details
        </MenuItemButton>
      )}
    </Menu.Item>
  );
}

function useDepositAccounts() {
  const router = useRouter();

  const depositAccountsNycb = useGetDepositAccountsQuery(
    {},
    {
      select(data) {
        return data.depositAccounts;
      },
    },
  );

  const [firstDeposit, setFirstDeposit] = React.useState('');

  React.useEffect(() => {
    const routerId = router.query.depositAccountId as string;

    const nycbDeposit = depositAccountsNycb.data?.find(byAccountId(routerId));
    if (nycbDeposit) {
      setFirstDeposit(nycbDeposit.id);
    } else {
      if (depositAccountsNycb.data?.[0]?.id) {
        setFirstDeposit(depositAccountsNycb.data[0].id);
      } else {
        setFirstDeposit('');
      }
    }
  }, [router.query.depositAccountId, depositAccountsNycb.data]);

  return {
    firstDeposit,
    isLoading: depositAccountsNycb.isLoading,
    numberOfAccounts: depositAccountsNycb.data?.length ?? 0,
  };
}

function useCreditAccounts() {
  const creditAccounts = useGetCardV1Cards(httpClient, {
    options: {
      select(data) {
        return data.data?.filter(isCreditAccount);
      },
    },
  });

  return {
    isLoading: creditAccounts.isLoading,
    firstCredit: creditAccounts.data?.[0]?.id,
    numberOfAccounts: creditAccounts.data?.length ?? 0,
  };
}

function Documents() {
  const legacyDeposit = useGetLedgerV2Accounts(httpClient, {});
  const controller = useDepositAccounts();
  const credit = useCreditAccounts();
  const routeType = useRouteType();

  if (!controller.isLoading && !credit.isLoading && !legacyDeposit.isLoading) {
    if ((routeType.isCredit || controller.numberOfAccounts === 0) && credit.firstCredit) {
      return (
        <Menu.Item>
          {(props) => (
            <MenuItemButton
              active={props.active}
              toNext={routeFor(CREDIT_STATEMENTS_PATH, { path: { cardId: credit.firstCredit } })}
            >
              Documents
            </MenuItemButton>
          )}
        </Menu.Item>
      );
    }

    if (controller.firstDeposit) {
      return (
        <Menu.Item>
          {(props) => (
            <MenuItemButton
              active={props.active}
              toNext={routeFor(NYCB_DEPOSIT_STATEMENTS_PATH, { path: { depositAccountId: controller.firstDeposit } })}
            >
              Documents
            </MenuItemButton>
          )}
        </Menu.Item>
      );
    }

    if (legacyDeposit.data?.data?.length > 0) {
      return (
        <Menu.Item>
          {(props) => (
            <MenuItemButton
              active={props.active}
              toNext={routeFor(NYCB_DEPOSIT_STATEMENTS_PATH, {
                path: { depositAccountId: legacyDeposit.data?.data?.[0]?.id },
              })}
            >
              Documents
            </MenuItemButton>
          )}
        </Menu.Item>
      );
    }
  }

  return (
    <Menu.Item>
      {(props) => (
        <MenuItemButton disabled active={props.active}>
          Documents
        </MenuItemButton>
      )}
    </Menu.Item>
  );
}

function ManageExternalAccounts() {
  return (
    <Menu.Item>
      {(props) => (
        <MenuItemButton active={props.active} toNext={ACCOUNT_MANAGE_LINKED_ACCOUNTS_PATH}>
          External Accounts
        </MenuItemButton>
      )}
    </Menu.Item>
  );
}

function ManageAppCredentials() {
  return (
    <Menu.Item>
      {(props) => (
        <MenuItemButton active={props.active} toNext={ACCOUNT_MANAGE_APP_CREDENTIALS_PATH}>
          App Credentials
        </MenuItemButton>
      )}
    </Menu.Item>
  );
}

function ReferFriend() {
  return (
    <Menu.Item>
      {(props) => (
        <MenuItemButton active={props.active} toNext={ACCOUNT_REFERRALS_PATH}>
          Refer a friend
        </MenuItemButton>
      )}
    </Menu.Item>
  );
}

function ManageCards() {
  return (
    <Menu.Item>
      {(props) => (
        <MenuItemButton active={props.active} toNext={ACCOUNT_MANAGE_CARD_PATH}>
          Manage Cards
        </MenuItemButton>
      )}
    </Menu.Item>
  );
}

const MenuItemButton = React.forwardRef<HTMLInputElement, { active: boolean; danger?: boolean } & ButtonProps>(
  function MenuItemButton(props, ref) {
    const { active, danger, children, disabled, ...buttonProps } = props;
    return (
      <Button
        {...buttonProps}
        color={BUTTON_COLORS.transparent}
        className={clx(
          'group flex rounded-md justify-start w-full px-2 py-2 items-center',
          danger ? 'text-red-800' : 'text-base',
          disabled && 'text-gray-300 pointer-events-none',
          {
            'bg-gray-50': active,
          },
        )}
      >
        {children}
      </Button>
    );
  },
);

function LogOut() {
  const logout = useLogout();

  return (
    <Menu.Item>
      {(props) => (
        <MenuItemButton danger active={props.active} onClick={logout.onLogout}>
          Log Out
        </MenuItemButton>
      )}
    </Menu.Item>
  );
}

/** @public */
export enum Anchor {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export function UserMenuItemList(props: { anchor?: Anchor }) {
  const deposit = useDepositAccounts();
  const credit = useCreditAccounts();

  return (
    <Transition
      as={React.Fragment}
      enter="transition ease-out duration-200"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-100"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={clsx(
          props.anchor == Anchor.LEFT ? 'bottom-8 ml-2 left-60' : 'shadow-xl top-16 right-4',
          'absolute mt-1.5 bg-white rounded-lg shadow-lg ring-1 z-50 ring-black ring-opacity-5 focus:outline-none',
        )}
      >
        <div className="px-2 py-2">
          <AccountDetails />
          <Documents />
          {credit.numberOfAccounts + deposit.numberOfAccounts > 0 && <ManageExternalAccounts />}
          <ManageAppCredentials />

          <ManageCards />
          <FeatureFlag flag={FEATURE_FLAG_GATED_REFERRALS}>
            <ReferFriend />
          </FeatureFlag>
          <LogOut />
        </div>
      </Menu.Items>
    </Transition>
  );
}
