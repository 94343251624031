import { CREDIT_ACCOUNT_BASE_PATH } from '@/constants/routes';
import { useRouter } from 'next/router';

enum RouteType {
  Credit,
  Debit,
}

export function useRouteType() {
  const router = useRouter();
  const routeType = router.pathname.includes(CREDIT_ACCOUNT_BASE_PATH) ? RouteType.Credit : RouteType.Debit;

  return {
    isCredit: routeType === RouteType.Credit,
    isDeposit: routeType === RouteType.Debit,
  };
}
