export const commandPaletteAtomTheme = {
  modal:
    'absolute w-96 md:w-2/5 top-28 left-0 right-0 bottom-auto border-0 bg-black-lighter overflow-hidden rounded outline-none p-sm mr-auto ml-auto',
  overlay: 'fixed top-0 left-0 w-full h-full bg-black-lighter z-5000 bg-opacity-70',
  header: 'text-grey-lighter',
  container: 'text-xs font-light font-sans pt-xs',
  content:
    'absolute min-w-96 md:min-w-2/5 top-28 left-0 right-0 bottom-auto border-0 bg-black overflow-hidden rounded outline-none pr-sm mr-auto ml-auto',
  input: 'text-base rounded borer-2 border-blue-lightest w-full p-sm text-white bg-dark caret-blue-lightest',
  inputFocused: 'border-2 border-blue-600 bg-black',
  spinner: 'border-1 border-solid border-white-dark',
  suggestionsContainer: 'h-full w-full',
  suggestionsContainerOpen: 'overflow-hidden border-t-1 border-b-1 border-black mt-sm ml-xs',
  suggestionsList: 'list-none p-0 mb-0 mt-0',
  suggestion: 'command-palette-atom-suggestion',
  suggestionHighlighted: 'text-white bg-black-inactive',
  trigger: 'hidden',
};

export enum CommandScope {
  Credit,
  Deposit,
}

export type PaletteCommand = {
  name: string;
  scopes: CommandScope[];
  command(): void;
};

export function filterScope(flags: { hasCreditAccount: boolean; hasDepositAccount: boolean }) {
  return (command: PaletteCommand) => {
    if (command.scopes.includes(CommandScope.Credit)) {
      return flags.hasCreditAccount;
    }
    if (command.scopes.includes(CommandScope.Deposit)) {
      return flags.hasDepositAccount;
    }
    return true;
  };
}
