import { useReactQueryClient } from '@/hooks/use-react-query-client';
import * as React from 'react';
import { QueryClientProvider, QueryClientProviderProps } from 'react-query';

type ReactQueryProviderProps = React.PropsWithChildren<Omit<QueryClientProviderProps, 'client'>>;

export function ReactQueryProvider(props: ReactQueryProviderProps) {
  const queryClient = useReactQueryClient();
  return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
}
