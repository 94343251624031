import { isNil } from '@/helpers/is-nil';
import { useSessionContext } from '@/hooks/use-session-context';
import { useContext } from 'react';
import { FlagsContext } from 'react-unleash-flags';
import { getUsersIdFromStrategies, isUserStrategy, Strategies } from '../helpers';

const ENABLED = {
  isEnabled: true,
};

const DISABLED = {
  isEnabled: false,
};

export function useFeatureWithUserStrategies(flag: string) {
  const sessionContext = useSessionContext();
  const feature = useContext(FlagsContext)?.getFlag(flag);

  if (!feature?.enabled) {
    return DISABLED;
  }

  if (sessionContext.isAuthenticated && feature.strategies.some(isUserStrategy)) {
    return getUsersIdFromStrategies(feature.strategies as Strategies).includes(sessionContext.user?.userID as string)
      ? ENABLED
      : DISABLED;
  }

  if (feature.enabled) {
    return ENABLED;
  }

  return DISABLED;
}

export function useFeatureWithLoading(flag: string) {
  const sessionContext = useSessionContext();
  const feature = useContext(FlagsContext)?.getFlag(flag);

  if (isNil(feature)) {
    return { isLoading: true };
  }

  if (!feature.enabled) {
    return { isLoading: false, isEnabled: false };
  }

  if (sessionContext.isAuthenticated && feature.strategies.some(isUserStrategy)) {
    return getUsersIdFromStrategies(feature.strategies as Strategies).includes(sessionContext.user?.userID as string)
      ? { isLoading: false, isEnabled: true }
      : { isLoading: false, isEnabled: false };
  }

  if (feature.enabled) {
    return { isLoading: false, isEnabled: true };
  }

  return { isLoading: false, isEnabled: false };
}
