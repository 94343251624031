import { fetcher } from '@/graphql/fetcher';
import { graphql, GraphQLContext, GraphQLRequest, ResponseResolver } from 'msw';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AmountInCents: number;
  Date: string;
  DateTime: string;
  Json: any;
  Percentage: number;
  TransferAmountInCents: number;
  Uuid: string;
  Vgstoken: string;
};

export type AcceptJointAccountInvitePayload = {
  __typename?: 'AcceptJointAccountInvitePayload';
  inviteId?: Maybe<Scalars['Uuid']>;
};

export enum AccountSource {
  Manual = 'MANUAL',
  Mx = 'MX',
  Plaid = 'PLAID',
}

export type Action =
  | CashbackRedemptionAction
  | CategorizeAction
  | CreditCardPaymentAction
  | ExternalTransferAction
  | InternalTransferAction
  | MultiAction
  | MultiPlanTransferAction
  | NotificationAction
  | PlanTransferAction
  | WebhookAction;

/** A tagged union type of all possible actions */
export type ActionInput = {
  cashbackRedemptionAction?: InputMaybe<CashbackRedemptionActionInput>;
  categorizeAction?: InputMaybe<CategorizeActionInput>;
  creditCardPaymentAction?: InputMaybe<CreditCardPaymentActionInput>;
  externalTransferAction?: InputMaybe<ExternalTransferActionInput>;
  internalTransferAction?: InputMaybe<InternalTransferActionInput>;
  multiAction?: InputMaybe<MultiActionInput>;
  multiPlanTransferAction?: InputMaybe<MultiPlanTransferActionInput>;
  notificationAction?: InputMaybe<NotificationActionInput>;
  planTransferAction?: InputMaybe<PlanTransferActionInput>;
  type?: InputMaybe<ActionTypes>;
  webhookAction?: InputMaybe<WebhookActionInput>;
};

export enum ActionTypes {
  CashbackRedemption = 'CASHBACK_REDEMPTION',
  Categorize = 'CATEGORIZE',
  CreditCardPayment = 'CREDIT_CARD_PAYMENT',
  ExternalTransfer = 'EXTERNAL_TRANSFER',
  InternalTransfer = 'INTERNAL_TRANSFER',
  MultiAction = 'MULTI_ACTION',
  MultiPlanTransfer = 'MULTI_PLAN_TRANSFER',
  Notification = 'NOTIFICATION',
  PlanTransfer = 'PLAN_TRANSFER',
  Webhook = 'WEBHOOK',
}

export type AddTransactionCategoryPayload = {
  __typename?: 'AddTransactionCategoryPayload';
  categorizationId?: Maybe<Scalars['Uuid']>;
};

/** APY info for a deposit account */
export type Apy = {
  __typename?: 'Apy';
  apyInterestRate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApyTracking = {
  __typename?: 'ApyTracking';
  depositedAmount?: Maybe<ApyTrackingValue>;
};

export type ApyTrackingDepositedAmountArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

/** APY tracking value */
export type ApyTrackingValue = {
  __typename?: 'ApyTrackingValue';
  currentValue?: Maybe<Scalars['AmountInCents']>;
  isWaived?: Maybe<Scalars['Boolean']>;
  onTrackToMeetRequirement?: Maybe<Scalars['Boolean']>;
  waivedUntil?: Maybe<Scalars['Date']>;
};

export type ArchiveExternalAccountPayload = {
  __typename?: 'ArchiveExternalAccountPayload';
  status?: Maybe<Scalars['String']>;
};

export type ArchivePlanPayload = {
  __typename?: 'ArchivePlanPayload';
  planId?: Maybe<Scalars['Uuid']>;
};

export type ArchiveTransactionCategoryPayload = {
  __typename?: 'ArchiveTransactionCategoryPayload';
  categorizationId?: Maybe<Scalars['Uuid']>;
};

export type AssignDepositAccountBeneficiaryPayload = {
  __typename?: 'AssignDepositAccountBeneficiaryPayload';
  id?: Maybe<Scalars['Uuid']>;
};

/** The fields needed for a Balance Trigger */
export type BalanceTrigger = {
  __typename?: 'BalanceTrigger';
  options: BalanceTriggerOptions;
  type: TriggerTypes;
  version: Scalars['String'];
};

/** The fields needed for a Balance Trigger */
export type BalanceTriggerInput = {
  options: BalanceTriggerOptionsInput;
  type: TriggerTypes;
  version: Scalars['String'];
};

export type BalanceTriggerOptions = {
  __typename?: 'BalanceTriggerOptions';
  amountInCents: Scalars['Int'];
  comparison: ComparisonTypes;
  depositAccountId: Scalars['Uuid'];
};

export type BalanceTriggerOptionsInput = {
  amountInCents: Scalars['Int'];
  comparison: ComparisonTypes;
  depositAccountId: Scalars['Uuid'];
};

/** The result of cancelling a scheduled internal transfer */
export type CancelInternalTransferScheduledPayload = {
  __typename?: 'CancelInternalTransferScheduledPayload';
  status?: Maybe<Scalars['String']>;
};

export type CancelJointAccountInvitePayload = {
  __typename?: 'CancelJointAccountInvitePayload';
  inviteId?: Maybe<Scalars['Uuid']>;
};

/** The result of cancelling a scheduled odfi transfer */
export type CancelOdfiTransferScheduledPayload = {
  __typename?: 'CancelOdfiTransferScheduledPayload';
  status?: Maybe<Scalars['String']>;
};

/** The result of cancelling a pending odfi transfer */
export type CancelPendingOdfiTransferPayload = {
  __typename?: 'CancelPendingOdfiTransferPayload';
  status?: Maybe<Scalars['String']>;
};

export type CashbackRedemptionAction = {
  __typename?: 'CashbackRedemptionAction';
  options: CashbackRedemptionOptions;
  type: ActionTypes;
  version: Scalars['String'];
};

export type CashbackRedemptionActionInput = {
  options: CashbackRedemptionOptionsInput;
  type: ActionTypes;
  version: Scalars['String'];
};

export type CashbackRedemptionOptions = {
  __typename?: 'CashbackRedemptionOptions';
  depositAccountId?: Maybe<Scalars['Uuid']>;
  externalAccountId?: Maybe<Scalars['Uuid']>;
};

export type CashbackRedemptionOptionsInput = {
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  externalAccountId?: InputMaybe<Scalars['Uuid']>;
};

/** The fields needed for a Cashback Redemption Trigger */
export type CashbackRedemptionTrigger = {
  __typename?: 'CashbackRedemptionTrigger';
  options: CashbackRedemptionTriggerOptions;
  type: TriggerTypes;
  version: Scalars['String'];
};

/** The fields needed for a Cashback Redemption Trigger */
export type CashbackRedemptionTriggerInput = {
  options: CashbackRedemptionTriggerOptionsInput;
  type: TriggerTypes;
  version: Scalars['String'];
};

export type CashbackRedemptionTriggerOptions = {
  __typename?: 'CashbackRedemptionTriggerOptions';
  accountId: Scalars['Uuid'];
  amount: Scalars['Int'];
};

export type CashbackRedemptionTriggerOptionsInput = {
  accountId: Scalars['Uuid'];
  amount: Scalars['Int'];
};

export type CategorizeAction = {
  __typename?: 'CategorizeAction';
  options: CategorizeOptions;
  type: ActionTypes;
  version: Scalars['String'];
};

export type CategorizeActionInput = {
  options: CategorizeOptionsInput;
  type: ActionTypes;
  version: Scalars['String'];
};

export type CategorizeOptions = {
  __typename?: 'CategorizeOptions';
  category: TransactionCategories;
  depositAccountId: Scalars['Uuid'];
};

export type CategorizeOptionsInput = {
  category: TransactionCategories;
  depositAccountId: Scalars['Uuid'];
};

/** The fields needed for a Category Trigger */
export type CategoryTrigger = {
  __typename?: 'CategoryTrigger';
  options: CategoryTriggerOptions;
  type: TriggerTypes;
  version: Scalars['String'];
};

/** The fields needed for a Category Trigger */
export type CategoryTriggerInput = {
  options: CategoryTriggerOptionsInput;
  type: TriggerTypes;
  version: Scalars['String'];
};

export type CategoryTriggerOptions = {
  __typename?: 'CategoryTriggerOptions';
  category: CategoryTypes;
  depositAccountId: Scalars['Uuid'];
};

export type CategoryTriggerOptionsInput = {
  category: CategoryTypes;
  depositAccountId: Scalars['Uuid'];
};

export enum CategoryTypes {
  CashbackRedemption = 'CASHBACK_REDEMPTION',
  DirectDeposit = 'DIRECT_DEPOSIT',
  InterestPosting = 'INTEREST_POSTING',
}

export enum ComparisonTypes {
  Gt = 'GT',
  Lt = 'LT',
}

export type ConfirmPiiPayload = {
  __typename?: 'ConfirmPiiPayload';
  piiChangeRequestId?: Maybe<Scalars['Uuid']>;
};

/** Fields needed for a consent */
export type Consent = {
  consentType?: InputMaybe<Scalars['String']>;
  consentedAt?: InputMaybe<Scalars['DateTime']>;
  policyId?: InputMaybe<Scalars['Uuid']>;
  policyName?: InputMaybe<Scalars['String']>;
  policyVersionId?: InputMaybe<Scalars['Uuid']>;
};

/** Fields needed for a consent */
export type Consents = {
  consentType?: InputMaybe<Scalars['String']>;
  consentedAt?: InputMaybe<Scalars['DateTime']>;
  entityId?: InputMaybe<Scalars['Uuid']>;
  entityType?: InputMaybe<Scalars['String']>;
  policyId?: InputMaybe<Scalars['Uuid']>;
  policyName?: InputMaybe<Scalars['String']>;
  policyVersionId?: InputMaybe<Scalars['Uuid']>;
};

export type CreateNewOdfiTransferPayload = {
  __typename?: 'CreateNewOdfiTransferPayload';
  odfiAchTransferId?: Maybe<Scalars['String']>;
};

export type CreatePlaidLinkTokenPayload = {
  __typename?: 'CreatePlaidLinkTokenPayload';
  expiration?: Maybe<Scalars['String']>;
  linkToken?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
};

export type CreatePlanPayload = {
  __typename?: 'CreatePlanPayload';
  planId?: Maybe<Scalars['Uuid']>;
};

export type CreateRoutinePayload = {
  __typename?: 'CreateRoutinePayload';
  routineId?: Maybe<Scalars['Uuid']>;
};

export enum CreditCardFromAccountTypes {
  Deposit = 'DEPOSIT',
  External = 'EXTERNAL',
}

export type CreditCardPaymentAction = {
  __typename?: 'CreditCardPaymentAction';
  options: CreditCardPaymentOptions;
  type: ActionTypes;
  version: Scalars['String'];
};

export type CreditCardPaymentActionInput = {
  options: CreditCardPaymentOptionsInput;
  type: ActionTypes;
  version: Scalars['String'];
};

/** Credit Card Payment Balance Types */
export enum CreditCardPaymentBalanceTypes {
  CurrentBalance = 'CURRENT_BALANCE',
  Custom = 'CUSTOM',
  MinimumDue = 'MINIMUM_DUE',
  RemainingStatementBalance = 'REMAINING_STATEMENT_BALANCE',
}

export type CreditCardPaymentOptions = {
  __typename?: 'CreditCardPaymentOptions';
  creditCardAccountId: Scalars['Uuid'];
  customAmountInCents?: Maybe<Scalars['Int']>;
  fromAccountId: Scalars['Uuid'];
  fromAccountType: CreditCardFromAccountTypes;
  paymentBalancePercentage?: Maybe<Scalars['Percentage']>;
  paymentBalanceType?: Maybe<CreditCardPaymentBalanceTypes>;
};

export type CreditCardPaymentOptionsInput = {
  creditCardAccountId: Scalars['Uuid'];
  customAmountInCents?: InputMaybe<Scalars['Int']>;
  fromAccountId: Scalars['Uuid'];
  fromAccountType: CreditCardFromAccountTypes;
  paymentBalancePercentage?: InputMaybe<Scalars['Percentage']>;
  paymentBalanceType?: InputMaybe<CreditCardPaymentBalanceTypes>;
};

export type CustomerExternalAccountCheckPayload = {
  __typename?: 'CustomerExternalAccountCheckPayload';
  accountBalancesSumInCents?: Maybe<Scalars['Int']>;
  accruedBalanceInCents?: Maybe<Scalars['Int']>;
  externalAccountRequired?: Maybe<Scalars['String']>;
};

/** The fields needed for a Date Trigger */
export type DateTrigger = {
  __typename?: 'DateTrigger';
  options: DateTriggerOptions;
  type: TriggerTypes;
  version: Scalars['String'];
};

export type DateTriggerInput = {
  options: DateTriggerOptionsInput;
  type: TriggerTypes;
  version: Scalars['String'];
};

export type DateTriggerOptions = {
  __typename?: 'DateTriggerOptions';
  pattern: Scalars['String'];
};

export type DateTriggerOptionsInput = {
  pattern: Scalars['String'];
};

export type DeclineJointAccountInvitePayload = {
  __typename?: 'DeclineJointAccountInvitePayload';
  inviteId?: Maybe<Scalars['Uuid']>;
};

export type DepositAccount = {
  __typename?: 'DepositAccount';
  accountStatus?: Maybe<DepositAccountStatus>;
  accountType?: Maybe<DepositAccountType>;
  /** Get the APY values for a given account */
  apy?: Maybe<Apy>;
  /** Get deposit account balance */
  balanceInCents?: Maybe<Scalars['AmountInCents']>;
  /** Single beneficiary of the deposit account */
  beneficiary?: Maybe<DepositAccountBeneficiary>;
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Get all balances for a deposit account */
  depositAccountBalances?: Maybe<DepositAccountBalances>;
  /** Get account number of the deposit account */
  depositAccountNumber?: Maybe<DepositAccountNumber>;
  /** List deposit account owners */
  depositAccountOwners?: Maybe<Array<Maybe<DepositAccountOwner>>>;
  id?: Maybe<Scalars['ID']>;
  /** Get the interest values for a given account */
  interest?: Maybe<Interest>;
  isPendingJointAccount?: Maybe<Scalars['Boolean']>;
  /** Get the ODFI transfer limit for a given account */
  odfiTransferLimit?: Maybe<OdfiTransferLimit>;
  openedAt?: Maybe<Scalars['DateTime']>;
  pendingJointAccountInvite?: Maybe<JointAccountInvite>;
  plans?: Maybe<Array<Maybe<Plan>>>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  /** List deposit account statements */
  statements?: Maybe<Array<Maybe<DepositAccountStatement>>>;
  /** List deposit account 1099-INT statements */
  taxDocuments?: Maybe<Array<Maybe<DepositAccount1099>>>;
  /** List the transactions of the deposit account */
  transactions?: Maybe<Array<Maybe<Transaction>>>;
};

export type DepositAccountTransactionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TransactionStatus>;
  statuses?: InputMaybe<Array<InputMaybe<TransactionStatus>>>;
  transferType?: InputMaybe<TransferType>;
};

/** Deposit account 1099-INT statement */
export type DepositAccount1099 = {
  __typename?: 'DepositAccount1099';
  fileKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  requestType?: Maybe<Scalars['String']>;
  taxYear?: Maybe<Scalars['Int']>;
  tempUrl?: Maybe<Scalars['String']>;
};

/** Deposit Account Balances */
export type DepositAccountBalances = {
  __typename?: 'DepositAccountBalances';
  availableBalanceInCents?: Maybe<Scalars['AmountInCents']>;
  balanceInCents?: Maybe<Scalars['AmountInCents']>;
  pendingBalanceInCents?: Maybe<Scalars['AmountInCents']>;
  totalPlansBalance?: Maybe<Scalars['AmountInCents']>;
};

/** Beneficiary attached to a Deposit Account */
export type DepositAccountBeneficiary = {
  __typename?: 'DepositAccountBeneficiary';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  instructionType?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

/** Instruction type for Deposit Account Beneficiary */
export enum DepositAccountBeneficiaryInstructionType {
  PayOnDeath = 'PAY_ON_DEATH',
  TottenTrust = 'TOTTEN_TRUST',
}

export type DepositAccountClosureReason = {
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DepositAccountClosureReasonType>;
};

/** Reasons for closing a deposit account */
export enum DepositAccountClosureReasonType {
  CustomerRequest = 'CUSTOMER_REQUEST',
}

/** Deposit account number attached to a Deposit Account */
export type DepositAccountNumber = {
  __typename?: 'DepositAccountNumber';
  accountNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastFour?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
};

/** Deposit account owner attached to a Deposit Account */
export type DepositAccountOwner = {
  __typename?: 'DepositAccountOwner';
  ownerId?: Maybe<Scalars['ID']>;
  /** Returns the person associated with the ownership */
  person?: Maybe<Person>;
};

/** Deposit account statement */
export type DepositAccountStatement = {
  __typename?: 'DepositAccountStatement';
  fileKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  periodEndDate?: Maybe<Scalars['Date']>;
  periodStartDate?: Maybe<Scalars['Date']>;
  requestType?: Maybe<Scalars['String']>;
  tempUrl?: Maybe<Scalars['String']>;
};

export enum DepositAccountStatus {
  Closed = 'CLOSED',
  Opened = 'OPENED',
  OpeningFailed = 'OPENING_FAILED',
  PendingClosure = 'PENDING_CLOSURE',
  Requested = 'REQUESTED',
}

export enum DepositAccountType {
  Individual = 'INDIVIDUAL',
  Joint = 'JOINT',
}

/** Conditions for determining if a transaction matches a trigger */
export type DepositTransactionConditions = {
  __typename?: 'DepositTransactionConditions';
  category?: Maybe<TransactionCategories>;
  direction?: Maybe<TransferDirection>;
  exactMatch?: Maybe<Scalars['String']>;
  greaterThan?: Maybe<Scalars['Int']>;
  isDirectDeposit?: Maybe<Scalars['Boolean']>;
  lessThan?: Maybe<Scalars['Int']>;
  partialMatch?: Maybe<Scalars['String']>;
};

/** Conditions for determining if a transaction matches a trigger */
export type DepositTransactionConditionsInput = {
  category?: InputMaybe<TransactionCategories>;
  direction?: InputMaybe<TransferDirection>;
  exactMatch?: InputMaybe<Scalars['String']>;
  greaterThan?: InputMaybe<Scalars['Int']>;
  isDirectDeposit?: InputMaybe<Scalars['Boolean']>;
  lessThan?: InputMaybe<Scalars['Int']>;
  partialMatch?: InputMaybe<Scalars['String']>;
};

/** A trigger for multiple conditions on a deposit transaction */
export type DepositTransactionTrigger = {
  __typename?: 'DepositTransactionTrigger';
  options: DepositTransactionTriggerOptions;
  type: TriggerTypes;
  version: Scalars['String'];
};

/** A trigger for multiple conditions on a deposit transaction */
export type DepositTransactionTriggerInput = {
  options: DepositTransactionTriggerOptionsInput;
  type: TriggerTypes;
  version: Scalars['String'];
};

export type DepositTransactionTriggerOptions = {
  __typename?: 'DepositTransactionTriggerOptions';
  conditions: DepositTransactionConditions;
  depositAccountId: Scalars['Uuid'];
};

export type DepositTransactionTriggerOptionsInput = {
  conditions: DepositTransactionConditionsInput;
  depositAccountId: Scalars['Uuid'];
};

/** The fields needed for a Description Trigger */
export type DescriptionTrigger = {
  __typename?: 'DescriptionTrigger';
  options: DescriptionTriggerOptions;
  type: TriggerTypes;
  version: Scalars['String'];
};

/** The fields needed for a Description Trigger */
export type DescriptionTriggerInput = {
  options: DescriptionTriggerOptionsInput;
  type: TriggerTypes;
  version: Scalars['String'];
};

export type DescriptionTriggerOptions = {
  __typename?: 'DescriptionTriggerOptions';
  depositAccountId: Scalars['Uuid'];
  description: Scalars['String'];
};

export type DescriptionTriggerOptionsInput = {
  depositAccountId: Scalars['Uuid'];
  description: Scalars['String'];
};

export type EntryValue = {
  __typename?: 'EntryValue';
  json?: Maybe<Scalars['Json']>;
  string?: Maybe<Scalars['String']>;
  toggle?: Maybe<Scalars['Boolean']>;
};

export type EntryValueInput = {
  json?: InputMaybe<Scalars['Json']>;
  string?: InputMaybe<Scalars['String']>;
  toggle?: InputMaybe<Scalars['Boolean']>;
};

export type ExchangePlaidPublicItemTokenPayload = {
  __typename?: 'ExchangePlaidPublicItemTokenPayload';
  status?: Maybe<Scalars['String']>;
};

/** External Account */
export type ExternalAccount = {
  __typename?: 'ExternalAccount';
  accountLastFour?: Maybe<Scalars['String']>;
  accountSource?: Maybe<AccountSource>;
  accountStatus?: Maybe<ExternalAccountStatus>;
  accountType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Uuid']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  nickname?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['Uuid']>;
  /** Get plaid balance of the external account */
  plaidBalance?: Maybe<PlaidBalance>;
  /** Get plaid institution of the external account */
  plaidInstitution?: Maybe<PlaidInstitution>;
  routingNumber?: Maybe<Scalars['String']>;
  updateReason?: Maybe<Scalars['String']>;
  updateRequired?: Maybe<Scalars['Boolean']>;
};

export enum ExternalAccountStatus {
  Invalid = 'INVALID',
  PendingValidation = 'PENDING_VALIDATION',
  Valid = 'VALID',
}

/** The fields needed for an External Balance Trigger */
export type ExternalBalanceTrigger = {
  __typename?: 'ExternalBalanceTrigger';
  options: ExternalBalanceTriggerOptions;
  type: TriggerTypes;
  version: Scalars['String'];
};

/** The fields needed for an External Balance Trigger */
export type ExternalBalanceTriggerInput = {
  options: ExternalBalanceTriggerOptionsInput;
  type: TriggerTypes;
  version: Scalars['String'];
};

export type ExternalBalanceTriggerOptions = {
  __typename?: 'ExternalBalanceTriggerOptions';
  amountInCents: Scalars['Int'];
  comparison: ComparisonTypes;
  externalAccountId: Scalars['Uuid'];
};

export type ExternalBalanceTriggerOptionsInput = {
  amountInCents: Scalars['Int'];
  comparison: ComparisonTypes;
  externalAccountId: Scalars['Uuid'];
};

export type ExternalTransferAction = {
  __typename?: 'ExternalTransferAction';
  options: ExternalTransferOptions;
  type: ActionTypes;
  version: Scalars['String'];
};

export type ExternalTransferActionInput = {
  options: ExternalTransferOptionsInput;
  type: ActionTypes;
  version: Scalars['String'];
};

export type ExternalTransferOptions = {
  __typename?: 'ExternalTransferOptions';
  amountInCents?: Maybe<Scalars['Int']>;
  depositAccountId: Scalars['Uuid'];
  externalAccountId: Scalars['Uuid'];
  percentage?: Maybe<Scalars['Percentage']>;
  type: OdfiTransferType;
};

export type ExternalTransferOptionsInput = {
  amountInCents?: InputMaybe<Scalars['Int']>;
  depositAccountId: Scalars['Uuid'];
  externalAccountId: Scalars['Uuid'];
  percentage?: InputMaybe<Scalars['Percentage']>;
  type: OdfiTransferType;
};

export type InitiateCashbackRedemptionPayload = {
  __typename?: 'InitiateCashbackRedemptionPayload';
  status?: Maybe<Scalars['String']>;
};

export type InitiateCreditCardAuthorizedUserCreationPayload = {
  __typename?: 'InitiateCreditCardAuthorizedUserCreationPayload';
  authorizedUserId?: Maybe<Scalars['Uuid']>;
};

export type InitiateCreditPaymentPayload = {
  __typename?: 'InitiateCreditPaymentPayload';
  status?: Maybe<Scalars['String']>;
};

export type InitiateDepositAccountClosureWithTransferPayload = {
  __typename?: 'InitiateDepositAccountClosureWithTransferPayload';
  status?: Maybe<Scalars['String']>;
};

export type InitiateDepositAccountClosureWithoutTransferPayload = {
  __typename?: 'InitiateDepositAccountClosureWithoutTransferPayload';
  status?: Maybe<Scalars['String']>;
};

export type InitiateInternalTransferPayload = {
  __typename?: 'InitiateInternalTransferPayload';
  id?: Maybe<Scalars['Uuid']>;
};

export type InitiateJointAccountInvitePayload = {
  __typename?: 'InitiateJointAccountInvitePayload';
  inviteId?: Maybe<Scalars['Uuid']>;
};

export type InitiatePlanTransferPayload = {
  __typename?: 'InitiatePlanTransferPayload';
  id?: Maybe<Scalars['Uuid']>;
};

export type InitiateRemoteCheckDepositPayload = {
  __typename?: 'InitiateRemoteCheckDepositPayload';
  id?: Maybe<Scalars['Uuid']>;
};

/** Interest info for a deposit account */
export type Interest = {
  __typename?: 'Interest';
  apyInterestLevel?: Maybe<Scalars['String']>;
  apyInterestRate?: Maybe<Scalars['String']>;
  balanceInterestAccruedOnAmount?: Maybe<Scalars['String']>;
  creditRequirementsMet?: Maybe<Scalars['Boolean']>;
  dailyInterestRate?: Maybe<Scalars['String']>;
  depositAccountBalance?: Maybe<Scalars['String']>;
  depositRequirementsMet?: Maybe<Scalars['Boolean']>;
  /** All interest postings for the current month */
  monthlyAccruedInterestInCents?: Maybe<Scalars['Int']>;
  originatingDepositAccountId?: Maybe<Scalars['Uuid']>;
  planBalanceSum?: Maybe<Scalars['String']>;
  postingInstructionBatchId?: Maybe<Scalars['ID']>;
  /** All interest postings for the lifetime of this account */
  totalAccruedInterestInCents?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InternalTransferAction = {
  __typename?: 'InternalTransferAction';
  options: InternalTransferOptions;
  type: ActionTypes;
  version: Scalars['String'];
};

export type InternalTransferActionInput = {
  options: InternalTransferOptionsInput;
  type: ActionTypes;
  version: Scalars['String'];
};

export type InternalTransferOptions = {
  __typename?: 'InternalTransferOptions';
  amountInCents?: Maybe<Scalars['Int']>;
  fromDepositAccountId: Scalars['Uuid'];
  percentage?: Maybe<Scalars['Percentage']>;
  toDepositAccountId: Scalars['Uuid'];
};

export type InternalTransferOptionsInput = {
  amountInCents?: InputMaybe<Scalars['Int']>;
  fromDepositAccountId: Scalars['Uuid'];
  percentage?: InputMaybe<Scalars['Percentage']>;
  toDepositAccountId: Scalars['Uuid'];
};

/** joint account invite */
export type JointAccountInvite = {
  __typename?: 'JointAccountInvite';
  inviteId?: Maybe<Scalars['ID']>;
  inviteeEmail?: Maybe<Scalars['String']>;
  inviteeFirstName?: Maybe<Scalars['String']>;
  inviteeLastName?: Maybe<Scalars['String']>;
  inviterEmail?: Maybe<Scalars['String']>;
  inviterFirstName?: Maybe<Scalars['String']>;
  inviterId?: Maybe<Scalars['ID']>;
  inviterLastName?: Maybe<Scalars['String']>;
};

export enum LinkType {
  AddAccount = 'ADD_ACCOUNT',
  AddNewAccountToExistingLink = 'ADD_NEW_ACCOUNT_TO_EXISTING_LINK',
  ResolveBrokenLink = 'RESOLVE_BROKEN_LINK',
  UpdateAccount = 'UPDATE_ACCOUNT',
}

export type LockPlanAccountNumberPayload = {
  __typename?: 'LockPlanAccountNumberPayload';
  planId?: Maybe<Scalars['Uuid']>;
};

/** Month Cash Flow */
export type MonthCashFlow = {
  __typename?: 'MonthCashFlow';
  date?: Maybe<Scalars['Date']>;
  savingRate?: Maybe<Scalars['Percentage']>;
  totalDepositsAmount?: Maybe<Scalars['AmountInCents']>;
  totalNetAmount?: Maybe<Scalars['AmountInCents']>;
  totalWithdrawalsAmount?: Maybe<Scalars['AmountInCents']>;
};

export type MultiAction = {
  __typename?: 'MultiAction';
  options: MultiActionOptions;
  type: ActionTypes;
  version: Scalars['String'];
};

/** The fields needed for an Routine's Action */
export type MultiActionInput = {
  options: MultiActionOptionsInput;
  type: ActionTypes;
  version: Scalars['String'];
};

export type MultiActionOptions = {
  __typename?: 'MultiActionOptions';
  actions?: Maybe<Array<Maybe<SubAction>>>;
};

export type MultiActionOptionsInput = {
  actions?: InputMaybe<Array<InputMaybe<SubActionInput>>>;
};

export type MultiPlanTransfer = {
  __typename?: 'MultiPlanTransfer';
  amountInCents?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Percentage']>;
  planId: Scalars['Uuid'];
};

/** The fields needed for an Routine's Action */
export type MultiPlanTransferAction = {
  __typename?: 'MultiPlanTransferAction';
  options: MultiPlanTransferOptions;
  type: ActionTypes;
  version: Scalars['String'];
};

export type MultiPlanTransferActionInput = {
  options: MultiPlanTransferOptionsInput;
  type: ActionTypes;
  version: Scalars['String'];
};

export type MultiPlanTransferInput = {
  amountInCents?: InputMaybe<Scalars['Int']>;
  percentage?: InputMaybe<Scalars['Percentage']>;
  planId: Scalars['Uuid'];
};

export type MultiPlanTransferOptions = {
  __typename?: 'MultiPlanTransferOptions';
  depositAccountId: Scalars['Uuid'];
  direction: TransferDirection;
  transfers: Array<Maybe<MultiPlanTransfer>>;
};

export type MultiPlanTransferOptionsInput = {
  depositAccountId: Scalars['Uuid'];
  direction: TransferDirection;
  transfers: Array<InputMaybe<MultiPlanTransferInput>>;
};

/** The fields needed for an Routine's Action */
export type NotificationAction = {
  __typename?: 'NotificationAction';
  options: NotificationActionOptions;
  type: ActionTypes;
  version: Scalars['String'];
};

export type NotificationActionInput = {
  options: NotificationActionOptionsInput;
  type: ActionTypes;
  version: Scalars['String'];
};

export type NotificationActionOptions = {
  __typename?: 'NotificationActionOptions';
  email?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  push?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
};

export type NotificationActionOptionsInput = {
  email?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  push?: InputMaybe<Scalars['Boolean']>;
  sms?: InputMaybe<Scalars['Boolean']>;
};

/** The plan transfer data if appending to an odfi */
export type OdfiPlanTransferData = {
  planId?: InputMaybe<Scalars['ID']>;
  planTransferAccountType?: InputMaybe<PlanTransferAccountType>;
};

/** ODFI transfer limit for a given account */
export type OdfiTransferLimit = {
  __typename?: 'OdfiTransferLimit';
  createdAt?: Maybe<Scalars['DateTime']>;
  hasPullLimit?: Maybe<Scalars['AmountInCents']>;
  hasPushLimit?: Maybe<Scalars['AmountInCents']>;
  pullAmountInCents?: Maybe<Scalars['AmountInCents']>;
  pushAmountInCents?: Maybe<Scalars['AmountInCents']>;
};

/** The type of an ODFI transfer */
export enum OdfiTransferType {
  Pull = 'PULL',
  Push = 'PUSH',
}

/** Offered APY values */
export type OfferedApyValue = {
  __typename?: 'OfferedApyValue';
  apyInterestRate?: Maybe<Scalars['String']>;
  apyLevel?: Maybe<Scalars['String']>;
};

export enum OriginatorToBeneficiaryTypes {
  EscrowNumber = 'ESCROW_NUMBER',
  FileNumber = 'FILE_NUMBER',
  LoanNumber = 'LOAN_NUMBER',
  ReferenceNumber = 'REFERENCE_NUMBER',
  SisNumber = 'SIS_NUMBER',
  TitleNumber = 'TITLE_NUMBER',
}

export type OutgoingWireTransfer = {
  __typename?: 'OutgoingWireTransfer';
  amountInCents?: Maybe<Scalars['Int']>;
  beneficiaryAccountNumber?: Maybe<Scalars['String']>;
  beneficiaryAddressLine1?: Maybe<Scalars['String']>;
  beneficiaryAddressLine2?: Maybe<Scalars['String']>;
  beneficiaryBankName?: Maybe<Scalars['String']>;
  beneficiaryName?: Maybe<Scalars['String']>;
  beneficiaryRoutingNumber?: Maybe<Scalars['String']>;
  callbackMissedAt?: Maybe<Scalars['DateTime']>;
  callbackRequiredAt?: Maybe<Scalars['DateTime']>;
  completedByFedAt?: Maybe<Scalars['DateTime']>;
  context?: Maybe<Scalars['Json']>;
  deliveredToFedAt?: Maybe<Scalars['DateTime']>;
  fedConfirmationNumber?: Maybe<Scalars['String']>;
  fileAcceptedAt?: Maybe<Scalars['DateTime']>;
  fileEditedAt?: Maybe<Scalars['DateTime']>;
  fileRejectedAt?: Maybe<Scalars['DateTime']>;
  fileUploadedAt?: Maybe<Scalars['DateTime']>;
  fromAccountId?: Maybe<Scalars['String']>;
  fromAccountType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  memo?: Maybe<Scalars['String']>;
  originatorAccountNumber?: Maybe<Scalars['String']>;
  originatorAddressLine1?: Maybe<Scalars['String']>;
  originatorAddressLine2?: Maybe<Scalars['String']>;
  originatorBankName?: Maybe<Scalars['String']>;
  originatorName?: Maybe<Scalars['String']>;
  originatorRoutingNumber?: Maybe<Scalars['String']>;
  originatorToBeneficiaryLine1?: Maybe<Scalars['String']>;
  originatorToBeneficiaryLine2?: Maybe<Scalars['String']>;
  postedAcceptedAt?: Maybe<Scalars['DateTime']>;
  postedAt?: Maybe<Scalars['DateTime']>;
  postedRejectedAt?: Maybe<Scalars['DateTime']>;
  rejectedByFedAt?: Maybe<Scalars['DateTime']>;
  requestAcceptedAt?: Maybe<Scalars['DateTime']>;
  requestDeniedAt?: Maybe<Scalars['DateTime']>;
  requestRefutedAt?: Maybe<Scalars['DateTime']>;
  requestVerifiedAt?: Maybe<Scalars['DateTime']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  requestedBy?: Maybe<Scalars['ID']>;
  rowInsertedAt?: Maybe<Scalars['DateTime']>;
  rowUpdatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<WireStatusTypes>;
  transferId?: Maybe<Scalars['ID']>;
};

/** A Person from Mainframe Registrar */
export type Person = {
  __typename?: 'Person';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
};

/** Plaid Balance for an External Account */
export type PlaidBalance = {
  __typename?: 'PlaidBalance';
  availableBalanceInCents?: Maybe<Scalars['AmountInCents']>;
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
};

/** Plaid Institution attached to an External Account */
export type PlaidInstitution = {
  __typename?: 'PlaidInstitution';
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Plan = {
  __typename?: 'Plan';
  /** DFI Account Number */
  accountNumber?: Maybe<Scalars['String']>;
  accountNumberLocked?: Maybe<Scalars['Boolean']>;
  /** Get plan balance */
  balanceInCents?: Maybe<Scalars['AmountInCents']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  depositAccountId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  goal?: Maybe<Scalars['Int']>;
  goalAmountInCents?: Maybe<Scalars['String']>;
  goalDate?: Maybe<Scalars['Date']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  initialContributionAmountInCents?: Maybe<Scalars['Int']>;
  monthlyContributionGoalAmountInCents?: Maybe<Scalars['Int']>;
  /** Get all balances for a plan */
  planBalances?: Maybe<PlanBalances>;
  planType?: Maybe<PlanTypes>;
  routingNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** List the transactions of the plan */
  transactions?: Maybe<Array<Maybe<Transaction>>>;
};

export type PlanTransactionsArgs = {
  status?: InputMaybe<TransactionStatus>;
};

/** Plan Balances */
export type PlanBalances = {
  __typename?: 'PlanBalances';
  availableBalanceInCents?: Maybe<Scalars['AmountInCents']>;
  balanceInCents?: Maybe<Scalars['AmountInCents']>;
  pendingBalanceInCents?: Maybe<Scalars['AmountInCents']>;
};

/** Account type for Plan Transfer */
export enum PlanTransferAccountType {
  Deposit = 'DEPOSIT',
  Plan = 'PLAN',
}

/** The fields needed for an Routine's Action */
export type PlanTransferAction = {
  __typename?: 'PlanTransferAction';
  options: PlanTransferOptions;
  type: ActionTypes;
  version: Scalars['String'];
};

/** The fields needed for an Routine's Action */
export type PlanTransferActionInput = {
  options: PlanTransferOptionsInput;
  type: ActionTypes;
  version: Scalars['String'];
};

export type PlanTransferOptions = {
  __typename?: 'PlanTransferOptions';
  amountInCents?: Maybe<Scalars['Int']>;
  depositAccountId: Scalars['Uuid'];
  fromAccountId: Scalars['Uuid'];
  fromAccountType: PlanTransferAccountType;
  percentage?: Maybe<Scalars['Percentage']>;
  toAccountId: Scalars['Uuid'];
  toAccountType: PlanTransferAccountType;
};

export type PlanTransferOptionsInput = {
  amountInCents?: InputMaybe<Scalars['Int']>;
  depositAccountId: Scalars['Uuid'];
  fromAccountId: Scalars['Uuid'];
  fromAccountType: PlanTransferAccountType;
  percentage?: InputMaybe<Scalars['Percentage']>;
  toAccountId: Scalars['Uuid'];
  toAccountType: PlanTransferAccountType;
};

export enum PlanTypes {
  Savings = 'SAVINGS',
  Spending = 'SPENDING',
}

/** The fields needed for a verified_account */
export enum RecurringPaymentType {
  MinimumPayment = 'MINIMUM_PAYMENT',
  RemainingStatementBalance = 'REMAINING_STATEMENT_BALANCE',
}

export type RemoveDepositAccountBeneficiaryPayload = {
  __typename?: 'RemoveDepositAccountBeneficiaryPayload';
  id?: Maybe<Scalars['Uuid']>;
};

export type RemovePlanAccountNumberPayload = {
  __typename?: 'RemovePlanAccountNumberPayload';
  planId?: Maybe<Scalars['Uuid']>;
};

export type RequestIndividualDepositAccountPayload = {
  __typename?: 'RequestIndividualDepositAccountPayload';
  id?: Maybe<Scalars['Uuid']>;
};

export type RequestOutgoingWireTransferV2Payload = {
  __typename?: 'RequestOutgoingWireTransferV2Payload';
  transferId?: Maybe<Scalars['Uuid']>;
};

export type RequestPlanAccountNumberPayload = {
  __typename?: 'RequestPlanAccountNumberPayload';
  planId?: Maybe<Scalars['Uuid']>;
};

export type ResetWorkflowEntriesPayload = {
  __typename?: 'ResetWorkflowEntriesPayload';
  count?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Accept a Joint Account invite */
  acceptJointAccountInvite?: Maybe<AcceptJointAccountInvitePayload>;
  /** Add a Transaction Category */
  addTransactionCategory?: Maybe<AddTransactionCategoryPayload>;
  /** Archives an External Account */
  archiveExternalAccount?: Maybe<ArchiveExternalAccountPayload>;
  /** Archive a Plan */
  archivePlan?: Maybe<ArchivePlanPayload>;
  /** Archive a Routine */
  archiveRoutine?: Maybe<CreateRoutinePayload>;
  /** Archive a Transaction Category */
  archiveTransactionCategory?: Maybe<ArchiveTransactionCategoryPayload>;
  /** Assign a Beneficiary to a Deposit Account */
  assignDepositAccountBeneficiary?: Maybe<AssignDepositAccountBeneficiaryPayload>;
  /** Cancel internal scheduled transfer */
  cancelInternalScheduledTransfer?: Maybe<CancelInternalTransferScheduledPayload>;
  /** Cancel a Joint Account invite */
  cancelJointAccountInvite?: Maybe<CancelJointAccountInvitePayload>;
  /** Cancel ODFI scheduled transfer */
  cancelOdfiScheduledTransfer?: Maybe<CancelOdfiTransferScheduledPayload>;
  /** Cancel pending ODFI transfer */
  cancelPendingOdfiTransfer?: Maybe<CancelPendingOdfiTransferPayload>;
  /** Create new ODFI transfer */
  createNewOdfiTransfer?: Maybe<CreateNewOdfiTransferPayload>;
  /** Creates a Plaid Link Token */
  createPlaidLinkToken?: Maybe<CreatePlaidLinkTokenPayload>;
  /** Creates a Plaid Link Token without transactions product. Only for testing purposes. */
  createPlaidLinkTokenWithoutTransactionsProduct?: Maybe<CreatePlaidLinkTokenPayload>;
  /** Create a Plan */
  createPlan?: Maybe<CreatePlanPayload>;
  /** Create a new Routine */
  createRoutine?: Maybe<CreateRoutinePayload>;
  /** Check if the customer needs an external account on close */
  customerExternalAccountCheck?: Maybe<CustomerExternalAccountCheckPayload>;
  /** Decline a Joint Account invite */
  declineJointAccountInvite?: Maybe<DeclineJointAccountInvitePayload>;
  /** Disable a Routine */
  disableRoutine?: Maybe<CreateRoutinePayload>;
  /** Edit an existing routine */
  editRoutine?: Maybe<CreateRoutinePayload>;
  /** Enable a Routine */
  enableRoutine?: Maybe<CreateRoutinePayload>;
  /** Exchanges a Plaid Public Item Token */
  exchangePlaidPublicItemToken?: Maybe<ExchangePlaidPublicItemTokenPayload>;
  /** Initiates a cashback redemption */
  initiateCashbackRedemption?: Maybe<InitiateCashbackRedemptionPayload>;
  /** Initiates the creation of a credit card account authorized user */
  initiateCreditCardAuthorizedUserCreation?: Maybe<InitiateCreditCardAuthorizedUserCreationPayload>;
  /** Initiates a credit card payment */
  initiateCreditPayment?: Maybe<InitiateCreditPaymentPayload>;
  /** Initiate a Deposit Account Closure with Transfer */
  initiateDepositAccountClosureWithTransfer?: Maybe<InitiateDepositAccountClosureWithTransferPayload>;
  /** Initiate a Deposit Account Closure without Transfer */
  initiateDepositAccountClosureWithoutTransfer?: Maybe<InitiateDepositAccountClosureWithoutTransferPayload>;
  /** Initiate a Internal Transfer */
  initiateInternalTransfer?: Maybe<InitiateInternalTransferPayload>;
  /** Initiate a Joint Account invite */
  initiateJointAccountInvite?: Maybe<InitiateJointAccountInvitePayload>;
  /** Initiate a Plan Transfer */
  initiatePlanTransfer?: Maybe<InitiatePlanTransferPayload>;
  /** Initiate a Remote Check Deposit */
  initiateRemoteCheckDeposit?: Maybe<InitiateRemoteCheckDepositPayload>;
  /** Lock account number for a Plan */
  lockPlanAccountNumber?: Maybe<LockPlanAccountNumberPayload>;
  putWorkflowEntry?: Maybe<WorkflowEntry>;
  /** Remove a Beneficiary from a Deposit Account */
  removeDepositAccountBeneficiary?: Maybe<RemoveDepositAccountBeneficiaryPayload>;
  /** Remove account number for a Plan */
  removePlanAccountNumber?: Maybe<RemovePlanAccountNumberPayload>;
  /** Requests an individual deposit account to be opened */
  requestIndividualDepositAccount?: Maybe<RequestIndividualDepositAccountPayload>;
  /** Request an outgoing wire */
  requestOutgoingWireTransferV2?: Maybe<RequestOutgoingWireTransferV2Payload>;
  /** Request account number for a Plan */
  requestPlanAccountNumber?: Maybe<RequestPlanAccountNumberPayload>;
  resetWorkflowEntries?: Maybe<ResetWorkflowEntriesPayload>;
  /** Schedules a credit card payment */
  scheduleCreditPayment?: Maybe<ScheduleCreditPaymentPayload>;
  /** Send a new PII change confirmation code */
  sendPiiConfirmationCode?: Maybe<ConfirmPiiPayload>;
  /** Sets a recurring credit card payment */
  setRecurringCreditPayment?: Maybe<SetRecurringCreditPaymentPayload>;
  /** Test a webhook connection */
  testWebhook?: Maybe<TestWebhookPayload>;
  /** Unlock account number for a Plan */
  unlockPlanAccountNumber?: Maybe<UnlockPlanAccountNumberPayload>;
  /** The fields needed for a customer address update */
  updateAddress?: Maybe<UpdatePiiPayload>;
  /** The fields needed for a customer email update */
  updateEmail?: Maybe<UpdatePiiPayload>;
  /** Updates an External Account */
  updateExternalAccount?: Maybe<UpdateExternalAccountPayload>;
  /** The fields needed for a customer name update */
  updateName?: Maybe<UpdatePiiPayload>;
  /** The fields needed for a customer phone number update */
  updatePhone?: Maybe<UpdatePiiPayload>;
  /** Updates a Plaid Item */
  updatePlaidItem?: Maybe<UpdatePlaidItemPayload>;
  /** Update a Plan */
  updatePlan?: Maybe<UpdatePlanPayload>;
  /** Verify PII change confirmation code */
  verifyPiiConfirmationCode?: Maybe<ConfirmPiiPayload>;
};

export type RootMutationTypeAcceptJointAccountInviteArgs = {
  inviteId: Scalars['Uuid'];
  sendDebitCard: Scalars['Boolean'];
};

export type RootMutationTypeAddTransactionCategoryArgs = {
  category?: InputMaybe<TransactionCategories>;
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  transactionId?: InputMaybe<Scalars['Uuid']>;
};

export type RootMutationTypeArchiveExternalAccountArgs = {
  externalAccountId: Scalars['Uuid'];
};

export type RootMutationTypeArchivePlanArgs = {
  planId: Scalars['Uuid'];
};

export type RootMutationTypeArchiveRoutineArgs = {
  routineId?: InputMaybe<Scalars['Uuid']>;
};

export type RootMutationTypeArchiveTransactionCategoryArgs = {
  category?: InputMaybe<TransactionCategories>;
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  transactionId?: InputMaybe<Scalars['Uuid']>;
};

export type RootMutationTypeAssignDepositAccountBeneficiaryArgs = {
  addressCity: Scalars['String'];
  addressCountryCode: Scalars['String'];
  addressLineOne: Scalars['String'];
  addressLineTwo?: InputMaybe<Scalars['String']>;
  addressPostalCode: Scalars['String'];
  addressStateCode: Scalars['String'];
  birthDate: Scalars['Date'];
  depositAccountId: Scalars['Uuid'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  instructionType: DepositAccountBeneficiaryInstructionType;
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  nationalIdNumberToken: Scalars['Vgstoken'];
  phoneNumber: Scalars['String'];
  suffix?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeCancelInternalScheduledTransferArgs = {
  internalTransferId: Scalars['Uuid'];
};

export type RootMutationTypeCancelJointAccountInviteArgs = {
  inviteId: Scalars['Uuid'];
};

export type RootMutationTypeCancelOdfiScheduledTransferArgs = {
  odfiAchTransferId: Scalars['Uuid'];
};

export type RootMutationTypeCancelPendingOdfiTransferArgs = {
  odfiAchTransferId: Scalars['Uuid'];
};

export type RootMutationTypeCreateNewOdfiTransferArgs = {
  amountInCents: Scalars['TransferAmountInCents'];
  depositAccountId: Scalars['Uuid'];
  externalAccountId: Scalars['Uuid'];
  legacyExternalAccounts?: InputMaybe<Scalars['Boolean']>;
  odfiPlanTransferData?: InputMaybe<OdfiPlanTransferData>;
  scheduledFor?: InputMaybe<Scalars['DateTime']>;
  type: OdfiTransferType;
};

export type RootMutationTypeCreatePlaidLinkTokenArgs = {
  externalAccountId?: InputMaybe<Scalars['Uuid']>;
  linkType?: InputMaybe<LinkType>;
};

export type RootMutationTypeCreatePlanArgs = {
  assignAccountNumber?: InputMaybe<Scalars['Boolean']>;
  color: Scalars['String'];
  depositAccountId: Scalars['Uuid'];
  description?: InputMaybe<Scalars['String']>;
  goalAmountInCents?: InputMaybe<Scalars['TransferAmountInCents']>;
  goalDate?: InputMaybe<Scalars['Date']>;
  icon?: InputMaybe<Scalars['String']>;
  initialContributionAmountInCents?: InputMaybe<Scalars['TransferAmountInCents']>;
  monthlyContributionGoalAmountInCents?: InputMaybe<Scalars['TransferAmountInCents']>;
  title: Scalars['String'];
  type?: InputMaybe<PlanTypes>;
};

export type RootMutationTypeCreateRoutineArgs = {
  action: ActionInput;
  consents?: InputMaybe<Array<InputMaybe<Consents>>>;
  description: Scalars['String'];
  name: Scalars['String'];
  templateId?: InputMaybe<Scalars['Uuid']>;
  trigger: TriggerInput;
};

export type RootMutationTypeCustomerExternalAccountCheckArgs = {
  depositAccountId: Scalars['Uuid'];
};

export type RootMutationTypeDeclineJointAccountInviteArgs = {
  inviteId: Scalars['Uuid'];
};

export type RootMutationTypeDisableRoutineArgs = {
  routineId?: InputMaybe<Scalars['Uuid']>;
};

export type RootMutationTypeEditRoutineArgs = {
  action: ActionInput;
  consents?: InputMaybe<Array<InputMaybe<Consents>>>;
  description: Scalars['String'];
  name: Scalars['String'];
  routineId?: InputMaybe<Scalars['Uuid']>;
  trigger: TriggerInput;
};

export type RootMutationTypeEnableRoutineArgs = {
  routineId?: InputMaybe<Scalars['Uuid']>;
};

export type RootMutationTypeExchangePlaidPublicItemTokenArgs = {
  metadata: Scalars['Json'];
  publicToken: Scalars['String'];
};

export type RootMutationTypeInitiateCashbackRedemptionArgs = {
  amountInCents: Scalars['TransferAmountInCents'];
  cardId: Scalars['Uuid'];
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  externalAccountId?: InputMaybe<Scalars['Uuid']>;
  redeemToCard?: InputMaybe<Scalars['Boolean']>;
  targetAccountLastFour?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeInitiateCreditCardAuthorizedUserCreationArgs = {
  birthDate: Scalars['Date'];
  cardId: Scalars['Uuid'];
  email: Scalars['String'];
  nameFirst: Scalars['String'];
  nameLast: Scalars['String'];
  nameMiddle?: InputMaybe<Scalars['String']>;
  nameSuffix?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type RootMutationTypeInitiateCreditPaymentArgs = {
  amountInCents: Scalars['TransferAmountInCents'];
  cardId: Scalars['Uuid'];
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  externalAccountId?: InputMaybe<Scalars['Uuid']>;
  ledgerId?: InputMaybe<Scalars['Uuid']>;
  paymentAccountLastFour?: InputMaybe<Scalars['String']>;
  unverifiedAccount?: InputMaybe<UnverifiedAccount>;
};

export type RootMutationTypeInitiateDepositAccountClosureWithTransferArgs = {
  closureReasons: Array<InputMaybe<DepositAccountClosureReason>>;
  depositAccountId: Scalars['Uuid'];
  externalAccountId: Scalars['Uuid'];
};

export type RootMutationTypeInitiateDepositAccountClosureWithoutTransferArgs = {
  closureReason: Array<InputMaybe<DepositAccountClosureReason>>;
  depositAccountId: Scalars['Uuid'];
};

export type RootMutationTypeInitiateInternalTransferArgs = {
  amountInCents: Scalars['TransferAmountInCents'];
  fromDepositAccountId: Scalars['Uuid'];
  scheduledFor?: InputMaybe<Scalars['DateTime']>;
  toDepositAccountId: Scalars['Uuid'];
};

export type RootMutationTypeInitiateJointAccountInviteArgs = {
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  inviteeAddressCity: Scalars['String'];
  inviteeAddressCountryCode: Scalars['String'];
  inviteeAddressLineOne: Scalars['String'];
  inviteeAddressLineTwo?: InputMaybe<Scalars['String']>;
  inviteeAddressPostalCode: Scalars['String'];
  inviteeAddressStateCode: Scalars['String'];
  inviteeDateOfBirth: Scalars['Date'];
  inviteeEmail: Scalars['String'];
  inviteeFirstName: Scalars['String'];
  inviteeLastName: Scalars['String'];
  inviteeNationalIdNumberToken: Scalars['Vgstoken'];
};

export type RootMutationTypeInitiatePlanTransferArgs = {
  amountInCents: Scalars['TransferAmountInCents'];
  depositAccountId: Scalars['Uuid'];
  fromAccountId: Scalars['Uuid'];
  fromAccountType: PlanTransferAccountType;
  scheduledFor?: InputMaybe<Scalars['DateTime']>;
  toAccountId: Scalars['Uuid'];
  toAccountType: PlanTransferAccountType;
};

export type RootMutationTypeInitiateRemoteCheckDepositArgs = {
  enteredAccountNumber: Scalars['String'];
  enteredAmountInCents: Scalars['TransferAmountInCents'];
  enteredRoutingNumber: Scalars['String'];
  provider: Scalars['String'];
  remoteCheckDepositId: Scalars['String'];
};

export type RootMutationTypeLockPlanAccountNumberArgs = {
  accountNumber: Scalars['String'];
  planId: Scalars['Uuid'];
};

export type RootMutationTypePutWorkflowEntryArgs = {
  domain: Scalars['String'];
  name: Scalars['String'];
  value: EntryValueInput;
};

export type RootMutationTypeRemoveDepositAccountBeneficiaryArgs = {
  beneficiaryId: Scalars['Uuid'];
  depositAccountId: Scalars['Uuid'];
};

export type RootMutationTypeRemovePlanAccountNumberArgs = {
  accountNumber?: InputMaybe<Scalars['String']>;
  planId: Scalars['Uuid'];
};

export type RootMutationTypeRequestOutgoingWireTransferV2Args = {
  amountInCents: Scalars['Int'];
  beneficiaryAccountNumber: Scalars['String'];
  beneficiaryAddressLine1: Scalars['String'];
  beneficiaryBankName: Scalars['String'];
  beneficiaryCity: Scalars['String'];
  beneficiaryName: Scalars['String'];
  beneficiaryPostalCode: Scalars['String'];
  beneficiaryRoutingNumber: Scalars['String'];
  beneficiaryStateCode: Scalars['String'];
  consent: Consent;
  memo: Scalars['String'];
  originatorAccountNumber: Scalars['String'];
  originatorToBeneficiaryLine1?: InputMaybe<OriginatorToBeneficiaryTypes>;
  originatorToBeneficiaryLine2?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeRequestPlanAccountNumberArgs = {
  planId: Scalars['Uuid'];
};

export type RootMutationTypeResetWorkflowEntriesArgs = {
  domain?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeScheduleCreditPaymentArgs = {
  amountInCents: Scalars['TransferAmountInCents'];
  cardId: Scalars['Uuid'];
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  externalAccountId?: InputMaybe<Scalars['Uuid']>;
  ledgerId?: InputMaybe<Scalars['Uuid']>;
  paymentAccountLastFour?: InputMaybe<Scalars['String']>;
  scheduledDate?: InputMaybe<Scalars['Date']>;
  unverifiedAccount?: InputMaybe<UnverifiedAccount>;
};

export type RootMutationTypeSendPiiConfirmationCodeArgs = {
  piiChangeRequestId: Scalars['Uuid'];
};

export type RootMutationTypeSetRecurringCreditPaymentArgs = {
  cardId: Scalars['Uuid'];
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  externalAccountId?: InputMaybe<Scalars['Uuid']>;
  isOn?: InputMaybe<Scalars['Boolean']>;
  ledgerId?: InputMaybe<Scalars['Uuid']>;
  paymentAccountLastFour?: InputMaybe<Scalars['String']>;
  recurringPaymentType?: InputMaybe<RecurringPaymentType>;
};

export type RootMutationTypeTestWebhookArgs = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  triggerType?: InputMaybe<TriggerTypes>;
  url: Scalars['String'];
};

export type RootMutationTypeUnlockPlanAccountNumberArgs = {
  accountNumber: Scalars['String'];
  planId: Scalars['Uuid'];
};

export type RootMutationTypeUpdateAddressArgs = {
  city: Scalars['String'];
  countryCode: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  stateCode: Scalars['String'];
};

export type RootMutationTypeUpdateEmailArgs = {
  email: Scalars['String'];
};

export type RootMutationTypeUpdateExternalAccountArgs = {
  externalAccountId: Scalars['Uuid'];
  nickname: Scalars['String'];
};

export type RootMutationTypeUpdateNameArgs = {
  nameFirst: Scalars['String'];
  nameLast: Scalars['String'];
  nameMiddle?: InputMaybe<Scalars['String']>;
  namePrefix?: InputMaybe<Scalars['String']>;
  nameSuffix?: InputMaybe<Scalars['String']>;
};

export type RootMutationTypeUpdatePhoneArgs = {
  phoneNumber: Scalars['String'];
};

export type RootMutationTypeUpdatePlaidItemArgs = {
  action: LinkType;
  externalAccountId: Scalars['Uuid'];
};

export type RootMutationTypeUpdatePlanArgs = {
  color: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  goalAmountInCents?: InputMaybe<Scalars['TransferAmountInCents']>;
  goalDate?: InputMaybe<Scalars['Date']>;
  icon?: InputMaybe<Scalars['String']>;
  monthlyContributionGoalAmountInCents?: InputMaybe<Scalars['TransferAmountInCents']>;
  planId: Scalars['Uuid'];
  title: Scalars['String'];
};

export type RootMutationTypeVerifyPiiConfirmationCodeArgs = {
  code: Scalars['String'];
  piiChangeRequestId: Scalars['Uuid'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Get the APY tracking values for the deposit account within the date range */
  apyTracking?: Maybe<ApyTracking>;
  /** Can the current user open an individual deposit account */
  canOpenIndividualDepositAccount?: Maybe<Scalars['Boolean']>;
  /** Can the current user open a pending joint account */
  canOpenPendingJointAccount?: Maybe<Scalars['Boolean']>;
  /** Get a deposit account by id */
  depositAccount?: Maybe<DepositAccount>;
  /** Get a list of all deposit account for the current user */
  depositAccounts?: Maybe<Array<Maybe<DepositAccount>>>;
  /** Get a list of all linked accounts for the current user */
  externalAccounts?: Maybe<Array<Maybe<ExternalAccount>>>;
  /** Get a list of all joint account invites for the current user */
  jointAccountInvites?: Maybe<Array<Maybe<JointAccountInvite>>>;
  /** Get a monthly cash flow of all for the current user */
  monthlyCashFlow?: Maybe<Array<Maybe<MonthCashFlow>>>;
  /** Get the offered apy interest rates */
  offeredApyValues?: Maybe<Array<Maybe<OfferedApyValue>>>;
  /** get a list of all outgoing wires for the current user */
  outgoingWireTransfers?: Maybe<Array<Maybe<OutgoingWireTransfer>>>;
  /** Get a list of all routine templates */
  routineTemplates?: Maybe<Array<Maybe<RoutineTemplate>>>;
  /** Get a list of all routines for the current user */
  routines?: Maybe<Array<Maybe<Routine>>>;
  /** Get a list of all shared routines for the current user */
  sharedRoutines?: Maybe<Array<Maybe<Routine>>>;
  workflowEntries?: Maybe<Array<Maybe<WorkflowEntry>>>;
  workflowEntry?: Maybe<WorkflowEntry>;
};

export type RootQueryTypeApyTrackingArgs = {
  depositAccountId: Scalars['Uuid'];
};

export type RootQueryTypeDepositAccountArgs = {
  depositAccountId: Scalars['ID'];
};

export type RootQueryTypeDepositAccountsArgs = {
  accountStatus?: InputMaybe<Array<InputMaybe<DepositAccountStatus>>>;
  accountType?: InputMaybe<DepositAccountType>;
};

export type RootQueryTypeMonthlyCashFlowArgs = {
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type RootQueryTypeOfferedApyValuesArgs = {
  depositAccountId: Scalars['Uuid'];
};

export type RootQueryTypeWorkflowEntriesArgs = {
  domain?: InputMaybe<Scalars['String']>;
};

export type RootQueryTypeWorkflowEntryArgs = {
  domain: Scalars['String'];
  name: Scalars['String'];
};

/** A routine */
export type Routine = {
  __typename?: 'Routine';
  action: Action;
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  createdByName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disabledAt?: Maybe<Scalars['DateTime']>;
  enabledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Uuid'];
  isEnabled: Scalars['Boolean'];
  isSharedRoutine: Scalars['Boolean'];
  lastRunAt?: Maybe<Scalars['DateTime']>;
  lastRunStatus?: Maybe<RoutineExecutionStatus>;
  name: Scalars['String'];
  templateId?: Maybe<Scalars['String']>;
  trigger: Trigger;
};

export enum RoutineExecutionStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Initiated = 'INITIATED',
  PartiallyCompleted = 'PARTIALLY_COMPLETED',
}

export type RoutineMetadata = {
  __typename?: 'RoutineMetadata';
  isArchived?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  lastRunAt?: Maybe<Scalars['DateTime']>;
  lastRunFinishedAt?: Maybe<Scalars['DateTime']>;
  lastRunStatus?: Maybe<RoutineExecutionStatus>;
  name?: Maybe<Scalars['String']>;
  routineId?: Maybe<Scalars['ID']>;
};

/** A routine template */
export type RoutineTemplate = {
  __typename?: 'RoutineTemplate';
  action: Action;
  categories?: Maybe<Array<Maybe<TemplateCategories>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  title: Scalars['String'];
  trigger: Trigger;
  uid?: Maybe<Scalars['String']>;
};

export type ScheduleCreditPaymentPayload = {
  __typename?: 'ScheduleCreditPaymentPayload';
  status?: Maybe<Scalars['String']>;
};

export type SetRecurringCreditPaymentPayload = {
  __typename?: 'SetRecurringCreditPaymentPayload';
  status?: Maybe<Scalars['String']>;
};

export type SubAction =
  | CashbackRedemptionAction
  | CategorizeAction
  | CreditCardPaymentAction
  | ExternalTransferAction
  | InternalTransferAction
  | MultiPlanTransferAction
  | NotificationAction
  | PlanTransferAction
  | WebhookAction;

/** A tagged union type of all possible sub actions for multi action */
export type SubActionInput = {
  cashbackRedemptionAction?: InputMaybe<CashbackRedemptionActionInput>;
  categorizeAction?: InputMaybe<CategorizeActionInput>;
  creditCardPaymentAction?: InputMaybe<CreditCardPaymentActionInput>;
  externalTransferAction?: InputMaybe<ExternalTransferActionInput>;
  internalTransferAction?: InputMaybe<InternalTransferActionInput>;
  multiPlanTransferAction?: InputMaybe<MultiPlanTransferActionInput>;
  notificationAction?: InputMaybe<NotificationActionInput>;
  planTransferAction?: InputMaybe<PlanTransferActionInput>;
  type?: InputMaybe<ActionTypes>;
  webhookAction?: InputMaybe<WebhookActionInput>;
};

export enum TemplateCategories {
  /** @deprecated Migrate template categories */
  Automation = 'AUTOMATION',
  /** @deprecated Migrate template categories */
  Budgeting = 'BUDGETING',
  BudgetTogether = 'BUDGET_TOGETHER',
  Featured = 'FEATURED',
  ManageSpending = 'MANAGE_SPENDING',
  /** @deprecated Migrate template categories */
  Rewards = 'REWARDS',
  SaveForTheFuture = 'SAVE_FOR_THE_FUTURE',
  SaveForToday = 'SAVE_FOR_TODAY',
  /** @deprecated Migrate template categories */
  SavingsHabits = 'SAVINGS_HABITS',
  /** @deprecated Migrate template categories */
  SharedFinances = 'SHARED_FINANCES',
  /** @deprecated Migrate template categories */
  SpendingHabits = 'SPENDING_HABITS',
}

export type TestWebhookPayload = {
  __typename?: 'TestWebhookPayload';
  response?: Maybe<WebhookResponseTypes>;
};

/** The data associated with any given transaction */
export type Transaction = {
  __typename?: 'Transaction';
  amount?: Maybe<Scalars['String']>;
  amountInCents?: Maybe<Scalars['String']>;
  balanceInCents?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  category?: Maybe<TransactionCategories>;
  description?: Maybe<Scalars['String']>;
  fromAccountId?: Maybe<Scalars['String']>;
  fromAccountType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  initiatedAt?: Maybe<Scalars['DateTime']>;
  initiatedBy?: Maybe<Scalars['String']>;
  isCredit?: Maybe<Scalars['Boolean']>;
  isDirectDeposit?: Maybe<Scalars['Boolean']>;
  planAccountNumber?: Maybe<Scalars['String']>;
  planBalanceInCents?: Maybe<Scalars['String']>;
  planTitle?: Maybe<Scalars['String']>;
  postedAt?: Maybe<Scalars['DateTime']>;
  routine?: Maybe<RoutineMetadata>;
  scheduledFor?: Maybe<Scalars['DateTime']>;
  status: TransactionStatus;
  toAccountId?: Maybe<Scalars['String']>;
  toAccountType?: Maybe<Scalars['String']>;
  /** @deprecated Use id instead */
  transferId?: Maybe<Scalars['ID']>;
  transferType: TransferType;
};

/** Transaction Categories */
export enum TransactionCategories {
  Bills = 'BILLS',
  Childcare = 'CHILDCARE',
  Entertainment = 'ENTERTAINMENT',
  FoodAndDrink = 'FOOD_AND_DRINK',
  GasAndMaintenance = 'GAS_AND_MAINTENANCE',
  Groceries = 'GROCERIES',
  Healthcare = 'HEALTHCARE',
  Hobbies = 'HOBBIES',
  Income = 'INCOME',
  Mortgage = 'MORTGAGE',
  PersonalCare = 'PERSONAL_CARE',
  Pets = 'PETS',
  Rent = 'RENT',
  Rewards = 'REWARDS',
  Savings = 'SAVINGS',
  Subscriptions = 'SUBSCRIPTIONS',
  TravelAndVacation = 'TRAVEL_AND_VACATION',
  Utilities = 'UTILITIES',
}

/** The stage of a transaction event */
export enum TransactionStatus {
  Authorized = 'AUTHORIZED',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Held = 'HELD',
  Pending = 'PENDING',
  Posted = 'POSTED',
  Queued = 'QUEUED',
  Rejected = 'REJECTED',
  Returned = 'RETURNED',
  Scheduled = 'SCHEDULED',
}

export enum TransferDirection {
  Pull = 'PULL',
  Push = 'PUSH',
}

/** The category of a transfer */
export enum TransferType {
  CashbackRedemptionTransfer = 'CASHBACK_REDEMPTION_TRANSFER',
  CloseAccountTransfer = 'CLOSE_ACCOUNT_TRANSFER',
  CoreTransfer = 'CORE_TRANSFER',
  CreditCardPaymentTransfer = 'CREDIT_CARD_PAYMENT_TRANSFER',
  DebitCardTransfer = 'DEBIT_CARD_TRANSFER',
  IncomingWireTransfer = 'INCOMING_WIRE_TRANSFER',
  InternalTransfer = 'INTERNAL_TRANSFER',
  OdfiTransfer = 'ODFI_TRANSFER',
  OperationalTransfer = 'OPERATIONAL_TRANSFER',
  OutgoingWireTransfer = 'OUTGOING_WIRE_TRANSFER',
  PlanTransfer = 'PLAN_TRANSFER',
  RdfiTransfer = 'RDFI_TRANSFER',
}

export type Trigger =
  | BalanceTrigger
  | CashbackRedemptionTrigger
  | CategoryTrigger
  | DateTrigger
  | DepositTransactionTrigger
  | DescriptionTrigger
  | ExternalBalanceTrigger
  | UserCategoryTrigger;

/** A tagged union type of all possible triggers */
export type TriggerInput = {
  balanceTrigger?: InputMaybe<BalanceTriggerInput>;
  cashbackRedemptionTrigger?: InputMaybe<CashbackRedemptionTriggerInput>;
  categoryTrigger?: InputMaybe<CategoryTriggerInput>;
  dateTrigger?: InputMaybe<DateTriggerInput>;
  depositTransactionTrigger?: InputMaybe<DepositTransactionTriggerInput>;
  descriptionTrigger?: InputMaybe<DescriptionTriggerInput>;
  externalBalanceTrigger?: InputMaybe<ExternalBalanceTriggerInput>;
  type?: InputMaybe<TriggerTypes>;
  userCategoryTrigger?: InputMaybe<UserCategoryTriggerInput>;
};

export enum TriggerTypes {
  Balance = 'BALANCE',
  CashbackRedemption = 'CASHBACK_REDEMPTION',
  Category = 'CATEGORY',
  Date = 'DATE',
  DepositTransaction = 'DEPOSIT_TRANSACTION',
  Description = 'DESCRIPTION',
  ExternalBalance = 'EXTERNAL_BALANCE',
  UserCategory = 'USER_CATEGORY',
}

export type UnlockPlanAccountNumberPayload = {
  __typename?: 'UnlockPlanAccountNumberPayload';
  planId?: Maybe<Scalars['Uuid']>;
};

/** The fields needed for an unverified_account */
export type UnverifiedAccount = {
  accountNumber?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  routingNumber?: InputMaybe<Scalars['String']>;
  saveExternalAccount?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateExternalAccountPayload = {
  __typename?: 'UpdateExternalAccountPayload';
  status?: Maybe<Scalars['String']>;
};

export type UpdatePiiPayload = {
  __typename?: 'UpdatePiiPayload';
  piiChangeRequestId?: Maybe<Scalars['Uuid']>;
  piiType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type UpdatePlaidItemPayload = {
  __typename?: 'UpdatePlaidItemPayload';
  status?: Maybe<Scalars['String']>;
};

export type UpdatePlanPayload = {
  __typename?: 'UpdatePlanPayload';
  planId?: Maybe<Scalars['Uuid']>;
};

/** The fields needed for a User Category Trigger */
export type UserCategoryTrigger = {
  __typename?: 'UserCategoryTrigger';
  options: UserCategoryTriggerOptions;
  type: TriggerTypes;
  version: Scalars['String'];
};

/** The fields needed for a User Category Trigger */
export type UserCategoryTriggerInput = {
  options: UserCategoryTriggerOptionsInput;
  type: TriggerTypes;
  version: Scalars['String'];
};

export type UserCategoryTriggerOptions = {
  __typename?: 'UserCategoryTriggerOptions';
  depositAccountId: Scalars['Uuid'];
  userCategory: TransactionCategories;
};

export type UserCategoryTriggerOptionsInput = {
  depositAccountId: Scalars['Uuid'];
  userCategory: TransactionCategories;
};

export type WebhookAction = {
  __typename?: 'WebhookAction';
  options: WebhookOptions;
  type: ActionTypes;
  version: Scalars['String'];
};

export type WebhookActionInput = {
  options: WebhookOptionsInput;
  type: ActionTypes;
  version: Scalars['String'];
};

export type WebhookHeader = {
  __typename?: 'WebhookHeader';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type WebhookHeaderInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type WebhookOptions = {
  __typename?: 'WebhookOptions';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  url: Scalars['String'];
  webhookHeaders?: Maybe<Array<Maybe<WebhookHeader>>>;
};

export type WebhookOptionsInput = {
  description?: InputMaybe<Scalars['String']>;
  headers?: InputMaybe<Array<InputMaybe<WebhookHeaderInput>>>;
  name: Scalars['String'];
  url: Scalars['String'];
};

export enum WebhookResponseTypes {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
}

export enum WireStatusTypes {
  CallbackRequired = 'CALLBACK_REQUIRED',
  FedCompleted = 'FED_COMPLETED',
  FedDelivered = 'FED_DELIVERED',
  FedRejected = 'FED_REJECTED',
  FileAccepted = 'FILE_ACCEPTED',
  FileRejected = 'FILE_REJECTED',
  FileUploaded = 'FILE_UPLOADED',
  PostingAccepted = 'POSTING_ACCEPTED',
  PostingFailed = 'POSTING_FAILED',
  PostingRejected = 'POSTING_REJECTED',
  RequestAccepted = 'REQUEST_ACCEPTED',
  RequestDenied = 'REQUEST_DENIED',
  RequestRefuted = 'REQUEST_REFUTED',
  RequestVerified = 'REQUEST_VERIFIED',
  WireDenied = 'WIRE_DENIED',
  WireRequested = 'WIRE_REQUESTED',
  WireReturned = 'WIRE_RETURNED',
}

export type WorkflowEntry = {
  __typename?: 'WorkflowEntry';
  domain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<EntryValue>;
};

export type InitiateCreditPaymentMutationVariables = Exact<{
  cardId: Scalars['Uuid'];
  amountInCents: Scalars['TransferAmountInCents'];
  externalAccountId?: InputMaybe<Scalars['Uuid']>;
  ledgerId?: InputMaybe<Scalars['Uuid']>;
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  paymentAccountLastFour?: InputMaybe<Scalars['String']>;
}>;

export type InitiateCreditPaymentMutation = {
  __typename?: 'RootMutationType';
  initiateCreditPayment?: { __typename?: 'InitiateCreditPaymentPayload'; status?: string | null } | null;
};

export type InitiateCreditCardAuthorizedUserCreationMutationVariables = Exact<{
  cardId: Scalars['Uuid'];
  nameFirst: Scalars['String'];
  nameMiddle?: InputMaybe<Scalars['String']>;
  nameLast: Scalars['String'];
  nameSuffix?: InputMaybe<Scalars['String']>;
  birthDate: Scalars['Date'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
}>;

export type InitiateCreditCardAuthorizedUserCreationMutation = {
  __typename?: 'RootMutationType';
  initiateCreditCardAuthorizedUserCreation?: {
    __typename?: 'InitiateCreditCardAuthorizedUserCreationPayload';
    authorizedUserId?: string | null;
  } | null;
};

export type ScheduleCreditPaymentMutationVariables = Exact<{
  cardId: Scalars['Uuid'];
  amountInCents: Scalars['TransferAmountInCents'];
  externalAccountId?: InputMaybe<Scalars['Uuid']>;
  ledgerId?: InputMaybe<Scalars['Uuid']>;
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  scheduledDate: Scalars['Date'];
  paymentAccountLastFour?: InputMaybe<Scalars['String']>;
}>;

export type ScheduleCreditPaymentMutation = {
  __typename?: 'RootMutationType';
  scheduleCreditPayment?: { __typename?: 'ScheduleCreditPaymentPayload'; status?: string | null } | null;
};

export type SetRecurringCreditPaymentMutationVariables = Exact<{
  cardId: Scalars['Uuid'];
  recurringPaymentType: RecurringPaymentType;
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  paymentAccountLastFour?: InputMaybe<Scalars['String']>;
  externalAccountId?: InputMaybe<Scalars['Uuid']>;
  ledgerId?: InputMaybe<Scalars['Uuid']>;
  isOn: Scalars['Boolean'];
}>;

export type SetRecurringCreditPaymentMutation = {
  __typename?: 'RootMutationType';
  setRecurringCreditPayment?: { __typename?: 'SetRecurringCreditPaymentPayload'; status?: string | null } | null;
};

export type InitiateCashbackRedemptionMutationVariables = Exact<{
  cardId: Scalars['Uuid'];
  amountInCents: Scalars['TransferAmountInCents'];
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  targetAccountLastFour?: InputMaybe<Scalars['String']>;
  externalAccountId?: InputMaybe<Scalars['Uuid']>;
  redeemToCard?: InputMaybe<Scalars['Boolean']>;
}>;

export type InitiateCashbackRedemptionMutation = {
  __typename?: 'RootMutationType';
  initiateCashbackRedemption?: { __typename?: 'InitiateCashbackRedemptionPayload'; status?: string | null } | null;
};

export type GetActiveDepositAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveDepositAccountsQuery = {
  __typename?: 'RootQueryType';
  depositAccounts?: Array<{ __typename?: 'DepositAccount'; id?: string | null } | null> | null;
};

export type GetDepositAccountsForPaymentQueryVariables = Exact<{ [key: string]: never }>;

export type GetDepositAccountsForPaymentQuery = {
  __typename?: 'RootQueryType';
  depositAccounts?: Array<{
    __typename?: 'DepositAccount';
    id?: string | null;
    accountType?: DepositAccountType | null;
    accountStatus?: DepositAccountStatus | null;
    depositAccountNumber?: { __typename?: 'DepositAccountNumber'; lastFour?: string | null } | null;
    depositAccountBalances?: { __typename?: 'DepositAccountBalances'; availableBalanceInCents?: number | null } | null;
  } | null> | null;
};

export type GetSubmitPaymentAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSubmitPaymentAccountsQuery = {
  __typename?: 'RootQueryType';
  depositAccounts?: Array<{
    __typename?: 'DepositAccount';
    id?: string | null;
    depositAccountNumber?: { __typename?: 'DepositAccountNumber'; lastFour?: string | null } | null;
  } | null> | null;
};

export type GetDepositAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDepositAccountsQuery = {
  __typename?: 'RootQueryType';
  depositAccounts?: Array<{
    __typename?: 'DepositAccount';
    id?: string | null;
    openedAt?: string | null;
    accountType?: DepositAccountType | null;
    balanceInCents?: number | null;
    accountStatus?: DepositAccountStatus | null;
    isPendingJointAccount?: boolean | null;
    depositAccountNumber?: {
      __typename?: 'DepositAccountNumber';
      routingNumber?: string | null;
      accountNumber?: string | null;
      lastFour?: string | null;
    } | null;
    plans?: Array<{
      __typename?: 'Plan';
      accountNumber?: string | null;
      routingNumber?: string | null;
      id?: string | null;
      title?: string | null;
      color?: string | null;
      goalAmountInCents?: string | null;
      balanceInCents?: number | null;
      createdAt?: string | null;
    } | null> | null;
    apy?: { __typename?: 'Apy'; apyInterestRate?: string | null } | null;
    depositAccountBalances?: {
      __typename?: 'DepositAccountBalances';
      availableBalanceInCents?: number | null;
      totalPlansBalance?: number | null;
      balanceInCents?: number | null;
    } | null;
    pendingJointAccountInvite?: {
      __typename?: 'JointAccountInvite';
      inviterId?: string | null;
      inviteeEmail?: string | null;
      inviteeFirstName?: string | null;
      inviteeLastName?: string | null;
    } | null;
    odfiTransferLimit?: {
      __typename?: 'OdfiTransferLimit';
      pushAmountInCents?: number | null;
      pullAmountInCents?: number | null;
    } | null;
  } | null> | null;
};

export type GetAccountDetailsDepositAccountQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountDetailsDepositAccountQuery = {
  __typename?: 'RootQueryType';
  depositAccounts?: Array<{
    __typename?: 'DepositAccount';
    id?: string | null;
    accountType?: DepositAccountType | null;
    balanceInCents?: number | null;
    accountStatus?: DepositAccountStatus | null;
    depositAccountNumber?: {
      __typename?: 'DepositAccountNumber';
      routingNumber?: string | null;
      accountNumber?: string | null;
    } | null;
    depositAccountOwners?: Array<{
      __typename?: 'DepositAccountOwner';
      ownerId?: string | null;
      person?: {
        __typename?: 'Person';
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type GetBeneficiaryOfDepositAccountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBeneficiaryOfDepositAccountQuery = {
  __typename?: 'RootQueryType';
  depositAccount?: {
    __typename?: 'DepositAccount';
    id?: string | null;
    beneficiary?: {
      __typename?: 'DepositAccountBeneficiary';
      id?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      instructionType?: string | null;
    } | null;
  } | null;
};

export type GetStatementsOfDepositAccountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetStatementsOfDepositAccountQuery = {
  __typename?: 'RootQueryType';
  depositAccount?: {
    __typename?: 'DepositAccount';
    id?: string | null;
    statements?: Array<{
      __typename?: 'DepositAccountStatement';
      id?: string | null;
      periodStartDate?: string | null;
      periodEndDate?: string | null;
      requestType?: string | null;
      tempUrl?: string | null;
    } | null> | null;
  } | null;
};

export type AddBeneficiaryOfDepositAccountMutationVariables = Exact<{
  depositAccountId: Scalars['Uuid'];
  firstName: Scalars['String'];
  middleName: Scalars['String'];
  lastName: Scalars['String'];
  suffix: Scalars['String'];
  birthDate: Scalars['Date'];
  nationalIdNumberToken: Scalars['Vgstoken'];
  addressLineOne: Scalars['String'];
  addressLineTwo: Scalars['String'];
  addressCity: Scalars['String'];
  addressPostalCode: Scalars['String'];
  addressStateCode: Scalars['String'];
  addressCountryCode: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  instructionType: DepositAccountBeneficiaryInstructionType;
}>;

export type AddBeneficiaryOfDepositAccountMutation = {
  __typename?: 'RootMutationType';
  assignDepositAccountBeneficiary?: {
    __typename?: 'AssignDepositAccountBeneficiaryPayload';
    id?: string | null;
  } | null;
};

export type RemoveBeneficiaryOfDepositAccountMutationVariables = Exact<{
  depositAccountId: Scalars['Uuid'];
  beneficiaryId: Scalars['Uuid'];
}>;

export type RemoveBeneficiaryOfDepositAccountMutation = {
  __typename?: 'RootMutationType';
  removeDepositAccountBeneficiary?: {
    __typename?: 'RemoveDepositAccountBeneficiaryPayload';
    id?: string | null;
  } | null;
};

export type GetDepositAccountTransactionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDepositAccountTransactionQuery = {
  __typename?: 'RootQueryType';
  depositAccount?: {
    __typename?: 'DepositAccount';
    accountType?: DepositAccountType | null;
    isPendingJointAccount?: boolean | null;
    depositAccountNumber?: { __typename?: 'DepositAccountNumber'; lastFour?: string | null } | null;
    transactions?: Array<{
      __typename?: 'Transaction';
      id?: string | null;
      transferType: TransferType;
      fromAccountId?: string | null;
      fromAccountType?: string | null;
      toAccountId?: string | null;
      toAccountType?: string | null;
      postedAt?: string | null;
      initiatedAt?: string | null;
      description?: string | null;
      amountInCents?: string | null;
      status: TransactionStatus;
      balanceInCents?: string | null;
      isCredit?: boolean | null;
      isDirectDeposit?: boolean | null;
      category?: TransactionCategories | null;
      scheduledFor?: string | null;
      cancelledAt?: string | null;
      planTitle?: string | null;
      planBalanceInCents?: string | null;
      planAccountNumber?: string | null;
      routine?: {
        __typename?: 'RoutineMetadata';
        routineId?: string | null;
        isArchived?: boolean | null;
        isEnabled?: boolean | null;
        lastRunAt?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetDepositAccountByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDepositAccountByIdQuery = {
  __typename?: 'RootQueryType';
  depositAccount?: {
    __typename?: 'DepositAccount';
    accountType?: DepositAccountType | null;
    accountStatus?: DepositAccountStatus | null;
    balanceInCents?: number | null;
    isPendingJointAccount?: boolean | null;
    depositAccountNumber?: {
      __typename?: 'DepositAccountNumber';
      routingNumber?: string | null;
      accountNumber?: string | null;
      lastFour?: string | null;
    } | null;
    depositAccountBalances?: {
      __typename?: 'DepositAccountBalances';
      balanceInCents?: number | null;
      availableBalanceInCents?: number | null;
      pendingBalanceInCents?: number | null;
      totalPlansBalance?: number | null;
    } | null;
    pendingJointAccountInvite?: {
      __typename?: 'JointAccountInvite';
      inviterId?: string | null;
      inviteeEmail?: string | null;
      inviteeFirstName?: string | null;
      inviteeLastName?: string | null;
    } | null;
  } | null;
};

export type GetDepositAccountsTransferLimitsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDepositAccountsTransferLimitsQuery = {
  __typename?: 'RootQueryType';
  depositAccounts?: Array<{
    __typename?: 'DepositAccount';
    accountType?: DepositAccountType | null;
    id?: string | null;
    openedAt?: string | null;
    accountStatus?: DepositAccountStatus | null;
    depositAccountNumber?: {
      __typename?: 'DepositAccountNumber';
      routingNumber?: string | null;
      accountNumber?: string | null;
      lastFour?: string | null;
    } | null;
    depositAccountBalances?: { __typename?: 'DepositAccountBalances'; availableBalanceInCents?: number | null } | null;
    odfiTransferLimit?: {
      __typename?: 'OdfiTransferLimit';
      pushAmountInCents?: number | null;
      pullAmountInCents?: number | null;
    } | null;
  } | null> | null;
};

export type GetDepositAccountEarningQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDepositAccountEarningQuery = {
  __typename?: 'RootQueryType';
  depositAccount?: {
    __typename?: 'DepositAccount';
    interest?: {
      __typename?: 'Interest';
      apyInterestRate?: string | null;
      totalAccruedInterestInCents?: number | null;
      monthlyAccruedInterestInCents?: number | null;
    } | null;
    apy?: { __typename?: 'Apy'; apyInterestRate?: string | null } | null;
  } | null;
};

export type CanOpenIndividualDepositAccountQueryVariables = Exact<{ [key: string]: never }>;

export type CanOpenIndividualDepositAccountQuery = {
  __typename?: 'RootQueryType';
  canOpenIndividualDepositAccount?: boolean | null;
};

export type RequestIndividualDepositAccountMutationVariables = Exact<{ [key: string]: never }>;

export type RequestIndividualDepositAccountMutation = {
  __typename?: 'RootMutationType';
  requestIndividualDepositAccount?: {
    __typename?: 'RequestIndividualDepositAccountPayload';
    id?: string | null;
  } | null;
};

export type GetTaxDocumentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTaxDocumentsQuery = {
  __typename?: 'RootQueryType';
  depositAccounts?: Array<{
    __typename?: 'DepositAccount';
    id?: string | null;
    taxDocuments?: Array<{
      __typename?: 'DepositAccount1099';
      id?: string | null;
      taxYear?: number | null;
      fileKey?: string | null;
      requestType?: string | null;
      tempUrl?: string | null;
    } | null> | null;
  } | null> | null;
};

export type InitiateInternalTransferMutationVariables = Exact<{
  amountInCents: Scalars['TransferAmountInCents'];
  fromAccountId: Scalars['Uuid'];
  toAccountId: Scalars['Uuid'];
  scheduledFor?: InputMaybe<Scalars['DateTime']>;
}>;

export type InitiateInternalTransferMutation = {
  __typename?: 'RootMutationType';
  initiateInternalTransfer?: { __typename?: 'InitiateInternalTransferPayload'; id?: string | null } | null;
};

export type InitiateOdfiTransferMutationVariables = Exact<{
  type: OdfiTransferType;
  amountInCents: Scalars['TransferAmountInCents'];
  depositAccountId: Scalars['Uuid'];
  externalAccountId: Scalars['Uuid'];
  scheduledFor?: InputMaybe<Scalars['DateTime']>;
  odfiPlanTransferData?: InputMaybe<OdfiPlanTransferData>;
  legacyExternalAccounts?: InputMaybe<Scalars['Boolean']>;
}>;

export type InitiateOdfiTransferMutation = {
  __typename?: 'RootMutationType';
  createNewOdfiTransfer?: { __typename?: 'CreateNewOdfiTransferPayload'; odfiAchTransferId?: string | null } | null;
};

export type GetClosedDepositAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClosedDepositAccountsQuery = {
  __typename?: 'RootQueryType';
  depositAccounts?: Array<{
    __typename?: 'DepositAccount';
    id?: string | null;
    openedAt?: string | null;
    accountType?: DepositAccountType | null;
    balanceInCents?: number | null;
    accountStatus?: DepositAccountStatus | null;
    depositAccountNumber?: {
      __typename?: 'DepositAccountNumber';
      routingNumber?: string | null;
      accountNumber?: string | null;
      lastFour?: string | null;
    } | null;
    plans?: Array<{ __typename?: 'Plan'; balanceInCents?: number | null } | null> | null;
    apy?: { __typename?: 'Apy'; apyInterestRate?: string | null } | null;
    depositAccountBalances?: {
      __typename?: 'DepositAccountBalances';
      availableBalanceInCents?: number | null;
      totalPlansBalance?: number | null;
    } | null;
  } | null> | null;
};

export type GetApyTrackingByIdAndDateQueryVariables = Exact<{
  depositAccountId: Scalars['Uuid'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
}>;

export type GetApyTrackingByIdAndDateQuery = {
  __typename?: 'RootQueryType';
  apyTracking?: {
    __typename?: 'ApyTracking';
    depositedAmount?: {
      __typename?: 'ApyTrackingValue';
      currentValue?: number | null;
      isWaived?: boolean | null;
      onTrackToMeetRequirement?: boolean | null;
      waivedUntil?: string | null;
    } | null;
  } | null;
};

export type GetMonthlyCashflowByAccountIdQueryVariables = Exact<{
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
}>;

export type GetMonthlyCashflowByAccountIdQuery = {
  __typename?: 'RootQueryType';
  monthlyCashFlow?: Array<{
    __typename?: 'MonthCashFlow';
    date?: string | null;
    savingRate?: number | null;
    totalDepositsAmount?: number | null;
    totalNetAmount?: number | null;
    totalWithdrawalsAmount?: number | null;
  } | null> | null;
};

export type CancelInternalScheduledTransferMutationVariables = Exact<{
  internalTransferId: Scalars['Uuid'];
}>;

export type CancelInternalScheduledTransferMutation = {
  __typename?: 'RootMutationType';
  cancelInternalScheduledTransfer?: {
    __typename?: 'CancelInternalTransferScheduledPayload';
    status?: string | null;
  } | null;
};

export type CancelOdfiScheduledTransferMutationVariables = Exact<{
  odfiAchTransferId: Scalars['Uuid'];
}>;

export type CancelOdfiScheduledTransferMutation = {
  __typename?: 'RootMutationType';
  cancelOdfiScheduledTransfer?: { __typename?: 'CancelOdfiTransferScheduledPayload'; status?: string | null } | null;
};

export type GetDepositAccountsWithTransactionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDepositAccountsWithTransactionsQuery = {
  __typename?: 'RootQueryType';
  depositAccounts?: Array<{
    __typename?: 'DepositAccount';
    id?: string | null;
    accountType?: DepositAccountType | null;
    accountStatus?: DepositAccountStatus | null;
    isPendingJointAccount?: boolean | null;
    depositAccountNumber?: { __typename?: 'DepositAccountNumber'; lastFour?: string | null } | null;
    transactions?: Array<{
      __typename?: 'Transaction';
      id?: string | null;
      postedAt?: string | null;
      amountInCents?: string | null;
      description?: string | null;
      transferType: TransferType;
      toAccountId?: string | null;
      status: TransactionStatus;
      fromAccountType?: string | null;
      fromAccountId?: string | null;
      toAccountType?: string | null;
      initiatedAt?: string | null;
      cancelledAt?: string | null;
      scheduledFor?: string | null;
    } | null> | null;
  } | null> | null;
};

export type RequestOutgoingWireMutationVariables = Exact<{
  originatorAccountNumber: Scalars['String'];
  beneficiaryBankName: Scalars['String'];
  beneficiaryRoutingNumber: Scalars['String'];
  beneficiaryAccountNumber: Scalars['String'];
  beneficiaryName: Scalars['String'];
  beneficiaryAddressLine1: Scalars['String'];
  beneficiaryCity: Scalars['String'];
  beneficiaryStateCode: Scalars['String'];
  beneficiaryPostalCode: Scalars['String'];
  memo: Scalars['String'];
  amountInCents: Scalars['Int'];
  originatorToBeneficiaryLine1?: InputMaybe<OriginatorToBeneficiaryTypes>;
  originatorToBeneficiaryLine2?: InputMaybe<Scalars['String']>;
  consent: Consent;
}>;

export type RequestOutgoingWireMutation = {
  __typename?: 'RootMutationType';
  requestOutgoingWireTransferV2?: {
    __typename?: 'RequestOutgoingWireTransferV2Payload';
    transferId?: string | null;
  } | null;
};

export type GetOutgoingWiresQueryVariables = Exact<{ [key: string]: never }>;

export type GetOutgoingWiresQuery = {
  __typename?: 'RootQueryType';
  outgoingWireTransfers?: Array<{
    __typename?: 'OutgoingWireTransfer';
    amountInCents?: number | null;
    beneficiaryAccountNumber?: string | null;
    beneficiaryAddressLine1?: string | null;
    beneficiaryAddressLine2?: string | null;
    beneficiaryBankName?: string | null;
    beneficiaryName?: string | null;
    beneficiaryRoutingNumber?: string | null;
    fromAccountId?: string | null;
    fromAccountType?: string | null;
    memo?: string | null;
    fedConfirmationNumber?: string | null;
    requestedAt?: string | null;
    postedAt?: string | null;
    requestedBy?: string | null;
    status?: WireStatusTypes | null;
    transferId?: string | null;
    originatorName?: string | null;
    originatorAccountNumber?: string | null;
    originatorBankName?: string | null;
    originatorRoutingNumber?: string | null;
    originatorToBeneficiaryLine1?: string | null;
    originatorToBeneficiaryLine2?: string | null;
  } | null> | null;
};

export type InitiateDepositAccountClosureWithTransferMutationVariables = Exact<{
  depositAccountId: Scalars['Uuid'];
  externalAccountId: Scalars['Uuid'];
  closureReasons: Array<InputMaybe<DepositAccountClosureReason>> | InputMaybe<DepositAccountClosureReason>;
}>;

export type InitiateDepositAccountClosureWithTransferMutation = {
  __typename?: 'RootMutationType';
  initiateDepositAccountClosureWithTransfer?: {
    __typename?: 'InitiateDepositAccountClosureWithTransferPayload';
    status?: string | null;
  } | null;
};

export type InitiateDepositAccountClosureWithoutTransferMutationVariables = Exact<{
  depositAccountId: Scalars['Uuid'];
  closureReason: Array<InputMaybe<DepositAccountClosureReason>> | InputMaybe<DepositAccountClosureReason>;
}>;

export type InitiateDepositAccountClosureWithoutTransferMutation = {
  __typename?: 'RootMutationType';
  initiateDepositAccountClosureWithoutTransfer?: {
    __typename?: 'InitiateDepositAccountClosureWithoutTransferPayload';
    status?: string | null;
  } | null;
};

export type CustomerExternalAccountCheckMutationVariables = Exact<{
  depositAccountId: Scalars['Uuid'];
}>;

export type CustomerExternalAccountCheckMutation = {
  __typename?: 'RootMutationType';
  customerExternalAccountCheck?: {
    __typename?: 'CustomerExternalAccountCheckPayload';
    externalAccountRequired?: string | null;
    accountBalancesSumInCents?: number | null;
    accruedBalanceInCents?: number | null;
  } | null;
};

export type CreatePlaidLinkTokenMutationVariables = Exact<{
  externalAccountId?: InputMaybe<Scalars['Uuid']>;
  linkType?: InputMaybe<LinkType>;
}>;

export type CreatePlaidLinkTokenMutation = {
  __typename?: 'RootMutationType';
  createPlaidLinkToken?: {
    __typename?: 'CreatePlaidLinkTokenPayload';
    linkToken?: string | null;
    expiration?: string | null;
  } | null;
};

export type CreatePlaidLinkTokenWithoutTransactionsProductMutationVariables = Exact<{ [key: string]: never }>;

export type CreatePlaidLinkTokenWithoutTransactionsProductMutation = {
  __typename?: 'RootMutationType';
  createPlaidLinkTokenWithoutTransactionsProduct?: {
    __typename?: 'CreatePlaidLinkTokenPayload';
    linkToken?: string | null;
    expiration?: string | null;
  } | null;
};

export type ExchangePlaidPublicItemTokenMutationVariables = Exact<{
  publicToken: Scalars['String'];
  metadata: Scalars['Json'];
}>;

export type ExchangePlaidPublicItemTokenMutation = {
  __typename?: 'RootMutationType';
  exchangePlaidPublicItemToken?: { __typename?: 'ExchangePlaidPublicItemTokenPayload'; status?: string | null } | null;
};

export type UpdatePlaidItemMutationVariables = Exact<{
  externalAccountId: Scalars['Uuid'];
  action: LinkType;
}>;

export type UpdatePlaidItemMutation = {
  __typename?: 'RootMutationType';
  updatePlaidItem?: { __typename?: 'UpdatePlaidItemPayload'; status?: string | null } | null;
};

export type ArchiveExternalAccountMutationVariables = Exact<{
  externalAccountId: Scalars['Uuid'];
}>;

export type ArchiveExternalAccountMutation = {
  __typename?: 'RootMutationType';
  archiveExternalAccount?: { __typename?: 'ArchiveExternalAccountPayload'; status?: string | null } | null;
};

export type UpdateExternalAccountMutationVariables = Exact<{
  externalAccountId: Scalars['Uuid'];
  nickname: Scalars['String'];
}>;

export type UpdateExternalAccountMutation = {
  __typename?: 'RootMutationType';
  updateExternalAccount?: { __typename?: 'UpdateExternalAccountPayload'; status?: string | null } | null;
};

export type GetExternalAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetExternalAccountsQuery = {
  __typename?: 'RootQueryType';
  externalAccounts?: Array<{
    __typename?: 'ExternalAccount';
    accountLastFour?: string | null;
    accountSource?: AccountSource | null;
    accountStatus?: ExternalAccountStatus | null;
    accountType?: string | null;
    id?: string | null;
    isVerified?: boolean | null;
    nickname?: string | null;
    ownerId?: string | null;
    routingNumber?: string | null;
    updateReason?: string | null;
    updateRequired?: boolean | null;
    plaidInstitution?: { __typename?: 'PlaidInstitution'; logo?: string | null; name?: string | null } | null;
    plaidBalance?: {
      __typename?: 'PlaidBalance';
      availableBalanceInCents?: number | null;
      lastUpdatedAt?: string | null;
    } | null;
  } | null> | null;
};

export type InitiateJointAccountInviteMutationVariables = Exact<{
  depositAccountId?: InputMaybe<Scalars['Uuid']>;
  inviteeAddressCity: Scalars['String'];
  inviteeAddressCountryCode: Scalars['String'];
  inviteeAddressLineOne: Scalars['String'];
  inviteeAddressLineTwo?: InputMaybe<Scalars['String']>;
  inviteeAddressPostalCode: Scalars['String'];
  inviteeAddressStateCode: Scalars['String'];
  inviteeDateOfBirth: Scalars['Date'];
  inviteeEmail: Scalars['String'];
  inviteeFirstName: Scalars['String'];
  inviteeLastName: Scalars['String'];
  inviteeNationalIdNumberToken: Scalars['Vgstoken'];
}>;

export type InitiateJointAccountInviteMutation = {
  __typename?: 'RootMutationType';
  initiateJointAccountInvite?: { __typename?: 'InitiateJointAccountInvitePayload'; inviteId?: string | null } | null;
};

export type AcceptJointAccountInviteMutationVariables = Exact<{
  inviteId: Scalars['Uuid'];
  sendDebitCard: Scalars['Boolean'];
}>;

export type AcceptJointAccountInviteMutation = {
  __typename?: 'RootMutationType';
  acceptJointAccountInvite?: { __typename?: 'AcceptJointAccountInvitePayload'; inviteId?: string | null } | null;
};

export type GetJointAccountInvitesQueryVariables = Exact<{ [key: string]: never }>;

export type GetJointAccountInvitesQuery = {
  __typename?: 'RootQueryType';
  jointAccountInvites?: Array<{
    __typename?: 'JointAccountInvite';
    inviteId?: string | null;
    inviterEmail?: string | null;
    inviterFirstName?: string | null;
    inviterId?: string | null;
    inviterLastName?: string | null;
  } | null> | null;
};

export type GetCanOpenPendingJointAccountQueryVariables = Exact<{ [key: string]: never }>;

export type GetCanOpenPendingJointAccountQuery = {
  __typename?: 'RootQueryType';
  canOpenPendingJointAccount?: boolean | null;
};

export type GetOfferedApyValuesQueryVariables = Exact<{
  id: Scalars['Uuid'];
}>;

export type GetOfferedApyValuesQuery = {
  __typename?: 'RootQueryType';
  offeredApyValues?: Array<{
    __typename?: 'OfferedApyValue';
    apyInterestRate?: string | null;
    apyLevel?: string | null;
  } | null> | null;
};

export type GetDepositAccountPlansQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDepositAccountPlansQuery = {
  __typename?: 'RootQueryType';
  depositAccount?: {
    __typename?: 'DepositAccount';
    accountType?: DepositAccountType | null;
    balanceInCents?: number | null;
    isPendingJointAccount?: boolean | null;
    depositAccountBalances?: { __typename?: 'DepositAccountBalances'; availableBalanceInCents?: number | null } | null;
    plans?: Array<{
      __typename?: 'Plan';
      accountNumber?: string | null;
      routingNumber?: string | null;
      id?: string | null;
      title?: string | null;
      color?: string | null;
      goalAmountInCents?: string | null;
      description?: string | null;
      icon?: string | null;
      createdAt?: string | null;
      balanceInCents?: number | null;
      depositAccountId?: string | null;
      goalDate?: string | null;
      monthlyContributionGoalAmountInCents?: number | null;
      planBalances?: {
        __typename?: 'PlanBalances';
        availableBalanceInCents?: number | null;
        balanceInCents?: number | null;
        pendingBalanceInCents?: number | null;
      } | null;
    } | null> | null;
    apy?: { __typename?: 'Apy'; apyInterestRate?: string | null } | null;
    depositAccountNumber?: {
      __typename?: 'DepositAccountNumber';
      lastFour?: string | null;
      accountNumber?: string | null;
    } | null;
  } | null;
};

export type GetPlanDetailsTransactionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPlanDetailsTransactionQuery = {
  __typename?: 'RootQueryType';
  depositAccount?: {
    __typename?: 'DepositAccount';
    plans?: Array<{
      __typename?: 'Plan';
      id?: string | null;
      transactions?: Array<{
        __typename?: 'Transaction';
        id?: string | null;
        transferType: TransferType;
        fromAccountId?: string | null;
        fromAccountType?: string | null;
        toAccountId?: string | null;
        toAccountType?: string | null;
        postedAt?: string | null;
        initiatedAt?: string | null;
        description?: string | null;
        amountInCents?: string | null;
        status: TransactionStatus;
        balanceInCents?: string | null;
        isCredit?: boolean | null;
        isDirectDeposit?: boolean | null;
        category?: TransactionCategories | null;
        scheduledFor?: string | null;
        cancelledAt?: string | null;
        planTitle?: string | null;
        planBalanceInCents?: string | null;
        planAccountNumber?: string | null;
        routine?: {
          __typename?: 'RoutineMetadata';
          routineId?: string | null;
          isArchived?: boolean | null;
          isEnabled?: boolean | null;
          lastRunAt?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type RequestPlanAccountNumberMutationVariables = Exact<{
  planId: Scalars['Uuid'];
}>;

export type RequestPlanAccountNumberMutation = {
  __typename?: 'RootMutationType';
  requestPlanAccountNumber?: { __typename?: 'RequestPlanAccountNumberPayload'; planId?: string | null } | null;
};

export type RemovePlanAccountNumberMutationVariables = Exact<{
  planId: Scalars['Uuid'];
  accountNumber?: InputMaybe<Scalars['String']>;
}>;

export type RemovePlanAccountNumberMutation = {
  __typename?: 'RootMutationType';
  removePlanAccountNumber?: { __typename?: 'RemovePlanAccountNumberPayload'; planId?: string | null } | null;
};

export type CreatePlanMutationVariables = Exact<{
  title: Scalars['String'];
  color: Scalars['String'];
  goalAmountInCents?: InputMaybe<Scalars['TransferAmountInCents']>;
  description: Scalars['String'];
  depositAccountId: Scalars['Uuid'];
  initialContributionAmountInCents?: InputMaybe<Scalars['TransferAmountInCents']>;
  monthlyContributionGoalAmountInCents?: InputMaybe<Scalars['TransferAmountInCents']>;
  goalDate?: InputMaybe<Scalars['Date']>;
  assignAccountNumber?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<PlanTypes>;
}>;

export type CreatePlanMutation = {
  __typename?: 'RootMutationType';
  createPlan?: { __typename?: 'CreatePlanPayload'; planId?: string | null } | null;
};

export type ArchivePlanMutationVariables = Exact<{
  planId: Scalars['Uuid'];
}>;

export type ArchivePlanMutation = {
  __typename?: 'RootMutationType';
  archivePlan?: { __typename?: 'ArchivePlanPayload'; planId?: string | null } | null;
};

export type UpdatePlanMutationVariables = Exact<{
  planId: Scalars['Uuid'];
  title: Scalars['String'];
  color: Scalars['String'];
  goalAmountInCents?: InputMaybe<Scalars['TransferAmountInCents']>;
  description?: InputMaybe<Scalars['String']>;
  goalDate?: InputMaybe<Scalars['Date']>;
  monthlyContributionGoalAmountInCents?: InputMaybe<Scalars['TransferAmountInCents']>;
}>;

export type UpdatePlanMutation = {
  __typename?: 'RootMutationType';
  updatePlan?: { __typename?: 'UpdatePlanPayload'; planId?: string | null } | null;
};

export type InitiatePlanTransferMutationVariables = Exact<{
  amountInCents: Scalars['TransferAmountInCents'];
  depositAccountId: Scalars['Uuid'];
  fromAccountId: Scalars['Uuid'];
  fromAccountType: PlanTransferAccountType;
  toAccountId: Scalars['Uuid'];
  toAccountType: PlanTransferAccountType;
  scheduledFor?: InputMaybe<Scalars['DateTime']>;
}>;

export type InitiatePlanTransferMutation = {
  __typename?: 'RootMutationType';
  initiatePlanTransfer?: { __typename?: 'InitiatePlanTransferPayload'; id?: string | null } | null;
};

type SubAction_CashbackRedemptionAction_Fragment = {
  __typename: 'CashbackRedemptionAction';
  type: ActionTypes;
  version: string;
  cashbackRedemptionActionOptions: {
    __typename?: 'CashbackRedemptionOptions';
    externalAccountId?: string | null;
    depositAccountId?: string | null;
  };
};

type SubAction_CategorizeAction_Fragment = {
  __typename: 'CategorizeAction';
  type: ActionTypes;
  version: string;
  categorizeActionOptions: {
    __typename?: 'CategorizeOptions';
    depositAccountId: string;
    category: TransactionCategories;
  };
};

type SubAction_CreditCardPaymentAction_Fragment = {
  __typename: 'CreditCardPaymentAction';
  type: ActionTypes;
  version: string;
  creditCardPaymentActionOptions: {
    __typename?: 'CreditCardPaymentOptions';
    creditCardAccountId: string;
    customAmountInCents?: number | null;
    fromAccountId: string;
    fromAccountType: CreditCardFromAccountTypes;
    paymentBalancePercentage?: number | null;
    paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
  };
};

type SubAction_ExternalTransferAction_Fragment = {
  __typename: 'ExternalTransferAction';
  type: ActionTypes;
  version: string;
  externalTransferAction: {
    __typename?: 'ExternalTransferOptions';
    depositAccountId: string;
    externalAccountId: string;
    amountInCents?: number | null;
    percentage?: number | null;
    type: OdfiTransferType;
  };
};

type SubAction_InternalTransferAction_Fragment = {
  __typename: 'InternalTransferAction';
  type: ActionTypes;
  version: string;
  internalTransferActionOptions: {
    __typename?: 'InternalTransferOptions';
    fromDepositAccountId: string;
    toDepositAccountId: string;
    amountInCents?: number | null;
    percentage?: number | null;
  };
};

type SubAction_MultiAction_Fragment = { __typename?: 'MultiAction' };

type SubAction_MultiPlanTransferAction_Fragment = {
  __typename: 'MultiPlanTransferAction';
  type: ActionTypes;
  version: string;
  multiPlanTransferActionOptions: {
    __typename?: 'MultiPlanTransferOptions';
    depositAccountId: string;
    direction: TransferDirection;
    transfers: Array<{
      __typename?: 'MultiPlanTransfer';
      planId: string;
      amountInCents?: number | null;
      percentage?: number | null;
    } | null>;
  };
};

type SubAction_NotificationAction_Fragment = {
  __typename: 'NotificationAction';
  type: ActionTypes;
  version: string;
  notificationActionOptions: {
    __typename?: 'NotificationActionOptions';
    sms?: boolean | null;
    push?: boolean | null;
    email?: boolean | null;
    message?: string | null;
  };
};

type SubAction_PlanTransferAction_Fragment = {
  __typename: 'PlanTransferAction';
  type: ActionTypes;
  version: string;
  planTransferActionOptions: {
    __typename?: 'PlanTransferOptions';
    depositAccountId: string;
    fromAccountId: string;
    fromAccountType: PlanTransferAccountType;
    toAccountId: string;
    toAccountType: PlanTransferAccountType;
    amountInCents?: number | null;
    percentage?: number | null;
  };
};

type SubAction_WebhookAction_Fragment = {
  __typename: 'WebhookAction';
  type: ActionTypes;
  version: string;
  webhookActionOptions: {
    __typename?: 'WebhookOptions';
    url: string;
    name: string;
    description?: string | null;
    webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
  };
};

export type SubActionFragment =
  | SubAction_CashbackRedemptionAction_Fragment
  | SubAction_CategorizeAction_Fragment
  | SubAction_CreditCardPaymentAction_Fragment
  | SubAction_ExternalTransferAction_Fragment
  | SubAction_InternalTransferAction_Fragment
  | SubAction_MultiAction_Fragment
  | SubAction_MultiPlanTransferAction_Fragment
  | SubAction_NotificationAction_Fragment
  | SubAction_PlanTransferAction_Fragment
  | SubAction_WebhookAction_Fragment;

type Trigger_BalanceTrigger_Fragment = {
  __typename: 'BalanceTrigger';
  type: TriggerTypes;
  version: string;
  balanceTriggerOptions: {
    __typename?: 'BalanceTriggerOptions';
    depositAccountId: string;
    amountInCents: number;
    comparison: ComparisonTypes;
  };
};

type Trigger_CashbackRedemptionTrigger_Fragment = {
  __typename: 'CashbackRedemptionTrigger';
  type: TriggerTypes;
  version: string;
  cashbackRedemptionTriggerOptions: {
    __typename?: 'CashbackRedemptionTriggerOptions';
    amount: number;
    accountId: string;
  };
};

type Trigger_CategoryTrigger_Fragment = {
  __typename: 'CategoryTrigger';
  type: TriggerTypes;
  version: string;
  categoryTriggerOptions: { __typename?: 'CategoryTriggerOptions'; depositAccountId: string; category: CategoryTypes };
};

type Trigger_DateTrigger_Fragment = {
  __typename: 'DateTrigger';
  type: TriggerTypes;
  version: string;
  dateTriggerOptions: { __typename?: 'DateTriggerOptions'; pattern: string };
};

type Trigger_DepositTransactionTrigger_Fragment = {
  __typename: 'DepositTransactionTrigger';
  type: TriggerTypes;
  version: string;
  depositTransactionTriggerOptions: {
    __typename?: 'DepositTransactionTriggerOptions';
    depositAccountId: string;
    conditions: {
      __typename?: 'DepositTransactionConditions';
      category?: TransactionCategories | null;
      direction?: TransferDirection | null;
      exactMatch?: string | null;
      greaterThan?: number | null;
      isDirectDeposit?: boolean | null;
      lessThan?: number | null;
      partialMatch?: string | null;
    };
  };
};

type Trigger_DescriptionTrigger_Fragment = {
  __typename: 'DescriptionTrigger';
  type: TriggerTypes;
  version: string;
  descriptionTriggerOptions: {
    __typename?: 'DescriptionTriggerOptions';
    depositAccountId: string;
    description: string;
  };
};

type Trigger_ExternalBalanceTrigger_Fragment = { __typename?: 'ExternalBalanceTrigger' };

type Trigger_UserCategoryTrigger_Fragment = {
  __typename: 'UserCategoryTrigger';
  type: TriggerTypes;
  version: string;
  userCategoryTriggerOptions: {
    __typename?: 'UserCategoryTriggerOptions';
    depositAccountId: string;
    userCategory: TransactionCategories;
  };
};

export type TriggerFragment =
  | Trigger_BalanceTrigger_Fragment
  | Trigger_CashbackRedemptionTrigger_Fragment
  | Trigger_CategoryTrigger_Fragment
  | Trigger_DateTrigger_Fragment
  | Trigger_DepositTransactionTrigger_Fragment
  | Trigger_DescriptionTrigger_Fragment
  | Trigger_ExternalBalanceTrigger_Fragment
  | Trigger_UserCategoryTrigger_Fragment;

type Action_CashbackRedemptionAction_Fragment = {
  __typename: 'CashbackRedemptionAction';
  type: ActionTypes;
  version: string;
  cashbackRedemptionActionOptions: {
    __typename?: 'CashbackRedemptionOptions';
    externalAccountId?: string | null;
    depositAccountId?: string | null;
  };
};

type Action_CategorizeAction_Fragment = {
  __typename: 'CategorizeAction';
  type: ActionTypes;
  version: string;
  categorizeActionOptions: {
    __typename?: 'CategorizeOptions';
    depositAccountId: string;
    category: TransactionCategories;
  };
};

type Action_CreditCardPaymentAction_Fragment = {
  __typename: 'CreditCardPaymentAction';
  type: ActionTypes;
  version: string;
  creditCardPaymentActionOptions: {
    __typename?: 'CreditCardPaymentOptions';
    creditCardAccountId: string;
    customAmountInCents?: number | null;
    fromAccountId: string;
    fromAccountType: CreditCardFromAccountTypes;
    paymentBalancePercentage?: number | null;
    paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
  };
};

type Action_ExternalTransferAction_Fragment = {
  __typename: 'ExternalTransferAction';
  type: ActionTypes;
  version: string;
  externalTransferAction: {
    __typename?: 'ExternalTransferOptions';
    depositAccountId: string;
    externalAccountId: string;
    amountInCents?: number | null;
    percentage?: number | null;
    type: OdfiTransferType;
  };
};

type Action_InternalTransferAction_Fragment = {
  __typename: 'InternalTransferAction';
  type: ActionTypes;
  version: string;
  internalTransferActionOptions: {
    __typename?: 'InternalTransferOptions';
    fromDepositAccountId: string;
    toDepositAccountId: string;
    amountInCents?: number | null;
    percentage?: number | null;
  };
};

type Action_MultiAction_Fragment = {
  __typename: 'MultiAction';
  type: ActionTypes;
  version: string;
  options: {
    __typename?: 'MultiActionOptions';
    actions?: Array<
      | {
          __typename: 'CashbackRedemptionAction';
          type: ActionTypes;
          version: string;
          cashbackRedemptionActionOptions: {
            __typename?: 'CashbackRedemptionOptions';
            externalAccountId?: string | null;
            depositAccountId?: string | null;
          };
        }
      | {
          __typename: 'CategorizeAction';
          type: ActionTypes;
          version: string;
          categorizeActionOptions: {
            __typename?: 'CategorizeOptions';
            depositAccountId: string;
            category: TransactionCategories;
          };
        }
      | {
          __typename: 'CreditCardPaymentAction';
          type: ActionTypes;
          version: string;
          creditCardPaymentActionOptions: {
            __typename?: 'CreditCardPaymentOptions';
            creditCardAccountId: string;
            customAmountInCents?: number | null;
            fromAccountId: string;
            fromAccountType: CreditCardFromAccountTypes;
            paymentBalancePercentage?: number | null;
            paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
          };
        }
      | {
          __typename: 'ExternalTransferAction';
          type: ActionTypes;
          version: string;
          externalTransferAction: {
            __typename?: 'ExternalTransferOptions';
            depositAccountId: string;
            externalAccountId: string;
            amountInCents?: number | null;
            percentage?: number | null;
            type: OdfiTransferType;
          };
        }
      | {
          __typename: 'InternalTransferAction';
          type: ActionTypes;
          version: string;
          internalTransferActionOptions: {
            __typename?: 'InternalTransferOptions';
            fromDepositAccountId: string;
            toDepositAccountId: string;
            amountInCents?: number | null;
            percentage?: number | null;
          };
        }
      | {
          __typename: 'MultiPlanTransferAction';
          type: ActionTypes;
          version: string;
          multiPlanTransferActionOptions: {
            __typename?: 'MultiPlanTransferOptions';
            depositAccountId: string;
            direction: TransferDirection;
            transfers: Array<{
              __typename?: 'MultiPlanTransfer';
              planId: string;
              amountInCents?: number | null;
              percentage?: number | null;
            } | null>;
          };
        }
      | {
          __typename: 'NotificationAction';
          type: ActionTypes;
          version: string;
          notificationActionOptions: {
            __typename?: 'NotificationActionOptions';
            sms?: boolean | null;
            push?: boolean | null;
            email?: boolean | null;
            message?: string | null;
          };
        }
      | {
          __typename: 'PlanTransferAction';
          type: ActionTypes;
          version: string;
          planTransferActionOptions: {
            __typename?: 'PlanTransferOptions';
            depositAccountId: string;
            fromAccountId: string;
            fromAccountType: PlanTransferAccountType;
            toAccountId: string;
            toAccountType: PlanTransferAccountType;
            amountInCents?: number | null;
            percentage?: number | null;
          };
        }
      | {
          __typename: 'WebhookAction';
          type: ActionTypes;
          version: string;
          webhookActionOptions: {
            __typename?: 'WebhookOptions';
            url: string;
            name: string;
            description?: string | null;
            webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
          };
        }
      | null
    > | null;
  };
};

type Action_MultiPlanTransferAction_Fragment = {
  __typename: 'MultiPlanTransferAction';
  type: ActionTypes;
  version: string;
  multiPlanTransferActionOptions: {
    __typename?: 'MultiPlanTransferOptions';
    depositAccountId: string;
    direction: TransferDirection;
    transfers: Array<{
      __typename?: 'MultiPlanTransfer';
      planId: string;
      amountInCents?: number | null;
      percentage?: number | null;
    } | null>;
  };
};

type Action_NotificationAction_Fragment = {
  __typename: 'NotificationAction';
  type: ActionTypes;
  version: string;
  notificationActionOptions: {
    __typename?: 'NotificationActionOptions';
    sms?: boolean | null;
    push?: boolean | null;
    email?: boolean | null;
    message?: string | null;
  };
};

type Action_PlanTransferAction_Fragment = {
  __typename: 'PlanTransferAction';
  type: ActionTypes;
  version: string;
  planTransferActionOptions: {
    __typename?: 'PlanTransferOptions';
    depositAccountId: string;
    fromAccountId: string;
    fromAccountType: PlanTransferAccountType;
    toAccountId: string;
    toAccountType: PlanTransferAccountType;
    amountInCents?: number | null;
    percentage?: number | null;
  };
};

type Action_WebhookAction_Fragment = {
  __typename: 'WebhookAction';
  type: ActionTypes;
  version: string;
  webhookActionOptions: {
    __typename?: 'WebhookOptions';
    url: string;
    name: string;
    description?: string | null;
    webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
  };
};

export type ActionFragment =
  | Action_CashbackRedemptionAction_Fragment
  | Action_CategorizeAction_Fragment
  | Action_CreditCardPaymentAction_Fragment
  | Action_ExternalTransferAction_Fragment
  | Action_InternalTransferAction_Fragment
  | Action_MultiAction_Fragment
  | Action_MultiPlanTransferAction_Fragment
  | Action_NotificationAction_Fragment
  | Action_PlanTransferAction_Fragment
  | Action_WebhookAction_Fragment;

export type RoutineFragment = {
  __typename?: 'Routine';
  id: string;
  isEnabled: boolean;
  archivedAt?: string | null;
  lastRunAt?: string | null;
  lastRunStatus?: RoutineExecutionStatus | null;
  createdByName?: string | null;
  createdBy?: string | null;
  isSharedRoutine: boolean;
  name: string;
  description?: string | null;
  templateId?: string | null;
  action:
    | {
        __typename: 'CashbackRedemptionAction';
        type: ActionTypes;
        version: string;
        cashbackRedemptionActionOptions: {
          __typename?: 'CashbackRedemptionOptions';
          externalAccountId?: string | null;
          depositAccountId?: string | null;
        };
      }
    | {
        __typename: 'CategorizeAction';
        type: ActionTypes;
        version: string;
        categorizeActionOptions: {
          __typename?: 'CategorizeOptions';
          depositAccountId: string;
          category: TransactionCategories;
        };
      }
    | {
        __typename: 'CreditCardPaymentAction';
        type: ActionTypes;
        version: string;
        creditCardPaymentActionOptions: {
          __typename?: 'CreditCardPaymentOptions';
          creditCardAccountId: string;
          customAmountInCents?: number | null;
          fromAccountId: string;
          fromAccountType: CreditCardFromAccountTypes;
          paymentBalancePercentage?: number | null;
          paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
        };
      }
    | {
        __typename: 'ExternalTransferAction';
        type: ActionTypes;
        version: string;
        externalTransferAction: {
          __typename?: 'ExternalTransferOptions';
          depositAccountId: string;
          externalAccountId: string;
          amountInCents?: number | null;
          percentage?: number | null;
          type: OdfiTransferType;
        };
      }
    | {
        __typename: 'InternalTransferAction';
        type: ActionTypes;
        version: string;
        internalTransferActionOptions: {
          __typename?: 'InternalTransferOptions';
          fromDepositAccountId: string;
          toDepositAccountId: string;
          amountInCents?: number | null;
          percentage?: number | null;
        };
      }
    | {
        __typename: 'MultiAction';
        type: ActionTypes;
        version: string;
        options: {
          __typename?: 'MultiActionOptions';
          actions?: Array<
            | {
                __typename: 'CashbackRedemptionAction';
                type: ActionTypes;
                version: string;
                cashbackRedemptionActionOptions: {
                  __typename?: 'CashbackRedemptionOptions';
                  externalAccountId?: string | null;
                  depositAccountId?: string | null;
                };
              }
            | {
                __typename: 'CategorizeAction';
                type: ActionTypes;
                version: string;
                categorizeActionOptions: {
                  __typename?: 'CategorizeOptions';
                  depositAccountId: string;
                  category: TransactionCategories;
                };
              }
            | {
                __typename: 'CreditCardPaymentAction';
                type: ActionTypes;
                version: string;
                creditCardPaymentActionOptions: {
                  __typename?: 'CreditCardPaymentOptions';
                  creditCardAccountId: string;
                  customAmountInCents?: number | null;
                  fromAccountId: string;
                  fromAccountType: CreditCardFromAccountTypes;
                  paymentBalancePercentage?: number | null;
                  paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
                };
              }
            | {
                __typename: 'ExternalTransferAction';
                type: ActionTypes;
                version: string;
                externalTransferAction: {
                  __typename?: 'ExternalTransferOptions';
                  depositAccountId: string;
                  externalAccountId: string;
                  amountInCents?: number | null;
                  percentage?: number | null;
                  type: OdfiTransferType;
                };
              }
            | {
                __typename: 'InternalTransferAction';
                type: ActionTypes;
                version: string;
                internalTransferActionOptions: {
                  __typename?: 'InternalTransferOptions';
                  fromDepositAccountId: string;
                  toDepositAccountId: string;
                  amountInCents?: number | null;
                  percentage?: number | null;
                };
              }
            | {
                __typename: 'MultiPlanTransferAction';
                type: ActionTypes;
                version: string;
                multiPlanTransferActionOptions: {
                  __typename?: 'MultiPlanTransferOptions';
                  depositAccountId: string;
                  direction: TransferDirection;
                  transfers: Array<{
                    __typename?: 'MultiPlanTransfer';
                    planId: string;
                    amountInCents?: number | null;
                    percentage?: number | null;
                  } | null>;
                };
              }
            | {
                __typename: 'NotificationAction';
                type: ActionTypes;
                version: string;
                notificationActionOptions: {
                  __typename?: 'NotificationActionOptions';
                  sms?: boolean | null;
                  push?: boolean | null;
                  email?: boolean | null;
                  message?: string | null;
                };
              }
            | {
                __typename: 'PlanTransferAction';
                type: ActionTypes;
                version: string;
                planTransferActionOptions: {
                  __typename?: 'PlanTransferOptions';
                  depositAccountId: string;
                  fromAccountId: string;
                  fromAccountType: PlanTransferAccountType;
                  toAccountId: string;
                  toAccountType: PlanTransferAccountType;
                  amountInCents?: number | null;
                  percentage?: number | null;
                };
              }
            | {
                __typename: 'WebhookAction';
                type: ActionTypes;
                version: string;
                webhookActionOptions: {
                  __typename?: 'WebhookOptions';
                  url: string;
                  name: string;
                  description?: string | null;
                  webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
                };
              }
            | null
          > | null;
        };
      }
    | {
        __typename: 'MultiPlanTransferAction';
        type: ActionTypes;
        version: string;
        multiPlanTransferActionOptions: {
          __typename?: 'MultiPlanTransferOptions';
          depositAccountId: string;
          direction: TransferDirection;
          transfers: Array<{
            __typename?: 'MultiPlanTransfer';
            planId: string;
            amountInCents?: number | null;
            percentage?: number | null;
          } | null>;
        };
      }
    | {
        __typename: 'NotificationAction';
        type: ActionTypes;
        version: string;
        notificationActionOptions: {
          __typename?: 'NotificationActionOptions';
          sms?: boolean | null;
          push?: boolean | null;
          email?: boolean | null;
          message?: string | null;
        };
      }
    | {
        __typename: 'PlanTransferAction';
        type: ActionTypes;
        version: string;
        planTransferActionOptions: {
          __typename?: 'PlanTransferOptions';
          depositAccountId: string;
          fromAccountId: string;
          fromAccountType: PlanTransferAccountType;
          toAccountId: string;
          toAccountType: PlanTransferAccountType;
          amountInCents?: number | null;
          percentage?: number | null;
        };
      }
    | {
        __typename: 'WebhookAction';
        type: ActionTypes;
        version: string;
        webhookActionOptions: {
          __typename?: 'WebhookOptions';
          url: string;
          name: string;
          description?: string | null;
          webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
        };
      };
  trigger:
    | {
        __typename: 'BalanceTrigger';
        type: TriggerTypes;
        version: string;
        balanceTriggerOptions: {
          __typename?: 'BalanceTriggerOptions';
          depositAccountId: string;
          amountInCents: number;
          comparison: ComparisonTypes;
        };
      }
    | {
        __typename: 'CashbackRedemptionTrigger';
        type: TriggerTypes;
        version: string;
        cashbackRedemptionTriggerOptions: {
          __typename?: 'CashbackRedemptionTriggerOptions';
          amount: number;
          accountId: string;
        };
      }
    | {
        __typename: 'CategoryTrigger';
        type: TriggerTypes;
        version: string;
        categoryTriggerOptions: {
          __typename?: 'CategoryTriggerOptions';
          depositAccountId: string;
          category: CategoryTypes;
        };
      }
    | {
        __typename: 'DateTrigger';
        type: TriggerTypes;
        version: string;
        dateTriggerOptions: { __typename?: 'DateTriggerOptions'; pattern: string };
      }
    | {
        __typename: 'DepositTransactionTrigger';
        type: TriggerTypes;
        version: string;
        depositTransactionTriggerOptions: {
          __typename?: 'DepositTransactionTriggerOptions';
          depositAccountId: string;
          conditions: {
            __typename?: 'DepositTransactionConditions';
            category?: TransactionCategories | null;
            direction?: TransferDirection | null;
            exactMatch?: string | null;
            greaterThan?: number | null;
            isDirectDeposit?: boolean | null;
            lessThan?: number | null;
            partialMatch?: string | null;
          };
        };
      }
    | {
        __typename: 'DescriptionTrigger';
        type: TriggerTypes;
        version: string;
        descriptionTriggerOptions: {
          __typename?: 'DescriptionTriggerOptions';
          depositAccountId: string;
          description: string;
        };
      }
    | { __typename?: 'ExternalBalanceTrigger' }
    | {
        __typename: 'UserCategoryTrigger';
        type: TriggerTypes;
        version: string;
        userCategoryTriggerOptions: {
          __typename?: 'UserCategoryTriggerOptions';
          depositAccountId: string;
          userCategory: TransactionCategories;
        };
      };
};

export type ListRoutinesQueryVariables = Exact<{ [key: string]: never }>;

export type ListRoutinesQuery = {
  __typename?: 'RootQueryType';
  routines?: Array<{
    __typename?: 'Routine';
    id: string;
    isEnabled: boolean;
    archivedAt?: string | null;
    lastRunAt?: string | null;
    lastRunStatus?: RoutineExecutionStatus | null;
    createdByName?: string | null;
    createdBy?: string | null;
    isSharedRoutine: boolean;
    name: string;
    description?: string | null;
    templateId?: string | null;
    action:
      | {
          __typename: 'CashbackRedemptionAction';
          type: ActionTypes;
          version: string;
          cashbackRedemptionActionOptions: {
            __typename?: 'CashbackRedemptionOptions';
            externalAccountId?: string | null;
            depositAccountId?: string | null;
          };
        }
      | {
          __typename: 'CategorizeAction';
          type: ActionTypes;
          version: string;
          categorizeActionOptions: {
            __typename?: 'CategorizeOptions';
            depositAccountId: string;
            category: TransactionCategories;
          };
        }
      | {
          __typename: 'CreditCardPaymentAction';
          type: ActionTypes;
          version: string;
          creditCardPaymentActionOptions: {
            __typename?: 'CreditCardPaymentOptions';
            creditCardAccountId: string;
            customAmountInCents?: number | null;
            fromAccountId: string;
            fromAccountType: CreditCardFromAccountTypes;
            paymentBalancePercentage?: number | null;
            paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
          };
        }
      | {
          __typename: 'ExternalTransferAction';
          type: ActionTypes;
          version: string;
          externalTransferAction: {
            __typename?: 'ExternalTransferOptions';
            depositAccountId: string;
            externalAccountId: string;
            amountInCents?: number | null;
            percentage?: number | null;
            type: OdfiTransferType;
          };
        }
      | {
          __typename: 'InternalTransferAction';
          type: ActionTypes;
          version: string;
          internalTransferActionOptions: {
            __typename?: 'InternalTransferOptions';
            fromDepositAccountId: string;
            toDepositAccountId: string;
            amountInCents?: number | null;
            percentage?: number | null;
          };
        }
      | {
          __typename: 'MultiAction';
          type: ActionTypes;
          version: string;
          options: {
            __typename?: 'MultiActionOptions';
            actions?: Array<
              | {
                  __typename: 'CashbackRedemptionAction';
                  type: ActionTypes;
                  version: string;
                  cashbackRedemptionActionOptions: {
                    __typename?: 'CashbackRedemptionOptions';
                    externalAccountId?: string | null;
                    depositAccountId?: string | null;
                  };
                }
              | {
                  __typename: 'CategorizeAction';
                  type: ActionTypes;
                  version: string;
                  categorizeActionOptions: {
                    __typename?: 'CategorizeOptions';
                    depositAccountId: string;
                    category: TransactionCategories;
                  };
                }
              | {
                  __typename: 'CreditCardPaymentAction';
                  type: ActionTypes;
                  version: string;
                  creditCardPaymentActionOptions: {
                    __typename?: 'CreditCardPaymentOptions';
                    creditCardAccountId: string;
                    customAmountInCents?: number | null;
                    fromAccountId: string;
                    fromAccountType: CreditCardFromAccountTypes;
                    paymentBalancePercentage?: number | null;
                    paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
                  };
                }
              | {
                  __typename: 'ExternalTransferAction';
                  type: ActionTypes;
                  version: string;
                  externalTransferAction: {
                    __typename?: 'ExternalTransferOptions';
                    depositAccountId: string;
                    externalAccountId: string;
                    amountInCents?: number | null;
                    percentage?: number | null;
                    type: OdfiTransferType;
                  };
                }
              | {
                  __typename: 'InternalTransferAction';
                  type: ActionTypes;
                  version: string;
                  internalTransferActionOptions: {
                    __typename?: 'InternalTransferOptions';
                    fromDepositAccountId: string;
                    toDepositAccountId: string;
                    amountInCents?: number | null;
                    percentage?: number | null;
                  };
                }
              | {
                  __typename: 'MultiPlanTransferAction';
                  type: ActionTypes;
                  version: string;
                  multiPlanTransferActionOptions: {
                    __typename?: 'MultiPlanTransferOptions';
                    depositAccountId: string;
                    direction: TransferDirection;
                    transfers: Array<{
                      __typename?: 'MultiPlanTransfer';
                      planId: string;
                      amountInCents?: number | null;
                      percentage?: number | null;
                    } | null>;
                  };
                }
              | {
                  __typename: 'NotificationAction';
                  type: ActionTypes;
                  version: string;
                  notificationActionOptions: {
                    __typename?: 'NotificationActionOptions';
                    sms?: boolean | null;
                    push?: boolean | null;
                    email?: boolean | null;
                    message?: string | null;
                  };
                }
              | {
                  __typename: 'PlanTransferAction';
                  type: ActionTypes;
                  version: string;
                  planTransferActionOptions: {
                    __typename?: 'PlanTransferOptions';
                    depositAccountId: string;
                    fromAccountId: string;
                    fromAccountType: PlanTransferAccountType;
                    toAccountId: string;
                    toAccountType: PlanTransferAccountType;
                    amountInCents?: number | null;
                    percentage?: number | null;
                  };
                }
              | {
                  __typename: 'WebhookAction';
                  type: ActionTypes;
                  version: string;
                  webhookActionOptions: {
                    __typename?: 'WebhookOptions';
                    url: string;
                    name: string;
                    description?: string | null;
                    webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
                  };
                }
              | null
            > | null;
          };
        }
      | {
          __typename: 'MultiPlanTransferAction';
          type: ActionTypes;
          version: string;
          multiPlanTransferActionOptions: {
            __typename?: 'MultiPlanTransferOptions';
            depositAccountId: string;
            direction: TransferDirection;
            transfers: Array<{
              __typename?: 'MultiPlanTransfer';
              planId: string;
              amountInCents?: number | null;
              percentage?: number | null;
            } | null>;
          };
        }
      | {
          __typename: 'NotificationAction';
          type: ActionTypes;
          version: string;
          notificationActionOptions: {
            __typename?: 'NotificationActionOptions';
            sms?: boolean | null;
            push?: boolean | null;
            email?: boolean | null;
            message?: string | null;
          };
        }
      | {
          __typename: 'PlanTransferAction';
          type: ActionTypes;
          version: string;
          planTransferActionOptions: {
            __typename?: 'PlanTransferOptions';
            depositAccountId: string;
            fromAccountId: string;
            fromAccountType: PlanTransferAccountType;
            toAccountId: string;
            toAccountType: PlanTransferAccountType;
            amountInCents?: number | null;
            percentage?: number | null;
          };
        }
      | {
          __typename: 'WebhookAction';
          type: ActionTypes;
          version: string;
          webhookActionOptions: {
            __typename?: 'WebhookOptions';
            url: string;
            name: string;
            description?: string | null;
            webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
          };
        };
    trigger:
      | {
          __typename: 'BalanceTrigger';
          type: TriggerTypes;
          version: string;
          balanceTriggerOptions: {
            __typename?: 'BalanceTriggerOptions';
            depositAccountId: string;
            amountInCents: number;
            comparison: ComparisonTypes;
          };
        }
      | {
          __typename: 'CashbackRedemptionTrigger';
          type: TriggerTypes;
          version: string;
          cashbackRedemptionTriggerOptions: {
            __typename?: 'CashbackRedemptionTriggerOptions';
            amount: number;
            accountId: string;
          };
        }
      | {
          __typename: 'CategoryTrigger';
          type: TriggerTypes;
          version: string;
          categoryTriggerOptions: {
            __typename?: 'CategoryTriggerOptions';
            depositAccountId: string;
            category: CategoryTypes;
          };
        }
      | {
          __typename: 'DateTrigger';
          type: TriggerTypes;
          version: string;
          dateTriggerOptions: { __typename?: 'DateTriggerOptions'; pattern: string };
        }
      | {
          __typename: 'DepositTransactionTrigger';
          type: TriggerTypes;
          version: string;
          depositTransactionTriggerOptions: {
            __typename?: 'DepositTransactionTriggerOptions';
            depositAccountId: string;
            conditions: {
              __typename?: 'DepositTransactionConditions';
              category?: TransactionCategories | null;
              direction?: TransferDirection | null;
              exactMatch?: string | null;
              greaterThan?: number | null;
              isDirectDeposit?: boolean | null;
              lessThan?: number | null;
              partialMatch?: string | null;
            };
          };
        }
      | {
          __typename: 'DescriptionTrigger';
          type: TriggerTypes;
          version: string;
          descriptionTriggerOptions: {
            __typename?: 'DescriptionTriggerOptions';
            depositAccountId: string;
            description: string;
          };
        }
      | { __typename?: 'ExternalBalanceTrigger' }
      | {
          __typename: 'UserCategoryTrigger';
          type: TriggerTypes;
          version: string;
          userCategoryTriggerOptions: {
            __typename?: 'UserCategoryTriggerOptions';
            depositAccountId: string;
            userCategory: TransactionCategories;
          };
        };
  } | null> | null;
};

export type ListSharedRoutinesQueryVariables = Exact<{ [key: string]: never }>;

export type ListSharedRoutinesQuery = {
  __typename?: 'RootQueryType';
  sharedRoutines?: Array<{
    __typename?: 'Routine';
    id: string;
    isEnabled: boolean;
    archivedAt?: string | null;
    lastRunAt?: string | null;
    lastRunStatus?: RoutineExecutionStatus | null;
    createdByName?: string | null;
    createdBy?: string | null;
    isSharedRoutine: boolean;
    name: string;
    description?: string | null;
    templateId?: string | null;
    action:
      | {
          __typename: 'CashbackRedemptionAction';
          type: ActionTypes;
          version: string;
          cashbackRedemptionActionOptions: {
            __typename?: 'CashbackRedemptionOptions';
            externalAccountId?: string | null;
            depositAccountId?: string | null;
          };
        }
      | {
          __typename: 'CategorizeAction';
          type: ActionTypes;
          version: string;
          categorizeActionOptions: {
            __typename?: 'CategorizeOptions';
            depositAccountId: string;
            category: TransactionCategories;
          };
        }
      | {
          __typename: 'CreditCardPaymentAction';
          type: ActionTypes;
          version: string;
          creditCardPaymentActionOptions: {
            __typename?: 'CreditCardPaymentOptions';
            creditCardAccountId: string;
            customAmountInCents?: number | null;
            fromAccountId: string;
            fromAccountType: CreditCardFromAccountTypes;
            paymentBalancePercentage?: number | null;
            paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
          };
        }
      | {
          __typename: 'ExternalTransferAction';
          type: ActionTypes;
          version: string;
          externalTransferAction: {
            __typename?: 'ExternalTransferOptions';
            depositAccountId: string;
            externalAccountId: string;
            amountInCents?: number | null;
            percentage?: number | null;
            type: OdfiTransferType;
          };
        }
      | {
          __typename: 'InternalTransferAction';
          type: ActionTypes;
          version: string;
          internalTransferActionOptions: {
            __typename?: 'InternalTransferOptions';
            fromDepositAccountId: string;
            toDepositAccountId: string;
            amountInCents?: number | null;
            percentage?: number | null;
          };
        }
      | {
          __typename: 'MultiAction';
          type: ActionTypes;
          version: string;
          options: {
            __typename?: 'MultiActionOptions';
            actions?: Array<
              | {
                  __typename: 'CashbackRedemptionAction';
                  type: ActionTypes;
                  version: string;
                  cashbackRedemptionActionOptions: {
                    __typename?: 'CashbackRedemptionOptions';
                    externalAccountId?: string | null;
                    depositAccountId?: string | null;
                  };
                }
              | {
                  __typename: 'CategorizeAction';
                  type: ActionTypes;
                  version: string;
                  categorizeActionOptions: {
                    __typename?: 'CategorizeOptions';
                    depositAccountId: string;
                    category: TransactionCategories;
                  };
                }
              | {
                  __typename: 'CreditCardPaymentAction';
                  type: ActionTypes;
                  version: string;
                  creditCardPaymentActionOptions: {
                    __typename?: 'CreditCardPaymentOptions';
                    creditCardAccountId: string;
                    customAmountInCents?: number | null;
                    fromAccountId: string;
                    fromAccountType: CreditCardFromAccountTypes;
                    paymentBalancePercentage?: number | null;
                    paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
                  };
                }
              | {
                  __typename: 'ExternalTransferAction';
                  type: ActionTypes;
                  version: string;
                  externalTransferAction: {
                    __typename?: 'ExternalTransferOptions';
                    depositAccountId: string;
                    externalAccountId: string;
                    amountInCents?: number | null;
                    percentage?: number | null;
                    type: OdfiTransferType;
                  };
                }
              | {
                  __typename: 'InternalTransferAction';
                  type: ActionTypes;
                  version: string;
                  internalTransferActionOptions: {
                    __typename?: 'InternalTransferOptions';
                    fromDepositAccountId: string;
                    toDepositAccountId: string;
                    amountInCents?: number | null;
                    percentage?: number | null;
                  };
                }
              | {
                  __typename: 'MultiPlanTransferAction';
                  type: ActionTypes;
                  version: string;
                  multiPlanTransferActionOptions: {
                    __typename?: 'MultiPlanTransferOptions';
                    depositAccountId: string;
                    direction: TransferDirection;
                    transfers: Array<{
                      __typename?: 'MultiPlanTransfer';
                      planId: string;
                      amountInCents?: number | null;
                      percentage?: number | null;
                    } | null>;
                  };
                }
              | {
                  __typename: 'NotificationAction';
                  type: ActionTypes;
                  version: string;
                  notificationActionOptions: {
                    __typename?: 'NotificationActionOptions';
                    sms?: boolean | null;
                    push?: boolean | null;
                    email?: boolean | null;
                    message?: string | null;
                  };
                }
              | {
                  __typename: 'PlanTransferAction';
                  type: ActionTypes;
                  version: string;
                  planTransferActionOptions: {
                    __typename?: 'PlanTransferOptions';
                    depositAccountId: string;
                    fromAccountId: string;
                    fromAccountType: PlanTransferAccountType;
                    toAccountId: string;
                    toAccountType: PlanTransferAccountType;
                    amountInCents?: number | null;
                    percentage?: number | null;
                  };
                }
              | {
                  __typename: 'WebhookAction';
                  type: ActionTypes;
                  version: string;
                  webhookActionOptions: {
                    __typename?: 'WebhookOptions';
                    url: string;
                    name: string;
                    description?: string | null;
                    webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
                  };
                }
              | null
            > | null;
          };
        }
      | {
          __typename: 'MultiPlanTransferAction';
          type: ActionTypes;
          version: string;
          multiPlanTransferActionOptions: {
            __typename?: 'MultiPlanTransferOptions';
            depositAccountId: string;
            direction: TransferDirection;
            transfers: Array<{
              __typename?: 'MultiPlanTransfer';
              planId: string;
              amountInCents?: number | null;
              percentage?: number | null;
            } | null>;
          };
        }
      | {
          __typename: 'NotificationAction';
          type: ActionTypes;
          version: string;
          notificationActionOptions: {
            __typename?: 'NotificationActionOptions';
            sms?: boolean | null;
            push?: boolean | null;
            email?: boolean | null;
            message?: string | null;
          };
        }
      | {
          __typename: 'PlanTransferAction';
          type: ActionTypes;
          version: string;
          planTransferActionOptions: {
            __typename?: 'PlanTransferOptions';
            depositAccountId: string;
            fromAccountId: string;
            fromAccountType: PlanTransferAccountType;
            toAccountId: string;
            toAccountType: PlanTransferAccountType;
            amountInCents?: number | null;
            percentage?: number | null;
          };
        }
      | {
          __typename: 'WebhookAction';
          type: ActionTypes;
          version: string;
          webhookActionOptions: {
            __typename?: 'WebhookOptions';
            url: string;
            name: string;
            description?: string | null;
            webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
          };
        };
    trigger:
      | {
          __typename: 'BalanceTrigger';
          type: TriggerTypes;
          version: string;
          balanceTriggerOptions: {
            __typename?: 'BalanceTriggerOptions';
            depositAccountId: string;
            amountInCents: number;
            comparison: ComparisonTypes;
          };
        }
      | {
          __typename: 'CashbackRedemptionTrigger';
          type: TriggerTypes;
          version: string;
          cashbackRedemptionTriggerOptions: {
            __typename?: 'CashbackRedemptionTriggerOptions';
            amount: number;
            accountId: string;
          };
        }
      | {
          __typename: 'CategoryTrigger';
          type: TriggerTypes;
          version: string;
          categoryTriggerOptions: {
            __typename?: 'CategoryTriggerOptions';
            depositAccountId: string;
            category: CategoryTypes;
          };
        }
      | {
          __typename: 'DateTrigger';
          type: TriggerTypes;
          version: string;
          dateTriggerOptions: { __typename?: 'DateTriggerOptions'; pattern: string };
        }
      | {
          __typename: 'DepositTransactionTrigger';
          type: TriggerTypes;
          version: string;
          depositTransactionTriggerOptions: {
            __typename?: 'DepositTransactionTriggerOptions';
            depositAccountId: string;
            conditions: {
              __typename?: 'DepositTransactionConditions';
              category?: TransactionCategories | null;
              direction?: TransferDirection | null;
              exactMatch?: string | null;
              greaterThan?: number | null;
              isDirectDeposit?: boolean | null;
              lessThan?: number | null;
              partialMatch?: string | null;
            };
          };
        }
      | {
          __typename: 'DescriptionTrigger';
          type: TriggerTypes;
          version: string;
          descriptionTriggerOptions: {
            __typename?: 'DescriptionTriggerOptions';
            depositAccountId: string;
            description: string;
          };
        }
      | { __typename?: 'ExternalBalanceTrigger' }
      | {
          __typename: 'UserCategoryTrigger';
          type: TriggerTypes;
          version: string;
          userCategoryTriggerOptions: {
            __typename?: 'UserCategoryTriggerOptions';
            depositAccountId: string;
            userCategory: TransactionCategories;
          };
        };
  } | null> | null;
};

export type GetAllAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllAccountsQuery = {
  __typename?: 'RootQueryType';
  depositAccounts?: Array<{
    __typename?: 'DepositAccount';
    id?: string | null;
    accountType?: DepositAccountType | null;
    transactions?: Array<{
      __typename?: 'Transaction';
      id?: string | null;
      transferType: TransferType;
      status: TransactionStatus;
      description?: string | null;
    } | null> | null;
    depositAccountNumber?: { __typename?: 'DepositAccountNumber'; lastFour?: string | null } | null;
    plans?: Array<{ __typename?: 'Plan'; id?: string | null; title?: string | null } | null> | null;
  } | null> | null;
};

type TemplateSubAction_CashbackRedemptionAction_Fragment = {
  __typename: 'CashbackRedemptionAction';
  type: ActionTypes;
  version: string;
};

type TemplateSubAction_CategorizeAction_Fragment = {
  __typename: 'CategorizeAction';
  type: ActionTypes;
  version: string;
  categorizeActionOptions: { __typename?: 'CategorizeOptions'; category: TransactionCategories };
};

type TemplateSubAction_CreditCardPaymentAction_Fragment = {
  __typename: 'CreditCardPaymentAction';
  type: ActionTypes;
  version: string;
  creditCardPaymentActionOptions: {
    __typename?: 'CreditCardPaymentOptions';
    creditCardAccountId: string;
    customAmountInCents?: number | null;
    fromAccountId: string;
    paymentBalancePercentage?: number | null;
    paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
    fromAccountTypeEnum: CreditCardFromAccountTypes;
  };
};

type TemplateSubAction_ExternalTransferAction_Fragment = {
  __typename: 'ExternalTransferAction';
  type: ActionTypes;
  version: string;
  externalTransferAction: {
    __typename?: 'ExternalTransferOptions';
    amountInCents?: number | null;
    percentage?: number | null;
    type: OdfiTransferType;
  };
};

type TemplateSubAction_InternalTransferAction_Fragment = {
  __typename: 'InternalTransferAction';
  type: ActionTypes;
  version: string;
  internalTransferActionOptions: {
    __typename?: 'InternalTransferOptions';
    amountInCents?: number | null;
    percentage?: number | null;
  };
};

type TemplateSubAction_MultiAction_Fragment = { __typename?: 'MultiAction' };

type TemplateSubAction_MultiPlanTransferAction_Fragment = {
  __typename: 'MultiPlanTransferAction';
  type: ActionTypes;
  version: string;
  multiPlanTransferActionOptions: {
    __typename?: 'MultiPlanTransferOptions';
    direction: TransferDirection;
    transfers: Array<{
      __typename?: 'MultiPlanTransfer';
      amountInCents?: number | null;
      percentage?: number | null;
    } | null>;
  };
};

type TemplateSubAction_NotificationAction_Fragment = {
  __typename: 'NotificationAction';
  type: ActionTypes;
  version: string;
  notificationActionOptions: {
    __typename?: 'NotificationActionOptions';
    sms?: boolean | null;
    push?: boolean | null;
    email?: boolean | null;
    message?: string | null;
  };
};

type TemplateSubAction_PlanTransferAction_Fragment = {
  __typename: 'PlanTransferAction';
  type: ActionTypes;
  version: string;
  planTransferActionOptions: {
    __typename?: 'PlanTransferOptions';
    fromAccountType: PlanTransferAccountType;
    toAccountType: PlanTransferAccountType;
    amountInCents?: number | null;
    percentage?: number | null;
  };
};

type TemplateSubAction_WebhookAction_Fragment = {
  __typename: 'WebhookAction';
  type: ActionTypes;
  version: string;
  webhookActionOptions: {
    __typename?: 'WebhookOptions';
    url: string;
    name: string;
    description?: string | null;
    webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
  };
};

export type TemplateSubActionFragment =
  | TemplateSubAction_CashbackRedemptionAction_Fragment
  | TemplateSubAction_CategorizeAction_Fragment
  | TemplateSubAction_CreditCardPaymentAction_Fragment
  | TemplateSubAction_ExternalTransferAction_Fragment
  | TemplateSubAction_InternalTransferAction_Fragment
  | TemplateSubAction_MultiAction_Fragment
  | TemplateSubAction_MultiPlanTransferAction_Fragment
  | TemplateSubAction_NotificationAction_Fragment
  | TemplateSubAction_PlanTransferAction_Fragment
  | TemplateSubAction_WebhookAction_Fragment;

type TemplateTrigger_BalanceTrigger_Fragment = {
  __typename: 'BalanceTrigger';
  type: TriggerTypes;
  version: string;
  options: { __typename?: 'BalanceTriggerOptions'; amountInCents: number; comparison: ComparisonTypes };
};

type TemplateTrigger_CashbackRedemptionTrigger_Fragment = {
  __typename: 'CashbackRedemptionTrigger';
  type: TriggerTypes;
  version: string;
  options: { __typename?: 'CashbackRedemptionTriggerOptions'; amount: number };
};

type TemplateTrigger_CategoryTrigger_Fragment = {
  __typename: 'CategoryTrigger';
  type: TriggerTypes;
  version: string;
  options: { __typename?: 'CategoryTriggerOptions'; category: CategoryTypes };
};

type TemplateTrigger_DateTrigger_Fragment = {
  __typename: 'DateTrigger';
  type: TriggerTypes;
  version: string;
  options: { __typename?: 'DateTriggerOptions'; pattern: string };
};

type TemplateTrigger_DepositTransactionTrigger_Fragment = {
  __typename: 'DepositTransactionTrigger';
  type: TriggerTypes;
  version: string;
  options: {
    __typename?: 'DepositTransactionTriggerOptions';
    conditions: {
      __typename?: 'DepositTransactionConditions';
      category?: TransactionCategories | null;
      direction?: TransferDirection | null;
      exactMatch?: string | null;
      greaterThan?: number | null;
      isDirectDeposit?: boolean | null;
      lessThan?: number | null;
      partialMatch?: string | null;
    };
  };
};

type TemplateTrigger_DescriptionTrigger_Fragment = {
  __typename: 'DescriptionTrigger';
  type: TriggerTypes;
  version: string;
  options: { __typename?: 'DescriptionTriggerOptions'; description: string };
};

type TemplateTrigger_ExternalBalanceTrigger_Fragment = { __typename?: 'ExternalBalanceTrigger' };

type TemplateTrigger_UserCategoryTrigger_Fragment = {
  __typename: 'UserCategoryTrigger';
  type: TriggerTypes;
  version: string;
  options: { __typename?: 'UserCategoryTriggerOptions'; userCategory: TransactionCategories };
};

export type TemplateTriggerFragment =
  | TemplateTrigger_BalanceTrigger_Fragment
  | TemplateTrigger_CashbackRedemptionTrigger_Fragment
  | TemplateTrigger_CategoryTrigger_Fragment
  | TemplateTrigger_DateTrigger_Fragment
  | TemplateTrigger_DepositTransactionTrigger_Fragment
  | TemplateTrigger_DescriptionTrigger_Fragment
  | TemplateTrigger_ExternalBalanceTrigger_Fragment
  | TemplateTrigger_UserCategoryTrigger_Fragment;

type TemplateAction_CashbackRedemptionAction_Fragment = {
  __typename: 'CashbackRedemptionAction';
  type: ActionTypes;
  version: string;
};

type TemplateAction_CategorizeAction_Fragment = {
  __typename: 'CategorizeAction';
  type: ActionTypes;
  version: string;
  categorizeActionOptions: { __typename?: 'CategorizeOptions'; category: TransactionCategories };
};

type TemplateAction_CreditCardPaymentAction_Fragment = {
  __typename: 'CreditCardPaymentAction';
  type: ActionTypes;
  version: string;
  creditCardPaymentActionOptions: {
    __typename?: 'CreditCardPaymentOptions';
    creditCardAccountId: string;
    customAmountInCents?: number | null;
    fromAccountId: string;
    paymentBalancePercentage?: number | null;
    paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
    fromAccountTypeEnum: CreditCardFromAccountTypes;
  };
};

type TemplateAction_ExternalTransferAction_Fragment = {
  __typename: 'ExternalTransferAction';
  type: ActionTypes;
  version: string;
  externalTransferAction: {
    __typename?: 'ExternalTransferOptions';
    amountInCents?: number | null;
    percentage?: number | null;
    type: OdfiTransferType;
  };
};

type TemplateAction_InternalTransferAction_Fragment = {
  __typename: 'InternalTransferAction';
  type: ActionTypes;
  version: string;
  internalTransferActionOptions: {
    __typename?: 'InternalTransferOptions';
    amountInCents?: number | null;
    percentage?: number | null;
  };
};

type TemplateAction_MultiAction_Fragment = {
  __typename: 'MultiAction';
  type: ActionTypes;
  version: string;
  options: {
    __typename?: 'MultiActionOptions';
    actions?: Array<
      | { __typename: 'CashbackRedemptionAction'; type: ActionTypes; version: string }
      | {
          __typename: 'CategorizeAction';
          type: ActionTypes;
          version: string;
          categorizeActionOptions: { __typename?: 'CategorizeOptions'; category: TransactionCategories };
        }
      | {
          __typename: 'CreditCardPaymentAction';
          type: ActionTypes;
          version: string;
          creditCardPaymentActionOptions: {
            __typename?: 'CreditCardPaymentOptions';
            creditCardAccountId: string;
            customAmountInCents?: number | null;
            fromAccountId: string;
            paymentBalancePercentage?: number | null;
            paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
            fromAccountTypeEnum: CreditCardFromAccountTypes;
          };
        }
      | {
          __typename: 'ExternalTransferAction';
          type: ActionTypes;
          version: string;
          externalTransferAction: {
            __typename?: 'ExternalTransferOptions';
            amountInCents?: number | null;
            percentage?: number | null;
            type: OdfiTransferType;
          };
        }
      | {
          __typename: 'InternalTransferAction';
          type: ActionTypes;
          version: string;
          internalTransferActionOptions: {
            __typename?: 'InternalTransferOptions';
            amountInCents?: number | null;
            percentage?: number | null;
          };
        }
      | {
          __typename: 'MultiPlanTransferAction';
          type: ActionTypes;
          version: string;
          multiPlanTransferActionOptions: {
            __typename?: 'MultiPlanTransferOptions';
            direction: TransferDirection;
            transfers: Array<{
              __typename?: 'MultiPlanTransfer';
              amountInCents?: number | null;
              percentage?: number | null;
            } | null>;
          };
        }
      | {
          __typename: 'NotificationAction';
          type: ActionTypes;
          version: string;
          notificationActionOptions: {
            __typename?: 'NotificationActionOptions';
            sms?: boolean | null;
            push?: boolean | null;
            email?: boolean | null;
            message?: string | null;
          };
        }
      | {
          __typename: 'PlanTransferAction';
          type: ActionTypes;
          version: string;
          planTransferActionOptions: {
            __typename?: 'PlanTransferOptions';
            fromAccountType: PlanTransferAccountType;
            toAccountType: PlanTransferAccountType;
            amountInCents?: number | null;
            percentage?: number | null;
          };
        }
      | {
          __typename: 'WebhookAction';
          type: ActionTypes;
          version: string;
          webhookActionOptions: {
            __typename?: 'WebhookOptions';
            url: string;
            name: string;
            description?: string | null;
            webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
          };
        }
      | null
    > | null;
  };
};

type TemplateAction_MultiPlanTransferAction_Fragment = {
  __typename: 'MultiPlanTransferAction';
  type: ActionTypes;
  version: string;
  multiPlanTransferActionOptions: {
    __typename?: 'MultiPlanTransferOptions';
    direction: TransferDirection;
    transfers: Array<{
      __typename?: 'MultiPlanTransfer';
      amountInCents?: number | null;
      percentage?: number | null;
    } | null>;
  };
};

type TemplateAction_NotificationAction_Fragment = {
  __typename: 'NotificationAction';
  type: ActionTypes;
  version: string;
  notificationActionOptions: {
    __typename?: 'NotificationActionOptions';
    sms?: boolean | null;
    push?: boolean | null;
    email?: boolean | null;
    message?: string | null;
  };
};

type TemplateAction_PlanTransferAction_Fragment = {
  __typename: 'PlanTransferAction';
  type: ActionTypes;
  version: string;
  planTransferActionOptions: {
    __typename?: 'PlanTransferOptions';
    fromAccountType: PlanTransferAccountType;
    toAccountType: PlanTransferAccountType;
    amountInCents?: number | null;
    percentage?: number | null;
  };
};

type TemplateAction_WebhookAction_Fragment = {
  __typename: 'WebhookAction';
  type: ActionTypes;
  version: string;
  webhookActionOptions: {
    __typename?: 'WebhookOptions';
    url: string;
    name: string;
    description?: string | null;
    webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
  };
};

export type TemplateActionFragment =
  | TemplateAction_CashbackRedemptionAction_Fragment
  | TemplateAction_CategorizeAction_Fragment
  | TemplateAction_CreditCardPaymentAction_Fragment
  | TemplateAction_ExternalTransferAction_Fragment
  | TemplateAction_InternalTransferAction_Fragment
  | TemplateAction_MultiAction_Fragment
  | TemplateAction_MultiPlanTransferAction_Fragment
  | TemplateAction_NotificationAction_Fragment
  | TemplateAction_PlanTransferAction_Fragment
  | TemplateAction_WebhookAction_Fragment;

export type RoutineTemplateFragment = {
  __typename?: 'RoutineTemplate';
  id: string;
  uid?: string | null;
  title: string;
  description?: string | null;
  categories?: Array<TemplateCategories | null> | null;
  action:
    | { __typename: 'CashbackRedemptionAction'; type: ActionTypes; version: string }
    | {
        __typename: 'CategorizeAction';
        type: ActionTypes;
        version: string;
        categorizeActionOptions: { __typename?: 'CategorizeOptions'; category: TransactionCategories };
      }
    | {
        __typename: 'CreditCardPaymentAction';
        type: ActionTypes;
        version: string;
        creditCardPaymentActionOptions: {
          __typename?: 'CreditCardPaymentOptions';
          creditCardAccountId: string;
          customAmountInCents?: number | null;
          fromAccountId: string;
          paymentBalancePercentage?: number | null;
          paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
          fromAccountTypeEnum: CreditCardFromAccountTypes;
        };
      }
    | {
        __typename: 'ExternalTransferAction';
        type: ActionTypes;
        version: string;
        externalTransferAction: {
          __typename?: 'ExternalTransferOptions';
          amountInCents?: number | null;
          percentage?: number | null;
          type: OdfiTransferType;
        };
      }
    | {
        __typename: 'InternalTransferAction';
        type: ActionTypes;
        version: string;
        internalTransferActionOptions: {
          __typename?: 'InternalTransferOptions';
          amountInCents?: number | null;
          percentage?: number | null;
        };
      }
    | {
        __typename: 'MultiAction';
        type: ActionTypes;
        version: string;
        options: {
          __typename?: 'MultiActionOptions';
          actions?: Array<
            | { __typename: 'CashbackRedemptionAction'; type: ActionTypes; version: string }
            | {
                __typename: 'CategorizeAction';
                type: ActionTypes;
                version: string;
                categorizeActionOptions: { __typename?: 'CategorizeOptions'; category: TransactionCategories };
              }
            | {
                __typename: 'CreditCardPaymentAction';
                type: ActionTypes;
                version: string;
                creditCardPaymentActionOptions: {
                  __typename?: 'CreditCardPaymentOptions';
                  creditCardAccountId: string;
                  customAmountInCents?: number | null;
                  fromAccountId: string;
                  paymentBalancePercentage?: number | null;
                  paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
                  fromAccountTypeEnum: CreditCardFromAccountTypes;
                };
              }
            | {
                __typename: 'ExternalTransferAction';
                type: ActionTypes;
                version: string;
                externalTransferAction: {
                  __typename?: 'ExternalTransferOptions';
                  amountInCents?: number | null;
                  percentage?: number | null;
                  type: OdfiTransferType;
                };
              }
            | {
                __typename: 'InternalTransferAction';
                type: ActionTypes;
                version: string;
                internalTransferActionOptions: {
                  __typename?: 'InternalTransferOptions';
                  amountInCents?: number | null;
                  percentage?: number | null;
                };
              }
            | {
                __typename: 'MultiPlanTransferAction';
                type: ActionTypes;
                version: string;
                multiPlanTransferActionOptions: {
                  __typename?: 'MultiPlanTransferOptions';
                  direction: TransferDirection;
                  transfers: Array<{
                    __typename?: 'MultiPlanTransfer';
                    amountInCents?: number | null;
                    percentage?: number | null;
                  } | null>;
                };
              }
            | {
                __typename: 'NotificationAction';
                type: ActionTypes;
                version: string;
                notificationActionOptions: {
                  __typename?: 'NotificationActionOptions';
                  sms?: boolean | null;
                  push?: boolean | null;
                  email?: boolean | null;
                  message?: string | null;
                };
              }
            | {
                __typename: 'PlanTransferAction';
                type: ActionTypes;
                version: string;
                planTransferActionOptions: {
                  __typename?: 'PlanTransferOptions';
                  fromAccountType: PlanTransferAccountType;
                  toAccountType: PlanTransferAccountType;
                  amountInCents?: number | null;
                  percentage?: number | null;
                };
              }
            | {
                __typename: 'WebhookAction';
                type: ActionTypes;
                version: string;
                webhookActionOptions: {
                  __typename?: 'WebhookOptions';
                  url: string;
                  name: string;
                  description?: string | null;
                  webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
                };
              }
            | null
          > | null;
        };
      }
    | {
        __typename: 'MultiPlanTransferAction';
        type: ActionTypes;
        version: string;
        multiPlanTransferActionOptions: {
          __typename?: 'MultiPlanTransferOptions';
          direction: TransferDirection;
          transfers: Array<{
            __typename?: 'MultiPlanTransfer';
            amountInCents?: number | null;
            percentage?: number | null;
          } | null>;
        };
      }
    | {
        __typename: 'NotificationAction';
        type: ActionTypes;
        version: string;
        notificationActionOptions: {
          __typename?: 'NotificationActionOptions';
          sms?: boolean | null;
          push?: boolean | null;
          email?: boolean | null;
          message?: string | null;
        };
      }
    | {
        __typename: 'PlanTransferAction';
        type: ActionTypes;
        version: string;
        planTransferActionOptions: {
          __typename?: 'PlanTransferOptions';
          fromAccountType: PlanTransferAccountType;
          toAccountType: PlanTransferAccountType;
          amountInCents?: number | null;
          percentage?: number | null;
        };
      }
    | {
        __typename: 'WebhookAction';
        type: ActionTypes;
        version: string;
        webhookActionOptions: {
          __typename?: 'WebhookOptions';
          url: string;
          name: string;
          description?: string | null;
          webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
        };
      };
  trigger:
    | {
        __typename: 'BalanceTrigger';
        type: TriggerTypes;
        version: string;
        options: { __typename?: 'BalanceTriggerOptions'; amountInCents: number; comparison: ComparisonTypes };
      }
    | {
        __typename: 'CashbackRedemptionTrigger';
        type: TriggerTypes;
        version: string;
        options: { __typename?: 'CashbackRedemptionTriggerOptions'; amount: number };
      }
    | {
        __typename: 'CategoryTrigger';
        type: TriggerTypes;
        version: string;
        options: { __typename?: 'CategoryTriggerOptions'; category: CategoryTypes };
      }
    | {
        __typename: 'DateTrigger';
        type: TriggerTypes;
        version: string;
        options: { __typename?: 'DateTriggerOptions'; pattern: string };
      }
    | {
        __typename: 'DepositTransactionTrigger';
        type: TriggerTypes;
        version: string;
        options: {
          __typename?: 'DepositTransactionTriggerOptions';
          conditions: {
            __typename?: 'DepositTransactionConditions';
            category?: TransactionCategories | null;
            direction?: TransferDirection | null;
            exactMatch?: string | null;
            greaterThan?: number | null;
            isDirectDeposit?: boolean | null;
            lessThan?: number | null;
            partialMatch?: string | null;
          };
        };
      }
    | {
        __typename: 'DescriptionTrigger';
        type: TriggerTypes;
        version: string;
        options: { __typename?: 'DescriptionTriggerOptions'; description: string };
      }
    | { __typename?: 'ExternalBalanceTrigger' }
    | {
        __typename: 'UserCategoryTrigger';
        type: TriggerTypes;
        version: string;
        options: { __typename?: 'UserCategoryTriggerOptions'; userCategory: TransactionCategories };
      };
};

export type ListTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type ListTemplatesQuery = {
  __typename?: 'RootQueryType';
  routineTemplates?: Array<{
    __typename?: 'RoutineTemplate';
    id: string;
    uid?: string | null;
    title: string;
    description?: string | null;
    categories?: Array<TemplateCategories | null> | null;
    action:
      | { __typename: 'CashbackRedemptionAction'; type: ActionTypes; version: string }
      | {
          __typename: 'CategorizeAction';
          type: ActionTypes;
          version: string;
          categorizeActionOptions: { __typename?: 'CategorizeOptions'; category: TransactionCategories };
        }
      | {
          __typename: 'CreditCardPaymentAction';
          type: ActionTypes;
          version: string;
          creditCardPaymentActionOptions: {
            __typename?: 'CreditCardPaymentOptions';
            creditCardAccountId: string;
            customAmountInCents?: number | null;
            fromAccountId: string;
            paymentBalancePercentage?: number | null;
            paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
            fromAccountTypeEnum: CreditCardFromAccountTypes;
          };
        }
      | {
          __typename: 'ExternalTransferAction';
          type: ActionTypes;
          version: string;
          externalTransferAction: {
            __typename?: 'ExternalTransferOptions';
            amountInCents?: number | null;
            percentage?: number | null;
            type: OdfiTransferType;
          };
        }
      | {
          __typename: 'InternalTransferAction';
          type: ActionTypes;
          version: string;
          internalTransferActionOptions: {
            __typename?: 'InternalTransferOptions';
            amountInCents?: number | null;
            percentage?: number | null;
          };
        }
      | {
          __typename: 'MultiAction';
          type: ActionTypes;
          version: string;
          options: {
            __typename?: 'MultiActionOptions';
            actions?: Array<
              | { __typename: 'CashbackRedemptionAction'; type: ActionTypes; version: string }
              | {
                  __typename: 'CategorizeAction';
                  type: ActionTypes;
                  version: string;
                  categorizeActionOptions: { __typename?: 'CategorizeOptions'; category: TransactionCategories };
                }
              | {
                  __typename: 'CreditCardPaymentAction';
                  type: ActionTypes;
                  version: string;
                  creditCardPaymentActionOptions: {
                    __typename?: 'CreditCardPaymentOptions';
                    creditCardAccountId: string;
                    customAmountInCents?: number | null;
                    fromAccountId: string;
                    paymentBalancePercentage?: number | null;
                    paymentBalanceType?: CreditCardPaymentBalanceTypes | null;
                    fromAccountTypeEnum: CreditCardFromAccountTypes;
                  };
                }
              | {
                  __typename: 'ExternalTransferAction';
                  type: ActionTypes;
                  version: string;
                  externalTransferAction: {
                    __typename?: 'ExternalTransferOptions';
                    amountInCents?: number | null;
                    percentage?: number | null;
                    type: OdfiTransferType;
                  };
                }
              | {
                  __typename: 'InternalTransferAction';
                  type: ActionTypes;
                  version: string;
                  internalTransferActionOptions: {
                    __typename?: 'InternalTransferOptions';
                    amountInCents?: number | null;
                    percentage?: number | null;
                  };
                }
              | {
                  __typename: 'MultiPlanTransferAction';
                  type: ActionTypes;
                  version: string;
                  multiPlanTransferActionOptions: {
                    __typename?: 'MultiPlanTransferOptions';
                    direction: TransferDirection;
                    transfers: Array<{
                      __typename?: 'MultiPlanTransfer';
                      amountInCents?: number | null;
                      percentage?: number | null;
                    } | null>;
                  };
                }
              | {
                  __typename: 'NotificationAction';
                  type: ActionTypes;
                  version: string;
                  notificationActionOptions: {
                    __typename?: 'NotificationActionOptions';
                    sms?: boolean | null;
                    push?: boolean | null;
                    email?: boolean | null;
                    message?: string | null;
                  };
                }
              | {
                  __typename: 'PlanTransferAction';
                  type: ActionTypes;
                  version: string;
                  planTransferActionOptions: {
                    __typename?: 'PlanTransferOptions';
                    fromAccountType: PlanTransferAccountType;
                    toAccountType: PlanTransferAccountType;
                    amountInCents?: number | null;
                    percentage?: number | null;
                  };
                }
              | {
                  __typename: 'WebhookAction';
                  type: ActionTypes;
                  version: string;
                  webhookActionOptions: {
                    __typename?: 'WebhookOptions';
                    url: string;
                    name: string;
                    description?: string | null;
                    webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
                  };
                }
              | null
            > | null;
          };
        }
      | {
          __typename: 'MultiPlanTransferAction';
          type: ActionTypes;
          version: string;
          multiPlanTransferActionOptions: {
            __typename?: 'MultiPlanTransferOptions';
            direction: TransferDirection;
            transfers: Array<{
              __typename?: 'MultiPlanTransfer';
              amountInCents?: number | null;
              percentage?: number | null;
            } | null>;
          };
        }
      | {
          __typename: 'NotificationAction';
          type: ActionTypes;
          version: string;
          notificationActionOptions: {
            __typename?: 'NotificationActionOptions';
            sms?: boolean | null;
            push?: boolean | null;
            email?: boolean | null;
            message?: string | null;
          };
        }
      | {
          __typename: 'PlanTransferAction';
          type: ActionTypes;
          version: string;
          planTransferActionOptions: {
            __typename?: 'PlanTransferOptions';
            fromAccountType: PlanTransferAccountType;
            toAccountType: PlanTransferAccountType;
            amountInCents?: number | null;
            percentage?: number | null;
          };
        }
      | {
          __typename: 'WebhookAction';
          type: ActionTypes;
          version: string;
          webhookActionOptions: {
            __typename?: 'WebhookOptions';
            url: string;
            name: string;
            description?: string | null;
            webhookHeaders?: Array<{ __typename?: 'WebhookHeader'; key: string; value: string } | null> | null;
          };
        };
    trigger:
      | {
          __typename: 'BalanceTrigger';
          type: TriggerTypes;
          version: string;
          options: { __typename?: 'BalanceTriggerOptions'; amountInCents: number; comparison: ComparisonTypes };
        }
      | {
          __typename: 'CashbackRedemptionTrigger';
          type: TriggerTypes;
          version: string;
          options: { __typename?: 'CashbackRedemptionTriggerOptions'; amount: number };
        }
      | {
          __typename: 'CategoryTrigger';
          type: TriggerTypes;
          version: string;
          options: { __typename?: 'CategoryTriggerOptions'; category: CategoryTypes };
        }
      | {
          __typename: 'DateTrigger';
          type: TriggerTypes;
          version: string;
          options: { __typename?: 'DateTriggerOptions'; pattern: string };
        }
      | {
          __typename: 'DepositTransactionTrigger';
          type: TriggerTypes;
          version: string;
          options: {
            __typename?: 'DepositTransactionTriggerOptions';
            conditions: {
              __typename?: 'DepositTransactionConditions';
              category?: TransactionCategories | null;
              direction?: TransferDirection | null;
              exactMatch?: string | null;
              greaterThan?: number | null;
              isDirectDeposit?: boolean | null;
              lessThan?: number | null;
              partialMatch?: string | null;
            };
          };
        }
      | {
          __typename: 'DescriptionTrigger';
          type: TriggerTypes;
          version: string;
          options: { __typename?: 'DescriptionTriggerOptions'; description: string };
        }
      | { __typename?: 'ExternalBalanceTrigger' }
      | {
          __typename: 'UserCategoryTrigger';
          type: TriggerTypes;
          version: string;
          options: { __typename?: 'UserCategoryTriggerOptions'; userCategory: TransactionCategories };
        };
  } | null> | null;
};

export type CreateRoutineMutationVariables = Exact<{
  templateId?: InputMaybe<Scalars['Uuid']>;
  name: Scalars['String'];
  description: Scalars['String'];
  trigger: TriggerInput;
  action: ActionInput;
  consents?: InputMaybe<Array<InputMaybe<Consents>> | InputMaybe<Consents>>;
}>;

export type CreateRoutineMutation = {
  __typename?: 'RootMutationType';
  createRoutine?: { __typename?: 'CreateRoutinePayload'; routineId?: string | null } | null;
};

export type EditRoutineMutationVariables = Exact<{
  routineId: Scalars['Uuid'];
  name: Scalars['String'];
  description: Scalars['String'];
  trigger: TriggerInput;
  action: ActionInput;
  consents?: InputMaybe<Array<InputMaybe<Consents>> | InputMaybe<Consents>>;
}>;

export type EditRoutineMutation = {
  __typename?: 'RootMutationType';
  editRoutine?: { __typename?: 'CreateRoutinePayload'; routineId?: string | null } | null;
};

export type EnableRoutineMutationVariables = Exact<{
  routineId: Scalars['Uuid'];
}>;

export type EnableRoutineMutation = {
  __typename?: 'RootMutationType';
  enableRoutine?: { __typename?: 'CreateRoutinePayload'; routineId?: string | null } | null;
};

export type DisableRoutineMutationVariables = Exact<{
  routineId: Scalars['Uuid'];
}>;

export type DisableRoutineMutation = {
  __typename?: 'RootMutationType';
  disableRoutine?: { __typename?: 'CreateRoutinePayload'; routineId?: string | null } | null;
};

export type ArchiveRoutineMutationVariables = Exact<{
  routineId: Scalars['Uuid'];
}>;

export type ArchiveRoutineMutation = {
  __typename?: 'RootMutationType';
  archiveRoutine?: { __typename?: 'CreateRoutinePayload'; routineId?: string | null } | null;
};

export type TestWebhookMutationVariables = Exact<{
  url: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  triggerType: TriggerTypes;
}>;

export type TestWebhookMutation = {
  __typename?: 'RootMutationType';
  testWebhook?: { __typename?: 'TestWebhookPayload'; response?: WebhookResponseTypes | null } | null;
};

export type GetAllAccountsWithTransferLimitsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllAccountsWithTransferLimitsQuery = {
  __typename?: 'RootQueryType';
  depositAccounts?: Array<{
    __typename?: 'DepositAccount';
    id?: string | null;
    accountType?: DepositAccountType | null;
    transactions?: Array<{
      __typename?: 'Transaction';
      id?: string | null;
      transferType: TransferType;
      status: TransactionStatus;
      description?: string | null;
    } | null> | null;
    depositAccountNumber?: { __typename?: 'DepositAccountNumber'; lastFour?: string | null } | null;
    plans?: Array<{ __typename?: 'Plan'; id?: string | null; title?: string | null } | null> | null;
    odfiTransferLimit?: {
      __typename?: 'OdfiTransferLimit';
      pushAmountInCents?: number | null;
      pullAmountInCents?: number | null;
    } | null;
  } | null> | null;
};

export type AddTransactionCategoryMutationVariables = Exact<{
  depositAccountId: Scalars['Uuid'];
  transactionId: Scalars['Uuid'];
  category: TransactionCategories;
}>;

export type AddTransactionCategoryMutation = {
  __typename?: 'RootMutationType';
  addTransactionCategory?: { __typename?: 'AddTransactionCategoryPayload'; categorizationId?: string | null } | null;
};

export type ArchiveTransactionCategoryMutationVariables = Exact<{
  depositAccountId: Scalars['Uuid'];
  transactionId: Scalars['Uuid'];
  category: TransactionCategories;
}>;

export type ArchiveTransactionCategoryMutation = {
  __typename?: 'RootMutationType';
  archiveTransactionCategory?: {
    __typename?: 'ArchiveTransactionCategoryPayload';
    categorizationId?: string | null;
  } | null;
};

export type UpdateAddressMutationVariables = Exact<{
  city: Scalars['String'];
  countryCode: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  stateCode: Scalars['String'];
}>;

export type UpdateAddressMutation = {
  __typename?: 'RootMutationType';
  updateAddress?: {
    __typename?: 'UpdatePiiPayload';
    piiChangeRequestId?: string | null;
    status?: string | null;
  } | null;
};

export type UpdateEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type UpdateEmailMutation = {
  __typename?: 'RootMutationType';
  updateEmail?: { __typename?: 'UpdatePiiPayload'; piiChangeRequestId?: string | null; status?: string | null } | null;
};

export type UpdatePhoneMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;

export type UpdatePhoneMutation = {
  __typename?: 'RootMutationType';
  updatePhone?: { __typename?: 'UpdatePiiPayload'; piiChangeRequestId?: string | null; status?: string | null } | null;
};

export type UpdateNameMutationVariables = Exact<{
  nameFirst: Scalars['String'];
  nameLast: Scalars['String'];
  nameMiddle?: InputMaybe<Scalars['String']>;
  namePrefix?: InputMaybe<Scalars['String']>;
  nameSuffix?: InputMaybe<Scalars['String']>;
}>;

export type UpdateNameMutation = {
  __typename?: 'RootMutationType';
  updateName?: { __typename?: 'UpdatePiiPayload'; piiChangeRequestId?: string | null; status?: string | null } | null;
};

export type VerifyPiiConfirmationCodeMutationVariables = Exact<{
  code: Scalars['String'];
  piiChangeRequestId: Scalars['Uuid'];
}>;

export type VerifyPiiConfirmationCodeMutation = {
  __typename?: 'RootMutationType';
  verifyPiiConfirmationCode?: { __typename?: 'ConfirmPiiPayload'; piiChangeRequestId?: string | null } | null;
};

export type SendPiiConfirmationCodeMutationVariables = Exact<{
  piiChangeRequestId: Scalars['Uuid'];
}>;

export type SendPiiConfirmationCodeMutation = {
  __typename?: 'RootMutationType';
  sendPiiConfirmationCode?: { __typename?: 'ConfirmPiiPayload'; piiChangeRequestId?: string | null } | null;
};

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockInitiateCreditPaymentMutation((req, res, ctx) => {
 *   const { cardId, amountInCents, externalAccountId, ledgerId, depositAccountId, paymentAccountLastFour } = req.variables;
 *   return res(
 *     ctx.data({ initiateCreditPayment })
 *   )
 * })
 */
export const mockInitiateCreditPaymentMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<InitiateCreditPaymentMutationVariables>,
    GraphQLContext<InitiateCreditPaymentMutation>,
    any
  >,
) =>
  graphql.mutation<InitiateCreditPaymentMutation, InitiateCreditPaymentMutationVariables>(
    'InitiateCreditPayment',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockInitiateCreditCardAuthorizedUserCreationMutation((req, res, ctx) => {
 *   const { cardId, nameFirst, nameMiddle, nameLast, nameSuffix, birthDate, email, phoneNumber } = req.variables;
 *   return res(
 *     ctx.data({ initiateCreditCardAuthorizedUserCreation })
 *   )
 * })
 */
export const mockInitiateCreditCardAuthorizedUserCreationMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<InitiateCreditCardAuthorizedUserCreationMutationVariables>,
    GraphQLContext<InitiateCreditCardAuthorizedUserCreationMutation>,
    any
  >,
) =>
  graphql.mutation<
    InitiateCreditCardAuthorizedUserCreationMutation,
    InitiateCreditCardAuthorizedUserCreationMutationVariables
  >('InitiateCreditCardAuthorizedUserCreation', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockScheduleCreditPaymentMutation((req, res, ctx) => {
 *   const { cardId, amountInCents, externalAccountId, ledgerId, depositAccountId, scheduledDate, paymentAccountLastFour } = req.variables;
 *   return res(
 *     ctx.data({ scheduleCreditPayment })
 *   )
 * })
 */
export const mockScheduleCreditPaymentMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<ScheduleCreditPaymentMutationVariables>,
    GraphQLContext<ScheduleCreditPaymentMutation>,
    any
  >,
) =>
  graphql.mutation<ScheduleCreditPaymentMutation, ScheduleCreditPaymentMutationVariables>(
    'ScheduleCreditPayment',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSetRecurringCreditPaymentMutation((req, res, ctx) => {
 *   const { cardId, recurringPaymentType, depositAccountId, paymentAccountLastFour, externalAccountId, ledgerId, isOn } = req.variables;
 *   return res(
 *     ctx.data({ setRecurringCreditPayment })
 *   )
 * })
 */
export const mockSetRecurringCreditPaymentMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<SetRecurringCreditPaymentMutationVariables>,
    GraphQLContext<SetRecurringCreditPaymentMutation>,
    any
  >,
) =>
  graphql.mutation<SetRecurringCreditPaymentMutation, SetRecurringCreditPaymentMutationVariables>(
    'setRecurringCreditPayment',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockInitiateCashbackRedemptionMutation((req, res, ctx) => {
 *   const { cardId, amountInCents, depositAccountId, targetAccountLastFour, externalAccountId, redeemToCard } = req.variables;
 *   return res(
 *     ctx.data({ initiateCashbackRedemption })
 *   )
 * })
 */
export const mockInitiateCashbackRedemptionMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<InitiateCashbackRedemptionMutationVariables>,
    GraphQLContext<InitiateCashbackRedemptionMutation>,
    any
  >,
) =>
  graphql.mutation<InitiateCashbackRedemptionMutation, InitiateCashbackRedemptionMutationVariables>(
    'InitiateCashbackRedemption',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetActiveDepositAccountsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ depositAccounts })
 *   )
 * })
 */
export const mockGetActiveDepositAccountsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetActiveDepositAccountsQueryVariables>,
    GraphQLContext<GetActiveDepositAccountsQuery>,
    any
  >,
) =>
  graphql.query<GetActiveDepositAccountsQuery, GetActiveDepositAccountsQueryVariables>(
    'GetActiveDepositAccounts',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDepositAccountsForPaymentQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ depositAccounts })
 *   )
 * })
 */
export const mockGetDepositAccountsForPaymentQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDepositAccountsForPaymentQueryVariables>,
    GraphQLContext<GetDepositAccountsForPaymentQuery>,
    any
  >,
) =>
  graphql.query<GetDepositAccountsForPaymentQuery, GetDepositAccountsForPaymentQueryVariables>(
    'GetDepositAccountsForPayment',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetSubmitPaymentAccountsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ depositAccounts })
 *   )
 * })
 */
export const mockGetSubmitPaymentAccountsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetSubmitPaymentAccountsQueryVariables>,
    GraphQLContext<GetSubmitPaymentAccountsQuery>,
    any
  >,
) =>
  graphql.query<GetSubmitPaymentAccountsQuery, GetSubmitPaymentAccountsQueryVariables>(
    'GetSubmitPaymentAccounts',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDepositAccountsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ depositAccounts })
 *   )
 * })
 */
export const mockGetDepositAccountsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDepositAccountsQueryVariables>,
    GraphQLContext<GetDepositAccountsQuery>,
    any
  >,
) => graphql.query<GetDepositAccountsQuery, GetDepositAccountsQueryVariables>('GetDepositAccounts', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAccountDetailsDepositAccountQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ depositAccounts })
 *   )
 * })
 */
export const mockGetAccountDetailsDepositAccountQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetAccountDetailsDepositAccountQueryVariables>,
    GraphQLContext<GetAccountDetailsDepositAccountQuery>,
    any
  >,
) =>
  graphql.query<GetAccountDetailsDepositAccountQuery, GetAccountDetailsDepositAccountQueryVariables>(
    'GetAccountDetailsDepositAccount',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetBeneficiaryOfDepositAccountQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ depositAccount })
 *   )
 * })
 */
export const mockGetBeneficiaryOfDepositAccountQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetBeneficiaryOfDepositAccountQueryVariables>,
    GraphQLContext<GetBeneficiaryOfDepositAccountQuery>,
    any
  >,
) =>
  graphql.query<GetBeneficiaryOfDepositAccountQuery, GetBeneficiaryOfDepositAccountQueryVariables>(
    'GetBeneficiaryOfDepositAccount',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetStatementsOfDepositAccountQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ depositAccount })
 *   )
 * })
 */
export const mockGetStatementsOfDepositAccountQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetStatementsOfDepositAccountQueryVariables>,
    GraphQLContext<GetStatementsOfDepositAccountQuery>,
    any
  >,
) =>
  graphql.query<GetStatementsOfDepositAccountQuery, GetStatementsOfDepositAccountQueryVariables>(
    'GetStatementsOfDepositAccount',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddBeneficiaryOfDepositAccountMutation((req, res, ctx) => {
 *   const { depositAccountId, firstName, middleName, lastName, suffix, birthDate, nationalIdNumberToken, addressLineOne, addressLineTwo, addressCity, addressPostalCode, addressStateCode, addressCountryCode, email, phoneNumber, instructionType } = req.variables;
 *   return res(
 *     ctx.data({ assignDepositAccountBeneficiary })
 *   )
 * })
 */
export const mockAddBeneficiaryOfDepositAccountMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AddBeneficiaryOfDepositAccountMutationVariables>,
    GraphQLContext<AddBeneficiaryOfDepositAccountMutation>,
    any
  >,
) =>
  graphql.mutation<AddBeneficiaryOfDepositAccountMutation, AddBeneficiaryOfDepositAccountMutationVariables>(
    'AddBeneficiaryOfDepositAccount',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRemoveBeneficiaryOfDepositAccountMutation((req, res, ctx) => {
 *   const { depositAccountId, beneficiaryId } = req.variables;
 *   return res(
 *     ctx.data({ removeDepositAccountBeneficiary })
 *   )
 * })
 */
export const mockRemoveBeneficiaryOfDepositAccountMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<RemoveBeneficiaryOfDepositAccountMutationVariables>,
    GraphQLContext<RemoveBeneficiaryOfDepositAccountMutation>,
    any
  >,
) =>
  graphql.mutation<RemoveBeneficiaryOfDepositAccountMutation, RemoveBeneficiaryOfDepositAccountMutationVariables>(
    'RemoveBeneficiaryOfDepositAccount',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDepositAccountTransactionQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ depositAccount })
 *   )
 * })
 */
export const mockGetDepositAccountTransactionQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDepositAccountTransactionQueryVariables>,
    GraphQLContext<GetDepositAccountTransactionQuery>,
    any
  >,
) =>
  graphql.query<GetDepositAccountTransactionQuery, GetDepositAccountTransactionQueryVariables>(
    'GetDepositAccountTransaction',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDepositAccountByIdQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ depositAccount })
 *   )
 * })
 */
export const mockGetDepositAccountByIdQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDepositAccountByIdQueryVariables>,
    GraphQLContext<GetDepositAccountByIdQuery>,
    any
  >,
) => graphql.query<GetDepositAccountByIdQuery, GetDepositAccountByIdQueryVariables>('GetDepositAccountById', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDepositAccountsTransferLimitsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ depositAccounts })
 *   )
 * })
 */
export const mockGetDepositAccountsTransferLimitsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDepositAccountsTransferLimitsQueryVariables>,
    GraphQLContext<GetDepositAccountsTransferLimitsQuery>,
    any
  >,
) =>
  graphql.query<GetDepositAccountsTransferLimitsQuery, GetDepositAccountsTransferLimitsQueryVariables>(
    'GetDepositAccountsTransferLimits',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDepositAccountEarningQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ depositAccount })
 *   )
 * })
 */
export const mockGetDepositAccountEarningQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDepositAccountEarningQueryVariables>,
    GraphQLContext<GetDepositAccountEarningQuery>,
    any
  >,
) =>
  graphql.query<GetDepositAccountEarningQuery, GetDepositAccountEarningQueryVariables>(
    'GetDepositAccountEarning',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCanOpenIndividualDepositAccountQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ canOpenIndividualDepositAccount })
 *   )
 * })
 */
export const mockCanOpenIndividualDepositAccountQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<CanOpenIndividualDepositAccountQueryVariables>,
    GraphQLContext<CanOpenIndividualDepositAccountQuery>,
    any
  >,
) =>
  graphql.query<CanOpenIndividualDepositAccountQuery, CanOpenIndividualDepositAccountQueryVariables>(
    'CanOpenIndividualDepositAccount',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRequestIndividualDepositAccountMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ requestIndividualDepositAccount })
 *   )
 * })
 */
export const mockRequestIndividualDepositAccountMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<RequestIndividualDepositAccountMutationVariables>,
    GraphQLContext<RequestIndividualDepositAccountMutation>,
    any
  >,
) =>
  graphql.mutation<RequestIndividualDepositAccountMutation, RequestIndividualDepositAccountMutationVariables>(
    'RequestIndividualDepositAccount',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTaxDocumentsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ depositAccounts })
 *   )
 * })
 */
export const mockGetTaxDocumentsQuery = (
  resolver: ResponseResolver<GraphQLRequest<GetTaxDocumentsQueryVariables>, GraphQLContext<GetTaxDocumentsQuery>, any>,
) => graphql.query<GetTaxDocumentsQuery, GetTaxDocumentsQueryVariables>('getTaxDocuments', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockInitiateInternalTransferMutation((req, res, ctx) => {
 *   const { amountInCents, fromAccountId, toAccountId, scheduledFor } = req.variables;
 *   return res(
 *     ctx.data({ initiateInternalTransfer })
 *   )
 * })
 */
export const mockInitiateInternalTransferMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<InitiateInternalTransferMutationVariables>,
    GraphQLContext<InitiateInternalTransferMutation>,
    any
  >,
) =>
  graphql.mutation<InitiateInternalTransferMutation, InitiateInternalTransferMutationVariables>(
    'InitiateInternalTransfer',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockInitiateOdfiTransferMutation((req, res, ctx) => {
 *   const { type, amountInCents, depositAccountId, externalAccountId, scheduledFor, odfiPlanTransferData, legacyExternalAccounts } = req.variables;
 *   return res(
 *     ctx.data({ createNewOdfiTransfer })
 *   )
 * })
 */
export const mockInitiateOdfiTransferMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<InitiateOdfiTransferMutationVariables>,
    GraphQLContext<InitiateOdfiTransferMutation>,
    any
  >,
) =>
  graphql.mutation<InitiateOdfiTransferMutation, InitiateOdfiTransferMutationVariables>(
    'InitiateOdfiTransfer',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetClosedDepositAccountsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ depositAccounts })
 *   )
 * })
 */
export const mockGetClosedDepositAccountsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetClosedDepositAccountsQueryVariables>,
    GraphQLContext<GetClosedDepositAccountsQuery>,
    any
  >,
) =>
  graphql.query<GetClosedDepositAccountsQuery, GetClosedDepositAccountsQueryVariables>(
    'GetClosedDepositAccounts',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetApyTrackingByIdAndDateQuery((req, res, ctx) => {
 *   const { depositAccountId, startDate, endDate } = req.variables;
 *   return res(
 *     ctx.data({ apyTracking })
 *   )
 * })
 */
export const mockGetApyTrackingByIdAndDateQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetApyTrackingByIdAndDateQueryVariables>,
    GraphQLContext<GetApyTrackingByIdAndDateQuery>,
    any
  >,
) =>
  graphql.query<GetApyTrackingByIdAndDateQuery, GetApyTrackingByIdAndDateQueryVariables>(
    'GetApyTrackingByIdAndDate',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMonthlyCashflowByAccountIdQuery((req, res, ctx) => {
 *   const { depositAccountId, startDate, endDate } = req.variables;
 *   return res(
 *     ctx.data({ monthlyCashFlow })
 *   )
 * })
 */
export const mockGetMonthlyCashflowByAccountIdQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetMonthlyCashflowByAccountIdQueryVariables>,
    GraphQLContext<GetMonthlyCashflowByAccountIdQuery>,
    any
  >,
) =>
  graphql.query<GetMonthlyCashflowByAccountIdQuery, GetMonthlyCashflowByAccountIdQueryVariables>(
    'getMonthlyCashflowByAccountId',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCancelInternalScheduledTransferMutation((req, res, ctx) => {
 *   const { internalTransferId } = req.variables;
 *   return res(
 *     ctx.data({ cancelInternalScheduledTransfer })
 *   )
 * })
 */
export const mockCancelInternalScheduledTransferMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CancelInternalScheduledTransferMutationVariables>,
    GraphQLContext<CancelInternalScheduledTransferMutation>,
    any
  >,
) =>
  graphql.mutation<CancelInternalScheduledTransferMutation, CancelInternalScheduledTransferMutationVariables>(
    'CancelInternalScheduledTransfer',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCancelOdfiScheduledTransferMutation((req, res, ctx) => {
 *   const { odfiAchTransferId } = req.variables;
 *   return res(
 *     ctx.data({ cancelOdfiScheduledTransfer })
 *   )
 * })
 */
export const mockCancelOdfiScheduledTransferMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CancelOdfiScheduledTransferMutationVariables>,
    GraphQLContext<CancelOdfiScheduledTransferMutation>,
    any
  >,
) =>
  graphql.mutation<CancelOdfiScheduledTransferMutation, CancelOdfiScheduledTransferMutationVariables>(
    'CancelOdfiScheduledTransfer',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDepositAccountsWithTransactionsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ depositAccounts })
 *   )
 * })
 */
export const mockGetDepositAccountsWithTransactionsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDepositAccountsWithTransactionsQueryVariables>,
    GraphQLContext<GetDepositAccountsWithTransactionsQuery>,
    any
  >,
) =>
  graphql.query<GetDepositAccountsWithTransactionsQuery, GetDepositAccountsWithTransactionsQueryVariables>(
    'GetDepositAccountsWithTransactions',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRequestOutgoingWireMutation((req, res, ctx) => {
 *   const { originatorAccountNumber, beneficiaryBankName, beneficiaryRoutingNumber, beneficiaryAccountNumber, beneficiaryName, beneficiaryAddressLine1, beneficiaryCity, beneficiaryStateCode, beneficiaryPostalCode, memo, amountInCents, originatorToBeneficiaryLine1, originatorToBeneficiaryLine2, consent } = req.variables;
 *   return res(
 *     ctx.data({ requestOutgoingWireTransferV2 })
 *   )
 * })
 */
export const mockRequestOutgoingWireMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<RequestOutgoingWireMutationVariables>,
    GraphQLContext<RequestOutgoingWireMutation>,
    any
  >,
) =>
  graphql.mutation<RequestOutgoingWireMutation, RequestOutgoingWireMutationVariables>('RequestOutgoingWire', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetOutgoingWiresQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ outgoingWireTransfers })
 *   )
 * })
 */
export const mockGetOutgoingWiresQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetOutgoingWiresQueryVariables>,
    GraphQLContext<GetOutgoingWiresQuery>,
    any
  >,
) => graphql.query<GetOutgoingWiresQuery, GetOutgoingWiresQueryVariables>('GetOutgoingWires', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockInitiateDepositAccountClosureWithTransferMutation((req, res, ctx) => {
 *   const { depositAccountId, externalAccountId, closureReasons } = req.variables;
 *   return res(
 *     ctx.data({ initiateDepositAccountClosureWithTransfer })
 *   )
 * })
 */
export const mockInitiateDepositAccountClosureWithTransferMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<InitiateDepositAccountClosureWithTransferMutationVariables>,
    GraphQLContext<InitiateDepositAccountClosureWithTransferMutation>,
    any
  >,
) =>
  graphql.mutation<
    InitiateDepositAccountClosureWithTransferMutation,
    InitiateDepositAccountClosureWithTransferMutationVariables
  >('InitiateDepositAccountClosureWithTransfer', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockInitiateDepositAccountClosureWithoutTransferMutation((req, res, ctx) => {
 *   const { depositAccountId, closureReason } = req.variables;
 *   return res(
 *     ctx.data({ initiateDepositAccountClosureWithoutTransfer })
 *   )
 * })
 */
export const mockInitiateDepositAccountClosureWithoutTransferMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<InitiateDepositAccountClosureWithoutTransferMutationVariables>,
    GraphQLContext<InitiateDepositAccountClosureWithoutTransferMutation>,
    any
  >,
) =>
  graphql.mutation<
    InitiateDepositAccountClosureWithoutTransferMutation,
    InitiateDepositAccountClosureWithoutTransferMutationVariables
  >('initiateDepositAccountClosureWithoutTransfer', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCustomerExternalAccountCheckMutation((req, res, ctx) => {
 *   const { depositAccountId } = req.variables;
 *   return res(
 *     ctx.data({ customerExternalAccountCheck })
 *   )
 * })
 */
export const mockCustomerExternalAccountCheckMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CustomerExternalAccountCheckMutationVariables>,
    GraphQLContext<CustomerExternalAccountCheckMutation>,
    any
  >,
) =>
  graphql.mutation<CustomerExternalAccountCheckMutation, CustomerExternalAccountCheckMutationVariables>(
    'customerExternalAccountCheck',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreatePlaidLinkTokenMutation((req, res, ctx) => {
 *   const { externalAccountId, linkType } = req.variables;
 *   return res(
 *     ctx.data({ createPlaidLinkToken })
 *   )
 * })
 */
export const mockCreatePlaidLinkTokenMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreatePlaidLinkTokenMutationVariables>,
    GraphQLContext<CreatePlaidLinkTokenMutation>,
    any
  >,
) =>
  graphql.mutation<CreatePlaidLinkTokenMutation, CreatePlaidLinkTokenMutationVariables>(
    'CreatePlaidLinkToken',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreatePlaidLinkTokenWithoutTransactionsProductMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ createPlaidLinkTokenWithoutTransactionsProduct })
 *   )
 * })
 */
export const mockCreatePlaidLinkTokenWithoutTransactionsProductMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreatePlaidLinkTokenWithoutTransactionsProductMutationVariables>,
    GraphQLContext<CreatePlaidLinkTokenWithoutTransactionsProductMutation>,
    any
  >,
) =>
  graphql.mutation<
    CreatePlaidLinkTokenWithoutTransactionsProductMutation,
    CreatePlaidLinkTokenWithoutTransactionsProductMutationVariables
  >('CreatePlaidLinkTokenWithoutTransactionsProduct', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockExchangePlaidPublicItemTokenMutation((req, res, ctx) => {
 *   const { publicToken, metadata } = req.variables;
 *   return res(
 *     ctx.data({ exchangePlaidPublicItemToken })
 *   )
 * })
 */
export const mockExchangePlaidPublicItemTokenMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<ExchangePlaidPublicItemTokenMutationVariables>,
    GraphQLContext<ExchangePlaidPublicItemTokenMutation>,
    any
  >,
) =>
  graphql.mutation<ExchangePlaidPublicItemTokenMutation, ExchangePlaidPublicItemTokenMutationVariables>(
    'ExchangePlaidPublicItemToken',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdatePlaidItemMutation((req, res, ctx) => {
 *   const { externalAccountId, action } = req.variables;
 *   return res(
 *     ctx.data({ updatePlaidItem })
 *   )
 * })
 */
export const mockUpdatePlaidItemMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdatePlaidItemMutationVariables>,
    GraphQLContext<UpdatePlaidItemMutation>,
    any
  >,
) => graphql.mutation<UpdatePlaidItemMutation, UpdatePlaidItemMutationVariables>('UpdatePlaidItem', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockArchiveExternalAccountMutation((req, res, ctx) => {
 *   const { externalAccountId } = req.variables;
 *   return res(
 *     ctx.data({ archiveExternalAccount })
 *   )
 * })
 */
export const mockArchiveExternalAccountMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<ArchiveExternalAccountMutationVariables>,
    GraphQLContext<ArchiveExternalAccountMutation>,
    any
  >,
) =>
  graphql.mutation<ArchiveExternalAccountMutation, ArchiveExternalAccountMutationVariables>(
    'ArchiveExternalAccount',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateExternalAccountMutation((req, res, ctx) => {
 *   const { externalAccountId, nickname } = req.variables;
 *   return res(
 *     ctx.data({ updateExternalAccount })
 *   )
 * })
 */
export const mockUpdateExternalAccountMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateExternalAccountMutationVariables>,
    GraphQLContext<UpdateExternalAccountMutation>,
    any
  >,
) =>
  graphql.mutation<UpdateExternalAccountMutation, UpdateExternalAccountMutationVariables>(
    'UpdateExternalAccount',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetExternalAccountsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ externalAccounts })
 *   )
 * })
 */
export const mockGetExternalAccountsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetExternalAccountsQueryVariables>,
    GraphQLContext<GetExternalAccountsQuery>,
    any
  >,
) => graphql.query<GetExternalAccountsQuery, GetExternalAccountsQueryVariables>('GetExternalAccounts', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockInitiateJointAccountInviteMutation((req, res, ctx) => {
 *   const { depositAccountId, inviteeAddressCity, inviteeAddressCountryCode, inviteeAddressLineOne, inviteeAddressLineTwo, inviteeAddressPostalCode, inviteeAddressStateCode, inviteeDateOfBirth, inviteeEmail, inviteeFirstName, inviteeLastName, inviteeNationalIdNumberToken } = req.variables;
 *   return res(
 *     ctx.data({ initiateJointAccountInvite })
 *   )
 * })
 */
export const mockInitiateJointAccountInviteMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<InitiateJointAccountInviteMutationVariables>,
    GraphQLContext<InitiateJointAccountInviteMutation>,
    any
  >,
) =>
  graphql.mutation<InitiateJointAccountInviteMutation, InitiateJointAccountInviteMutationVariables>(
    'InitiateJointAccountInvite',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAcceptJointAccountInviteMutation((req, res, ctx) => {
 *   const { inviteId, sendDebitCard } = req.variables;
 *   return res(
 *     ctx.data({ acceptJointAccountInvite })
 *   )
 * })
 */
export const mockAcceptJointAccountInviteMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AcceptJointAccountInviteMutationVariables>,
    GraphQLContext<AcceptJointAccountInviteMutation>,
    any
  >,
) =>
  graphql.mutation<AcceptJointAccountInviteMutation, AcceptJointAccountInviteMutationVariables>(
    'AcceptJointAccountInvite',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetJointAccountInvitesQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ jointAccountInvites })
 *   )
 * })
 */
export const mockGetJointAccountInvitesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetJointAccountInvitesQueryVariables>,
    GraphQLContext<GetJointAccountInvitesQuery>,
    any
  >,
) =>
  graphql.query<GetJointAccountInvitesQuery, GetJointAccountInvitesQueryVariables>('GetJointAccountInvites', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetCanOpenPendingJointAccountQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ canOpenPendingJointAccount })
 *   )
 * })
 */
export const mockGetCanOpenPendingJointAccountQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetCanOpenPendingJointAccountQueryVariables>,
    GraphQLContext<GetCanOpenPendingJointAccountQuery>,
    any
  >,
) =>
  graphql.query<GetCanOpenPendingJointAccountQuery, GetCanOpenPendingJointAccountQueryVariables>(
    'GetCanOpenPendingJointAccount',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetOfferedApyValuesQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ offeredApyValues })
 *   )
 * })
 */
export const mockGetOfferedApyValuesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetOfferedApyValuesQueryVariables>,
    GraphQLContext<GetOfferedApyValuesQuery>,
    any
  >,
) => graphql.query<GetOfferedApyValuesQuery, GetOfferedApyValuesQueryVariables>('GetOfferedApyValues', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetDepositAccountPlansQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ depositAccount })
 *   )
 * })
 */
export const mockGetDepositAccountPlansQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetDepositAccountPlansQueryVariables>,
    GraphQLContext<GetDepositAccountPlansQuery>,
    any
  >,
) =>
  graphql.query<GetDepositAccountPlansQuery, GetDepositAccountPlansQueryVariables>('GetDepositAccountPlans', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPlanDetailsTransactionQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ depositAccount })
 *   )
 * })
 */
export const mockGetPlanDetailsTransactionQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetPlanDetailsTransactionQueryVariables>,
    GraphQLContext<GetPlanDetailsTransactionQuery>,
    any
  >,
) =>
  graphql.query<GetPlanDetailsTransactionQuery, GetPlanDetailsTransactionQueryVariables>(
    'GetPlanDetailsTransaction',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRequestPlanAccountNumberMutation((req, res, ctx) => {
 *   const { planId } = req.variables;
 *   return res(
 *     ctx.data({ requestPlanAccountNumber })
 *   )
 * })
 */
export const mockRequestPlanAccountNumberMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<RequestPlanAccountNumberMutationVariables>,
    GraphQLContext<RequestPlanAccountNumberMutation>,
    any
  >,
) =>
  graphql.mutation<RequestPlanAccountNumberMutation, RequestPlanAccountNumberMutationVariables>(
    'requestPlanAccountNumber',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRemovePlanAccountNumberMutation((req, res, ctx) => {
 *   const { planId, accountNumber } = req.variables;
 *   return res(
 *     ctx.data({ removePlanAccountNumber })
 *   )
 * })
 */
export const mockRemovePlanAccountNumberMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<RemovePlanAccountNumberMutationVariables>,
    GraphQLContext<RemovePlanAccountNumberMutation>,
    any
  >,
) =>
  graphql.mutation<RemovePlanAccountNumberMutation, RemovePlanAccountNumberMutationVariables>(
    'removePlanAccountNumber',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreatePlanMutation((req, res, ctx) => {
 *   const { title, color, goalAmountInCents, description, depositAccountId, initialContributionAmountInCents, monthlyContributionGoalAmountInCents, goalDate, assignAccountNumber, type } = req.variables;
 *   return res(
 *     ctx.data({ createPlan })
 *   )
 * })
 */
export const mockCreatePlanMutation = (
  resolver: ResponseResolver<GraphQLRequest<CreatePlanMutationVariables>, GraphQLContext<CreatePlanMutation>, any>,
) => graphql.mutation<CreatePlanMutation, CreatePlanMutationVariables>('CreatePlan', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockArchivePlanMutation((req, res, ctx) => {
 *   const { planId } = req.variables;
 *   return res(
 *     ctx.data({ archivePlan })
 *   )
 * })
 */
export const mockArchivePlanMutation = (
  resolver: ResponseResolver<GraphQLRequest<ArchivePlanMutationVariables>, GraphQLContext<ArchivePlanMutation>, any>,
) => graphql.mutation<ArchivePlanMutation, ArchivePlanMutationVariables>('ArchivePlan', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdatePlanMutation((req, res, ctx) => {
 *   const { planId, title, color, goalAmountInCents, description, goalDate, monthlyContributionGoalAmountInCents } = req.variables;
 *   return res(
 *     ctx.data({ updatePlan })
 *   )
 * })
 */
export const mockUpdatePlanMutation = (
  resolver: ResponseResolver<GraphQLRequest<UpdatePlanMutationVariables>, GraphQLContext<UpdatePlanMutation>, any>,
) => graphql.mutation<UpdatePlanMutation, UpdatePlanMutationVariables>('UpdatePlan', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockInitiatePlanTransferMutation((req, res, ctx) => {
 *   const { amountInCents, depositAccountId, fromAccountId, fromAccountType, toAccountId, toAccountType, scheduledFor } = req.variables;
 *   return res(
 *     ctx.data({ initiatePlanTransfer })
 *   )
 * })
 */
export const mockInitiatePlanTransferMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<InitiatePlanTransferMutationVariables>,
    GraphQLContext<InitiatePlanTransferMutation>,
    any
  >,
) =>
  graphql.mutation<InitiatePlanTransferMutation, InitiatePlanTransferMutationVariables>(
    'InitiatePlanTransfer',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockListRoutinesQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ routines })
 *   )
 * })
 */
export const mockListRoutinesQuery = (
  resolver: ResponseResolver<GraphQLRequest<ListRoutinesQueryVariables>, GraphQLContext<ListRoutinesQuery>, any>,
) => graphql.query<ListRoutinesQuery, ListRoutinesQueryVariables>('ListRoutines', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockListSharedRoutinesQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ sharedRoutines })
 *   )
 * })
 */
export const mockListSharedRoutinesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<ListSharedRoutinesQueryVariables>,
    GraphQLContext<ListSharedRoutinesQuery>,
    any
  >,
) => graphql.query<ListSharedRoutinesQuery, ListSharedRoutinesQueryVariables>('ListSharedRoutines', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAllAccountsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ depositAccounts })
 *   )
 * })
 */
export const mockGetAllAccountsQuery = (
  resolver: ResponseResolver<GraphQLRequest<GetAllAccountsQueryVariables>, GraphQLContext<GetAllAccountsQuery>, any>,
) => graphql.query<GetAllAccountsQuery, GetAllAccountsQueryVariables>('GetAllAccounts', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockListTemplatesQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ routineTemplates })
 *   )
 * })
 */
export const mockListTemplatesQuery = (
  resolver: ResponseResolver<GraphQLRequest<ListTemplatesQueryVariables>, GraphQLContext<ListTemplatesQuery>, any>,
) => graphql.query<ListTemplatesQuery, ListTemplatesQueryVariables>('ListTemplates', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateRoutineMutation((req, res, ctx) => {
 *   const { templateId, name, description, trigger, action, consents } = req.variables;
 *   return res(
 *     ctx.data({ createRoutine })
 *   )
 * })
 */
export const mockCreateRoutineMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateRoutineMutationVariables>,
    GraphQLContext<CreateRoutineMutation>,
    any
  >,
) => graphql.mutation<CreateRoutineMutation, CreateRoutineMutationVariables>('CreateRoutine', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockEditRoutineMutation((req, res, ctx) => {
 *   const { routineId, name, description, trigger, action, consents } = req.variables;
 *   return res(
 *     ctx.data({ editRoutine })
 *   )
 * })
 */
export const mockEditRoutineMutation = (
  resolver: ResponseResolver<GraphQLRequest<EditRoutineMutationVariables>, GraphQLContext<EditRoutineMutation>, any>,
) => graphql.mutation<EditRoutineMutation, EditRoutineMutationVariables>('EditRoutine', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockEnableRoutineMutation((req, res, ctx) => {
 *   const { routineId } = req.variables;
 *   return res(
 *     ctx.data({ enableRoutine })
 *   )
 * })
 */
export const mockEnableRoutineMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<EnableRoutineMutationVariables>,
    GraphQLContext<EnableRoutineMutation>,
    any
  >,
) => graphql.mutation<EnableRoutineMutation, EnableRoutineMutationVariables>('EnableRoutine', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDisableRoutineMutation((req, res, ctx) => {
 *   const { routineId } = req.variables;
 *   return res(
 *     ctx.data({ disableRoutine })
 *   )
 * })
 */
export const mockDisableRoutineMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DisableRoutineMutationVariables>,
    GraphQLContext<DisableRoutineMutation>,
    any
  >,
) => graphql.mutation<DisableRoutineMutation, DisableRoutineMutationVariables>('DisableRoutine', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockArchiveRoutineMutation((req, res, ctx) => {
 *   const { routineId } = req.variables;
 *   return res(
 *     ctx.data({ archiveRoutine })
 *   )
 * })
 */
export const mockArchiveRoutineMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<ArchiveRoutineMutationVariables>,
    GraphQLContext<ArchiveRoutineMutation>,
    any
  >,
) => graphql.mutation<ArchiveRoutineMutation, ArchiveRoutineMutationVariables>('ArchiveRoutine', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockTestWebhookMutation((req, res, ctx) => {
 *   const { url, name, description, triggerType } = req.variables;
 *   return res(
 *     ctx.data({ testWebhook })
 *   )
 * })
 */
export const mockTestWebhookMutation = (
  resolver: ResponseResolver<GraphQLRequest<TestWebhookMutationVariables>, GraphQLContext<TestWebhookMutation>, any>,
) => graphql.mutation<TestWebhookMutation, TestWebhookMutationVariables>('TestWebhook', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAllAccountsWithTransferLimitsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ depositAccounts })
 *   )
 * })
 */
export const mockGetAllAccountsWithTransferLimitsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetAllAccountsWithTransferLimitsQueryVariables>,
    GraphQLContext<GetAllAccountsWithTransferLimitsQuery>,
    any
  >,
) =>
  graphql.query<GetAllAccountsWithTransferLimitsQuery, GetAllAccountsWithTransferLimitsQueryVariables>(
    'GetAllAccountsWithTransferLimits',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddTransactionCategoryMutation((req, res, ctx) => {
 *   const { depositAccountId, transactionId, category } = req.variables;
 *   return res(
 *     ctx.data({ addTransactionCategory })
 *   )
 * })
 */
export const mockAddTransactionCategoryMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AddTransactionCategoryMutationVariables>,
    GraphQLContext<AddTransactionCategoryMutation>,
    any
  >,
) =>
  graphql.mutation<AddTransactionCategoryMutation, AddTransactionCategoryMutationVariables>(
    'AddTransactionCategory',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockArchiveTransactionCategoryMutation((req, res, ctx) => {
 *   const { depositAccountId, transactionId, category } = req.variables;
 *   return res(
 *     ctx.data({ archiveTransactionCategory })
 *   )
 * })
 */
export const mockArchiveTransactionCategoryMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<ArchiveTransactionCategoryMutationVariables>,
    GraphQLContext<ArchiveTransactionCategoryMutation>,
    any
  >,
) =>
  graphql.mutation<ArchiveTransactionCategoryMutation, ArchiveTransactionCategoryMutationVariables>(
    'ArchiveTransactionCategory',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateAddressMutation((req, res, ctx) => {
 *   const { city, countryCode, line1, line2, postalCode, stateCode } = req.variables;
 *   return res(
 *     ctx.data({ updateAddress })
 *   )
 * })
 */
export const mockUpdateAddressMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateAddressMutationVariables>,
    GraphQLContext<UpdateAddressMutation>,
    any
  >,
) => graphql.mutation<UpdateAddressMutation, UpdateAddressMutationVariables>('UpdateAddress', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateEmailMutation((req, res, ctx) => {
 *   const { email } = req.variables;
 *   return res(
 *     ctx.data({ updateEmail })
 *   )
 * })
 */
export const mockUpdateEmailMutation = (
  resolver: ResponseResolver<GraphQLRequest<UpdateEmailMutationVariables>, GraphQLContext<UpdateEmailMutation>, any>,
) => graphql.mutation<UpdateEmailMutation, UpdateEmailMutationVariables>('updateEmail', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdatePhoneMutation((req, res, ctx) => {
 *   const { phoneNumber } = req.variables;
 *   return res(
 *     ctx.data({ updatePhone })
 *   )
 * })
 */
export const mockUpdatePhoneMutation = (
  resolver: ResponseResolver<GraphQLRequest<UpdatePhoneMutationVariables>, GraphQLContext<UpdatePhoneMutation>, any>,
) => graphql.mutation<UpdatePhoneMutation, UpdatePhoneMutationVariables>('UpdatePhone', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateNameMutation((req, res, ctx) => {
 *   const { nameFirst, nameLast, nameMiddle, namePrefix, nameSuffix } = req.variables;
 *   return res(
 *     ctx.data({ updateName })
 *   )
 * })
 */
export const mockUpdateNameMutation = (
  resolver: ResponseResolver<GraphQLRequest<UpdateNameMutationVariables>, GraphQLContext<UpdateNameMutation>, any>,
) => graphql.mutation<UpdateNameMutation, UpdateNameMutationVariables>('UpdateName', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockVerifyPiiConfirmationCodeMutation((req, res, ctx) => {
 *   const { code, piiChangeRequestId } = req.variables;
 *   return res(
 *     ctx.data({ verifyPiiConfirmationCode })
 *   )
 * })
 */
export const mockVerifyPiiConfirmationCodeMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<VerifyPiiConfirmationCodeMutationVariables>,
    GraphQLContext<VerifyPiiConfirmationCodeMutation>,
    any
  >,
) =>
  graphql.mutation<VerifyPiiConfirmationCodeMutation, VerifyPiiConfirmationCodeMutationVariables>(
    'VerifyPiiConfirmationCode',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSendPiiConfirmationCodeMutation((req, res, ctx) => {
 *   const { piiChangeRequestId } = req.variables;
 *   return res(
 *     ctx.data({ sendPiiConfirmationCode })
 *   )
 * })
 */
export const mockSendPiiConfirmationCodeMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<SendPiiConfirmationCodeMutationVariables>,
    GraphQLContext<SendPiiConfirmationCodeMutation>,
    any
  >,
) =>
  graphql.mutation<SendPiiConfirmationCodeMutation, SendPiiConfirmationCodeMutationVariables>(
    'SendPiiConfirmationCode',
    resolver,
  );

export const SubActionFragmentDoc = `
    fragment SubAction on Action {
  ... on PlanTransferAction {
    __typename
    type
    planTransferActionOptions: options {
      depositAccountId
      fromAccountId
      fromAccountType
      toAccountId
      toAccountType
      amountInCents
      percentage
    }
    version
  }
  ... on InternalTransferAction {
    __typename
    type
    internalTransferActionOptions: options {
      fromDepositAccountId
      toDepositAccountId
      amountInCents
      percentage
    }
    version
  }
  ... on CategorizeAction {
    __typename
    type
    categorizeActionOptions: options {
      depositAccountId
      category
    }
    version
  }
  ... on ExternalTransferAction {
    __typename
    type
    externalTransferAction: options {
      depositAccountId
      externalAccountId
      amountInCents
      percentage
      type
    }
    version
  }
  ... on MultiPlanTransferAction {
    __typename
    type
    multiPlanTransferActionOptions: options {
      depositAccountId
      direction
      transfers {
        planId
        amountInCents
        percentage
      }
    }
    version
  }
  ... on NotificationAction {
    __typename
    type
    notificationActionOptions: options {
      sms
      push
      email
      message
    }
    version
  }
  ... on CashbackRedemptionAction {
    __typename
    type
    cashbackRedemptionActionOptions: options {
      externalAccountId
      depositAccountId
    }
    version
  }
  ... on CreditCardPaymentAction {
    __typename
    type
    creditCardPaymentActionOptions: options {
      creditCardAccountId
      customAmountInCents
      fromAccountId
      fromAccountType
      paymentBalancePercentage
      paymentBalanceType
    }
    version
  }
  ... on WebhookAction {
    __typename
    type
    webhookActionOptions: options {
      url
      name
      description
      webhookHeaders {
        key
        value
      }
    }
    version
  }
}
    `;
export const ActionFragmentDoc = `
    fragment Action on Action {
  ...SubAction
  ... on MultiAction {
    __typename
    type
    version
    options {
      actions {
        ...SubAction
      }
    }
  }
}
    ${SubActionFragmentDoc}`;
export const TriggerFragmentDoc = `
    fragment Trigger on Trigger {
  ... on CategoryTrigger {
    __typename
    type
    version
    categoryTriggerOptions: options {
      depositAccountId
      category
    }
  }
  ... on DateTrigger {
    __typename
    type
    version
    dateTriggerOptions: options {
      pattern
    }
  }
  ... on BalanceTrigger {
    __typename
    type
    version
    balanceTriggerOptions: options {
      depositAccountId
      amountInCents
      comparison
    }
  }
  ... on DescriptionTrigger {
    __typename
    type
    version
    descriptionTriggerOptions: options {
      depositAccountId
      description
    }
  }
  ... on UserCategoryTrigger {
    __typename
    type
    version
    userCategoryTriggerOptions: options {
      depositAccountId
      userCategory
    }
  }
  ... on CashbackRedemptionTrigger {
    __typename
    type
    version
    cashbackRedemptionTriggerOptions: options {
      amount
      accountId
    }
  }
  ... on DepositTransactionTrigger {
    __typename
    type
    version
    depositTransactionTriggerOptions: options {
      depositAccountId
      conditions {
        category
        direction
        exactMatch
        greaterThan
        isDirectDeposit
        lessThan
        partialMatch
      }
    }
  }
}
    `;
export const RoutineFragmentDoc = `
    fragment Routine on Routine {
  id
  isEnabled
  archivedAt
  lastRunAt
  lastRunStatus
  createdByName
  createdBy
  isSharedRoutine
  name
  description
  templateId
  action {
    ...Action
  }
  trigger {
    ...Trigger
  }
}
    ${ActionFragmentDoc}
${TriggerFragmentDoc}`;
export const TemplateSubActionFragmentDoc = `
    fragment TemplateSubAction on Action {
  ... on PlanTransferAction {
    __typename
    type
    planTransferActionOptions: options {
      fromAccountType
      toAccountType
      amountInCents
      percentage
    }
    version
  }
  ... on InternalTransferAction {
    __typename
    type
    internalTransferActionOptions: options {
      amountInCents
      percentage
    }
    version
  }
  ... on CategorizeAction {
    __typename
    type
    categorizeActionOptions: options {
      category
    }
    version
  }
  ... on ExternalTransferAction {
    __typename
    type
    externalTransferAction: options {
      amountInCents
      percentage
      type
    }
    version
  }
  ... on MultiPlanTransferAction {
    __typename
    type
    multiPlanTransferActionOptions: options {
      direction
      transfers {
        amountInCents
        percentage
      }
    }
    version
  }
  ... on NotificationAction {
    __typename
    type
    notificationActionOptions: options {
      sms
      push
      email
      message
    }
    version
  }
  ... on CashbackRedemptionAction {
    __typename
    type
    version
  }
  ... on WebhookAction {
    __typename
    type
    webhookActionOptions: options {
      url
      name
      description
      webhookHeaders {
        key
        value
      }
    }
    version
  }
  ... on CreditCardPaymentAction {
    __typename
    type
    version
    creditCardPaymentActionOptions: options {
      creditCardAccountId
      customAmountInCents
      fromAccountId
      fromAccountTypeEnum: fromAccountType
      paymentBalancePercentage
      paymentBalanceType
    }
  }
}
    `;
export const TemplateActionFragmentDoc = `
    fragment TemplateAction on Action {
  ...TemplateSubAction
  ... on MultiAction {
    __typename
    type
    version
    options {
      actions {
        ...TemplateSubAction
      }
    }
  }
}
    ${TemplateSubActionFragmentDoc}`;
export const TemplateTriggerFragmentDoc = `
    fragment TemplateTrigger on Trigger {
  ... on CategoryTrigger {
    __typename
    type
    version
    options {
      category
    }
  }
  ... on DateTrigger {
    __typename
    type
    version
    options {
      pattern
    }
  }
  ... on BalanceTrigger {
    __typename
    type
    version
    options {
      amountInCents
      comparison
    }
  }
  ... on DescriptionTrigger {
    __typename
    type
    version
    options {
      description
    }
  }
  ... on UserCategoryTrigger {
    __typename
    type
    version
    options {
      userCategory
    }
  }
  ... on CashbackRedemptionTrigger {
    __typename
    type
    version
    options {
      amount
    }
  }
  ... on DepositTransactionTrigger {
    __typename
    type
    version
    options {
      conditions {
        category
        direction
        exactMatch
        greaterThan
        isDirectDeposit
        lessThan
        partialMatch
      }
    }
  }
}
    `;
export const RoutineTemplateFragmentDoc = `
    fragment RoutineTemplate on RoutineTemplate {
  id
  uid
  title
  description
  categories
  action {
    ...TemplateAction
  }
  trigger {
    ...TemplateTrigger
  }
}
    ${TemplateActionFragmentDoc}
${TemplateTriggerFragmentDoc}`;
export const InitiateCreditPaymentDocument = `
    mutation InitiateCreditPayment($cardId: Uuid!, $amountInCents: TransferAmountInCents!, $externalAccountId: Uuid, $ledgerId: Uuid, $depositAccountId: Uuid, $paymentAccountLastFour: String) {
  initiateCreditPayment(
    cardId: $cardId
    amountInCents: $amountInCents
    externalAccountId: $externalAccountId
    ledgerId: $ledgerId
    depositAccountId: $depositAccountId
    paymentAccountLastFour: $paymentAccountLastFour
  ) {
    status
  }
}
    `;
export const useInitiateCreditPaymentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<InitiateCreditPaymentMutation, TError, InitiateCreditPaymentMutationVariables, TContext>,
) =>
  useMutation<InitiateCreditPaymentMutation, TError, InitiateCreditPaymentMutationVariables, TContext>(
    ['InitiateCreditPayment'],
    (variables?: InitiateCreditPaymentMutationVariables) =>
      fetcher<InitiateCreditPaymentMutation, InitiateCreditPaymentMutationVariables>(
        InitiateCreditPaymentDocument,
        variables,
      )(),
    options,
  );
export const InitiateCreditCardAuthorizedUserCreationDocument = `
    mutation InitiateCreditCardAuthorizedUserCreation($cardId: Uuid!, $nameFirst: String!, $nameMiddle: String, $nameLast: String!, $nameSuffix: String, $birthDate: Date!, $email: String!, $phoneNumber: String!) {
  initiateCreditCardAuthorizedUserCreation(
    cardId: $cardId
    nameFirst: $nameFirst
    nameMiddle: $nameMiddle
    nameLast: $nameLast
    nameSuffix: $nameSuffix
    birthDate: $birthDate
    email: $email
    phoneNumber: $phoneNumber
  ) {
    authorizedUserId
  }
}
    `;
export const useInitiateCreditCardAuthorizedUserCreationMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    InitiateCreditCardAuthorizedUserCreationMutation,
    TError,
    InitiateCreditCardAuthorizedUserCreationMutationVariables,
    TContext
  >,
) =>
  useMutation<
    InitiateCreditCardAuthorizedUserCreationMutation,
    TError,
    InitiateCreditCardAuthorizedUserCreationMutationVariables,
    TContext
  >(
    ['InitiateCreditCardAuthorizedUserCreation'],
    (variables?: InitiateCreditCardAuthorizedUserCreationMutationVariables) =>
      fetcher<
        InitiateCreditCardAuthorizedUserCreationMutation,
        InitiateCreditCardAuthorizedUserCreationMutationVariables
      >(InitiateCreditCardAuthorizedUserCreationDocument, variables)(),
    options,
  );
export const ScheduleCreditPaymentDocument = `
    mutation ScheduleCreditPayment($cardId: Uuid!, $amountInCents: TransferAmountInCents!, $externalAccountId: Uuid, $ledgerId: Uuid, $depositAccountId: Uuid, $scheduledDate: Date!, $paymentAccountLastFour: String) {
  scheduleCreditPayment(
    cardId: $cardId
    amountInCents: $amountInCents
    externalAccountId: $externalAccountId
    ledgerId: $ledgerId
    depositAccountId: $depositAccountId
    scheduledDate: $scheduledDate
    paymentAccountLastFour: $paymentAccountLastFour
  ) {
    status
  }
}
    `;
export const useScheduleCreditPaymentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ScheduleCreditPaymentMutation, TError, ScheduleCreditPaymentMutationVariables, TContext>,
) =>
  useMutation<ScheduleCreditPaymentMutation, TError, ScheduleCreditPaymentMutationVariables, TContext>(
    ['ScheduleCreditPayment'],
    (variables?: ScheduleCreditPaymentMutationVariables) =>
      fetcher<ScheduleCreditPaymentMutation, ScheduleCreditPaymentMutationVariables>(
        ScheduleCreditPaymentDocument,
        variables,
      )(),
    options,
  );
export const SetRecurringCreditPaymentDocument = `
    mutation setRecurringCreditPayment($cardId: Uuid!, $recurringPaymentType: RecurringPaymentType!, $depositAccountId: Uuid, $paymentAccountLastFour: String, $externalAccountId: Uuid, $ledgerId: Uuid, $isOn: Boolean!) {
  setRecurringCreditPayment(
    cardId: $cardId
    recurringPaymentType: $recurringPaymentType
    depositAccountId: $depositAccountId
    paymentAccountLastFour: $paymentAccountLastFour
    externalAccountId: $externalAccountId
    ledgerId: $ledgerId
    isOn: $isOn
  ) {
    status
  }
}
    `;
export const useSetRecurringCreditPaymentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SetRecurringCreditPaymentMutation,
    TError,
    SetRecurringCreditPaymentMutationVariables,
    TContext
  >,
) =>
  useMutation<SetRecurringCreditPaymentMutation, TError, SetRecurringCreditPaymentMutationVariables, TContext>(
    ['setRecurringCreditPayment'],
    (variables?: SetRecurringCreditPaymentMutationVariables) =>
      fetcher<SetRecurringCreditPaymentMutation, SetRecurringCreditPaymentMutationVariables>(
        SetRecurringCreditPaymentDocument,
        variables,
      )(),
    options,
  );
export const InitiateCashbackRedemptionDocument = `
    mutation InitiateCashbackRedemption($cardId: Uuid!, $amountInCents: TransferAmountInCents!, $depositAccountId: Uuid, $targetAccountLastFour: String, $externalAccountId: Uuid, $redeemToCard: Boolean) {
  initiateCashbackRedemption(
    cardId: $cardId
    amountInCents: $amountInCents
    depositAccountId: $depositAccountId
    targetAccountLastFour: $targetAccountLastFour
    externalAccountId: $externalAccountId
    redeemToCard: $redeemToCard
  ) {
    status
  }
}
    `;
export const useInitiateCashbackRedemptionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    InitiateCashbackRedemptionMutation,
    TError,
    InitiateCashbackRedemptionMutationVariables,
    TContext
  >,
) =>
  useMutation<InitiateCashbackRedemptionMutation, TError, InitiateCashbackRedemptionMutationVariables, TContext>(
    ['InitiateCashbackRedemption'],
    (variables?: InitiateCashbackRedemptionMutationVariables) =>
      fetcher<InitiateCashbackRedemptionMutation, InitiateCashbackRedemptionMutationVariables>(
        InitiateCashbackRedemptionDocument,
        variables,
      )(),
    options,
  );
export const GetActiveDepositAccountsDocument = `
    query GetActiveDepositAccounts {
  depositAccounts {
    id
  }
}
    `;
export const useGetActiveDepositAccountsQuery = <TData = GetActiveDepositAccountsQuery, TError = unknown>(
  variables?: GetActiveDepositAccountsQueryVariables,
  options?: UseQueryOptions<GetActiveDepositAccountsQuery, TError, TData>,
) =>
  useQuery<GetActiveDepositAccountsQuery, TError, TData>(
    variables === undefined ? ['GetActiveDepositAccounts'] : ['GetActiveDepositAccounts', variables],
    fetcher<GetActiveDepositAccountsQuery, GetActiveDepositAccountsQueryVariables>(
      GetActiveDepositAccountsDocument,
      variables,
    ),
    options,
  );

useGetActiveDepositAccountsQuery.getKey = (variables?: GetActiveDepositAccountsQueryVariables) =>
  variables === undefined ? ['GetActiveDepositAccounts'] : ['GetActiveDepositAccounts', variables];
export const GetDepositAccountsForPaymentDocument = `
    query GetDepositAccountsForPayment {
  depositAccounts {
    id
    accountType
    accountStatus
    depositAccountNumber {
      lastFour
    }
    depositAccountBalances {
      availableBalanceInCents
    }
  }
}
    `;
export const useGetDepositAccountsForPaymentQuery = <TData = GetDepositAccountsForPaymentQuery, TError = unknown>(
  variables?: GetDepositAccountsForPaymentQueryVariables,
  options?: UseQueryOptions<GetDepositAccountsForPaymentQuery, TError, TData>,
) =>
  useQuery<GetDepositAccountsForPaymentQuery, TError, TData>(
    variables === undefined ? ['GetDepositAccountsForPayment'] : ['GetDepositAccountsForPayment', variables],
    fetcher<GetDepositAccountsForPaymentQuery, GetDepositAccountsForPaymentQueryVariables>(
      GetDepositAccountsForPaymentDocument,
      variables,
    ),
    options,
  );

useGetDepositAccountsForPaymentQuery.getKey = (variables?: GetDepositAccountsForPaymentQueryVariables) =>
  variables === undefined ? ['GetDepositAccountsForPayment'] : ['GetDepositAccountsForPayment', variables];
export const GetSubmitPaymentAccountsDocument = `
    query GetSubmitPaymentAccounts {
  depositAccounts {
    id
    depositAccountNumber {
      lastFour
    }
  }
}
    `;
export const useGetSubmitPaymentAccountsQuery = <TData = GetSubmitPaymentAccountsQuery, TError = unknown>(
  variables?: GetSubmitPaymentAccountsQueryVariables,
  options?: UseQueryOptions<GetSubmitPaymentAccountsQuery, TError, TData>,
) =>
  useQuery<GetSubmitPaymentAccountsQuery, TError, TData>(
    variables === undefined ? ['GetSubmitPaymentAccounts'] : ['GetSubmitPaymentAccounts', variables],
    fetcher<GetSubmitPaymentAccountsQuery, GetSubmitPaymentAccountsQueryVariables>(
      GetSubmitPaymentAccountsDocument,
      variables,
    ),
    options,
  );

useGetSubmitPaymentAccountsQuery.getKey = (variables?: GetSubmitPaymentAccountsQueryVariables) =>
  variables === undefined ? ['GetSubmitPaymentAccounts'] : ['GetSubmitPaymentAccounts', variables];
export const GetDepositAccountsDocument = `
    query GetDepositAccounts {
  depositAccounts {
    depositAccountNumber {
      routingNumber
      accountNumber
      lastFour
    }
    plans {
      accountNumber
      routingNumber
      id
      title
      color
      goalAmountInCents
      balanceInCents
      createdAt
    }
    apy {
      apyInterestRate
    }
    depositAccountBalances {
      availableBalanceInCents
      totalPlansBalance
      balanceInCents
    }
    id
    openedAt
    accountType
    balanceInCents
    accountStatus
    isPendingJointAccount
    pendingJointAccountInvite {
      inviterId
      inviteeEmail
      inviteeFirstName
      inviteeLastName
    }
    odfiTransferLimit {
      pushAmountInCents
      pullAmountInCents
    }
  }
}
    `;
export const useGetDepositAccountsQuery = <TData = GetDepositAccountsQuery, TError = unknown>(
  variables?: GetDepositAccountsQueryVariables,
  options?: UseQueryOptions<GetDepositAccountsQuery, TError, TData>,
) =>
  useQuery<GetDepositAccountsQuery, TError, TData>(
    variables === undefined ? ['GetDepositAccounts'] : ['GetDepositAccounts', variables],
    fetcher<GetDepositAccountsQuery, GetDepositAccountsQueryVariables>(GetDepositAccountsDocument, variables),
    options,
  );

useGetDepositAccountsQuery.getKey = (variables?: GetDepositAccountsQueryVariables) =>
  variables === undefined ? ['GetDepositAccounts'] : ['GetDepositAccounts', variables];
export const GetAccountDetailsDepositAccountDocument = `
    query GetAccountDetailsDepositAccount {
  depositAccounts {
    depositAccountNumber {
      routingNumber
      accountNumber
    }
    depositAccountOwners {
      ownerId
      person {
        firstName
        lastName
        email
      }
    }
    id
    accountType
    balanceInCents
    accountStatus
  }
}
    `;
export const useGetAccountDetailsDepositAccountQuery = <TData = GetAccountDetailsDepositAccountQuery, TError = unknown>(
  variables?: GetAccountDetailsDepositAccountQueryVariables,
  options?: UseQueryOptions<GetAccountDetailsDepositAccountQuery, TError, TData>,
) =>
  useQuery<GetAccountDetailsDepositAccountQuery, TError, TData>(
    variables === undefined ? ['GetAccountDetailsDepositAccount'] : ['GetAccountDetailsDepositAccount', variables],
    fetcher<GetAccountDetailsDepositAccountQuery, GetAccountDetailsDepositAccountQueryVariables>(
      GetAccountDetailsDepositAccountDocument,
      variables,
    ),
    options,
  );

useGetAccountDetailsDepositAccountQuery.getKey = (variables?: GetAccountDetailsDepositAccountQueryVariables) =>
  variables === undefined ? ['GetAccountDetailsDepositAccount'] : ['GetAccountDetailsDepositAccount', variables];
export const GetBeneficiaryOfDepositAccountDocument = `
    query GetBeneficiaryOfDepositAccount($id: ID!) {
  depositAccount(depositAccountId: $id) {
    id
    beneficiary {
      id
      firstName
      lastName
      instructionType
    }
  }
}
    `;
export const useGetBeneficiaryOfDepositAccountQuery = <TData = GetBeneficiaryOfDepositAccountQuery, TError = unknown>(
  variables: GetBeneficiaryOfDepositAccountQueryVariables,
  options?: UseQueryOptions<GetBeneficiaryOfDepositAccountQuery, TError, TData>,
) =>
  useQuery<GetBeneficiaryOfDepositAccountQuery, TError, TData>(
    ['GetBeneficiaryOfDepositAccount', variables],
    fetcher<GetBeneficiaryOfDepositAccountQuery, GetBeneficiaryOfDepositAccountQueryVariables>(
      GetBeneficiaryOfDepositAccountDocument,
      variables,
    ),
    options,
  );

useGetBeneficiaryOfDepositAccountQuery.getKey = (variables: GetBeneficiaryOfDepositAccountQueryVariables) => [
  'GetBeneficiaryOfDepositAccount',
  variables,
];
export const GetStatementsOfDepositAccountDocument = `
    query GetStatementsOfDepositAccount($id: ID!) {
  depositAccount(depositAccountId: $id) {
    id
    statements {
      id
      periodStartDate
      periodEndDate
      requestType
      tempUrl
    }
  }
}
    `;
export const useGetStatementsOfDepositAccountQuery = <TData = GetStatementsOfDepositAccountQuery, TError = unknown>(
  variables: GetStatementsOfDepositAccountQueryVariables,
  options?: UseQueryOptions<GetStatementsOfDepositAccountQuery, TError, TData>,
) =>
  useQuery<GetStatementsOfDepositAccountQuery, TError, TData>(
    ['GetStatementsOfDepositAccount', variables],
    fetcher<GetStatementsOfDepositAccountQuery, GetStatementsOfDepositAccountQueryVariables>(
      GetStatementsOfDepositAccountDocument,
      variables,
    ),
    options,
  );

useGetStatementsOfDepositAccountQuery.getKey = (variables: GetStatementsOfDepositAccountQueryVariables) => [
  'GetStatementsOfDepositAccount',
  variables,
];
export const AddBeneficiaryOfDepositAccountDocument = `
    mutation AddBeneficiaryOfDepositAccount($depositAccountId: Uuid!, $firstName: String!, $middleName: String!, $lastName: String!, $suffix: String!, $birthDate: Date!, $nationalIdNumberToken: Vgstoken!, $addressLineOne: String!, $addressLineTwo: String!, $addressCity: String!, $addressPostalCode: String!, $addressStateCode: String!, $addressCountryCode: String!, $email: String!, $phoneNumber: String!, $instructionType: DepositAccountBeneficiaryInstructionType!) {
  assignDepositAccountBeneficiary(
    depositAccountId: $depositAccountId
    firstName: $firstName
    middleName: $middleName
    lastName: $lastName
    suffix: $suffix
    birthDate: $birthDate
    nationalIdNumberToken: $nationalIdNumberToken
    addressLineOne: $addressLineOne
    addressLineTwo: $addressLineTwo
    addressCity: $addressCity
    addressPostalCode: $addressPostalCode
    addressStateCode: $addressStateCode
    addressCountryCode: $addressCountryCode
    email: $email
    phoneNumber: $phoneNumber
    instructionType: $instructionType
  ) {
    id
  }
}
    `;
export const useAddBeneficiaryOfDepositAccountMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AddBeneficiaryOfDepositAccountMutation,
    TError,
    AddBeneficiaryOfDepositAccountMutationVariables,
    TContext
  >,
) =>
  useMutation<
    AddBeneficiaryOfDepositAccountMutation,
    TError,
    AddBeneficiaryOfDepositAccountMutationVariables,
    TContext
  >(
    ['AddBeneficiaryOfDepositAccount'],
    (variables?: AddBeneficiaryOfDepositAccountMutationVariables) =>
      fetcher<AddBeneficiaryOfDepositAccountMutation, AddBeneficiaryOfDepositAccountMutationVariables>(
        AddBeneficiaryOfDepositAccountDocument,
        variables,
      )(),
    options,
  );
export const RemoveBeneficiaryOfDepositAccountDocument = `
    mutation RemoveBeneficiaryOfDepositAccount($depositAccountId: Uuid!, $beneficiaryId: Uuid!) {
  removeDepositAccountBeneficiary(
    depositAccountId: $depositAccountId
    beneficiaryId: $beneficiaryId
  ) {
    id
  }
}
    `;
export const useRemoveBeneficiaryOfDepositAccountMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveBeneficiaryOfDepositAccountMutation,
    TError,
    RemoveBeneficiaryOfDepositAccountMutationVariables,
    TContext
  >,
) =>
  useMutation<
    RemoveBeneficiaryOfDepositAccountMutation,
    TError,
    RemoveBeneficiaryOfDepositAccountMutationVariables,
    TContext
  >(
    ['RemoveBeneficiaryOfDepositAccount'],
    (variables?: RemoveBeneficiaryOfDepositAccountMutationVariables) =>
      fetcher<RemoveBeneficiaryOfDepositAccountMutation, RemoveBeneficiaryOfDepositAccountMutationVariables>(
        RemoveBeneficiaryOfDepositAccountDocument,
        variables,
      )(),
    options,
  );
export const GetDepositAccountTransactionDocument = `
    query GetDepositAccountTransaction($id: ID!) {
  depositAccount(depositAccountId: $id) {
    accountType
    depositAccountNumber {
      lastFour
    }
    isPendingJointAccount
    transactions {
      id
      transferType
      fromAccountId
      fromAccountType
      toAccountId
      toAccountType
      postedAt
      initiatedAt
      description
      amountInCents
      status
      balanceInCents
      isCredit
      isDirectDeposit
      category
      scheduledFor
      cancelledAt
      planTitle
      planBalanceInCents
      planAccountNumber
      transferType
      routine {
        routineId
        isArchived
        isEnabled
        lastRunAt
        name
      }
    }
  }
}
    `;
export const useGetDepositAccountTransactionQuery = <TData = GetDepositAccountTransactionQuery, TError = unknown>(
  variables: GetDepositAccountTransactionQueryVariables,
  options?: UseQueryOptions<GetDepositAccountTransactionQuery, TError, TData>,
) =>
  useQuery<GetDepositAccountTransactionQuery, TError, TData>(
    ['GetDepositAccountTransaction', variables],
    fetcher<GetDepositAccountTransactionQuery, GetDepositAccountTransactionQueryVariables>(
      GetDepositAccountTransactionDocument,
      variables,
    ),
    options,
  );

useGetDepositAccountTransactionQuery.getKey = (variables: GetDepositAccountTransactionQueryVariables) => [
  'GetDepositAccountTransaction',
  variables,
];
export const GetDepositAccountByIdDocument = `
    query GetDepositAccountById($id: ID!) {
  depositAccount(depositAccountId: $id) {
    depositAccountNumber {
      routingNumber
      accountNumber
      lastFour
    }
    depositAccountBalances {
      balanceInCents
      availableBalanceInCents
      pendingBalanceInCents
      totalPlansBalance
    }
    accountType
    accountStatus
    balanceInCents
    isPendingJointAccount
    pendingJointAccountInvite {
      inviterId
      inviteeEmail
      inviteeFirstName
      inviteeLastName
    }
  }
}
    `;
export const useGetDepositAccountByIdQuery = <TData = GetDepositAccountByIdQuery, TError = unknown>(
  variables: GetDepositAccountByIdQueryVariables,
  options?: UseQueryOptions<GetDepositAccountByIdQuery, TError, TData>,
) =>
  useQuery<GetDepositAccountByIdQuery, TError, TData>(
    ['GetDepositAccountById', variables],
    fetcher<GetDepositAccountByIdQuery, GetDepositAccountByIdQueryVariables>(GetDepositAccountByIdDocument, variables),
    options,
  );

useGetDepositAccountByIdQuery.getKey = (variables: GetDepositAccountByIdQueryVariables) => [
  'GetDepositAccountById',
  variables,
];
export const GetDepositAccountsTransferLimitsDocument = `
    query GetDepositAccountsTransferLimits {
  depositAccounts {
    accountType
    depositAccountNumber {
      routingNumber
      accountNumber
      lastFour
    }
    depositAccountBalances {
      availableBalanceInCents
    }
    id
    openedAt
    accountStatus
    odfiTransferLimit {
      pushAmountInCents
      pullAmountInCents
    }
  }
}
    `;
export const useGetDepositAccountsTransferLimitsQuery = <
  TData = GetDepositAccountsTransferLimitsQuery,
  TError = unknown,
>(
  variables?: GetDepositAccountsTransferLimitsQueryVariables,
  options?: UseQueryOptions<GetDepositAccountsTransferLimitsQuery, TError, TData>,
) =>
  useQuery<GetDepositAccountsTransferLimitsQuery, TError, TData>(
    variables === undefined ? ['GetDepositAccountsTransferLimits'] : ['GetDepositAccountsTransferLimits', variables],
    fetcher<GetDepositAccountsTransferLimitsQuery, GetDepositAccountsTransferLimitsQueryVariables>(
      GetDepositAccountsTransferLimitsDocument,
      variables,
    ),
    options,
  );

useGetDepositAccountsTransferLimitsQuery.getKey = (variables?: GetDepositAccountsTransferLimitsQueryVariables) =>
  variables === undefined ? ['GetDepositAccountsTransferLimits'] : ['GetDepositAccountsTransferLimits', variables];
export const GetDepositAccountEarningDocument = `
    query GetDepositAccountEarning($id: ID!) {
  depositAccount(depositAccountId: $id) {
    interest {
      apyInterestRate
      totalAccruedInterestInCents
      monthlyAccruedInterestInCents
    }
    apy {
      apyInterestRate
    }
  }
}
    `;
export const useGetDepositAccountEarningQuery = <TData = GetDepositAccountEarningQuery, TError = unknown>(
  variables: GetDepositAccountEarningQueryVariables,
  options?: UseQueryOptions<GetDepositAccountEarningQuery, TError, TData>,
) =>
  useQuery<GetDepositAccountEarningQuery, TError, TData>(
    ['GetDepositAccountEarning', variables],
    fetcher<GetDepositAccountEarningQuery, GetDepositAccountEarningQueryVariables>(
      GetDepositAccountEarningDocument,
      variables,
    ),
    options,
  );

useGetDepositAccountEarningQuery.getKey = (variables: GetDepositAccountEarningQueryVariables) => [
  'GetDepositAccountEarning',
  variables,
];
export const CanOpenIndividualDepositAccountDocument = `
    query CanOpenIndividualDepositAccount {
  canOpenIndividualDepositAccount
}
    `;
export const useCanOpenIndividualDepositAccountQuery = <TData = CanOpenIndividualDepositAccountQuery, TError = unknown>(
  variables?: CanOpenIndividualDepositAccountQueryVariables,
  options?: UseQueryOptions<CanOpenIndividualDepositAccountQuery, TError, TData>,
) =>
  useQuery<CanOpenIndividualDepositAccountQuery, TError, TData>(
    variables === undefined ? ['CanOpenIndividualDepositAccount'] : ['CanOpenIndividualDepositAccount', variables],
    fetcher<CanOpenIndividualDepositAccountQuery, CanOpenIndividualDepositAccountQueryVariables>(
      CanOpenIndividualDepositAccountDocument,
      variables,
    ),
    options,
  );

useCanOpenIndividualDepositAccountQuery.getKey = (variables?: CanOpenIndividualDepositAccountQueryVariables) =>
  variables === undefined ? ['CanOpenIndividualDepositAccount'] : ['CanOpenIndividualDepositAccount', variables];
export const RequestIndividualDepositAccountDocument = `
    mutation RequestIndividualDepositAccount {
  requestIndividualDepositAccount {
    id
  }
}
    `;
export const useRequestIndividualDepositAccountMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RequestIndividualDepositAccountMutation,
    TError,
    RequestIndividualDepositAccountMutationVariables,
    TContext
  >,
) =>
  useMutation<
    RequestIndividualDepositAccountMutation,
    TError,
    RequestIndividualDepositAccountMutationVariables,
    TContext
  >(
    ['RequestIndividualDepositAccount'],
    (variables?: RequestIndividualDepositAccountMutationVariables) =>
      fetcher<RequestIndividualDepositAccountMutation, RequestIndividualDepositAccountMutationVariables>(
        RequestIndividualDepositAccountDocument,
        variables,
      )(),
    options,
  );
export const GetTaxDocumentsDocument = `
    query getTaxDocuments {
  depositAccounts(accountStatus: [CLOSED, PENDING_CLOSURE, OPENED]) {
    id
    taxDocuments {
      id
      taxYear
      fileKey
      requestType
      tempUrl
    }
  }
}
    `;
export const useGetTaxDocumentsQuery = <TData = GetTaxDocumentsQuery, TError = unknown>(
  variables?: GetTaxDocumentsQueryVariables,
  options?: UseQueryOptions<GetTaxDocumentsQuery, TError, TData>,
) =>
  useQuery<GetTaxDocumentsQuery, TError, TData>(
    variables === undefined ? ['getTaxDocuments'] : ['getTaxDocuments', variables],
    fetcher<GetTaxDocumentsQuery, GetTaxDocumentsQueryVariables>(GetTaxDocumentsDocument, variables),
    options,
  );

useGetTaxDocumentsQuery.getKey = (variables?: GetTaxDocumentsQueryVariables) =>
  variables === undefined ? ['getTaxDocuments'] : ['getTaxDocuments', variables];
export const InitiateInternalTransferDocument = `
    mutation InitiateInternalTransfer($amountInCents: TransferAmountInCents!, $fromAccountId: Uuid!, $toAccountId: Uuid!, $scheduledFor: DateTime) {
  initiateInternalTransfer(
    amountInCents: $amountInCents
    fromDepositAccountId: $fromAccountId
    toDepositAccountId: $toAccountId
    scheduledFor: $scheduledFor
  ) {
    id
  }
}
    `;
export const useInitiateInternalTransferMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    InitiateInternalTransferMutation,
    TError,
    InitiateInternalTransferMutationVariables,
    TContext
  >,
) =>
  useMutation<InitiateInternalTransferMutation, TError, InitiateInternalTransferMutationVariables, TContext>(
    ['InitiateInternalTransfer'],
    (variables?: InitiateInternalTransferMutationVariables) =>
      fetcher<InitiateInternalTransferMutation, InitiateInternalTransferMutationVariables>(
        InitiateInternalTransferDocument,
        variables,
      )(),
    options,
  );
export const InitiateOdfiTransferDocument = `
    mutation InitiateOdfiTransfer($type: OdfiTransferType!, $amountInCents: TransferAmountInCents!, $depositAccountId: Uuid!, $externalAccountId: Uuid!, $scheduledFor: DateTime, $odfiPlanTransferData: OdfiPlanTransferData, $legacyExternalAccounts: Boolean) {
  createNewOdfiTransfer(
    type: $type
    amountInCents: $amountInCents
    depositAccountId: $depositAccountId
    externalAccountId: $externalAccountId
    scheduledFor: $scheduledFor
    odfiPlanTransferData: $odfiPlanTransferData
    legacyExternalAccounts: $legacyExternalAccounts
  ) {
    odfiAchTransferId
  }
}
    `;
export const useInitiateOdfiTransferMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<InitiateOdfiTransferMutation, TError, InitiateOdfiTransferMutationVariables, TContext>,
) =>
  useMutation<InitiateOdfiTransferMutation, TError, InitiateOdfiTransferMutationVariables, TContext>(
    ['InitiateOdfiTransfer'],
    (variables?: InitiateOdfiTransferMutationVariables) =>
      fetcher<InitiateOdfiTransferMutation, InitiateOdfiTransferMutationVariables>(
        InitiateOdfiTransferDocument,
        variables,
      )(),
    options,
  );
export const GetClosedDepositAccountsDocument = `
    query GetClosedDepositAccounts {
  depositAccounts(accountStatus: [CLOSED, PENDING_CLOSURE]) {
    depositAccountNumber {
      routingNumber
      accountNumber
      lastFour
    }
    plans {
      balanceInCents
    }
    apy {
      apyInterestRate
    }
    depositAccountBalances {
      availableBalanceInCents
      totalPlansBalance
    }
    id
    openedAt
    accountType
    balanceInCents
    accountStatus
  }
}
    `;
export const useGetClosedDepositAccountsQuery = <TData = GetClosedDepositAccountsQuery, TError = unknown>(
  variables?: GetClosedDepositAccountsQueryVariables,
  options?: UseQueryOptions<GetClosedDepositAccountsQuery, TError, TData>,
) =>
  useQuery<GetClosedDepositAccountsQuery, TError, TData>(
    variables === undefined ? ['GetClosedDepositAccounts'] : ['GetClosedDepositAccounts', variables],
    fetcher<GetClosedDepositAccountsQuery, GetClosedDepositAccountsQueryVariables>(
      GetClosedDepositAccountsDocument,
      variables,
    ),
    options,
  );

useGetClosedDepositAccountsQuery.getKey = (variables?: GetClosedDepositAccountsQueryVariables) =>
  variables === undefined ? ['GetClosedDepositAccounts'] : ['GetClosedDepositAccounts', variables];
export const GetApyTrackingByIdAndDateDocument = `
    query GetApyTrackingByIdAndDate($depositAccountId: Uuid!, $startDate: Date!, $endDate: Date!) {
  apyTracking(depositAccountId: $depositAccountId) {
    depositedAmount(startDate: $startDate, endDate: $endDate) {
      currentValue
      isWaived
      onTrackToMeetRequirement
      waivedUntil
    }
  }
}
    `;
export const useGetApyTrackingByIdAndDateQuery = <TData = GetApyTrackingByIdAndDateQuery, TError = unknown>(
  variables: GetApyTrackingByIdAndDateQueryVariables,
  options?: UseQueryOptions<GetApyTrackingByIdAndDateQuery, TError, TData>,
) =>
  useQuery<GetApyTrackingByIdAndDateQuery, TError, TData>(
    ['GetApyTrackingByIdAndDate', variables],
    fetcher<GetApyTrackingByIdAndDateQuery, GetApyTrackingByIdAndDateQueryVariables>(
      GetApyTrackingByIdAndDateDocument,
      variables,
    ),
    options,
  );

useGetApyTrackingByIdAndDateQuery.getKey = (variables: GetApyTrackingByIdAndDateQueryVariables) => [
  'GetApyTrackingByIdAndDate',
  variables,
];
export const GetMonthlyCashflowByAccountIdDocument = `
    query getMonthlyCashflowByAccountId($depositAccountId: Uuid, $startDate: Date, $endDate: Date) {
  monthlyCashFlow(
    depositAccountId: $depositAccountId
    startDate: $startDate
    endDate: $endDate
  ) {
    date
    savingRate
    totalDepositsAmount
    totalNetAmount
    totalWithdrawalsAmount
  }
}
    `;
export const useGetMonthlyCashflowByAccountIdQuery = <TData = GetMonthlyCashflowByAccountIdQuery, TError = unknown>(
  variables?: GetMonthlyCashflowByAccountIdQueryVariables,
  options?: UseQueryOptions<GetMonthlyCashflowByAccountIdQuery, TError, TData>,
) =>
  useQuery<GetMonthlyCashflowByAccountIdQuery, TError, TData>(
    variables === undefined ? ['getMonthlyCashflowByAccountId'] : ['getMonthlyCashflowByAccountId', variables],
    fetcher<GetMonthlyCashflowByAccountIdQuery, GetMonthlyCashflowByAccountIdQueryVariables>(
      GetMonthlyCashflowByAccountIdDocument,
      variables,
    ),
    options,
  );

useGetMonthlyCashflowByAccountIdQuery.getKey = (variables?: GetMonthlyCashflowByAccountIdQueryVariables) =>
  variables === undefined ? ['getMonthlyCashflowByAccountId'] : ['getMonthlyCashflowByAccountId', variables];
export const CancelInternalScheduledTransferDocument = `
    mutation CancelInternalScheduledTransfer($internalTransferId: Uuid!) {
  cancelInternalScheduledTransfer(internalTransferId: $internalTransferId) {
    status
  }
}
    `;
export const useCancelInternalScheduledTransferMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CancelInternalScheduledTransferMutation,
    TError,
    CancelInternalScheduledTransferMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CancelInternalScheduledTransferMutation,
    TError,
    CancelInternalScheduledTransferMutationVariables,
    TContext
  >(
    ['CancelInternalScheduledTransfer'],
    (variables?: CancelInternalScheduledTransferMutationVariables) =>
      fetcher<CancelInternalScheduledTransferMutation, CancelInternalScheduledTransferMutationVariables>(
        CancelInternalScheduledTransferDocument,
        variables,
      )(),
    options,
  );
export const CancelOdfiScheduledTransferDocument = `
    mutation CancelOdfiScheduledTransfer($odfiAchTransferId: Uuid!) {
  cancelOdfiScheduledTransfer(odfiAchTransferId: $odfiAchTransferId) {
    status
  }
}
    `;
export const useCancelOdfiScheduledTransferMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CancelOdfiScheduledTransferMutation,
    TError,
    CancelOdfiScheduledTransferMutationVariables,
    TContext
  >,
) =>
  useMutation<CancelOdfiScheduledTransferMutation, TError, CancelOdfiScheduledTransferMutationVariables, TContext>(
    ['CancelOdfiScheduledTransfer'],
    (variables?: CancelOdfiScheduledTransferMutationVariables) =>
      fetcher<CancelOdfiScheduledTransferMutation, CancelOdfiScheduledTransferMutationVariables>(
        CancelOdfiScheduledTransferDocument,
        variables,
      )(),
    options,
  );
export const GetDepositAccountsWithTransactionsDocument = `
    query GetDepositAccountsWithTransactions {
  depositAccounts {
    id
    accountType
    accountStatus
    isPendingJointAccount
    depositAccountNumber {
      lastFour
    }
    transactions {
      id
      postedAt
      amountInCents
      description
      transferType
      toAccountId
      status
      fromAccountType
      fromAccountId
      toAccountType
      initiatedAt
      postedAt
      cancelledAt
      scheduledFor
    }
  }
}
    `;
export const useGetDepositAccountsWithTransactionsQuery = <
  TData = GetDepositAccountsWithTransactionsQuery,
  TError = unknown,
>(
  variables?: GetDepositAccountsWithTransactionsQueryVariables,
  options?: UseQueryOptions<GetDepositAccountsWithTransactionsQuery, TError, TData>,
) =>
  useQuery<GetDepositAccountsWithTransactionsQuery, TError, TData>(
    variables === undefined
      ? ['GetDepositAccountsWithTransactions']
      : ['GetDepositAccountsWithTransactions', variables],
    fetcher<GetDepositAccountsWithTransactionsQuery, GetDepositAccountsWithTransactionsQueryVariables>(
      GetDepositAccountsWithTransactionsDocument,
      variables,
    ),
    options,
  );

useGetDepositAccountsWithTransactionsQuery.getKey = (variables?: GetDepositAccountsWithTransactionsQueryVariables) =>
  variables === undefined ? ['GetDepositAccountsWithTransactions'] : ['GetDepositAccountsWithTransactions', variables];
export const RequestOutgoingWireDocument = `
    mutation RequestOutgoingWire($originatorAccountNumber: String!, $beneficiaryBankName: String!, $beneficiaryRoutingNumber: String!, $beneficiaryAccountNumber: String!, $beneficiaryName: String!, $beneficiaryAddressLine1: String!, $beneficiaryCity: String!, $beneficiaryStateCode: String!, $beneficiaryPostalCode: String!, $memo: String!, $amountInCents: Int!, $originatorToBeneficiaryLine1: OriginatorToBeneficiaryTypes, $originatorToBeneficiaryLine2: String, $consent: Consent!) {
  requestOutgoingWireTransferV2(
    originatorAccountNumber: $originatorAccountNumber
    beneficiaryBankName: $beneficiaryBankName
    beneficiaryRoutingNumber: $beneficiaryRoutingNumber
    beneficiaryAccountNumber: $beneficiaryAccountNumber
    beneficiaryAddressLine1: $beneficiaryAddressLine1
    beneficiaryName: $beneficiaryName
    beneficiaryCity: $beneficiaryCity
    beneficiaryStateCode: $beneficiaryStateCode
    beneficiaryPostalCode: $beneficiaryPostalCode
    memo: $memo
    amountInCents: $amountInCents
    originatorToBeneficiaryLine1: $originatorToBeneficiaryLine1
    originatorToBeneficiaryLine2: $originatorToBeneficiaryLine2
    consent: $consent
  ) {
    transferId
  }
}
    `;
export const useRequestOutgoingWireMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<RequestOutgoingWireMutation, TError, RequestOutgoingWireMutationVariables, TContext>,
) =>
  useMutation<RequestOutgoingWireMutation, TError, RequestOutgoingWireMutationVariables, TContext>(
    ['RequestOutgoingWire'],
    (variables?: RequestOutgoingWireMutationVariables) =>
      fetcher<RequestOutgoingWireMutation, RequestOutgoingWireMutationVariables>(
        RequestOutgoingWireDocument,
        variables,
      )(),
    options,
  );
export const GetOutgoingWiresDocument = `
    query GetOutgoingWires {
  outgoingWireTransfers {
    amountInCents
    beneficiaryAccountNumber
    beneficiaryAddressLine1
    beneficiaryAddressLine2
    beneficiaryBankName
    beneficiaryName
    beneficiaryRoutingNumber
    fromAccountId
    fromAccountType
    memo
    fedConfirmationNumber
    requestedAt
    postedAt
    requestedBy
    status
    transferId
    originatorName
    originatorAccountNumber
    originatorBankName
    originatorRoutingNumber
    originatorToBeneficiaryLine1
    originatorToBeneficiaryLine2
  }
}
    `;
export const useGetOutgoingWiresQuery = <TData = GetOutgoingWiresQuery, TError = unknown>(
  variables?: GetOutgoingWiresQueryVariables,
  options?: UseQueryOptions<GetOutgoingWiresQuery, TError, TData>,
) =>
  useQuery<GetOutgoingWiresQuery, TError, TData>(
    variables === undefined ? ['GetOutgoingWires'] : ['GetOutgoingWires', variables],
    fetcher<GetOutgoingWiresQuery, GetOutgoingWiresQueryVariables>(GetOutgoingWiresDocument, variables),
    options,
  );

useGetOutgoingWiresQuery.getKey = (variables?: GetOutgoingWiresQueryVariables) =>
  variables === undefined ? ['GetOutgoingWires'] : ['GetOutgoingWires', variables];
export const InitiateDepositAccountClosureWithTransferDocument = `
    mutation InitiateDepositAccountClosureWithTransfer($depositAccountId: Uuid!, $externalAccountId: Uuid!, $closureReasons: [DepositAccountClosureReason]!) {
  initiateDepositAccountClosureWithTransfer(
    depositAccountId: $depositAccountId
    externalAccountId: $externalAccountId
    closureReasons: $closureReasons
  ) {
    status
  }
}
    `;
export const useInitiateDepositAccountClosureWithTransferMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    InitiateDepositAccountClosureWithTransferMutation,
    TError,
    InitiateDepositAccountClosureWithTransferMutationVariables,
    TContext
  >,
) =>
  useMutation<
    InitiateDepositAccountClosureWithTransferMutation,
    TError,
    InitiateDepositAccountClosureWithTransferMutationVariables,
    TContext
  >(
    ['InitiateDepositAccountClosureWithTransfer'],
    (variables?: InitiateDepositAccountClosureWithTransferMutationVariables) =>
      fetcher<
        InitiateDepositAccountClosureWithTransferMutation,
        InitiateDepositAccountClosureWithTransferMutationVariables
      >(InitiateDepositAccountClosureWithTransferDocument, variables)(),
    options,
  );
export const InitiateDepositAccountClosureWithoutTransferDocument = `
    mutation initiateDepositAccountClosureWithoutTransfer($depositAccountId: Uuid!, $closureReason: [DepositAccountClosureReason]!) {
  initiateDepositAccountClosureWithoutTransfer(
    depositAccountId: $depositAccountId
    closureReason: $closureReason
  ) {
    status
  }
}
    `;
export const useInitiateDepositAccountClosureWithoutTransferMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    InitiateDepositAccountClosureWithoutTransferMutation,
    TError,
    InitiateDepositAccountClosureWithoutTransferMutationVariables,
    TContext
  >,
) =>
  useMutation<
    InitiateDepositAccountClosureWithoutTransferMutation,
    TError,
    InitiateDepositAccountClosureWithoutTransferMutationVariables,
    TContext
  >(
    ['initiateDepositAccountClosureWithoutTransfer'],
    (variables?: InitiateDepositAccountClosureWithoutTransferMutationVariables) =>
      fetcher<
        InitiateDepositAccountClosureWithoutTransferMutation,
        InitiateDepositAccountClosureWithoutTransferMutationVariables
      >(InitiateDepositAccountClosureWithoutTransferDocument, variables)(),
    options,
  );
export const CustomerExternalAccountCheckDocument = `
    mutation customerExternalAccountCheck($depositAccountId: Uuid!) {
  customerExternalAccountCheck(depositAccountId: $depositAccountId) {
    externalAccountRequired
    accountBalancesSumInCents
    accruedBalanceInCents
  }
}
    `;
export const useCustomerExternalAccountCheckMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CustomerExternalAccountCheckMutation,
    TError,
    CustomerExternalAccountCheckMutationVariables,
    TContext
  >,
) =>
  useMutation<CustomerExternalAccountCheckMutation, TError, CustomerExternalAccountCheckMutationVariables, TContext>(
    ['customerExternalAccountCheck'],
    (variables?: CustomerExternalAccountCheckMutationVariables) =>
      fetcher<CustomerExternalAccountCheckMutation, CustomerExternalAccountCheckMutationVariables>(
        CustomerExternalAccountCheckDocument,
        variables,
      )(),
    options,
  );
export const CreatePlaidLinkTokenDocument = `
    mutation CreatePlaidLinkToken($externalAccountId: Uuid, $linkType: LinkType) {
  createPlaidLinkToken(externalAccountId: $externalAccountId, linkType: $linkType) {
    linkToken
    expiration
  }
}
    `;
export const useCreatePlaidLinkTokenMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreatePlaidLinkTokenMutation, TError, CreatePlaidLinkTokenMutationVariables, TContext>,
) =>
  useMutation<CreatePlaidLinkTokenMutation, TError, CreatePlaidLinkTokenMutationVariables, TContext>(
    ['CreatePlaidLinkToken'],
    (variables?: CreatePlaidLinkTokenMutationVariables) =>
      fetcher<CreatePlaidLinkTokenMutation, CreatePlaidLinkTokenMutationVariables>(
        CreatePlaidLinkTokenDocument,
        variables,
      )(),
    options,
  );
export const CreatePlaidLinkTokenWithoutTransactionsProductDocument = `
    mutation CreatePlaidLinkTokenWithoutTransactionsProduct {
  createPlaidLinkTokenWithoutTransactionsProduct {
    linkToken
    expiration
  }
}
    `;
export const useCreatePlaidLinkTokenWithoutTransactionsProductMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreatePlaidLinkTokenWithoutTransactionsProductMutation,
    TError,
    CreatePlaidLinkTokenWithoutTransactionsProductMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CreatePlaidLinkTokenWithoutTransactionsProductMutation,
    TError,
    CreatePlaidLinkTokenWithoutTransactionsProductMutationVariables,
    TContext
  >(
    ['CreatePlaidLinkTokenWithoutTransactionsProduct'],
    (variables?: CreatePlaidLinkTokenWithoutTransactionsProductMutationVariables) =>
      fetcher<
        CreatePlaidLinkTokenWithoutTransactionsProductMutation,
        CreatePlaidLinkTokenWithoutTransactionsProductMutationVariables
      >(CreatePlaidLinkTokenWithoutTransactionsProductDocument, variables)(),
    options,
  );
export const ExchangePlaidPublicItemTokenDocument = `
    mutation ExchangePlaidPublicItemToken($publicToken: String!, $metadata: Json!) {
  exchangePlaidPublicItemToken(publicToken: $publicToken, metadata: $metadata) {
    status
  }
}
    `;
export const useExchangePlaidPublicItemTokenMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ExchangePlaidPublicItemTokenMutation,
    TError,
    ExchangePlaidPublicItemTokenMutationVariables,
    TContext
  >,
) =>
  useMutation<ExchangePlaidPublicItemTokenMutation, TError, ExchangePlaidPublicItemTokenMutationVariables, TContext>(
    ['ExchangePlaidPublicItemToken'],
    (variables?: ExchangePlaidPublicItemTokenMutationVariables) =>
      fetcher<ExchangePlaidPublicItemTokenMutation, ExchangePlaidPublicItemTokenMutationVariables>(
        ExchangePlaidPublicItemTokenDocument,
        variables,
      )(),
    options,
  );
export const UpdatePlaidItemDocument = `
    mutation UpdatePlaidItem($externalAccountId: Uuid!, $action: LinkType!) {
  updatePlaidItem(externalAccountId: $externalAccountId, action: $action) {
    status
  }
}
    `;
export const useUpdatePlaidItemMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdatePlaidItemMutation, TError, UpdatePlaidItemMutationVariables, TContext>,
) =>
  useMutation<UpdatePlaidItemMutation, TError, UpdatePlaidItemMutationVariables, TContext>(
    ['UpdatePlaidItem'],
    (variables?: UpdatePlaidItemMutationVariables) =>
      fetcher<UpdatePlaidItemMutation, UpdatePlaidItemMutationVariables>(UpdatePlaidItemDocument, variables)(),
    options,
  );
export const ArchiveExternalAccountDocument = `
    mutation ArchiveExternalAccount($externalAccountId: Uuid!) {
  archiveExternalAccount(externalAccountId: $externalAccountId) {
    status
  }
}
    `;
export const useArchiveExternalAccountMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ArchiveExternalAccountMutation,
    TError,
    ArchiveExternalAccountMutationVariables,
    TContext
  >,
) =>
  useMutation<ArchiveExternalAccountMutation, TError, ArchiveExternalAccountMutationVariables, TContext>(
    ['ArchiveExternalAccount'],
    (variables?: ArchiveExternalAccountMutationVariables) =>
      fetcher<ArchiveExternalAccountMutation, ArchiveExternalAccountMutationVariables>(
        ArchiveExternalAccountDocument,
        variables,
      )(),
    options,
  );
export const UpdateExternalAccountDocument = `
    mutation UpdateExternalAccount($externalAccountId: Uuid!, $nickname: String!) {
  updateExternalAccount(
    externalAccountId: $externalAccountId
    nickname: $nickname
  ) {
    status
  }
}
    `;
export const useUpdateExternalAccountMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateExternalAccountMutation, TError, UpdateExternalAccountMutationVariables, TContext>,
) =>
  useMutation<UpdateExternalAccountMutation, TError, UpdateExternalAccountMutationVariables, TContext>(
    ['UpdateExternalAccount'],
    (variables?: UpdateExternalAccountMutationVariables) =>
      fetcher<UpdateExternalAccountMutation, UpdateExternalAccountMutationVariables>(
        UpdateExternalAccountDocument,
        variables,
      )(),
    options,
  );
export const GetExternalAccountsDocument = `
    query GetExternalAccounts {
  externalAccounts {
    accountLastFour
    accountSource
    accountStatus
    accountType
    id
    isVerified
    nickname
    ownerId
    plaidInstitution {
      logo
      name
    }
    plaidBalance {
      availableBalanceInCents
      lastUpdatedAt
    }
    routingNumber
    updateReason
    updateRequired
  }
}
    `;
export const useGetExternalAccountsQuery = <TData = GetExternalAccountsQuery, TError = unknown>(
  variables?: GetExternalAccountsQueryVariables,
  options?: UseQueryOptions<GetExternalAccountsQuery, TError, TData>,
) =>
  useQuery<GetExternalAccountsQuery, TError, TData>(
    variables === undefined ? ['GetExternalAccounts'] : ['GetExternalAccounts', variables],
    fetcher<GetExternalAccountsQuery, GetExternalAccountsQueryVariables>(GetExternalAccountsDocument, variables),
    options,
  );

useGetExternalAccountsQuery.getKey = (variables?: GetExternalAccountsQueryVariables) =>
  variables === undefined ? ['GetExternalAccounts'] : ['GetExternalAccounts', variables];
export const InitiateJointAccountInviteDocument = `
    mutation InitiateJointAccountInvite($depositAccountId: Uuid, $inviteeAddressCity: String!, $inviteeAddressCountryCode: String!, $inviteeAddressLineOne: String!, $inviteeAddressLineTwo: String, $inviteeAddressPostalCode: String!, $inviteeAddressStateCode: String!, $inviteeDateOfBirth: Date!, $inviteeEmail: String!, $inviteeFirstName: String!, $inviteeLastName: String!, $inviteeNationalIdNumberToken: Vgstoken!) {
  initiateJointAccountInvite(
    depositAccountId: $depositAccountId
    inviteeAddressCity: $inviteeAddressCity
    inviteeAddressCountryCode: $inviteeAddressCountryCode
    inviteeAddressLineOne: $inviteeAddressLineOne
    inviteeAddressLineTwo: $inviteeAddressLineTwo
    inviteeAddressPostalCode: $inviteeAddressPostalCode
    inviteeAddressStateCode: $inviteeAddressStateCode
    inviteeDateOfBirth: $inviteeDateOfBirth
    inviteeEmail: $inviteeEmail
    inviteeFirstName: $inviteeFirstName
    inviteeLastName: $inviteeLastName
    inviteeNationalIdNumberToken: $inviteeNationalIdNumberToken
  ) {
    inviteId
  }
}
    `;
export const useInitiateJointAccountInviteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    InitiateJointAccountInviteMutation,
    TError,
    InitiateJointAccountInviteMutationVariables,
    TContext
  >,
) =>
  useMutation<InitiateJointAccountInviteMutation, TError, InitiateJointAccountInviteMutationVariables, TContext>(
    ['InitiateJointAccountInvite'],
    (variables?: InitiateJointAccountInviteMutationVariables) =>
      fetcher<InitiateJointAccountInviteMutation, InitiateJointAccountInviteMutationVariables>(
        InitiateJointAccountInviteDocument,
        variables,
      )(),
    options,
  );
export const AcceptJointAccountInviteDocument = `
    mutation AcceptJointAccountInvite($inviteId: Uuid!, $sendDebitCard: Boolean!) {
  acceptJointAccountInvite(inviteId: $inviteId, sendDebitCard: $sendDebitCard) {
    inviteId
  }
}
    `;
export const useAcceptJointAccountInviteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AcceptJointAccountInviteMutation,
    TError,
    AcceptJointAccountInviteMutationVariables,
    TContext
  >,
) =>
  useMutation<AcceptJointAccountInviteMutation, TError, AcceptJointAccountInviteMutationVariables, TContext>(
    ['AcceptJointAccountInvite'],
    (variables?: AcceptJointAccountInviteMutationVariables) =>
      fetcher<AcceptJointAccountInviteMutation, AcceptJointAccountInviteMutationVariables>(
        AcceptJointAccountInviteDocument,
        variables,
      )(),
    options,
  );
export const GetJointAccountInvitesDocument = `
    query GetJointAccountInvites {
  jointAccountInvites {
    inviteId
    inviterEmail
    inviterFirstName
    inviterId
    inviterLastName
  }
}
    `;
export const useGetJointAccountInvitesQuery = <TData = GetJointAccountInvitesQuery, TError = unknown>(
  variables?: GetJointAccountInvitesQueryVariables,
  options?: UseQueryOptions<GetJointAccountInvitesQuery, TError, TData>,
) =>
  useQuery<GetJointAccountInvitesQuery, TError, TData>(
    variables === undefined ? ['GetJointAccountInvites'] : ['GetJointAccountInvites', variables],
    fetcher<GetJointAccountInvitesQuery, GetJointAccountInvitesQueryVariables>(
      GetJointAccountInvitesDocument,
      variables,
    ),
    options,
  );

useGetJointAccountInvitesQuery.getKey = (variables?: GetJointAccountInvitesQueryVariables) =>
  variables === undefined ? ['GetJointAccountInvites'] : ['GetJointAccountInvites', variables];
export const GetCanOpenPendingJointAccountDocument = `
    query GetCanOpenPendingJointAccount {
  canOpenPendingJointAccount
}
    `;
export const useGetCanOpenPendingJointAccountQuery = <TData = GetCanOpenPendingJointAccountQuery, TError = unknown>(
  variables?: GetCanOpenPendingJointAccountQueryVariables,
  options?: UseQueryOptions<GetCanOpenPendingJointAccountQuery, TError, TData>,
) =>
  useQuery<GetCanOpenPendingJointAccountQuery, TError, TData>(
    variables === undefined ? ['GetCanOpenPendingJointAccount'] : ['GetCanOpenPendingJointAccount', variables],
    fetcher<GetCanOpenPendingJointAccountQuery, GetCanOpenPendingJointAccountQueryVariables>(
      GetCanOpenPendingJointAccountDocument,
      variables,
    ),
    options,
  );

useGetCanOpenPendingJointAccountQuery.getKey = (variables?: GetCanOpenPendingJointAccountQueryVariables) =>
  variables === undefined ? ['GetCanOpenPendingJointAccount'] : ['GetCanOpenPendingJointAccount', variables];
export const GetOfferedApyValuesDocument = `
    query GetOfferedApyValues($id: Uuid!) {
  offeredApyValues(depositAccountId: $id) {
    apyInterestRate
    apyLevel
  }
}
    `;
export const useGetOfferedApyValuesQuery = <TData = GetOfferedApyValuesQuery, TError = unknown>(
  variables: GetOfferedApyValuesQueryVariables,
  options?: UseQueryOptions<GetOfferedApyValuesQuery, TError, TData>,
) =>
  useQuery<GetOfferedApyValuesQuery, TError, TData>(
    ['GetOfferedApyValues', variables],
    fetcher<GetOfferedApyValuesQuery, GetOfferedApyValuesQueryVariables>(GetOfferedApyValuesDocument, variables),
    options,
  );

useGetOfferedApyValuesQuery.getKey = (variables: GetOfferedApyValuesQueryVariables) => [
  'GetOfferedApyValues',
  variables,
];
export const GetDepositAccountPlansDocument = `
    query GetDepositAccountPlans($id: ID!) {
  depositAccount(depositAccountId: $id) {
    accountType
    balanceInCents
    depositAccountBalances {
      availableBalanceInCents
    }
    plans {
      accountNumber
      routingNumber
      id
      title
      color
      goalAmountInCents
      description
      icon
      createdAt
      balanceInCents
      planBalances {
        availableBalanceInCents
        balanceInCents
        pendingBalanceInCents
      }
      depositAccountId
      goalDate
      createdAt
      monthlyContributionGoalAmountInCents
    }
    apy {
      apyInterestRate
    }
    isPendingJointAccount
    depositAccountNumber {
      lastFour
      accountNumber
    }
  }
}
    `;
export const useGetDepositAccountPlansQuery = <TData = GetDepositAccountPlansQuery, TError = unknown>(
  variables: GetDepositAccountPlansQueryVariables,
  options?: UseQueryOptions<GetDepositAccountPlansQuery, TError, TData>,
) =>
  useQuery<GetDepositAccountPlansQuery, TError, TData>(
    ['GetDepositAccountPlans', variables],
    fetcher<GetDepositAccountPlansQuery, GetDepositAccountPlansQueryVariables>(
      GetDepositAccountPlansDocument,
      variables,
    ),
    options,
  );

useGetDepositAccountPlansQuery.getKey = (variables: GetDepositAccountPlansQueryVariables) => [
  'GetDepositAccountPlans',
  variables,
];
export const GetPlanDetailsTransactionDocument = `
    query GetPlanDetailsTransaction($id: ID!) {
  depositAccount(depositAccountId: $id) {
    plans {
      id
      transactions {
        id
        transferType
        fromAccountId
        fromAccountType
        toAccountId
        toAccountType
        postedAt
        initiatedAt
        description
        amountInCents
        status
        balanceInCents
        isCredit
        isDirectDeposit
        category
        scheduledFor
        cancelledAt
        planTitle
        planBalanceInCents
        planAccountNumber
        transferType
        routine {
          routineId
          isArchived
          isEnabled
          lastRunAt
          name
        }
      }
    }
  }
}
    `;
export const useGetPlanDetailsTransactionQuery = <TData = GetPlanDetailsTransactionQuery, TError = unknown>(
  variables: GetPlanDetailsTransactionQueryVariables,
  options?: UseQueryOptions<GetPlanDetailsTransactionQuery, TError, TData>,
) =>
  useQuery<GetPlanDetailsTransactionQuery, TError, TData>(
    ['GetPlanDetailsTransaction', variables],
    fetcher<GetPlanDetailsTransactionQuery, GetPlanDetailsTransactionQueryVariables>(
      GetPlanDetailsTransactionDocument,
      variables,
    ),
    options,
  );

useGetPlanDetailsTransactionQuery.getKey = (variables: GetPlanDetailsTransactionQueryVariables) => [
  'GetPlanDetailsTransaction',
  variables,
];
export const RequestPlanAccountNumberDocument = `
    mutation requestPlanAccountNumber($planId: Uuid!) {
  requestPlanAccountNumber(planId: $planId) {
    planId
  }
}
    `;
export const useRequestPlanAccountNumberMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RequestPlanAccountNumberMutation,
    TError,
    RequestPlanAccountNumberMutationVariables,
    TContext
  >,
) =>
  useMutation<RequestPlanAccountNumberMutation, TError, RequestPlanAccountNumberMutationVariables, TContext>(
    ['requestPlanAccountNumber'],
    (variables?: RequestPlanAccountNumberMutationVariables) =>
      fetcher<RequestPlanAccountNumberMutation, RequestPlanAccountNumberMutationVariables>(
        RequestPlanAccountNumberDocument,
        variables,
      )(),
    options,
  );
export const RemovePlanAccountNumberDocument = `
    mutation removePlanAccountNumber($planId: Uuid!, $accountNumber: String) {
  removePlanAccountNumber(planId: $planId, accountNumber: $accountNumber) {
    planId
  }
}
    `;
export const useRemovePlanAccountNumberMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemovePlanAccountNumberMutation,
    TError,
    RemovePlanAccountNumberMutationVariables,
    TContext
  >,
) =>
  useMutation<RemovePlanAccountNumberMutation, TError, RemovePlanAccountNumberMutationVariables, TContext>(
    ['removePlanAccountNumber'],
    (variables?: RemovePlanAccountNumberMutationVariables) =>
      fetcher<RemovePlanAccountNumberMutation, RemovePlanAccountNumberMutationVariables>(
        RemovePlanAccountNumberDocument,
        variables,
      )(),
    options,
  );
export const CreatePlanDocument = `
    mutation CreatePlan($title: String!, $color: String!, $goalAmountInCents: TransferAmountInCents, $description: String!, $depositAccountId: Uuid!, $initialContributionAmountInCents: TransferAmountInCents, $monthlyContributionGoalAmountInCents: TransferAmountInCents, $goalDate: Date, $assignAccountNumber: Boolean, $type: PlanTypes) {
  createPlan(
    title: $title
    color: $color
    type: $type
    goalDate: $goalDate
    goalAmountInCents: $goalAmountInCents
    description: $description
    icon: ""
    depositAccountId: $depositAccountId
    initialContributionAmountInCents: $initialContributionAmountInCents
    monthlyContributionGoalAmountInCents: $monthlyContributionGoalAmountInCents
    assignAccountNumber: $assignAccountNumber
  ) {
    planId
  }
}
    `;
export const useCreatePlanMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreatePlanMutation, TError, CreatePlanMutationVariables, TContext>,
) =>
  useMutation<CreatePlanMutation, TError, CreatePlanMutationVariables, TContext>(
    ['CreatePlan'],
    (variables?: CreatePlanMutationVariables) =>
      fetcher<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, variables)(),
    options,
  );
export const ArchivePlanDocument = `
    mutation ArchivePlan($planId: Uuid!) {
  archivePlan(planId: $planId) {
    planId
  }
}
    `;
export const useArchivePlanMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ArchivePlanMutation, TError, ArchivePlanMutationVariables, TContext>,
) =>
  useMutation<ArchivePlanMutation, TError, ArchivePlanMutationVariables, TContext>(
    ['ArchivePlan'],
    (variables?: ArchivePlanMutationVariables) =>
      fetcher<ArchivePlanMutation, ArchivePlanMutationVariables>(ArchivePlanDocument, variables)(),
    options,
  );
export const UpdatePlanDocument = `
    mutation UpdatePlan($planId: Uuid!, $title: String!, $color: String!, $goalAmountInCents: TransferAmountInCents, $description: String, $goalDate: Date, $monthlyContributionGoalAmountInCents: TransferAmountInCents) {
  updatePlan(
    planId: $planId
    title: $title
    color: $color
    goalDate: $goalDate
    monthlyContributionGoalAmountInCents: $monthlyContributionGoalAmountInCents
    goalAmountInCents: $goalAmountInCents
    description: $description
    icon: ""
  ) {
    planId
  }
}
    `;
export const useUpdatePlanMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdatePlanMutation, TError, UpdatePlanMutationVariables, TContext>,
) =>
  useMutation<UpdatePlanMutation, TError, UpdatePlanMutationVariables, TContext>(
    ['UpdatePlan'],
    (variables?: UpdatePlanMutationVariables) =>
      fetcher<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, variables)(),
    options,
  );
export const InitiatePlanTransferDocument = `
    mutation InitiatePlanTransfer($amountInCents: TransferAmountInCents!, $depositAccountId: Uuid!, $fromAccountId: Uuid!, $fromAccountType: PlanTransferAccountType!, $toAccountId: Uuid!, $toAccountType: PlanTransferAccountType!, $scheduledFor: DateTime) {
  initiatePlanTransfer(
    amountInCents: $amountInCents
    depositAccountId: $depositAccountId
    fromAccountId: $fromAccountId
    fromAccountType: $fromAccountType
    toAccountId: $toAccountId
    toAccountType: $toAccountType
    scheduledFor: $scheduledFor
  ) {
    id
  }
}
    `;
export const useInitiatePlanTransferMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<InitiatePlanTransferMutation, TError, InitiatePlanTransferMutationVariables, TContext>,
) =>
  useMutation<InitiatePlanTransferMutation, TError, InitiatePlanTransferMutationVariables, TContext>(
    ['InitiatePlanTransfer'],
    (variables?: InitiatePlanTransferMutationVariables) =>
      fetcher<InitiatePlanTransferMutation, InitiatePlanTransferMutationVariables>(
        InitiatePlanTransferDocument,
        variables,
      )(),
    options,
  );
export const ListRoutinesDocument = `
    query ListRoutines {
  routines {
    ...Routine
  }
}
    ${RoutineFragmentDoc}`;
export const useListRoutinesQuery = <TData = ListRoutinesQuery, TError = unknown>(
  variables?: ListRoutinesQueryVariables,
  options?: UseQueryOptions<ListRoutinesQuery, TError, TData>,
) =>
  useQuery<ListRoutinesQuery, TError, TData>(
    variables === undefined ? ['ListRoutines'] : ['ListRoutines', variables],
    fetcher<ListRoutinesQuery, ListRoutinesQueryVariables>(ListRoutinesDocument, variables),
    options,
  );

useListRoutinesQuery.getKey = (variables?: ListRoutinesQueryVariables) =>
  variables === undefined ? ['ListRoutines'] : ['ListRoutines', variables];
export const ListSharedRoutinesDocument = `
    query ListSharedRoutines {
  sharedRoutines {
    ...Routine
  }
}
    ${RoutineFragmentDoc}`;
export const useListSharedRoutinesQuery = <TData = ListSharedRoutinesQuery, TError = unknown>(
  variables?: ListSharedRoutinesQueryVariables,
  options?: UseQueryOptions<ListSharedRoutinesQuery, TError, TData>,
) =>
  useQuery<ListSharedRoutinesQuery, TError, TData>(
    variables === undefined ? ['ListSharedRoutines'] : ['ListSharedRoutines', variables],
    fetcher<ListSharedRoutinesQuery, ListSharedRoutinesQueryVariables>(ListSharedRoutinesDocument, variables),
    options,
  );

useListSharedRoutinesQuery.getKey = (variables?: ListSharedRoutinesQueryVariables) =>
  variables === undefined ? ['ListSharedRoutines'] : ['ListSharedRoutines', variables];
export const GetAllAccountsDocument = `
    query GetAllAccounts {
  depositAccounts {
    id
    accountType
    transactions {
      id
      transferType
      status
      description
    }
    depositAccountNumber {
      lastFour
    }
    plans {
      id
      title
    }
  }
}
    `;
export const useGetAllAccountsQuery = <TData = GetAllAccountsQuery, TError = unknown>(
  variables?: GetAllAccountsQueryVariables,
  options?: UseQueryOptions<GetAllAccountsQuery, TError, TData>,
) =>
  useQuery<GetAllAccountsQuery, TError, TData>(
    variables === undefined ? ['GetAllAccounts'] : ['GetAllAccounts', variables],
    fetcher<GetAllAccountsQuery, GetAllAccountsQueryVariables>(GetAllAccountsDocument, variables),
    options,
  );

useGetAllAccountsQuery.getKey = (variables?: GetAllAccountsQueryVariables) =>
  variables === undefined ? ['GetAllAccounts'] : ['GetAllAccounts', variables];
export const ListTemplatesDocument = `
    query ListTemplates {
  routineTemplates {
    ...RoutineTemplate
  }
}
    ${RoutineTemplateFragmentDoc}`;
export const useListTemplatesQuery = <TData = ListTemplatesQuery, TError = unknown>(
  variables?: ListTemplatesQueryVariables,
  options?: UseQueryOptions<ListTemplatesQuery, TError, TData>,
) =>
  useQuery<ListTemplatesQuery, TError, TData>(
    variables === undefined ? ['ListTemplates'] : ['ListTemplates', variables],
    fetcher<ListTemplatesQuery, ListTemplatesQueryVariables>(ListTemplatesDocument, variables),
    options,
  );

useListTemplatesQuery.getKey = (variables?: ListTemplatesQueryVariables) =>
  variables === undefined ? ['ListTemplates'] : ['ListTemplates', variables];
export const CreateRoutineDocument = `
    mutation CreateRoutine($templateId: Uuid, $name: String!, $description: String!, $trigger: TriggerInput!, $action: ActionInput!, $consents: [Consents]) {
  createRoutine(
    name: $name
    templateId: $templateId
    description: $description
    action: $action
    trigger: $trigger
    consents: $consents
  ) {
    routineId
  }
}
    `;
export const useCreateRoutineMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreateRoutineMutation, TError, CreateRoutineMutationVariables, TContext>,
) =>
  useMutation<CreateRoutineMutation, TError, CreateRoutineMutationVariables, TContext>(
    ['CreateRoutine'],
    (variables?: CreateRoutineMutationVariables) =>
      fetcher<CreateRoutineMutation, CreateRoutineMutationVariables>(CreateRoutineDocument, variables)(),
    options,
  );
export const EditRoutineDocument = `
    mutation EditRoutine($routineId: Uuid!, $name: String!, $description: String!, $trigger: TriggerInput!, $action: ActionInput!, $consents: [Consents]) {
  editRoutine(
    routineId: $routineId
    name: $name
    description: $description
    action: $action
    trigger: $trigger
    consents: $consents
  ) {
    routineId
  }
}
    `;
export const useEditRoutineMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<EditRoutineMutation, TError, EditRoutineMutationVariables, TContext>,
) =>
  useMutation<EditRoutineMutation, TError, EditRoutineMutationVariables, TContext>(
    ['EditRoutine'],
    (variables?: EditRoutineMutationVariables) =>
      fetcher<EditRoutineMutation, EditRoutineMutationVariables>(EditRoutineDocument, variables)(),
    options,
  );
export const EnableRoutineDocument = `
    mutation EnableRoutine($routineId: Uuid!) {
  enableRoutine(routineId: $routineId) {
    routineId
  }
}
    `;
export const useEnableRoutineMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<EnableRoutineMutation, TError, EnableRoutineMutationVariables, TContext>,
) =>
  useMutation<EnableRoutineMutation, TError, EnableRoutineMutationVariables, TContext>(
    ['EnableRoutine'],
    (variables?: EnableRoutineMutationVariables) =>
      fetcher<EnableRoutineMutation, EnableRoutineMutationVariables>(EnableRoutineDocument, variables)(),
    options,
  );
export const DisableRoutineDocument = `
    mutation DisableRoutine($routineId: Uuid!) {
  disableRoutine(routineId: $routineId) {
    routineId
  }
}
    `;
export const useDisableRoutineMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<DisableRoutineMutation, TError, DisableRoutineMutationVariables, TContext>,
) =>
  useMutation<DisableRoutineMutation, TError, DisableRoutineMutationVariables, TContext>(
    ['DisableRoutine'],
    (variables?: DisableRoutineMutationVariables) =>
      fetcher<DisableRoutineMutation, DisableRoutineMutationVariables>(DisableRoutineDocument, variables)(),
    options,
  );
export const ArchiveRoutineDocument = `
    mutation ArchiveRoutine($routineId: Uuid!) {
  archiveRoutine(routineId: $routineId) {
    routineId
  }
}
    `;
export const useArchiveRoutineMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ArchiveRoutineMutation, TError, ArchiveRoutineMutationVariables, TContext>,
) =>
  useMutation<ArchiveRoutineMutation, TError, ArchiveRoutineMutationVariables, TContext>(
    ['ArchiveRoutine'],
    (variables?: ArchiveRoutineMutationVariables) =>
      fetcher<ArchiveRoutineMutation, ArchiveRoutineMutationVariables>(ArchiveRoutineDocument, variables)(),
    options,
  );
export const TestWebhookDocument = `
    mutation TestWebhook($url: String!, $name: String!, $description: String!, $triggerType: TriggerTypes!) {
  testWebhook(
    name: $name
    description: $description
    url: $url
    triggerType: $triggerType
  ) {
    response
  }
}
    `;
export const useTestWebhookMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<TestWebhookMutation, TError, TestWebhookMutationVariables, TContext>,
) =>
  useMutation<TestWebhookMutation, TError, TestWebhookMutationVariables, TContext>(
    ['TestWebhook'],
    (variables?: TestWebhookMutationVariables) =>
      fetcher<TestWebhookMutation, TestWebhookMutationVariables>(TestWebhookDocument, variables)(),
    options,
  );
export const GetAllAccountsWithTransferLimitsDocument = `
    query GetAllAccountsWithTransferLimits {
  depositAccounts {
    id
    accountType
    transactions {
      id
      transferType
      status
      description
    }
    depositAccountNumber {
      lastFour
    }
    plans {
      id
      title
    }
    odfiTransferLimit {
      pushAmountInCents
      pullAmountInCents
    }
  }
}
    `;
export const useGetAllAccountsWithTransferLimitsQuery = <
  TData = GetAllAccountsWithTransferLimitsQuery,
  TError = unknown,
>(
  variables?: GetAllAccountsWithTransferLimitsQueryVariables,
  options?: UseQueryOptions<GetAllAccountsWithTransferLimitsQuery, TError, TData>,
) =>
  useQuery<GetAllAccountsWithTransferLimitsQuery, TError, TData>(
    variables === undefined ? ['GetAllAccountsWithTransferLimits'] : ['GetAllAccountsWithTransferLimits', variables],
    fetcher<GetAllAccountsWithTransferLimitsQuery, GetAllAccountsWithTransferLimitsQueryVariables>(
      GetAllAccountsWithTransferLimitsDocument,
      variables,
    ),
    options,
  );

useGetAllAccountsWithTransferLimitsQuery.getKey = (variables?: GetAllAccountsWithTransferLimitsQueryVariables) =>
  variables === undefined ? ['GetAllAccountsWithTransferLimits'] : ['GetAllAccountsWithTransferLimits', variables];
export const AddTransactionCategoryDocument = `
    mutation AddTransactionCategory($depositAccountId: Uuid!, $transactionId: Uuid!, $category: TransactionCategories!) {
  addTransactionCategory(
    depositAccountId: $depositAccountId
    transactionId: $transactionId
    category: $category
  ) {
    categorizationId
  }
}
    `;
export const useAddTransactionCategoryMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AddTransactionCategoryMutation,
    TError,
    AddTransactionCategoryMutationVariables,
    TContext
  >,
) =>
  useMutation<AddTransactionCategoryMutation, TError, AddTransactionCategoryMutationVariables, TContext>(
    ['AddTransactionCategory'],
    (variables?: AddTransactionCategoryMutationVariables) =>
      fetcher<AddTransactionCategoryMutation, AddTransactionCategoryMutationVariables>(
        AddTransactionCategoryDocument,
        variables,
      )(),
    options,
  );
export const ArchiveTransactionCategoryDocument = `
    mutation ArchiveTransactionCategory($depositAccountId: Uuid!, $transactionId: Uuid!, $category: TransactionCategories!) {
  archiveTransactionCategory(
    depositAccountId: $depositAccountId
    transactionId: $transactionId
    category: $category
  ) {
    categorizationId
  }
}
    `;
export const useArchiveTransactionCategoryMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ArchiveTransactionCategoryMutation,
    TError,
    ArchiveTransactionCategoryMutationVariables,
    TContext
  >,
) =>
  useMutation<ArchiveTransactionCategoryMutation, TError, ArchiveTransactionCategoryMutationVariables, TContext>(
    ['ArchiveTransactionCategory'],
    (variables?: ArchiveTransactionCategoryMutationVariables) =>
      fetcher<ArchiveTransactionCategoryMutation, ArchiveTransactionCategoryMutationVariables>(
        ArchiveTransactionCategoryDocument,
        variables,
      )(),
    options,
  );
export const UpdateAddressDocument = `
    mutation UpdateAddress($city: String!, $countryCode: String!, $line1: String!, $line2: String, $postalCode: String!, $stateCode: String!) {
  updateAddress(
    city: $city
    countryCode: $countryCode
    line1: $line1
    line2: $line2
    postalCode: $postalCode
    stateCode: $stateCode
  ) {
    piiChangeRequestId
    status
  }
}
    `;
export const useUpdateAddressMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateAddressMutation, TError, UpdateAddressMutationVariables, TContext>,
) =>
  useMutation<UpdateAddressMutation, TError, UpdateAddressMutationVariables, TContext>(
    ['UpdateAddress'],
    (variables?: UpdateAddressMutationVariables) =>
      fetcher<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, variables)(),
    options,
  );
export const UpdateEmailDocument = `
    mutation updateEmail($email: String!) {
  updateEmail(email: $email) {
    piiChangeRequestId
    status
  }
}
    `;
export const useUpdateEmailMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateEmailMutation, TError, UpdateEmailMutationVariables, TContext>,
) =>
  useMutation<UpdateEmailMutation, TError, UpdateEmailMutationVariables, TContext>(
    ['updateEmail'],
    (variables?: UpdateEmailMutationVariables) =>
      fetcher<UpdateEmailMutation, UpdateEmailMutationVariables>(UpdateEmailDocument, variables)(),
    options,
  );
export const UpdatePhoneDocument = `
    mutation UpdatePhone($phoneNumber: String!) {
  updatePhone(phoneNumber: $phoneNumber) {
    piiChangeRequestId
    status
  }
}
    `;
export const useUpdatePhoneMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdatePhoneMutation, TError, UpdatePhoneMutationVariables, TContext>,
) =>
  useMutation<UpdatePhoneMutation, TError, UpdatePhoneMutationVariables, TContext>(
    ['UpdatePhone'],
    (variables?: UpdatePhoneMutationVariables) =>
      fetcher<UpdatePhoneMutation, UpdatePhoneMutationVariables>(UpdatePhoneDocument, variables)(),
    options,
  );
export const UpdateNameDocument = `
    mutation UpdateName($nameFirst: String!, $nameLast: String!, $nameMiddle: String, $namePrefix: String, $nameSuffix: String) {
  updateName(
    nameFirst: $nameFirst
    nameLast: $nameLast
    nameMiddle: $nameMiddle
    namePrefix: $namePrefix
    nameSuffix: $nameSuffix
  ) {
    piiChangeRequestId
    status
  }
}
    `;
export const useUpdateNameMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateNameMutation, TError, UpdateNameMutationVariables, TContext>,
) =>
  useMutation<UpdateNameMutation, TError, UpdateNameMutationVariables, TContext>(
    ['UpdateName'],
    (variables?: UpdateNameMutationVariables) =>
      fetcher<UpdateNameMutation, UpdateNameMutationVariables>(UpdateNameDocument, variables)(),
    options,
  );
export const VerifyPiiConfirmationCodeDocument = `
    mutation VerifyPiiConfirmationCode($code: String!, $piiChangeRequestId: Uuid!) {
  verifyPiiConfirmationCode(code: $code, piiChangeRequestId: $piiChangeRequestId) {
    piiChangeRequestId
  }
}
    `;
export const useVerifyPiiConfirmationCodeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    VerifyPiiConfirmationCodeMutation,
    TError,
    VerifyPiiConfirmationCodeMutationVariables,
    TContext
  >,
) =>
  useMutation<VerifyPiiConfirmationCodeMutation, TError, VerifyPiiConfirmationCodeMutationVariables, TContext>(
    ['VerifyPiiConfirmationCode'],
    (variables?: VerifyPiiConfirmationCodeMutationVariables) =>
      fetcher<VerifyPiiConfirmationCodeMutation, VerifyPiiConfirmationCodeMutationVariables>(
        VerifyPiiConfirmationCodeDocument,
        variables,
      )(),
    options,
  );
export const SendPiiConfirmationCodeDocument = `
    mutation SendPiiConfirmationCode($piiChangeRequestId: Uuid!) {
  sendPiiConfirmationCode(piiChangeRequestId: $piiChangeRequestId) {
    piiChangeRequestId
  }
}
    `;
export const useSendPiiConfirmationCodeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SendPiiConfirmationCodeMutation,
    TError,
    SendPiiConfirmationCodeMutationVariables,
    TContext
  >,
) =>
  useMutation<SendPiiConfirmationCodeMutation, TError, SendPiiConfirmationCodeMutationVariables, TContext>(
    ['SendPiiConfirmationCode'],
    (variables?: SendPiiConfirmationCodeMutationVariables) =>
      fetcher<SendPiiConfirmationCodeMutation, SendPiiConfirmationCodeMutationVariables>(
        SendPiiConfirmationCodeDocument,
        variables,
      )(),
    options,
  );
