const URL_HMB_TO = 'https://hmb.to';
export const ASSET_BASE_URL = process.env.NEXT_PUBLIC_ASSET_BASE_URL || 'https://images.hmbradley.com/pronto';
export const HELP_DESK = process.env.NEXT_PUBLIC_HELP_DESK_URL_PATH;
export const HELP_DESK_API_PATH = process.env.NEXT_PUBLIC_HELP_DESK_API_PATH;
export const FAQ = process.env.NEXT_PUBLIC_FAQ_URL_PATH;
export const MARKETING_HP = process.env.NEXT_PUBLIC_MARKETING_URL_PATH;
export const GET_ROUTING_NUMBERS_URL = process.env.NEXT_PUBLIC_GET_ROUTING_NUMBERS_URL;
const HELPDESK_API_PATH = process.env.NEXT_PUBLIC_HELP_DESK_API_PATH;
export const INDIVIDUAL_ACCOUNT_TYPE = 'Individual';
export const DEPOSIT_ACCOUNT_TYPE = 'Deposit';
export const CREDIT_ACCOUNT_TYPE = 'Credit';
export const JOINT_ACCOUNT_TYPE = 'Joint';
export const EMAIL_VALIDATION_URL = `${HELPDESK_API_PATH}/api/email/check`;
export const TRUSTED_USER_LOCAL_STORAGE_KEY = 'hmb_trusted_user';
export const CREDIT_CARD_TERMS_AND_CONDITIONS = `${process.env.NEXT_PUBLIC_MARKETING_URL_PATH}/credit-card-terms`;
export const FAQ_AVAILABLE_CREDIT = `${URL_HMB_TO}/faq-available-credit`;
export const HOW_DO_I_OPEN_A_CREDIT_CARD_ACCOUNT_FAQ = `${FAQ}/hc/en-us/articles/360046191552-How-do-I-open-an-HMBradley-Credit-Card-account-What-are-the-eligibility-requirements`;
export const CREDIT_CARD_TERMS = `${MARKETING_HP}/credit-card-terms`;
export const OCC_CG_TERMS_AND_CONDITIONS = `${process.env.NEXT_PUBLIC_POLICIES_URL_PATH}/credit/v3.0.0/OCC+and+CG+Terms+and+Conditions+V3.pdf`;
export const CREDIT_CARD_HOLDER_AGREEMENT_URL = `${URL_HMB_TO}/credit-cardholder-agreement`;
export const URL_HMB_TO_A_NEW_CHAPTER = `${URL_HMB_TO}/a-new-chapter`;
export const URL_HMB_TO_APYS_NYCB = `${URL_HMB_TO}/apys-nycb`;
export const URL_HMB_TO_NYCB_PROMO = `${URL_HMB_TO}/nycb-promo`;
export const URL_HMB_TO_NYCB_PRIVACY_NOTICE = `${URL_HMB_TO}/NYCB-privacy-notice`;
export const URL_HMB_TO_NONTAXABLE_INCOME = `${URL_HMB_TO}/nontaxable-income`;
export const URL_HMB_TO_THE_ROAD_AHEAD = `${URL_HMB_TO}/the-road-ahead`;
export const URL_HMB_BUSINESS = `https://business.hmbradley.com/`;
export const URL_CASHBACK_CATEGORIES = `${MARKETING_HP}/merchant-categories`;
export const URL_HMB_TO_NYCB_DEPOSIT_AGREEMENT = `${URL_HMB_TO}/NYCB-DAAD`;
export const FORWARD_USER_TO_DEPOSIT_APPLICATION_LOCAL_STORAGE_KEY = 'hmb_forward_user_to_deposit_application';
export const HOW_CASHFLOW_IS_CALCULATED_FAQ = `${FAQ}/hc/en-us/articles/9688803581844-How-does-HMBradley-calculate-cash-flow-on-deposit-accounts-`;
export const ACCEPTED_JOINT_INIVTE_STORAGE_KEY = 'hmb_accepted_joint_invite_storage_key';
export const FDIC_WEBSITE_URL = 'https://www.fdic.gov/resources/deposit-insurance/';
export const URL_HMB_TO_ROUTINES_TEMPLATES = 'https://www.hmbradley.com/routines/templates/:template';
export const URL_HMB_TO_ROUTINES_LEARN_MORE = 'https://hmb.to/faq-setting-up-a-routine';
export const ROUTINE_DRAFTS_STORAGE_KEY = 'hmb_routine_drafts';
export const CLOSED_OVERVIEW_BANNERS_STORAGE_KEY = 'hmb_closed_banners';
export const URL_HMB_TO_ROUTINES_LISTING = 'https://www.hmbradley.com/routines/listing';
export const WEB_AUTHN_USERS_LOCAL_STORAGE_KEY = 'hmb_web_authn_users';
export const URL_HMB_TO_ROUTINES_TOS = 'https://hmb.to/routines-tos';
