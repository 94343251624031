import React from 'react';

import { CustomPlanOption } from '@/components/create-plan/components/plan-type-form/components/custom-plan-option';
import { PlanTypeOption } from '@/components/create-plan/components/plan-type-form/components/plan-type-option';
import cx from 'clsx';

export function PlanTypeForm(props: {
  options: { name: string; color: string }[];
  customPlanTypeName: string;
  selectedPlanType: string;
  onSelectPlanType: (type: string) => void;
  children?: React.ReactNode;
  optionsContainerClassName?: string;
}) {
  return (
    <>
      <p className="text-lg text-gray-950 mb-2">What are you saving for?</p>
      {props.children}
      <CustomPlanOption
        isSelected={props.selectedPlanType === props.customPlanTypeName}
        onClick={() => props.onSelectPlanType(props.customPlanTypeName)}
      />
      <p className="text-gray-950 font-normal text-sm mt-6">Frequently created Plans</p>
      <div className={cx('flex flex-wrap flex-row w-full sm:w-3/4', props.optionsContainerClassName)}>
        {props.options.map((option) => (
          <PlanTypeOption
            isSelected={props.selectedPlanType === option.name}
            onClick={() => props.onSelectPlanType(option.name)}
            key={option.name}
            name={option.name}
            color={option.color}
          />
        ))}
      </div>
    </>
  );
}
