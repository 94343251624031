import cx from 'clsx';
import React from 'react';

export function StepIndicator(props: { withFundPlanSteps: boolean; step: number; itemClassName?: string }) {
  return (
    <div className="flex justify-center my-8">
      <div
        className={cx(
          'w-2.5 h-2.5 rounded-full mx-1.5',
          {
            'bg-gray-300': props.step !== 1,
            'bg-gray-950': props.step === 1,
          },
          props.itemClassName,
        )}
      />
      <div
        className={cx(
          'w-2.5 h-2.5 rounded-full mx-1.5',
          {
            'bg-gray-300': props.step !== 2,
            'bg-gray-950': props.step === 2,
          },
          props.itemClassName,
        )}
      />
      <div
        className={cx(
          'w-2.5 h-2.5 rounded-full mx-1.5',
          {
            'bg-gray-300': props.step !== 3,
            'bg-gray-950': props.step === 3,
          },
          props.itemClassName,
        )}
      />
      {props.withFundPlanSteps && (
        <div
          className={cx(
            'w-2.5 h-2.5 rounded-full mx-1.5',
            {
              'bg-gray-300': props.step !== 4,
              'bg-gray-950': props.step === 4,
            },
            props.itemClassName,
          )}
        />
      )}
    </div>
  );
}
