import { Popover, Transition } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import cx from 'clsx';
import * as React from 'react';

function ToolTip(props: {
  children: React.ReactNode;
  className?: string;
  panelClassName?: string;
  iconClassName?: string;
  icon?: React.ReactNode;
}) {
  return (
    <Popover className={cx('relative h-5', props.className)}>
      <Popover.Button>
        {props.icon ? props.icon : <QuestionMarkCircleIcon className={props.iconClassName || 'text-blue w-5 h-5'} />}
      </Popover.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className={cx('absolute z-10 w-64 left-1/2 -translate-x-1/2 mt-1 transform', props.panelClassName)}
        >
          {props.children}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export function InfoButtonToolTip(props: {
  children: React.ReactNode;
  className?: string;
  panelClassName?: string;
  arrowClassName?: string;
  iconClassName?: string;
  icon?: React.ReactNode;
}) {
  return (
    <ToolTip
      icon={props.icon}
      className={props.className}
      panelClassName={props.panelClassName}
      iconClassName={props.iconClassName}
    >
      <div className="flex justify-center">
        <div
          className={cx(
            'border-solid border-b-gray-700 border-b-8 border-x-transparent border-x-8 border-t-0 w-fit',
            props.arrowClassName,
          )}
        />
      </div>
      {props.children}
    </ToolTip>
  );
}

export function InfoButtonToolTipTop(props: {
  children: React.ReactNode;
  className?: string;
  panelClassName?: string;
  iconClassName?: string;
}) {
  return (
    <ToolTip
      className={props.className}
      panelClassName={cx('-translate-y-full -top-4', props.panelClassName)}
      iconClassName={props.iconClassName}
    >
      {props.children}
      <div className="flex justify-center">
        <div className="border-solid border-b-gray-700 border-b-8 border-x-transparent rotate-180 border-x-8 border-t-0 w-fit" />
      </div>
    </ToolTip>
  );
}
