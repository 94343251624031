import { useMediaQuery } from 'react-responsive';
import { XL_MOBILE_WIDTH } from '../../../../styles/media_queries';

type Lines = [px: string, value: number];

type BgLinesProps = {
  lines: Array<Lines>;
  linesMobile?: Array<Lines>;
  background?: string;
  color?: string;
  className?: string;
};

export function BgLines({
  color = '#000000',
  lines = [],
  linesMobile = [],
  background = 'transparent',
  className = '',
}: BgLinesProps) {
  const isMobile = useMediaQuery({ minWidth: XL_MOBILE_WIDTH + 1 });
  const linesToRender = isMobile && linesMobile.length ? linesMobile : lines;

  return (
    <div className={className} style={{ background }}>
      {linesToRender.map(([width, marginRight]) => (
        <div
          key={width + marginRight}
          style={{
            width,
            height: '100%',
            marginRight: `${marginRight}px`,
            background: color,
          }}
        />
      ))}
    </div>
  );
}
