import {
  AccountSource,
  ExternalAccount as UmbrellaExternalAccount,
  ExternalAccountStatus,
  useArchiveExternalAccountMutation,
  useGetExternalAccountsQuery,
  useUpdateExternalAccountMutation,
} from '@/generated/graphql';
import { isNil } from '@/helpers/is-nil';
import { LedgerExternalAccount } from '@core/mainframe-js-sdk';

type Modify<T, R> = Omit<T, keyof R> & R;
export type ExternalAccount = Modify<
  LedgerExternalAccount,
  {
    providerType: AccountSource;
    plaidInstitutionLogo: string;
    availableBalanceInCents: number | null;
    lastUpdatedAt: string | null;
    updatedRequired: boolean;
    updateReason: string | null;
  }
>;

function mapUmbrellaExternalAccount(externalAccount: UmbrellaExternalAccount): ExternalAccount {
  return {
    id: externalAccount.id,
    externalAccountLastFour: externalAccount.accountLastFour,
    externalRoutingNumber: externalAccount.routingNumber,
    externalAccountNickname: externalAccount.nickname,
    externalAccountType: externalAccount.accountType,
    isVerified: externalAccount.isVerified,
    plaidInstitutionName: externalAccount.plaidInstitution?.name,
    plaidInstitutionLogo: externalAccount.plaidInstitution?.logo,
    providerType: externalAccount.accountSource,
    accountStatus: externalAccount.accountStatus === ExternalAccountStatus.Valid ? 'verified' : 'unverified',
    lastUpdatedAt:
      !isNil(externalAccount.plaidBalance) && !isNil(externalAccount.plaidBalance.lastUpdatedAt)
        ? externalAccount.plaidBalance.lastUpdatedAt
        : null,
    availableBalanceInCents:
      !isNil(externalAccount.plaidBalance) && !isNil(externalAccount.plaidBalance.availableBalanceInCents)
        ? externalAccount.plaidBalance.availableBalanceInCents
        : null,
    updatedRequired: externalAccount.updateRequired || false,
    updateReason: externalAccount.updateReason || null,
  };
}

export function useExternalAccounts() {
  const getExternalAccountsQuery = useGetExternalAccountsQuery();

  const updateExternalAccountMutation = useUpdateExternalAccountMutation({});
  const archiveExternalAccountMutation = useArchiveExternalAccountMutation({});

  async function refetch() {
    getExternalAccountsQuery.refetch();
  }

  async function editExternalAccountNickname(accountId: string, externalAccountNickname: string) {
    await updateExternalAccountMutation.mutateAsync({
      externalAccountId: accountId,
      nickname: externalAccountNickname,
    });
    await refetch();
  }

  async function deleteExternalAccount(accountId: string) {
    await archiveExternalAccountMutation.mutateAsync({
      externalAccountId: accountId,
    });
    await refetch();
  }

  return {
    isFetched: getExternalAccountsQuery.isFetched,
    isLoading: getExternalAccountsQuery.isLoading,
    isUpdating: updateExternalAccountMutation.isLoading,
    isDeleting: archiveExternalAccountMutation.isLoading,
    isError: getExternalAccountsQuery.isError,
    refetch,
    deleteExternalAccount,
    editExternalAccountNickname,
    externalAccounts: (getExternalAccountsQuery.data?.externalAccounts || []).map(mapUmbrellaExternalAccount),
  };
}
