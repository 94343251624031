import * as React from 'react';
import { cssTransition, ToastContainer } from 'react-toastify';
import Close from './icons/close.svg';

// Using context allows toast children to access the closest toast to where they're being rendered
export const ToastContext = React.createContext<Partial<{ id: string }>>({});

type CloseButtonProps = {
  closeToast?: React.MouseEventHandler<HTMLDivElement> | React.KeyboardEventHandler<HTMLDivElement>;
};

const CloseButton = ({ closeToast = () => {} }: CloseButtonProps) => (
  <div
    tabIndex={0}
    role="button"
    className="Toastify__close-button"
    onClick={closeToast as React.MouseEventHandler<HTMLDivElement>}
    onKeyPress={closeToast as React.KeyboardEventHandler<HTMLDivElement>}
  >
    <Close />
  </div>
);

// Wrapping ToastContainer in a Provider and rendering it's children here
// allows us to  use multiple toasts at different levels in the DOM tree.
// Components at different levels in the tree will post notifications to the nearest
// Toast by default instead of requiring the child to know which Toast they
// will be posting to

type ToastContainerProps = {
  containerId: string;
  children: React.ReactNode;
};

export function ToastContainerWrapper({ containerId, children = null }: ToastContainerProps) {
  return (
    <ToastContext.Provider value={{ id: containerId }}>
      <div className="w-full min-h-screen">
        <ToastContainer
          position="top-right"
          newestOnTop
          closeOnClick={false}
          closeButton={<CloseButton />}
          rtl={false}
          draggable={false}
          className="large"
          enableMultiContainer
          containerId={containerId}
          transition={cssTransition({
            enter: 'Toastify__slide-enter',
            exit: 'Toastify__slide-exit',
            appendPosition: true,
          })}
        />
        {children}
      </div>
    </ToastContext.Provider>
  );
}
