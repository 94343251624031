import { StepIndicator } from '@/components/create-plan/components/step-indicator';
import { ControlledDatePicker } from '@/components/form/components/form-inputs/controlled-date-picker';
import { CurrencyField } from '@/components/form/components/form-inputs/currency-field';
import { useGetDepositAccountsQuery, useGetOfferedApyValuesQuery } from '@/generated/graphql';
import { fromCents } from '@/helpers/currency';
import { formatDate } from '@/i18n/datetime';
import { Cta } from '@/routes/account/components/create-plan-drawer/components/cta';
import { ProjectedPlan } from '@/routes/account/components/create-plan-drawer/components/projected-plan';
import { RunProjectionButton } from '@/routes/account/components/create-plan-drawer/components/run-projection-button';
import { CreatePlanSteps, getPlanGoalSchema, IPlanGoal } from '@/routes/account/components/create-plan-drawer/helpers';
import { useCreatePlanDrawerContext } from '@/routes/account/components/create-plan-drawer/hooks/use-create-plan-drawer-context';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

const minDate = DateTime.local().plus({ month: 1 }).set({ day: 1 }).startOf('day');
const defaultDate = DateTime.local().plus({ years: 1, month: 1 }).set({ day: 1 }).startOf('day');

function getHighestApyValue(apyValues: { apyInterestRate?: string }[]) {
  return apyValues.reduce(
    (acc, apyValue) => (parseFloat(apyValue.apyInterestRate) > acc ? parseFloat(apyValue.apyInterestRate) : acc),
    0,
  );
}

export function PlanGoalStep(props: {
  onCreatePlan: (values?: IPlanGoal) => void;
  createPlanLoading: boolean;
  createPlanError?: string;
}) {
  const [runProjectionClicked, setRunProjectionClicked] = React.useState(false);

  const controller = useCreatePlanDrawerContext();
  const getOfferedApyValuesQuery = useGetOfferedApyValuesQuery(
    { id: controller.accountId },
    { enabled: !!controller.accountId },
  );
  const getDepositAccountsQuery = useGetDepositAccountsQuery();

  const account = (getDepositAccountsQuery.data?.depositAccounts || []).find(
    (account) => account.id === controller.accountId,
  );

  const availableBalance = fromCents(account?.depositAccountBalances?.availableBalanceInCents || 0);

  const form = useForm({
    defaultValues: {
      goalAmount: '',
      monthlyContribution: '',
      initialContribution: '',
      achieveGoalBy: defaultDate.toFormat('yyyy-MM-dd'),
    },
    mode: 'onChange',
    resolver: yupResolver(getPlanGoalSchema(availableBalance)),
  });

  function onCreatePlan() {
    const values = form.getValues();
    controller.setPlanGoal(values);
    props.onCreatePlan(values);
  }

  function onRunProjection() {
    setRunProjectionClicked(true);
    controller.setPlanGoal(form.getValues());
    controller.setStep(CreatePlanSteps.ProjectedPlan);
  }

  function onClickBack() {
    controller.setStep(CreatePlanSteps.PlanName);
  }

  const displayGraph = runProjectionClicked && !!controller.planGoal && !!getOfferedApyValuesQuery.data;
  const formValues = form.watch();

  return (
    <div className="h-full min-w-full flex flex-col justify-between p-6 overflow-y-auto">
      <div>
        {displayGraph && (
          <ProjectedPlan apyValue={getHighestApyValue(getOfferedApyValuesQuery.data.offeredApyValues || [])} />
        )}
        <p className="text-lg text-gray-950 mb-4">How much do you want to save?</p>
        <Controller
          render={({ field }) => {
            return (
              <CurrencyField
                {...field}
                label="Goal amount"
                placeholder="$0.00"
                className="mb-3"
                error={form.formState?.errors?.goalAmount?.message}
                isOptional
              />
            );
          }}
          control={form.control}
          name="goalAmount"
        />
        <div className="mt-4">
          <ControlledDatePicker
            name="achieveGoalBy"
            label="Achieve goal by"
            placeholder="Achieve goal by"
            minDate={minDate}
            value={formValues.achieveGoalBy}
            control={form.control}
            error={form.formState.errors?.achieveGoalBy?.message}
          />
        </div>
        <p className="text-lg text-gray-950 mb-4 mt-7">How much do you anticipate contributing?</p>
        <div className="flex justify-between">
          <Controller
            render={({ field }) => {
              return (
                <CurrencyField
                  {...field}
                  isOptional
                  className="flex-1"
                  label="Monthly Contribution"
                  placeholder="$0.00"
                  error={form.formState?.errors?.monthlyContribution?.message}
                />
              );
            }}
            control={form.control}
            name="monthlyContribution"
          />
          {controller.withFundPlanSteps && (
            <Controller
              render={({ field }) => {
                return (
                  <CurrencyField
                    {...field}
                    isOptional
                    className="flex-1 ml-2"
                    label="Initial Contribution"
                    placeholder="$0.00"
                    error={form.formState?.errors?.initialContribution?.message}
                  />
                );
              }}
              control={form.control}
              name="initialContribution"
            />
          )}
        </div>
        <RunProjectionButton isDisabled={!form.formState.isValid} onClick={onRunProjection} />
        {displayGraph && (
          <>
            <div className="w-full h-px bg-gray-200 mt-10 mb-10" />
            <p className="text-gray-600 font-normal text-tiny mb-6">
              * The calculated values above assume (1) the interest on your account remains the same for the entire time
              period that you entered (please note, interest rates are as of{' '}
              {formatDate(DateTime.now().toFormat('yyyy-MM-dd'))} and are subject to change), (2) 100% of your
              contributions (both your initial contribution and your monthly contributions) and 100% interest earned
              from those contributions remain on deposit in this account for the entire time period that you entered,
              (3) you make the initial contribution on the date that you create this Plan, and (4) the you make the
              monthly contributions no later than on the same day of each month that you set up this Plan.
            </p>
          </>
        )}
      </div>
      <div className="mt-4">
        {!!props.createPlanError && (
          <p className="mb-6 text-red text-center leading-5 font-normal text-base">{props.createPlanError}</p>
        )}
        <StepIndicator
          withFundPlanSteps={controller.withFundPlanSteps}
          step={controller.stepNumber}
          itemClassName="mx-1 w-2 h-2"
        />
        <Cta
          onClickBack={onClickBack}
          onClickNext={onCreatePlan}
          backButtonLabel="Back"
          nextButtonLabel="Create Plan"
          isNextDisabled={!form.formState.isValid}
          isLoading={props.createPlanLoading}
        />
      </div>
    </div>
  );
}
