export function ArrowAltIcon(props: { className?: string }) {
  return (
    <div className={props.className}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M11.3333 5.3335L14 8.00016M14 8.00016L11.3333 10.6668M14 8.00016H2"
          stroke="#67767F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function TriggerIcon() {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none">
      <rect width="38" height="38" rx="10" fill="#E0D7FF" />
      <rect x="16.0527" y="17.9619" width="6.33951" height="2.81756" fill="#4B36CE" />
      <rect x="23.801" y="17.9619" width="2.81756" height="2.81756" fill="#4B36CE" />
      <rect x="28.0272" y="17.9619" width="2.81756" height="2.81756" fill="#5A46D7" />
      <rect x="32.2537" y="17.9619" width="1.40878" height="2.81756" fill="#5A46D7" />
      <rect x="35.0712" y="17.9619" width="1.40878" height="2.81756" fill="#7561E2" />
      <ellipse cx="12.5307" cy="19.3707" rx="4.93073" ry="4.93073" fill="#3321A6" />
      <circle opacity="0.2" cx="12.5401" cy="19.3799" r="7.98" fill="#5A46D7" />
    </svg>
  );
}

export function ActionIcon() {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none">
      <rect width="38" height="38" rx="10" fill="#D3F5F8" />
      <rect x="12.6995" y="18.5762" width="9.78194" height="2.79484" fill="#1EA1C1" />
      <rect x="26.0851" y="13.6802" width="3.04" height="12.16" rx="1.52" fill="#0886A5" />
      <rect opacity="0.3" x="26.0851" y="10.6401" width="3.04" height="18.24" rx="1.52" fill="#0886A5" />
      <rect x="4.3147" y="18.5762" width="2.79484" height="2.79484" fill="#39B8D5" />
      <rect x="8.50696" y="18.5762" width="2.79484" height="2.79484" fill="#1EA1C1" />
      <rect x="1.52002" y="18.5762" width="1.39742" height="2.79484" fill="#39B8D5" />
      <path
        d="M19.7679 25.0561C19.109 25.5524 18.1664 25.0823 18.1664 24.2573V15.6805C18.1664 14.8574 19.1053 14.3868 19.7648 14.8794L25.4827 19.1502C26.0168 19.5492 26.0183 20.3492 25.4858 20.7502L19.7679 25.0561Z"
        fill="#0886A5"
      />
    </svg>
  );
}

export function LearnMoreIcon() {
  return (
    <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 1L19 5M19 5L15 9M19 5H1"
        stroke="#29353B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
