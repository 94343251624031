import { OnboardingApprovedStatus } from '@/components/onboarding-approved-status';
import { wrapDisplayName } from '@/helpers/recompose';
import * as React from 'react';

export function withOnboardingApprovedStatus<T>(WrappedComponent: React.ComponentType<T>) {
  function WithOnboardingApprovedStatus(props: React.PropsWithChildren<any>) {
    return (
      <OnboardingApprovedStatus>
        <WrappedComponent {...props} />
      </OnboardingApprovedStatus>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    WithOnboardingApprovedStatus.displayName = wrapDisplayName(WrappedComponent, 'WithOnboardingApprovedStatus');
  }

  return WithOnboardingApprovedStatus;
}
