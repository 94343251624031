import {
  ActionFragment,
  ActionInput,
  ActionTypes,
  ComparisonTypes,
  Consents,
  CreditCardPaymentBalanceTypes,
  DepositAccount,
  DepositAccountType,
  DepositTransactionConditions,
  OdfiTransferType,
  Plan,
  PlanTransferAccountType,
  RoutineFragment,
  RoutineMetadata,
  RoutineTemplateFragment,
  TemplateActionFragment,
  TemplateTriggerFragment,
  Transaction,
  TransactionCategories,
  TransactionStatus,
  TransferDirection,
  TransferType,
  TriggerFragment,
  TriggerInput,
  TriggerTypes,
} from '@/generated/graphql';
import { fromCents, toAmountInCents, toAmountInDollarsFromCents } from '@/helpers/currency';
import { isNil } from '@/helpers/is-nil';
import { ExternalAccount } from '@/hooks/use-external-accounts';
import { accountName } from '@/routes/account/helpers';
import { CardAccountCardV2 } from '@core/mainframe-js-sdk';
import { DateTime } from 'luxon';
import {
  AccountType,
  ActionFormState,
  AmountTypes,
  DepositTransactionConditionsState,
  FrequencyTypes,
  TransactionType,
  TriggerFormState,
} from '../types';

export function isIncomeTrigger(routine: RoutineFragment) {
  if (
    'type' in routine.trigger &&
    routine.trigger.type == TriggerTypes.UserCategory &&
    routine.trigger.__typename == 'UserCategoryTrigger'
  ) {
    return routine.trigger.userCategoryTriggerOptions.userCategory == TransactionCategories.Income;
  }
  return false;
}

export function isCategorizeIncomeAction(routine: RoutineFragment) {
  if (
    'type' in routine.action &&
    routine.action.type == ActionTypes.Categorize &&
    routine.action.__typename == 'CategorizeAction' &&
    !routine.archivedAt
  ) {
    return routine.action.categorizeActionOptions.category == TransactionCategories.Income;
  }
  return false;
}

export function isExternalTransferAction(routine: RoutineFragment) {
  return (
    'type' in routine.action &&
    routine.action.type == ActionTypes.ExternalTransfer &&
    routine.action.__typename == 'ExternalTransferAction' &&
    !routine.archivedAt
  );
}

export function isCategorizeTransaction(description: string) {
  return function (routine: RoutineFragment) {
    if (
      'type' in routine.trigger &&
      'type' in routine.action &&
      routine.trigger.type == TriggerTypes.Description &&
      routine.trigger.__typename == 'DescriptionTrigger' &&
      routine.action.type == ActionTypes.Categorize
    ) {
      return routine.trigger.descriptionTriggerOptions.description == description;
    }
    return false;
  };
}

export function lastRun(routine: RoutineMetadata | RoutineFragment) {
  return routine.lastRunAt ? `Last ran ${DateTime.fromISO(routine.lastRunAt).toRelative()}` : "Hasn't run yet";
}

export function toAccountOptions(depositAccount: DepositAccount) {
  return {
    value: depositAccount.id,
    label: accountName(
      depositAccount.accountType === DepositAccountType.Joint ? 'Joint' : 'Deposit',
      depositAccount.depositAccountNumber.lastFour,
    ),
  };
}

export function toHmbAccountOptions(depositAccount: DepositAccount) {
  return {
    value: depositAccount.id,
    label: `HMBradley ${accountName(
      depositAccount.accountType === DepositAccountType.Joint ? 'Joint' : 'Deposit',
      depositAccount.depositAccountNumber.lastFour,
    )}`,
  };
}

export function toExternalAccountOptions(externalAccount: ExternalAccount) {
  return {
    value: externalAccount.id,
    label:
      externalAccount.externalAccountNickname +
      (externalAccount.availableBalanceInCents !== null
        ? ` (${toAmountInDollarsFromCents(externalAccount.availableBalanceInCents)})`
        : ''),
  };
}

export function toCreditCardsOptions(creditAccountId: string) {
  return (creditCard: CardAccountCardV2) => {
    return {
      value: creditAccountId,
      label: `Credit Card ${creditCard.lastFour}`,
    };
  };
}

export const amountTimeOptions = [
  { value: CreditCardPaymentBalanceTypes.RemainingStatementBalance, label: 'Remaining statement balance' },
  { value: CreditCardPaymentBalanceTypes.CurrentBalance, label: 'Current balance' },
  { value: CreditCardPaymentBalanceTypes.MinimumDue, label: 'Minimum due' },
];

export function toTransactionOptions(transaction: Transaction) {
  return {
    label: transaction.description,
    value: transaction.description,
  };
}

export function toPlanOptions(plan: Plan) {
  return {
    value: plan.id,
    label: plan.title,
  };
}

export function toTransactions(result: { [key: string]: Transaction[] }, depositAccount: DepositAccount) {
  result[depositAccount.id] = depositAccount.transactions;
  return result;
}

export function getValue(props: { value: string; label: string }) {
  return props.value;
}

export function individualFirst(a: Partial<DepositAccount>, b: Partial<DepositAccount>) {
  if (a.accountType == b.accountType) return 0;
  if (a.accountType == DepositAccountType.Individual) return -1;
  return 1;
}

export function isValidTransaction(val: Transaction, index: number, arr: Transaction[]) {
  return (
    val.transferType == TransferType.RdfiTransfer &&
    val.status == TransactionStatus.Posted &&
    arr.findIndex((i) => i.description == val.description) === index
  );
}

export function isTransactionType(val: Transaction) {
  return (
    [
      TransferType.RdfiTransfer,
      TransferType.CoreTransfer,
      TransferType.CashbackRedemptionTransfer,
      TransferType.OdfiTransfer,
    ].includes(val.transferType) && val.status == TransactionStatus.Posted
  );
}

export function byTransactionTrigger(trigger: TriggerFormState) {
  return function (option: { label: string; value: ActionTypes }) {
    return legalTriggerActionCombos(trigger).includes(option.value);
  };
}

export function byOpenedCreditAccount(hasOpenedCreditAccount: boolean) {
  return function (option: { label: string; value: ActionTypes }) {
    if (option.value === ActionTypes.CreditCardPayment && !hasOpenedCreditAccount) return;
    else return option;
  };
}

export function legalTriggerActionCombos(triggerForm?: TriggerFormState) {
  const trigger = triggerForm?.options?.type ?? triggerForm?.trigger;
  if (!trigger) return [];
  return queryBy(trigger, {
    [TriggerTypes.Category]: [
      ActionTypes.Categorize,
      ActionTypes.Notification,
      ActionTypes.PlanTransfer,
      ActionTypes.ExternalTransfer,
      ActionTypes.InternalTransfer,
      ActionTypes.MultiAction,
      ActionTypes.CreditCardPayment,
      ActionTypes.Webhook,
    ],
    [TriggerTypes.DepositTransaction]: [
      ActionTypes.Categorize,
      ActionTypes.Notification,
      ActionTypes.PlanTransfer,
      ActionTypes.ExternalTransfer,
      ActionTypes.InternalTransfer,
      ActionTypes.MultiAction,
      ActionTypes.Webhook,
    ],
    [TriggerTypes.Description]: [
      ActionTypes.Categorize,
      ActionTypes.Notification,
      ActionTypes.PlanTransfer,
      ActionTypes.ExternalTransfer,
      ActionTypes.InternalTransfer,
      ActionTypes.MultiAction,
      ActionTypes.CreditCardPayment,
      ActionTypes.Webhook,
    ],
    [TriggerTypes.UserCategory]: [
      ActionTypes.Notification,
      ActionTypes.PlanTransfer,
      ActionTypes.ExternalTransfer,
      ActionTypes.InternalTransfer,
      ActionTypes.MultiAction,
      ActionTypes.CreditCardPayment,
      ActionTypes.Webhook,
    ],
    [TriggerTypes.Balance]: [
      ActionTypes.Notification,
      ActionTypes.PlanTransfer,
      ActionTypes.ExternalTransfer,
      ActionTypes.InternalTransfer,
      ActionTypes.MultiAction,
      ActionTypes.CreditCardPayment,
      ActionTypes.Webhook,
    ],
    [TriggerTypes.CashbackRedemption]: [
      ActionTypes.Notification,
      ActionTypes.MultiAction,
      ActionTypes.CashbackRedemption,
      ActionTypes.Webhook,
    ],
    [TriggerTypes.Date]: [
      ActionTypes.Notification,
      ActionTypes.PlanTransfer,
      ActionTypes.ExternalTransfer,
      ActionTypes.InternalTransfer,
      ActionTypes.MultiAction,
      ActionTypes.CreditCardPayment,
      ActionTypes.Webhook,
    ],
    undefined: [
      ActionTypes.Categorize,
      ActionTypes.Notification,
      ActionTypes.PlanTransfer,
      ActionTypes.ExternalTransfer,
      ActionTypes.InternalTransfer,
      ActionTypes.MultiAction,
      ActionTypes.CreditCardPayment,
      ActionTypes.Webhook,
    ],
  });
}

export function queryBy<TProps>(attr: string | number, mapping: Record<any, TProps>) {
  if (process.env.NODE_ENV !== 'production') {
    if (!mapping.hasOwnProperty(attr)) {
      throw new Error(`[queryBy] The attribute "${attr}" is not defined in the mapping.`);
    }
  }

  return mapping[attr];
}

export function byTemplateId(id: string) {
  return function (template: RoutineTemplateFragment) {
    return template.id === id;
  };
}

export function byRoutineId(id: string) {
  return function (routine: RoutineFragment) {
    return routine.id === id;
  };
}

export function byNotArchived(routine: RoutineFragment) {
  return !routine.archivedAt;
}

export function cronToFrequency(pattern: string) {
  const patternSplit = pattern.split(' ');
  if (patternSplit.length >= 5 && patternSplit[4] !== '*') {
    const days = patternSplit[4].split(',');
    return { frequency: FrequencyTypes.Weekly, day: days.map((i) => parseInt(i)) };
  }
  if (patternSplit.length >= 3 && patternSplit[2] !== '*') {
    const days = patternSplit[2].split(',');
    return { frequency: FrequencyTypes.Monthly, day: days.map((i) => parseInt(i)) };
  }
  return { frequency: FrequencyTypes.Monthly, day: [1] };
}

export function transformAction(actionForm: ActionFormState): ActionInput {
  if (actionForm.action == ActionTypes.PlanTransfer && actionForm.options.type == ActionTypes.PlanTransfer) {
    const amount = getAmountFromForm(actionForm.options.amountType, actionForm.options.amount);
    if (actionForm.options.direction == TransferDirection.Push) {
      return {
        type: ActionTypes.PlanTransfer,
        planTransferAction: {
          version: '0.01',
          type: ActionTypes.PlanTransfer,
          options: {
            depositAccountId: actionForm.options.depositAccount,
            fromAccountId: actionForm.options.depositAccount,
            fromAccountType: PlanTransferAccountType.Deposit,
            toAccountId: actionForm.options.plan,
            toAccountType: PlanTransferAccountType.Plan,
            ...amount,
          },
        },
      };
    } else {
      return {
        type: ActionTypes.PlanTransfer,
        planTransferAction: {
          version: '0.01',
          type: ActionTypes.PlanTransfer,
          options: {
            depositAccountId: actionForm.options.depositAccount,
            fromAccountId: actionForm.options.plan,
            fromAccountType: PlanTransferAccountType.Plan,
            toAccountId: actionForm.options.depositAccount,
            toAccountType: PlanTransferAccountType.Deposit,
            ...amount,
          },
        },
      };
    }
  }

  if (actionForm.action == ActionTypes.Categorize && actionForm.options.type == ActionTypes.Categorize) {
    return {
      type: ActionTypes.Categorize,
      categorizeAction: {
        version: '0.01',
        type: ActionTypes.Categorize,
        options: {
          depositAccountId: actionForm.options.depositAccount,
          category: actionForm.options.category,
        },
      },
    };
  }

  if (actionForm.action == ActionTypes.Notification && actionForm.options.type == ActionTypes.Notification) {
    return {
      type: ActionTypes.Notification,
      notificationAction: {
        version: '0.01',
        type: ActionTypes.Notification,
        options: {
          sms: actionForm.options.sms,
          push: actionForm.options.push,
          email: actionForm.options.email,
          message: actionForm.options.message,
        },
      },
    };
  }

  if (actionForm.action == ActionTypes.Webhook && actionForm.options.type == ActionTypes.Webhook) {
    return {
      type: ActionTypes.Webhook,
      webhookAction: {
        version: '0.01',
        type: ActionTypes.Webhook,
        options: {
          url: actionForm.options.url,
          name: actionForm.options.webhookName,
          description: actionForm.options.description,
          headers: actionForm.options.headers ?? [],
        },
      },
    };
  }

  if (actionForm.action == ActionTypes.InternalTransfer && actionForm.options.type == ActionTypes.InternalTransfer) {
    const amount = getAmountFromForm(actionForm.options.amountType, actionForm.options.amount);

    return {
      type: ActionTypes.InternalTransfer,
      internalTransferAction: {
        version: '0.01',
        type: ActionTypes.InternalTransfer,
        options: {
          fromDepositAccountId: actionForm.options.fromDepositAccount,
          toDepositAccountId: actionForm.options.toDepositAccount,
          ...amount,
        },
      },
    };
  }

  if (actionForm.action == ActionTypes.ExternalTransfer && actionForm.options.type == ActionTypes.ExternalTransfer) {
    const amount = getAmountFromForm(actionForm.options.amountType, actionForm.options.amount);

    return {
      type: ActionTypes.ExternalTransfer,
      externalTransferAction: {
        version: '0.01',
        type: ActionTypes.ExternalTransfer,
        options: {
          depositAccountId: actionForm.options.depositAccount,
          externalAccountId: actionForm.options.externalAccount,
          type: actionForm.options.direction == TransferDirection.Pull ? OdfiTransferType.Pull : OdfiTransferType.Push,
          ...amount,
        },
      },
    };
  }

  if (actionForm.action == ActionTypes.MultiAction && actionForm.options.type == ActionTypes.MultiAction) {
    return {
      type: ActionTypes.MultiAction,
      multiAction: {
        version: '0.01',
        type: ActionTypes.MultiAction,
        options: {
          actions: actionForm.options.actions.map(transformAction),
        },
      },
    };
  }

  if (
    actionForm.action === ActionTypes.CashbackRedemption &&
    actionForm.options.type === ActionTypes.CashbackRedemption
  ) {
    return {
      type: ActionTypes.CashbackRedemption,
      cashbackRedemptionAction: {
        version: '0.01',
        type: ActionTypes.CashbackRedemption,
        options: {
          depositAccountId:
            actionForm.options.accountType === AccountType.DepositAccount ? actionForm.options.accountId : undefined,
          externalAccountId:
            actionForm.options.accountType === AccountType.ExternalAccount ? actionForm.options.accountId : undefined,
        },
      },
    };
  }

  if (
    actionForm.action === ActionTypes.CreditCardPayment &&
    actionForm.options.type === ActionTypes.CreditCardPayment
  ) {
    return {
      type: ActionTypes.CreditCardPayment,
      creditCardPaymentAction: {
        version: '0.01',
        type: ActionTypes.CreditCardPayment,
        options: {
          creditCardAccountId: actionForm.options.creditCardAccountId,
          fromAccountId: actionForm.options.fromAccountId,
          fromAccountType: actionForm.options.fromAccountType,
          paymentBalanceType: actionForm.options.paymentBalanceType,
        },
      },
    };
  }

  return undefined;
}

function getAmountFromForm(amountType: AmountTypes, amount: number) {
  return amountType == AmountTypes.Amount ? { amountInCents: toAmountInCents(amount) } : { percentage: amount / 100 };
}

export function formToCron(opts: { frequency: FrequencyTypes; day: number[] }): string {
  return queryBy(opts.frequency, {
    [FrequencyTypes.Weekly]: `0 16 * * ${opts.day.join(',')}`,
    [FrequencyTypes.Monthly]: `0 16 ${opts.day.join(',')} * *`,
  });
}

function translateFormConditions(conditions: DepositTransactionConditionsState): DepositTransactionConditions {
  return {
    greaterThan: !isNil(conditions.greaterThan) ? toAmountInCents(conditions.greaterThan) : undefined,
    lessThan: !isNil(conditions.lessThan) ? toAmountInCents(conditions.lessThan) : undefined,
    partialMatch: conditions.partialMatch,
    exactMatch: conditions.exactMatch,
    category: conditions.category,
    isDirectDeposit: conditions.transactionType == TransactionType.DirectDeposit ? true : undefined,
    direction: queryBy(conditions.transactionType, {
      [TransactionType.Deposit]: TransferDirection.Pull,
      [TransactionType.DirectDeposit]: TransferDirection.Pull,
      [TransactionType.Withdrawal]: TransferDirection.Push,
      undefined: undefined,
    }),
  };
}

export function getConsentsFromAction(action: ActionFormState): Consents[] {
  if (
    (action.action == ActionTypes.ExternalTransfer && action.options.type == ActionTypes.ExternalTransfer) ||
    (action.action == ActionTypes.CreditCardPayment && action.options.type == ActionTypes.CreditCardPayment)
  ) {
    return action.options.consents;
  }
  if (action.action == ActionTypes.MultiAction && action.options.type == ActionTypes.MultiAction) {
    return action.options.actions.reduce((acc, action) => [...acc, ...getConsentsFromAction(action)], []);
  }
  return [];
}

export function transformTrigger(triggerForm: TriggerFormState): TriggerInput {
  if (
    triggerForm.trigger == TriggerTypes.DepositTransaction &&
    triggerForm.options.type == TriggerTypes.DepositTransaction
  ) {
    return {
      type: TriggerTypes.DepositTransaction,
      depositTransactionTrigger: {
        version: '0.01',
        type: TriggerTypes.DepositTransaction,
        options: {
          depositAccountId: triggerForm.options.depositAccount,
          conditions: translateFormConditions(triggerForm.options.conditions),
        },
      },
    };
  }
  if (triggerForm.trigger == TriggerTypes.Category && triggerForm.options.type == TriggerTypes.Category) {
    return {
      type: TriggerTypes.Category,
      categoryTrigger: {
        version: '0.01',
        type: TriggerTypes.Category,
        options: {
          depositAccountId: triggerForm.options.account,
          category: triggerForm.options.category,
        },
      },
    };
  }
  if (triggerForm.trigger == TriggerTypes.Balance && triggerForm.options.type == TriggerTypes.Balance) {
    return {
      type: TriggerTypes.Balance,
      balanceTrigger: {
        version: '0.01',
        type: TriggerTypes.Balance,
        options: {
          amountInCents: toAmountInCents(triggerForm.options.amount),
          depositAccountId: triggerForm.options.account,
          comparison: triggerForm.options.comparison,
        },
      },
    };
  }

  if (triggerForm.trigger == TriggerTypes.Balance && triggerForm.options.type == TriggerTypes.CashbackRedemption) {
    return {
      type: TriggerTypes.CashbackRedemption,
      cashbackRedemptionTrigger: {
        version: '0.01',
        type: TriggerTypes.CashbackRedemption,
        options: {
          amount: toAmountInCents(triggerForm.options.amount),
          accountId: triggerForm.options.account,
        },
      },
    };
  }
  if (triggerForm.trigger == TriggerTypes.Description && triggerForm.options.type == TriggerTypes.Description) {
    return {
      type: TriggerTypes.Description,
      descriptionTrigger: {
        version: '0.01',
        type: TriggerTypes.Description,
        options: {
          depositAccountId: triggerForm.options.account,
          description: triggerForm.options.description,
        },
      },
    };
  }
  if (triggerForm.trigger == TriggerTypes.Date && triggerForm.options.type == TriggerTypes.Date) {
    return {
      type: TriggerTypes.Date,
      dateTrigger: {
        version: '0.01',
        type: TriggerTypes.Date,
        options: {
          pattern: formToCron(triggerForm.options),
        },
      },
    };
  }
  if (triggerForm.trigger == TriggerTypes.UserCategory && triggerForm.options.type == TriggerTypes.UserCategory) {
    return {
      type: TriggerTypes.UserCategory,
      userCategoryTrigger: {
        version: '0.01',
        type: TriggerTypes.UserCategory,
        options: {
          depositAccountId: triggerForm.options.account,
          userCategory: triggerForm.options.userCategory,
        },
      },
    };
  }
  return undefined;
}

function getTransactionType(conditions: DepositTransactionConditions) {
  if (conditions.isDirectDeposit) return TransactionType.DirectDeposit;
  return queryBy(conditions.direction, {
    [TransferDirection.Pull]: TransactionType.Deposit,
    [TransferDirection.Push]: TransactionType.Withdrawal,
    null: undefined,
  });
}

function translateConditions(conditions: DepositTransactionConditions): DepositTransactionConditionsState {
  return {
    greaterThan: !isNil(conditions.greaterThan) ? fromCents(conditions.greaterThan) : undefined,
    lessThan: !isNil(conditions.lessThan) ? fromCents(conditions.lessThan) : undefined,
    partialMatch: conditions.partialMatch,
    exactMatch: conditions.exactMatch,
    category: conditions.category,
    transactionType: getTransactionType(conditions),
  };
}

export function triggerToForm(trigger: TriggerFragment): TriggerFormState {
  if (!trigger || !('type' in trigger)) return undefined;
  if (trigger.type == TriggerTypes.DepositTransaction && trigger.__typename == 'DepositTransactionTrigger') {
    return {
      trigger: TriggerTypes.DepositTransaction,
      options: {
        type: TriggerTypes.DepositTransaction,
        depositAccount: trigger.depositTransactionTriggerOptions.depositAccountId,
        conditions: translateConditions(trigger.depositTransactionTriggerOptions.conditions),
      },
    };
  }
  if (trigger.type == TriggerTypes.Category && trigger.__typename == 'CategoryTrigger') {
    return {
      trigger: TriggerTypes.Category,
      options: {
        type: TriggerTypes.Category,
        account: trigger.categoryTriggerOptions.depositAccountId,
        category: trigger.categoryTriggerOptions.category,
      },
    };
  }
  if (trigger.type == TriggerTypes.UserCategory && trigger.__typename == 'UserCategoryTrigger') {
    return {
      trigger: TriggerTypes.UserCategory,
      options: {
        type: TriggerTypes.UserCategory,
        account: trigger.userCategoryTriggerOptions.depositAccountId,
        userCategory: trigger.userCategoryTriggerOptions.userCategory,
      },
    };
  }
  if (trigger.type == TriggerTypes.Balance && trigger.__typename == 'BalanceTrigger') {
    return {
      trigger: TriggerTypes.Balance,
      options: {
        type: TriggerTypes.Balance,
        account: trigger.balanceTriggerOptions.depositAccountId,
        amount: fromCents(trigger.balanceTriggerOptions.amountInCents),
        comparison: trigger.balanceTriggerOptions.comparison,
      },
    };
  }
  if (trigger.type == TriggerTypes.Description && trigger.__typename == 'DescriptionTrigger') {
    return {
      trigger: TriggerTypes.Description,
      options: {
        type: TriggerTypes.Description,
        account: trigger.descriptionTriggerOptions.depositAccountId,
        description: trigger.descriptionTriggerOptions.description,
      },
    };
  }
  if (trigger.type == TriggerTypes.Date && trigger.__typename == 'DateTrigger') {
    return {
      trigger: TriggerTypes.Date,
      options: {
        type: TriggerTypes.Date,
        ...cronToFrequency(trigger.dateTriggerOptions.pattern),
      },
    };
  }
  if (trigger.type == TriggerTypes.CashbackRedemption && trigger.__typename == 'CashbackRedemptionTrigger') {
    return {
      trigger: TriggerTypes.Balance,
      options: {
        type: TriggerTypes.CashbackRedemption,
        amount: fromCents(trigger.cashbackRedemptionTriggerOptions.amount),
        comparison: ComparisonTypes.Gt,
        account: trigger.cashbackRedemptionTriggerOptions.accountId,
      },
    };
  }
}

export function actionToForm(action: ActionFragment): ActionFormState {
  if (!action) return undefined;
  if (!action || !('type' in action)) return undefined;
  if (action.type == ActionTypes.PlanTransfer && action.__typename == 'PlanTransferAction') {
    if (action.planTransferActionOptions.toAccountType == PlanTransferAccountType.Plan) {
      return {
        action: ActionTypes.PlanTransfer,
        options: {
          type: ActionTypes.PlanTransfer,
          direction: TransferDirection.Push,
          depositAccount: action.planTransferActionOptions.depositAccountId,
          plan: action.planTransferActionOptions.toAccountId,
          amountType: action.planTransferActionOptions.amountInCents ? AmountTypes.Amount : AmountTypes.Percentage,
          amount: action.planTransferActionOptions.amountInCents
            ? fromCents(action.planTransferActionOptions.amountInCents)
            : action.planTransferActionOptions.percentage * 100,
        },
      };
    } else {
      return {
        action: ActionTypes.PlanTransfer,
        options: {
          type: ActionTypes.PlanTransfer,
          direction: TransferDirection.Pull,
          depositAccount: action.planTransferActionOptions.depositAccountId,
          plan: action.planTransferActionOptions.fromAccountId,
          amountType: action.planTransferActionOptions.amountInCents ? AmountTypes.Amount : AmountTypes.Percentage,
          amount: action.planTransferActionOptions.amountInCents
            ? fromCents(action.planTransferActionOptions.amountInCents)
            : action.planTransferActionOptions.percentage * 100,
        },
      };
    }
  }
  if (action.type == ActionTypes.ExternalTransfer && action.__typename == 'ExternalTransferAction') {
    return {
      action: ActionTypes.ExternalTransfer,
      options: {
        type: ActionTypes.ExternalTransfer,
        consents: [],
        direction:
          action.externalTransferAction.type == OdfiTransferType.Push ? TransferDirection.Push : TransferDirection.Pull,
        depositAccount: action.externalTransferAction.depositAccountId,
        externalAccount: action.externalTransferAction.externalAccountId,
        amountType: action.externalTransferAction.amountInCents ? AmountTypes.Amount : AmountTypes.Percentage,
        amount: action.externalTransferAction.amountInCents
          ? fromCents(action.externalTransferAction.amountInCents)
          : action.externalTransferAction.percentage * 100,
      },
    };
  }
  if (action.type == ActionTypes.InternalTransfer && action.__typename == 'InternalTransferAction') {
    return {
      action: ActionTypes.InternalTransfer,
      options: {
        type: ActionTypes.InternalTransfer,
        fromDepositAccount: action.internalTransferActionOptions.fromDepositAccountId,
        toDepositAccount: action.internalTransferActionOptions.toDepositAccountId,
        amountType: action.internalTransferActionOptions.amountInCents ? AmountTypes.Amount : AmountTypes.Percentage,
        amount: action.internalTransferActionOptions.amountInCents
          ? fromCents(action.internalTransferActionOptions.amountInCents)
          : action.internalTransferActionOptions.percentage * 100,
      },
    };
  }
  if (action.type == ActionTypes.Categorize && action.__typename == 'CategorizeAction') {
    return {
      action: ActionTypes.Categorize,
      options: {
        type: ActionTypes.Categorize,
        depositAccount: action.categorizeActionOptions.depositAccountId,
        category: action.categorizeActionOptions.category,
      },
    };
  }
  if (action.type == ActionTypes.Notification && action.__typename == 'NotificationAction') {
    return {
      action: ActionTypes.Notification,
      options: {
        type: ActionTypes.Notification,
        sms: action.notificationActionOptions.sms,
        push: action.notificationActionOptions.push,
        email: action.notificationActionOptions.email,
        message: action.notificationActionOptions.message,
      },
    };
  }
  if (action.type == ActionTypes.Webhook && action.__typename == 'WebhookAction') {
    return {
      action: ActionTypes.Webhook,
      options: {
        type: ActionTypes.Webhook,
        description: action.webhookActionOptions.description,
        url: action.webhookActionOptions.url,
        webhookName: action.webhookActionOptions.name,
        headers: action.webhookActionOptions.webhookHeaders ?? [],
      },
    };
  }
  if (action.type == ActionTypes.MultiAction && action.__typename == 'MultiAction') {
    return {
      action: ActionTypes.MultiAction,
      options: {
        type: ActionTypes.MultiAction,
        actions: action.options.actions.map(actionToForm),
      },
    };
  }
  if (action.type == ActionTypes.CashbackRedemption && action.__typename == 'CashbackRedemptionAction') {
    return {
      action: ActionTypes.CashbackRedemption,
      options: {
        type: ActionTypes.CashbackRedemption,
        accountId:
          action.cashbackRedemptionActionOptions.depositAccountId ||
          action.cashbackRedemptionActionOptions.externalAccountId,
        accountType: action.cashbackRedemptionActionOptions.depositAccountId
          ? AccountType.DepositAccount
          : AccountType.ExternalAccount,
      },
    };
  }
  if (action.type == ActionTypes.CreditCardPayment && action.__typename == 'CreditCardPaymentAction') {
    return {
      action: ActionTypes.CreditCardPayment,
      options: {
        type: ActionTypes.CreditCardPayment,
        paymentBalanceType: action.creditCardPaymentActionOptions.paymentBalanceType,
        fromAccountType: action.creditCardPaymentActionOptions.fromAccountType,
        fromAccountId: action.creditCardPaymentActionOptions.fromAccountId,
        creditCardAccountId: action.creditCardPaymentActionOptions.creditCardAccountId,
        consents: [],
      },
    };
  }
}

export function templateTriggerToForm(
  trigger: TemplateTriggerFragment,
  options?: { depositAccountId?: string; creditAccountId?: string },
): TriggerFormState {
  if (!trigger || !('type' in trigger)) return undefined;
  if (trigger.type == TriggerTypes.DepositTransaction && trigger.__typename == 'DepositTransactionTrigger') {
    return {
      trigger: TriggerTypes.DepositTransaction,
      options: {
        type: TriggerTypes.DepositTransaction,
        depositAccount: options?.depositAccountId,
        conditions: translateConditions(trigger.options.conditions),
      },
    };
  }
  if (trigger.type == TriggerTypes.Category && trigger.__typename == 'CategoryTrigger') {
    return {
      trigger: TriggerTypes.Category,
      options: {
        type: TriggerTypes.Category,
        account: options?.depositAccountId,
        category: trigger.options.category,
      },
    };
  }
  if (trigger.type == TriggerTypes.UserCategory && trigger.__typename == 'UserCategoryTrigger') {
    return {
      trigger: TriggerTypes.UserCategory,
      options: {
        type: TriggerTypes.UserCategory,
        account: options?.depositAccountId,
        userCategory: trigger.options.userCategory,
      },
    };
  }
  if (trigger.type == TriggerTypes.Balance && trigger.__typename == 'BalanceTrigger') {
    return {
      trigger: TriggerTypes.Balance,
      options: {
        type: TriggerTypes.Balance,
        account: options?.depositAccountId,
        amount: fromCents(trigger.options.amountInCents),
        comparison: trigger.options.comparison,
      },
    };
  }
  if (trigger.type == TriggerTypes.Description && trigger.__typename == 'DescriptionTrigger') {
    return {
      trigger: TriggerTypes.Description,
      options: {
        type: TriggerTypes.Description,
        account: options?.depositAccountId,
        description: trigger.options.description,
      },
    };
  }
  if (trigger.type == TriggerTypes.Date && trigger.__typename == 'DateTrigger') {
    return {
      trigger: TriggerTypes.Date,
      options: {
        type: TriggerTypes.Date,
        ...cronToFrequency(trigger.options.pattern),
      },
    };
  }
  if (trigger.type == TriggerTypes.CashbackRedemption && trigger.__typename == 'CashbackRedemptionTrigger') {
    return {
      trigger: TriggerTypes.Balance,
      options: {
        type: TriggerTypes.CashbackRedemption,
        amount: fromCents(trigger.options.amount),
        comparison: ComparisonTypes.Gt,
        account: options?.creditAccountId,
      },
    };
  }
}

export function templateActionToForm(action: TemplateActionFragment, account?: string): ActionFormState {
  if (!action || !('type' in action)) return undefined;
  if (action.type == ActionTypes.PlanTransfer && action.__typename == 'PlanTransferAction') {
    if (action.planTransferActionOptions.toAccountType == PlanTransferAccountType.Plan) {
      return {
        action: ActionTypes.PlanTransfer,
        options: {
          type: ActionTypes.PlanTransfer,
          direction: TransferDirection.Push,
          depositAccount: account,
          plan: undefined,
          amountType: action.planTransferActionOptions.amountInCents ? AmountTypes.Amount : AmountTypes.Percentage,
          amount: action.planTransferActionOptions.amountInCents
            ? fromCents(action.planTransferActionOptions.amountInCents)
            : action.planTransferActionOptions.percentage * 100,
        },
      };
    } else {
      return {
        action: ActionTypes.PlanTransfer,
        options: {
          type: ActionTypes.PlanTransfer,
          direction: TransferDirection.Pull,
          depositAccount: account,
          plan: undefined,
          amountType: action.planTransferActionOptions.amountInCents ? AmountTypes.Amount : AmountTypes.Percentage,
          amount: action.planTransferActionOptions.amountInCents
            ? fromCents(action.planTransferActionOptions.amountInCents)
            : action.planTransferActionOptions.percentage * 100,
        },
      };
    }
  }
  if (action.type == ActionTypes.ExternalTransfer && action.__typename == 'ExternalTransferAction') {
    return {
      action: ActionTypes.ExternalTransfer,
      options: {
        type: ActionTypes.ExternalTransfer,
        consents: [],
        direction:
          action.externalTransferAction.type == OdfiTransferType.Push ? TransferDirection.Push : TransferDirection.Pull,
        depositAccount: account,
        externalAccount: undefined,
        amountType: action.externalTransferAction.amountInCents ? AmountTypes.Amount : AmountTypes.Percentage,
        amount: action.externalTransferAction.amountInCents
          ? fromCents(action.externalTransferAction.amountInCents)
          : action.externalTransferAction.percentage * 100,
      },
    };
  }
  if (action.type == ActionTypes.InternalTransfer && action.__typename == 'InternalTransferAction') {
    return {
      action: ActionTypes.InternalTransfer,
      options: {
        type: ActionTypes.InternalTransfer,
        fromDepositAccount: account,
        toDepositAccount: undefined,
        amountType: action.internalTransferActionOptions.amountInCents ? AmountTypes.Amount : AmountTypes.Percentage,
        amount: action.internalTransferActionOptions.amountInCents
          ? fromCents(action.internalTransferActionOptions.amountInCents)
          : action.internalTransferActionOptions.percentage * 100,
      },
    };
  }
  if (action.type == ActionTypes.Categorize && action.__typename == 'CategorizeAction') {
    return {
      action: ActionTypes.Categorize,
      options: {
        type: ActionTypes.Categorize,
        depositAccount: account,
        category: action.categorizeActionOptions.category,
      },
    };
  }
  if (action.type == ActionTypes.Webhook && action.__typename == 'WebhookAction') {
    return {
      action: ActionTypes.Webhook,
      options: {
        type: ActionTypes.Webhook,
        url: action.webhookActionOptions.url,
        webhookName: action.webhookActionOptions.name,
        description: action.webhookActionOptions.description,
        headers: action.webhookActionOptions.webhookHeaders ?? [],
      },
    };
  }
  if (action.type == ActionTypes.Notification && action.__typename == 'NotificationAction') {
    return {
      action: ActionTypes.Notification,
      options: {
        type: ActionTypes.Notification,
        sms: action.notificationActionOptions.sms,
        push: action.notificationActionOptions.push,
        email: action.notificationActionOptions.email,
        message: action.notificationActionOptions.message,
      },
    };
  }
  if (action.type == ActionTypes.MultiAction && action.__typename == 'MultiAction') {
    return {
      action: ActionTypes.MultiAction,
      options: {
        type: ActionTypes.MultiAction,
        actions: action.options.actions.map((action) => templateActionToForm(action, account)),
      },
    };
  }
  if (action.type == ActionTypes.CashbackRedemption && action.__typename == 'CashbackRedemptionAction') {
    return {
      action: ActionTypes.CashbackRedemption,
      options: {
        type: ActionTypes.CashbackRedemption,
        accountId: account,
        accountType: AccountType.DepositAccount,
      },
    };
  }
  if (action.type === ActionTypes.CreditCardPayment && action.__typename === 'CreditCardPaymentAction') {
    return {
      action: ActionTypes.CreditCardPayment,
      options: {
        type: ActionTypes.CreditCardPayment,
        paymentBalanceType: action.creditCardPaymentActionOptions.paymentBalanceType,
        fromAccountType: action.creditCardPaymentActionOptions.fromAccountTypeEnum,
        fromAccountId: action.creditCardPaymentActionOptions.fromAccountId,
        creditCardAccountId: action.creditCardPaymentActionOptions.creditCardAccountId,
        consents: [],
      },
    };
  }
}
