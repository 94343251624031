import '@/i18n';
import '@/polyfills';

import { AnalyticsPageTrack } from '@/components/analytics-page-track';
import { ApiStatusProvider } from '@/components/api-status-provider';
import { ExternalWebsitePopup } from '@/components/external-website-popup';
import { getDashboardPageLayout } from '@/components/page-layouts';
import { ReactQueryProvider } from '@/components/react-query-provider';
import { RouterSpinner } from '@/components/router-spinner';
import { SafeHydrate } from '@/components/safe-hydrate';
import { SegmentAnalytics } from '@/components/segment-analytics';
import { SessionProvider } from '@/components/session-provider';
import { FEATURE_FLAG_CONFIG, NEXT_PUBLIC_SEGMENT_CDN_URL, NEXT_PUBLIC_SEGMENT_WRITE_KEY } from '@/constants';
import { NextPageWithLayout } from '@/types/next';
import { onBrowser } from '@straw-hat/is-ssr';
import type { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import * as React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { FlagsProvider } from 'react-unleash-flags';

if (process.env.NODE_ENV === 'development') {
  require('./msw-mocks');
}

if (process.env.NODE_ENV !== 'production') {
  onBrowser(() => {
    // TODO: Add axe back
    // When we enable the inject (either in CRA or NextJS) it is adding a lot
    // of time-to-interaction for some reason
    // const ReactDOM = require('react-dom');
    // const React = require('react');
    // const axe = require('@axe-core/react');
    // axe(React, ReactDOM, 500);
  });
}

type AppProps = NextAppProps & {
  Component: NextPageWithLayout;
};

export function App(props: AppProps) {
  const getLayout = props.Component.getPageLayout ?? getDashboardPageLayout;

  return (
    <SafeHydrate fallback={<RouterSpinner />}>
      <Head>
        <title>HMBradley | Secure Online Banking</title>
        <meta charSet="utf-8" />
        <meta name="apple-itunes-app" content="app-id=1513277120" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />

        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="A bank account that helps you save your way to 3% APY. HMBradley rewards you for how you spend
        and how you save - not by how many zeros are in your account."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
        <meta property="og:title" content="HMBradley – Banking designed to help you save more." />
        <meta
          property="og:description"
          content="HMBradley rewards you for how you spend and how you save – not by
      how many zeros are in your account. With one, simple account that earns interest like a traditional savings
      account, you can start working towards interest rates as high as 3% APY when you set up direct deposit."
        />
        <meta property="og:image" content="/fb.png" />
        <meta name="twitter:title" content="HMBradley – Banking designed to help you save more." />
        <meta
          name="twitter:description"
          content="HMBradley rewards you for how you spend and how you save – not by
      how many zeros are in your account. With one, simple account that earns interest like a traditional savings
      account, you can start working towards interest rates as high as 3% APY when you set up direct deposit."
        />
        <meta name="twitter:image" content="/twitter.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      <ExternalWebsitePopup />
      <FlagsProvider config={FEATURE_FLAG_CONFIG}>
        <ReactQueryProvider>
          <ReactQueryDevtools />
          <React.Suspense fallback={<RouterSpinner />}>
            <SegmentAnalytics cdnUrl={NEXT_PUBLIC_SEGMENT_CDN_URL} writeKey={NEXT_PUBLIC_SEGMENT_WRITE_KEY}>
              <AnalyticsPageTrack />
              <ApiStatusProvider>
                <SessionProvider>
                  {/* @ts-ignore Workaround for https://github.com/vercel/next.js/issues/8592 */}
                  {getLayout(<props.Component {...props.pageProps} err={props.err} />)}
                </SessionProvider>
              </ApiStatusProvider>
            </SegmentAnalytics>
          </React.Suspense>
        </ReactQueryProvider>
      </FlagsProvider>
    </SafeHydrate>
  );
}
