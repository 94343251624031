import { createClientId } from '@/helpers';

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const SSN_ITIN_REGEX = new RegExp(/^\d{3}-\d{2}-\d{4}$/);

export const FEATURE_FLAG_CONFIG = {
  appName: process.env.NEXT_PUBLIC_FLAGS_CTX_APP_NAME,
  instanceId: process.env.NEXT_PUBLIC_FLAGS_CTX_INSTANCE_ID,
  host: process.env.NEXT_PUBLIC_FLAGS_CTX_URL,
};

export const NEXT_PUBLIC_SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
export const NEXT_PUBLIC_SEGMENT_CDN_URL = process.env.NEXT_PUBLIC_SEGMENT_CDN_URL;
export const NEXT_PUBLIC_CIO_SITE_ID = process.env.NEXT_PUBLIC_CIO_SITE_ID;
export const NEXT_PUBLIC_CIO_ORG_ID = process.env.NEXT_PUBLIC_CIO_ORG_ID;

export const SMARTY_STREETS_VALIDATION_URL = 'https://us-street.api.smartystreets.com/street-address';

export const SMARTY_STREETS_KEY_PARAM = `key=${process.env.NEXT_PUBLIC_SMARTY_STREETS_KEY}`;
export const FED_HMBRADLEY_WIRE_SEARCH_URL = 'https://fed.hmbradley.com/fed/wire/search';

export enum HMBradleyTimeZones {
  CreditCardPaymentCutOff = 'America/New_York',
  CreditCardLedger = 'America/Chicago',
  DepositLedger = 'America/Los_Angeles',
}

export const ROUTINES_TOU_TAG = 'nycb-routines';
export const COMPLIANCE_OCC_TAG = 'oneclick';
export const COMPLIANCE_POLICIES_CREDIT_TAG = 'credit';
export const COMPLIANCE_POLICIES_EULA_TAG = 'credit';
export const NYCB_DEPOSIT_ACCOUNT_TAG = 'nycb-depositaccount';
export const DEPOSIT_ACCOUNT_TAG = 'depositaccount';
export const ONBOARDING_TAG = 'onboarding';
export const NYCB_SWITCH_TAG = 'nycb-switch';
export const NYCB_PII_TAG = 'nycb-pii';
export const NYCB_TAX_TAG = 'nycb-tax';

export const HTTP_UNAUTHORIZED = 401;
export const HTTP_FORBIDDEN = 403;

const RELEASE = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ?? 'unknown';

export const HMB_CLIENT_ID = createClientId({
  name: 'Pronto',
  release: RELEASE,
  environment: process.env.NEXT_PUBLIC_ENV,
});

/**
 * TODO: Improve fullstory integration
 * NOTE: .fs-exclude is replacing the now deprecated (but still currently
 * supported) CSS class .fs-block. Similarly, .fs-exclude-without-consent is
 * replacing the now deprecated (but still currently supported) CSS class
 * .fs-record-with-consent. Only users with FullStory Private by Default will
 * need to use .fs-unmask to completely unmask elements.
 */
export const FULLSTORY_EXCLUDE = 'fs-exclude';
export const FULLSTORY_MASK = 'fs-mask';
export const FULLSTORY_UNMASK = 'fs-unmask';

export const PATRIOT_ACT_POLICY_NAME = 'PATRIOTACT';
export const NYCB_TAX_POLICY_NAME = 'NYCB_TAX';
export const NYCB_PN_POLICY_NAME = 'NYCB_PN';

export const HMB_FUSION_AUTH_START_LOGIN = `${process.env.NEXT_PUBLIC_MAINFRAME_API_PATH}/registrar/webauthn/start`;
export const HMB_FUSION_AUTH_COMPLETE_LOGIN = `${process.env.NEXT_PUBLIC_MAINFRAME_API_PATH}/registrar/webauthn/login`;
export const HMB_FUSION_AUTH_START_ENROLL = `${process.env.NEXT_PUBLIC_MAINFRAME_API_PATH}/registrar/webauthn/register/start`;
export const HMB_FUSION_AUTH_COMPLETE_ENROLL = `${process.env.NEXT_PUBLIC_MAINFRAME_API_PATH}/registrar/webauthn/register/complete`;
