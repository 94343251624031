import * as React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { DatePickerComponent, DatePickTextFieldProps } from '../date-picker';

type ControlledDatePickerProp<T> = Omit<ControllerProps<T>, 'render'> &
  Omit<DatePickTextFieldProps, 'onChange' | 'onBlur' | 'value' | 'error' | 'ref'> & {
    value: any;
    error?: string;
  };

export function ControlledDatePicker<T>({
  control,
  name,
  defaultValue,
  ...datePickerProps
}: ControlledDatePickerProp<T>) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <DatePickerComponent
          name={name}
          ref={field.ref}
          onChange={(e) => field.onChange(e)}
          onBlur={field.onBlur}
          {...datePickerProps}
        />
      )}
    />
  );
}
