import { useRouter } from 'next/router';
import * as React from 'react';
import { QueryClient } from 'react-query';

import { HTTP_FORBIDDEN, HTTP_UNAUTHORIZED } from '@/constants';
import { PATH_LOGIN } from '@/constants/routes';
import { FetcherError } from '@core/mainframe-js-sdk';

export function useReactQueryClient() {
  const router = useRouter();
  const [queryClient] = React.useState(function () {
    return new QueryClient({
      defaultOptions: {
        queries: {
          retryOnMount: false,
          retry: false,
          async onError(err: FetcherError) {
            if (err.status === HTTP_UNAUTHORIZED) {
              // TODO: Enable this once we figure out the redirection issue
              // await router.replace({ pathname: CLIENT_LOGIN_PATH, query: { referrer: router.asPath } });

              await router.replace({ pathname: PATH_LOGIN });

              // NOTE: the problem is the /login pages since it needs to be
              // refactored because it keeps redirecting back. We should follow
              // up on this once the /login is refactored and make sure the issue
              // persist.
              return window.location.reload();
            }

            if (err.status === HTTP_FORBIDDEN) {
              return router.replace(PATH_LOGIN);
            }
          },
        },
      },
    });
  });

  return queryClient;
}
