import { CreatePlanDrawerContext } from '@/routes/account/components/create-plan-drawer/contexts/create-plan-drawer-context';
import React from 'react';

export function useCreatePlanDrawerContext() {
  const value = React.useContext(CreatePlanDrawerContext);

  if (value === undefined) {
    throw new Error('CreatePlanDrawerContext is undefined');
  }

  return value;
}
