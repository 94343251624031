import {
  ACCEPTED_JOINT_INIVTE_STORAGE_KEY,
  FORWARD_USER_TO_DEPOSIT_APPLICATION_LOCAL_STORAGE_KEY,
  TRUSTED_USER_LOCAL_STORAGE_KEY,
  WEB_AUTHN_USERS_LOCAL_STORAGE_KEY,
} from '@/constants/app';

/** @public */
export function setLocalStorageItem<TValue = unknown>(key: string, value: TValue) {
  return window.localStorage.setItem(key, JSON.stringify(value));
}

/** @public */
export function removeLocalStorageItem(key: string) {
  return window.localStorage.removeItem(key);
}

/** @public */
export function getLocalStorageItem<TValue = unknown>(key: string): TValue | null {
  return JSON.parse(window.localStorage.getItem(key));
}

function makeWebAuthnStorage<TValue = { users: string[]; skipUsers: string[] }>() {
  return {
    set(value: TValue) {
      return setLocalStorageItem(WEB_AUTHN_USERS_LOCAL_STORAGE_KEY, value);
    },
    get() {
      return getLocalStorageItem<TValue>(WEB_AUTHN_USERS_LOCAL_STORAGE_KEY);
    },
    addSkipUser(user: string) {
      const skipUsers = this.get()?.skipUsers ?? [];
      if (!skipUsers.includes(user)) {
        return this.set({ ...this.get(), skipUsers: [...skipUsers, user] });
      }
    },
    addAuthUser(user: string) {
      const users = this.get()?.users ?? [];
      if (!users.includes(user)) {
        return this.set({ ...this.get(), users: [...users, user] });
      }
    },
    isRegistered(user: string) {
      const users = this.get()?.users ?? [];
      return users.includes(user);
    },
    isSkipped(user: string) {
      const skipUsers = this.get()?.skipUsers ?? [];
      return skipUsers.includes(user);
    },
    remove() {
      return removeLocalStorageItem(WEB_AUTHN_USERS_LOCAL_STORAGE_KEY);
    },
  };
}

export const webAuthnUserStorage = makeWebAuthnStorage();

function makeTrustedUserStorage<
  TValue = { username: string; rememberMe: boolean; twoFactorTrustID?: string; webAuthnUser?: string },
>() {
  return {
    set(value: TValue) {
      return setLocalStorageItem(TRUSTED_USER_LOCAL_STORAGE_KEY, value);
    },
    get() {
      return getLocalStorageItem<TValue>(TRUSTED_USER_LOCAL_STORAGE_KEY);
    },
    merge(value: Partial<TValue>) {
      return this.set({ ...this.get(), ...value });
    },
    remove() {
      return removeLocalStorageItem(TRUSTED_USER_LOCAL_STORAGE_KEY);
    },
  };
}

export const trustedUserStorage = makeTrustedUserStorage();

function makeForwardToDepositApplicationStorage<TValue = { enabled: boolean }>() {
  return {
    set(value: TValue) {
      return setLocalStorageItem(FORWARD_USER_TO_DEPOSIT_APPLICATION_LOCAL_STORAGE_KEY, value);
    },
    get() {
      return getLocalStorageItem<TValue>(FORWARD_USER_TO_DEPOSIT_APPLICATION_LOCAL_STORAGE_KEY);
    },
    remove() {
      return removeLocalStorageItem(FORWARD_USER_TO_DEPOSIT_APPLICATION_LOCAL_STORAGE_KEY);
    },
  };
}

export const forwardToDepositApplicationStorage = makeForwardToDepositApplicationStorage();

function makeAcceptJointInviteStorage<TValue = string>() {
  return {
    set(value: TValue) {
      return setLocalStorageItem(ACCEPTED_JOINT_INIVTE_STORAGE_KEY, value);
    },
    get() {
      return getLocalStorageItem<TValue>(ACCEPTED_JOINT_INIVTE_STORAGE_KEY);
    },
    remove() {
      return removeLocalStorageItem(ACCEPTED_JOINT_INIVTE_STORAGE_KEY);
    },
  };
}

export const acceptJointInviteStorage = makeAcceptJointInviteStorage();
