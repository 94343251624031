import { CreatePlanDrawer } from '@/routes/account/components/create-plan-drawer';
import { CreatePlanDrawerContext } from '@/routes/account/components/create-plan-drawer/contexts/create-plan-drawer-context';
import { useCreatePlanDrawer } from '@/routes/account/components/create-plan-drawer/hooks/use-create-plan-drawer';
import * as React from 'react';

export function CreatePlanDrawerProvider(props: React.PropsWithChildren<{}>) {
  const controller = useCreatePlanDrawer();

  return (
    <CreatePlanDrawerContext.Provider value={controller}>
      <CreatePlanDrawer onClose={controller.closeCreatePlanDrawer} isOpen={controller.isOpen} />
      {props.children}
    </CreatePlanDrawerContext.Provider>
  );
}
