import { MEDIUM, Spinner } from '@/components/loaders/spinner';
import COLORS from '@/styles/colors';
import clsx from 'clsx';
import React from 'react';

export function Button(props: {
  isDisabled?: boolean;
  isLoading?: boolean;
  label: string;
  onClick: () => void;
  className?: string;
  loadingClassName?: string;
}) {
  return (
    <button
      type="button"
      className={clsx(
        'sm:w-fit relative overflow-hidden outline-none w-full text-center text-base font-medium px-6 border-2 rounded-md text-gray-100',
        {
          'bg-blue border-blue': !props.isDisabled,
          'bg-gray-400 border-gray-400': props.isDisabled,
        },
        props.className,
      )}
      disabled={props.isDisabled || props.isLoading}
      onClick={props.onClick}
    >
      {props.isLoading && (
        <div
          className={clsx(
            'absolute flex justify-center items-center top-0 left-0 w-full h-full bg-blue z-10',
            props.loadingClassName,
          )}
        >
          <Spinner size={MEDIUM} color={COLORS.white} />
        </div>
      )}
      {props.label}
    </button>
  );
}

export function TransparentButton(props: { label: string; onClick: () => void; className?: string }) {
  return (
    <div
      role="button"
      onClick={props.onClick}
      className={clsx('min-w-[100px] rounded-md border-2 border-gray-200 py-2.5 px-5 w-full sm:w-fit', props.className)}
    >
      <p className="text-center leading-none text-gray-800">{props.label}</p>
    </div>
  );
}
