import { useFeatureWithUserStrategies } from '@/components/feature-flag/hooks/use-feature-with-user-strategies';
import {
  HMB_FUSION_AUTH_COMPLETE_ENROLL,
  HMB_FUSION_AUTH_COMPLETE_LOGIN,
  HMB_FUSION_AUTH_START_ENROLL,
  HMB_FUSION_AUTH_START_LOGIN,
} from '@/constants';
import { FEATURE_WEB_AUTHN } from '@/constants/features';
import { webAuthnUserStorage } from '@/storages/local';
import {
  AuthenticationCredential,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
  RegistrationCredential,
} from '@simplewebauthn/typescript-types';
import { useEffect, useState } from 'react';

export function usePlatformAuth() {
  const flag = useFeatureWithUserStrategies(FEATURE_WEB_AUTHN);

  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    isPlatformAuthenticatorSupported()
      .then((value) => setIsSupported(value))
      .catch(() => setIsSupported(false));
  }, []);

  function canRegister(username: string) {
    return (
      !webAuthnUserStorage.isRegistered(username) &&
      !webAuthnUserStorage.isSkipped(username) &&
      isSupported &&
      flag.isEnabled
    );
  }

  function showBiometricButton(username: string) {
    return webAuthnUserStorage.isRegistered(username) && isSupported && flag.isEnabled;
  }

  return { canRegister, showBiometricButton };
}

function isPlatformAuthenticatorSupported() {
  if (!window.PublicKeyCredential) {
    return Promise.reject(false);
  }

  if (!window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable) {
    return Promise.resolve(false);
  } else {
    return PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
  }
}

export async function beginLoginCeremony(loginId: string) {
  const request = {
    applicationId: process.env.NEXT_PUBLIC_FUSION_AUTH_APPLICATION_ID,
    loginId: loginId,
    workflow: 'reauthentication',
  };
  const data = await fetch(HMB_FUSION_AUTH_START_LOGIN, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  });
  const response = (await data.json()) as { options: PublicKeyCredentialRequestOptionsJSON };
  const options: CredentialRequestOptions = {
    publicKey: {
      challenge: base64URLToBuffer(response.options.challenge),
      allowCredentials: response.options.allowCredentials.map((credential) => ({
        id: base64URLToBuffer(credential.id),
        type: credential.type,
        transports: credential.transports as AuthenticatorTransport[],
      })),
      userVerification: response.options.userVerification,
      rpId: response.options.rpId,
      timeout: response.options.timeout,
    },
  };
  return (await navigator.credentials.get(options)) as AuthenticationCredential;
}

export async function completeLoginCeremony(credential: AuthenticationCredential, twoFactorTrustID?: string) {
  const request = {
    credential: {
      id: bufferToBase64URL(credential.rawId),
      rpId: process.env.NEXT_PUBLIC_PRONTO_RP_ID,
      response: {
        authenticatorData: bufferToBase64URL(credential.response.authenticatorData),
        clientDataJSON: bufferToBase64URL(credential.response.clientDataJSON),
        signature: bufferToBase64URL(credential.response.signature),
        userHandle: bufferToBase64URL(credential.response.userHandle),
      },
      type: credential.type,
    },
    twoFactorTrustID: twoFactorTrustID,
    rpId: process.env.NEXT_PUBLIC_PRONTO_RP_ID,
    origin: process.env.NEXT_PUBLIC_PRONTO_URL,
  };
  const data = await fetch(HMB_FUSION_AUTH_COMPLETE_LOGIN, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  });
  const response = await data.json();
  return response;
}

export async function beginEnrollmentCeremony(user: { id: string; name: string; displayName: string }) {
  const request = {
    name: user.displayName,
    displayName: user.displayName,
    userId: user.id,
    workflow: 'reauthentication',
  };
  const data = await fetch(HMB_FUSION_AUTH_START_ENROLL, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  });
  const response = (await data.json()) as { options: PublicKeyCredentialCreationOptionsJSON };
  const creationOptions: CredentialCreationOptions = {
    publicKey: {
      rp: { name: response.options.rp?.name, id: process.env.NEXT_PUBLIC_PRONTO_RP_ID },
      user: {
        name: response.options.user.name,
        id: base64URLToBuffer(response.options.user.id),
        displayName: response.options.user.displayName,
      },
      attestation: response.options.attestation,
      timeout: response.options.timeout,
      extensions: response.options.extensions,
      pubKeyCredParams: response.options.pubKeyCredParams,
      challenge: base64URLToBuffer(response.options.challenge),
      authenticatorSelection: response.options.authenticatorSelection,
    },
  };
  return (await navigator.credentials.create(creationOptions)) as RegistrationCredential;
}

export async function completeEnrollmentCeremony(credential: RegistrationCredential, userId: string) {
  const request = {
    credential: {
      rpId: process.env.NEXT_PUBLIC_PRONTO_RP_ID,
      clientExtensionResults: {
        credProps: {
          rk: false,
        },
      },
      id: bufferToBase64URL(credential.rawId),
      response: {
        attestationObject: bufferToBase64URL(credential.response.attestationObject),
        clientDataJSON: bufferToBase64URL(credential.response.clientDataJSON),
      },
      transports: ['internal'],
      type: 'public-key',
    },
    origin: process.env.NEXT_PUBLIC_PRONTO_URL,
    userId: userId,
    rpId: process.env.NEXT_PUBLIC_PRONTO_RP_ID,
  };
  const data = await fetch(HMB_FUSION_AUTH_COMPLETE_ENROLL, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  });

  const response = await data.json();
  return response;
}

function base64URLToBuffer(base64URL: string) {
  const base64 = base64URL.replace(/-/g, '+').replace(/_/g, '/');
  const padLen = (4 - (base64.length % 4)) % 4;
  return Uint8Array.from(atob(base64.padEnd(base64.length + padLen, '=')), (c) => c.charCodeAt(0));
}

function bufferToBase64URL(buffer: ArrayBuffer) {
  const bytes = new Uint8Array(buffer);
  let string = '';
  bytes.forEach((b) => (string += String.fromCharCode(b)));
  const base64 = btoa(string);
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}
