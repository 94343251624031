import { Offer } from '@/types/offer';

enum Roles {
  Bank = 'Bank',
  Customer = 'Customer',
  CreditCard = 'CreditCard',
  CreditGuide = 'CreditGuide',
}

export enum OnboardingStatus {
  UserUnverified = 'USER_UNVERIFIED',
  UserVerified = 'USER_VERIFIED',
  PersonCreated = 'PERSON_CREATED',
  PhoneVerified = 'PHONE_VERIFIED',
  LegalSigned = 'LEGAL_SIGNED',
  OccRecorded = 'OCC_RECORDED',
  Approved = 'APPROVED',
  Denied = 'DENIED',
  ManualReview = 'MANUAL_REVIEW',
  ManualReviewPending = 'MANUAL_REVIEW_PENDING',
}

export type GetCurrentUserRegistrarV1Result = {
  userID: string;
  username: string;
  nameFirst: string;
  nameLast: string;
  email: string;
  isVerified: boolean;
  isPrelaunch: boolean;
  isTwoFactorEnabled: boolean;
  twoFactorMethod: string;
  referralCode: string;
  roles: Roles;
  onboardingStatus: OnboardingStatus;
  hasDepositAccount: boolean;
  hasCreditAccount: boolean;
};

export type ComplianceConsent = {
  isMostRecent: boolean;
  id: string;
  policyID: string;
  policyVersionID: string;
  policyVersion: string;
  policyName: string;
  policyTitle: string;
  consentType: string;
  consentedAt: string;
  tags: string[];
  entity: {
    Type: string;
    ID: string;
  };
  expirationDate?: string;
  renewalDate?: string;
  revokedAt?: string;
  shouldOptIn: boolean;
};

export enum V2IncomeBandReportedBy {
  Self = 'Self',
}

export type V2IncomeBand = {
  incomeCeil?: string;
  incomeFloor: string;
  year: number;
  reportedBy: V2IncomeBandReportedBy;
};

export enum V3IncomeBandReportedBy {
  Self = 'Self',
  Hmb = 'HMB',
}

export type V3IncomeBand = {
  incomeCeil?: string;
  incomeFloor: string;
  year: number;
  reportedBy: V3IncomeBandReportedBy;
  yearlyIncome: string;
};

export type CompliancePolicy = {
  id: string;
  policyName: string;
  policyTitle: string;
  policyVersionID: string;
  version: string;
  fileURL: string;
  policyText: string;
  tags: string[];
};

export type UseGetDeciderV1OffersResult = {
  creditCardOffers: Array<Offer>;
};

export type UseGetDeciderV1OccNextRunDateResult = {
  nextRunDate: string;
};

export type creditAlert = {
  id: string;
  experianID: string;
  eventTimestamp: string;
};

export type alertDetail = {
  experianID: string;
  accountName: string;
  openDate: string;
  balance: string;
};

export type GetDeciderV1ExperianCreditReportResult = {
  creditUtilization: string;
  creditLimit: string;
  creditBalance: string;
  orderedDate: string;
  dti: string;
  monthlyIncome: string;
  monthlyDebt: string;
};

export enum CreditScoreVantageRating {
  VeryGood = 'VERY_GOOD',
  Good = 'GOOD',
  Fair = 'FAIR',
  Poor = 'POOR',
  VeryPoor = 'VERY_POOR',
}

export type GetDeciderV1ExperianCreditScoreResult = {
  score: string;
  scoreFloor: string;
  scoreCeil: string;
  vantageRating: CreditScoreVantageRating;
  orderedDate: string;
};
