import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18next
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['en'],
    fallbackNS: 'common',
    defaultNS: 'common',
    ns: [],
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });
