import { Drawer } from '@/components/drawers';
import { LARGE, Spinner } from '@/components/loaders/spinner';
import { URL_HMB_TO_ROUTINES_TEMPLATES } from '@/constants/app';
import { PATH_ROUTINES_NEW_TEMPLATE } from '@/constants/routes';
import {
  DepositAccount,
  RoutineTemplateFragment,
  TemplateCategories,
  useGetAllAccountsQuery,
  useListRoutinesQuery,
  useListTemplatesQuery,
} from '@/generated/graphql';
import { routeFor } from '@/helpers/router';
import { ExternalAccount } from '@/hooks/use-external-accounts';
import { useLinkedAccounts } from '@/routes/account/routes/manage/routes/linkedaccounts/hooks';
import { MinimalOverview } from '@/routes/account/routes/routines/components/overview';
import { templateActionToForm, templateTriggerToForm } from '@/routes/account/routes/routines/helpers/helpers';
import { formatTemplateCategory } from '@/routes/account/routes/routines/helpers/routine-formatters';
import COLORS from '@/styles/colors';
import { Dialog } from '@headlessui/react';
import {
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
  ClipboardDocumentListIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';

function useTemplatesDrawer() {
  const routines = useListRoutinesQuery();
  const depositAccounts = useGetAllAccountsQuery({});
  const externalAccountsController = useLinkedAccounts();
  const listTemplates = useListTemplatesQuery();

  return {
    isLoading: routines.isLoading || depositAccounts.isLoading || listTemplates.isLoading,
    routines: routines.data?.routines ?? [],
    depositAccounts: depositAccounts.data?.depositAccounts ?? [],
    externalAccounts: externalAccountsController.plaidAccounts ?? [],
    templates: listTemplates.data?.routineTemplates ?? [],
  };
}

type TemplatesDrawerProps = {
  isOpen: boolean;
  onDrawerClose: () => void;
};

export function TemplatesDrawer(props: TemplatesDrawerProps) {
  const controller = useTemplatesDrawer();
  const categoryTemplates = [
    TemplateCategories.SaveForToday,
    TemplateCategories.SaveForTheFuture,
    TemplateCategories.BudgetTogether,
    TemplateCategories.ManageSpending,
  ];
  const featuredTemplates = [TemplateCategories.Featured];

  const itemsRef = useRef<Map<TemplateCategories, HTMLDivElement>>(null);

  function scrollToCategory(category: TemplateCategories) {
    return function () {
      const map = getMap();
      const node = map.get(category);
      parentRef.current?.scrollTo({ behavior: 'smooth', top: node.offsetTop - 75 });
    };
  }

  function getMap() {
    if (!itemsRef.current) {
      itemsRef.current = new Map<TemplateCategories, HTMLDivElement>();
    }
    return itemsRef.current;
  }

  function getRef(category: TemplateCategories) {
    return function (node: HTMLDivElement) {
      const map = getMap();
      if (node) {
        map.set(category, node);
      } else {
        map.delete(category);
      }
    };
  }

  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <Drawer onDrawerClose={props.onDrawerClose} open={props.isOpen} anchor="right" className="lg:w-[70vw] w-[90vw]">
      <Dialog.Panel className="m-2 overflow-hidden rounded-lg h-screen transform bg-white text-left align-middle shadow-xl transition-all">
        <Dialog.Title as="div" className="flex justify-between font-medium leading-6 text-sm text-grey bg-white p-6">
          <p className="capitalize">Routine templates</p>
          <XMarkIcon className="w-6 cursor-pointer" onClick={props.onDrawerClose} />
        </Dialog.Title>
        {controller.isLoading ? (
          <div className="h-full flex items-center justify-center w-[68vw]">
            <Spinner color={COLORS.brand_teal} size={LARGE} />
          </div>
        ) : (
          <div className="grid grid-cols-9 h-full overflow-hidden">
            <div className="col-span-7 bg-gray-50 p-6 flex flex-col gap-4 overflow-y-scroll pb-40" ref={parentRef}>
              <div className="flex flex-col gap-4">
                <p className="text-lg font-medium text-gray-900">Get started</p>
                <div className="flex flex-col gap-6 pt-4" ref={getRef(TemplateCategories.Featured)}>
                  <p className="uppercase text-sm font-medium text-gray-600">Featured</p>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                    {controller.templates.filter(byTemplateCategory(TemplateCategories.Featured)).map((template, i) => (
                      <TemplateCard
                        key={`${template.id}-${i}-featured`}
                        template={template}
                        externalAccounts={controller.externalAccounts}
                        depositAccounts={controller.depositAccounts}
                        onClick={props.onDrawerClose}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <p className="text-lg font-medium text-gray-900">Browse by Category</p>
                <div className="flex flex-col gap-6">
                  {categoryTemplates.map((category, i) => (
                    <div key={`${category}-${i}`} className="flex flex-col gap-6 pt-4" ref={getRef(category)}>
                      <p className="uppercase text-sm font-medium text-gray-600">{formatTemplateCategory(category)}</p>
                      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                        {controller.templates.filter(byTemplateCategory(category)).map((template, i) => (
                          <TemplateCard
                            key={`${template.id}-${i}-${category}`}
                            template={template}
                            externalAccounts={controller.externalAccounts}
                            depositAccounts={controller.depositAccounts}
                            onClick={props.onDrawerClose}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-span-2 bg-white p-6 flex flex-col gap-6">
              {/* <div className="border-gray-100 rounded-lg p-4 border-1 flex flex-row gap-2">
                <MagnifyingGlassIcon className="w-6" />
                <p className="text-gray-300">Search Routines</p>
              </div> */}
              <div className="flex flex-col gap-4 pl-4">
                <p className="text-base font-medium text-gray-600">Get started</p>
                <div className="flex flex-col gap-4 pl-2">
                  {featuredTemplates.map((category, j) => (
                    <li
                      className={clsx('text-gray-600 text-base font-medium cursor-pointer')}
                      key={`${category}-${j}-search`}
                      onClick={scrollToCategory(category)}
                    >
                      {formatTemplateCategory(category)}
                    </li>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-4 pl-4">
                <p className="text-base font-medium text-gray-600">By category</p>
                <div className="flex flex-col gap-4 pl-2">
                  {categoryTemplates.map((category, j) => (
                    <li
                      className={clsx('text-gray-600 text-base font-medium cursor-pointer')}
                      key={`${category}-${j}-search`}
                      onClick={scrollToCategory(category)}
                    >
                      {formatTemplateCategory(category)}
                    </li>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </Dialog.Panel>
    </Drawer>
  );
}

function byTemplateCategory(category: TemplateCategories) {
  return function (template: RoutineTemplateFragment) {
    return template.categories?.includes(category);
  };
}

export function TemplateCard(props: {
  template: RoutineTemplateFragment;
  depositAccounts: DepositAccount[];
  externalAccounts: ExternalAccount[];
  onClick?: () => void;
  className?: string;
  depositAccount?: string;
}) {
  return (
    <div className={clsx('bg-white rounded-lg p-4 gap-1 flex flex-col', props.className)}>
      <p className="text-lg font-medium text-gray-900">{props.template.title}</p>
      <p className="text-sm font-normal text-gray-600">{props.template.description}</p>
      <div className="py-6">
        <MinimalOverview
          href={routeFor(PATH_ROUTINES_NEW_TEMPLATE, { path: { templateId: props.template.id } })}
          trigger={templateTriggerToForm(props.template.trigger)}
          action={templateActionToForm(props.template.action, props.depositAccount)}
          depositAccounts={props.depositAccounts}
          externalAccounts={props.externalAccounts}
        />
      </div>
      <div className="flex-1" />
      <div className="flex flex-row justify-between items-center px-4">
        {props.template?.uid ? (
          <>
            <Link
              href={routeFor(URL_HMB_TO_ROUTINES_TEMPLATES, {
                path: { template: props.template.uid },
              })}
              className="flex flex-col items-center"
            >
              <ArrowTopRightOnSquareIcon className="w-6 text-gray-600" />
              <p className="text-tiny text-gray-600 font-normal">Learn More</p>
            </Link>
            <CopyButton
              href={routeFor(URL_HMB_TO_ROUTINES_TEMPLATES, {
                path: { template: props.template.uid },
              })}
            />
          </>
        ) : (
          <div />
        )}
        <Link
          href={routeFor(PATH_ROUTINES_NEW_TEMPLATE, { path: { templateId: props.template.id } })}
          onClick={props.onClick}
          className="bg-gray-700 rounded-3xl p-2 px-4 text-white flex flex-row gap-4"
        >
          Try it <ArrowRightIcon className="w-6" />
        </Link>
      </div>
    </div>
  );
}

function CopyButton(props: { href: string }) {
  const [copySuccess, setCopySuccess] = useState<string | null>(null);
  const spanRef = useRef(null);

  const copy = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(props.href);
    const target = event.target as HTMLButtonElement;
    target.focus();
    setCopySuccess('Copied!');
  };

  useEffect(() => {
    let intervalId = 0;
    if (copySuccess === 'Copied!') {
      intervalId = window.setInterval(() => {
        setCopySuccess(null);
      }, 2000);
    }
    return () => clearInterval(intervalId);
  }, [copySuccess]);

  return (
    <button
      type="button"
      className="relative flex flex-col justify-between py-xs px-0 hover:text-blue focus:text-blue"
      onClick={copy}
      aria-label={`Click to copy template link to the clipboard`}
    >
      <span className="inline-block w-md h-lg">
        <ClipboardDocumentListIcon className="w-6 text-gray-600" />
      </span>
      <p ref={spanRef} className="text-tiny text-gray-600 font-normal">
        Share
      </p>
      {copySuccess && (
        <p className="absolute top-10 text-tiny font-bold text-blue left-0 bottom-[100%]">{copySuccess}</p>
      )}
    </button>
  );
}
