import { Button } from '@/components/create-plan/components/button';
import React from 'react';

export function Cta(props: {
  onClickNext: () => void;
  onClickBack?: () => void;
  isNextDisabled?: boolean;
  isLoading?: boolean;
  nextButtonLabel: string;
  backButtonLabel?: string;
  withoutBackButton?: boolean;
}) {
  return (
    <div className="relative flex sm:flex-row flex-col-reverse items-center justify-center w-full">
      {!props.withoutBackButton && (
        <button
          type="button"
          className="outline-none w-full sm:w-1/2 text-center text-base text-gray-800 py-2.5 border-2 border-gray-200 rounded-lg sm:mr-2 sm:mt-0 mt-2"
          onClick={props.onClickBack}
        >
          {props.backButtonLabel}
        </button>
      )}
      <Button
        onClick={props.onClickNext}
        label={props.nextButtonLabel}
        isDisabled={props.isNextDisabled}
        className="py-2.5 w-full sm:w-1/2 flex-1"
        isLoading={props.isLoading}
      />
    </div>
  );
}
