export function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(process.env.NEXT_PUBLIC_UMBRELLA_API_PATH as string, {
      method: 'POST',
      ...{ credentials: 'include', mode: 'cors', headers: { 'content-type': 'application/json' } },
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors && Array.isArray(json.errors)) {
      const { message } = json.errors[0];
      throw new Error(message);
    } else if (json.errors && json.message) {
      throw new Error(json.message);
    } else if (json?.errors?.detail) {
      throw new Error(json.errors.detail);
    }

    return json.data;
  };
}
