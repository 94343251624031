import cx from 'clsx';
import * as React from 'react';
export interface InputProps extends React.HTMLProps<HTMLInputElement> {
  name: string;
  className?: string;
  hasError?: boolean;
  hasValue?: boolean;
  testId?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(
  { className, hasValue, hasError, name, placeholder, ...inputProps },
  ref,
) {
  return (
    <input
      {...inputProps}
      name={name}
      id={inputProps.id ?? name}
      data-testid={inputProps?.testId || name}
      placeholder={placeholder ?? ' '}
      className={cx('input-base', className, {
        'border-red-800 focus:shadow-red focus:shadow-input': hasError,
        'input-has-value': hasValue,
      })}
      ref={ref}
    />
  );
});
