import { HMB_CLIENT_ID } from '@/constants';
import { createClient, createMiddleware } from '@core/mainframe-js-sdk';

function addClientVersion(_id: string) {
  return createMiddleware((next) => (request) => {
    return next(request);
  });
}

export const httpClient = createClient({
  url: process.env.NEXT_PUBLIC_MAINFRAME_API_PATH,
  middleware: addClientVersion(HMB_CLIENT_ID),
});
