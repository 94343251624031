import {
  PATH_ACCOUNT_HOME,
  PATH_LOGIN,
  PATH_LOGIN_2FA,
  PATH_LOGIN_2FA_SETUP,
  PATH_REGISTER_WEB_AUTHN,
} from '@/constants/routes';
import { removeUndefinedValues } from '@/helpers';
import { useIdentifyAnalytics, useResetAnalytics } from '@/hooks/analytics';
import { httpClient } from '@/transports/http';
import { GetCurrentUserRegistrarV1Result } from '@/types/mainframe';
import { FetcherError } from '@core/mainframe-js-sdk';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';

import { usePlatformAuth } from '@/routes/login/components/web-authn';
import {
  useGetCurrentUserRegistrarV1,
  useGetCurrentUserRegistrarV1QueryKey,
  useLoginRegistrarV1,
  useLogoutRegistrarV1,
} from '@core/mainframe-react-query';
import { useQueryClient } from 'react-query';

export function useLogin() {
  const router = useRouter();
  const refetchSession = useRefetchSession();
  const resetAnalytics = useResetAnalytics();
  const platformAuth = usePlatformAuth();

  type TwoFactorSetupRequired = {
    accessToken: string;
    applicationStatus: string;
    email: string;
    expiresIn: number;
    isPrelaunch: boolean;
    isTwoFactorEnabled: boolean;
    isVerified: boolean;
    maskedEmail?: string;
    maskedPhone?: string;
    refreshToken: string;
    roles: Array<string>;
    twoFactorMethod: string;
    twoFactorSetupRequired: boolean;
    userID: string;
  };

  type RequiresTwoFactor = {
    requiresTwoFactor: boolean;
    twoFactorID: string;
    userID: string;
  };

  const loginRegistrarV1 = useLoginRegistrarV1(httpClient, {
    options: {
      async onSuccess(data: TwoFactorSetupRequired | RequiresTwoFactor, variables) {
        const requiresTwoFactorData = data as TwoFactorSetupRequired;
        const twoFactorData = data as RequiresTwoFactor;

        if (requiresTwoFactorData.twoFactorSetupRequired) {
          await router.replace({
            pathname: PATH_LOGIN_2FA_SETUP,
            query: removeUndefinedValues({
              userID: requiresTwoFactorData.userID,
              maskedEmail: requiresTwoFactorData.maskedEmail,
              maskedPhone: requiresTwoFactorData.maskedPhone,
            }),
          });
        } else if (twoFactorData.requiresTwoFactor) {
          await router.replace({
            pathname: PATH_LOGIN_2FA,
            query: removeUndefinedValues({
              userID: twoFactorData.userID,
              twoFactorID: twoFactorData.twoFactorID,
              redirectTo: router.query.redirectTo,
            }),
          });
        } else {
          await refetchSession();
          platformAuth.canRegister(variables.body.username)
            ? router.replace(PATH_REGISTER_WEB_AUTHN)
            : router.replace((router.query.redirectTo as string) ?? PATH_ACCOUNT_HOME);
        }
      },
    },
  });

  function onLogin(data: { username: string; password: string; twoFactorTrustID?: string }) {
    // NOTE: In the case of multiple user on a single device we need to make
    // sure that any previous user traits are not attributed to the current user.
    // Since We can't guarantee that a user will take an action to logout of the app
    // because credential expire we're resetting attributes just before login to ensure
    // this is tracked as the newest user. After login, we will identify the new user.
    resetAnalytics();
    return loginRegistrarV1.mutateAsync({ body: data });
  }

  return {
    onLogin,
    isError: loginRegistrarV1.isError,
    error: loginRegistrarV1.error as FetcherError,
  };
}

export function useLogout() {
  const router = useRouter();
  const refetchSession = useRefetchSession();
  const queryClient = useQueryClient();
  const resetAnalytics = useResetAnalytics();
  const logoutRegistrarV1 = useLogoutRegistrarV1(httpClient, {
    options: {
      async onSuccess() {
        Sentry.configureScope((scope) => scope.setUser(null)); // move this to useSession loading state?
        resetAnalytics();
        await router.replace(PATH_LOGIN);
        await queryClient.clear();
        await refetchSession();
      },
    },
  });

  function onLogout() {
    return logoutRegistrarV1.mutateAsync({});
  }

  return { onLogout };
}

export function useRefetchSession() {
  const queryClient = useQueryClient();
  const currentUserQueryKey = useGetCurrentUserRegistrarV1QueryKey();
  return () => queryClient.refetchQueries(currentUserQueryKey);
}

export function useSession() {
  const identifyAnalytics = useIdentifyAnalytics();
  const currentUserQuery = useGetCurrentUserRegistrarV1<GetCurrentUserRegistrarV1Result, FetcherError>(httpClient, {
    options: {
      retry: false,
      refetchOnWindowFocus: false,
      async onSuccess(data) {
        Sentry.setUser({
          username: data.username,
          email: data.email,
          id: data.userID,
        });

        await identifyAnalytics(data.userID, {
          username: data.username,
          email: data.email,
        });

        return data;
      },
      onError() {},
    },
  });

  return {
    isLoading: currentUserQuery.isLoading,
    user: currentUserQuery.data,
    isAuthenticated: !currentUserQuery.isLoading && Boolean(currentUserQuery.data),
  };
}
