import { removeUndefinedValues } from '@/helpers';
import { styledBy } from '@/helpers/csskit';
import cx from 'clsx';
import Link from 'next/link';

/** @public */
export enum BUTTON_DISPLAY {
  inline = 'inline',
  block = 'block',
  responsive = 'responsive',
}

/** @public */
export enum BUTTON_COLORS {
  white = 'white',
  black = 'black',
  teal = 'teal',
  red = 'red',
  transparent = 'transparent',
  purple = 'purple',
  none = 'none',
}

/** @public */
export enum BUTTON_SIZES {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

/** @public */
export enum BUTTON_TYPES {
  text = 'text',
  fill = 'fill',
  outline = 'outline',
  inline = 'inline',
}

/** @public */
export enum BUTTON_HTML_TYPES {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

type ButtonSize = `${BUTTON_SIZES}`;
type ButtonDisplay = `${BUTTON_DISPLAY}`;
type ButtonColors = `${BUTTON_COLORS}`;
type ButtonTypes = `${BUTTON_TYPES}`;
type ButtonHtmlTypes = `${BUTTON_HTML_TYPES}`;

export type ButtonProps = {
  className?: string;
  children: string | React.ReactNode;
  disabled?: boolean;
  href?: string;
  isNavLink?: boolean;
  activeClassName?: string;
  toNext?: string | { pathname: string; query: { [key: string]: string | boolean } };
  color?: ButtonColors;
  type?: ButtonTypes;
  size?: ButtonSize;
  display?: ButtonDisplay;
  htmlType?: ButtonHtmlTypes;
  onClick?: () => void;
  replace?: boolean;
  tabIndex?: number;
  target?: string;
  rel?: string;
};

export function Button(props: ButtonProps) {
  const {
    href = undefined,
    toNext = undefined,
    type = BUTTON_TYPES.fill,
    size = BUTTON_SIZES.md,
    color = BUTTON_COLORS.white,
    display = BUTTON_DISPLAY.inline,
    className = '',
    children,
    htmlType = 'button',
    disabled = undefined,
    // for NavLink
    replace,
    isNavLink = false,
    activeClassName = undefined,
    ...rest
  } = props;
  const isOutline = BUTTON_TYPES.outline === type;
  const isFill = BUTTON_TYPES.fill === type;
  const isText = BUTTON_TYPES.text === type;

  const rootClass = cx(
    className,
    'button-base',
    isOutline && 'border-2 border-solid',
    //colors
    disabled
      ? cx('pointer-events-none', {
          'text-white bg-grey-lighter': isFill,
          'text-grey-lighter border-grey-lighter': isOutline,
        })
      : styledBy(color, {
          transparent: cx({
            'text-black border-black': isOutline,
            'text-black bg-transparent': isFill,
            'text-black': isText,
          }),
          white: cx({ 'text-blue border-blue': isOutline, 'text-blue bg-white': isFill, 'text-blue': isText }),
          red: cx({ 'text-red border-red': isOutline, 'text-white bg-red': isFill, 'text-red': isText }),
          purple: cx({ 'text-purple border-purple': isOutline, 'text-white bg-purple': isFill, 'text-purple': isText }),
          black: cx({ 'text-black border-black': isOutline, 'text-white bg-black': isFill, 'text-black': isText }),
          teal: cx({ 'text-blue border-blue': isOutline, 'text-white bg-blue': isFill, 'text-blue': isText }),
          none: '-',
          null: '',
        }),
    //sizes
    styledBy(size, {
      [BUTTON_SIZES.sm]: cx('h-9 py-0 px-3 text-sm', isOutline ? 'leading-8' : 'leading-9'),
      [BUTTON_SIZES.md]: cx('h-2xl py-0 px-lg text-base', isOutline ? 'leading-11' : 'leading-12'),
      [BUTTON_SIZES.lg]: cx('h-[54px] py-0 px-9 text-md', isOutline ? 'leading-13' : 'leading-14'),
      null: '',
    }),
    //display
    styledBy(display, {
      [BUTTON_DISPLAY.inline]: 'inline-block',
      [BUTTON_DISPLAY.block]: 'block w-full',
      [BUTTON_DISPLAY.responsive]: 'block w-full md:inline-block md:w-auto',
      null: '',
    }),
  );

  if (toNext && typeof toNext === 'string') {
    return (
      <Link href={toNext} passHref className={cx(rootClass, 'no-underline')} {...rest}>
        {children}
      </Link>
    );
  }

  if (toNext && typeof toNext === 'object') {
    return (
      <Link
        href={{
          pathname: toNext.pathname,
          query: removeUndefinedValues({ ...toNext.query }),
        }}
        passHref
        className={cx(rootClass, 'no-underline')}
        {...rest}
      >
        {children}
      </Link>
    );
  }

  if (href) {
    return (
      <a href={href} className={cx(rootClass, 'no-underline')} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    );
  }

  return (
    <button type={htmlType} className={rootClass} disabled={disabled} {...rest}>
      {children}
    </button>
  );
}
