import { NextTransitionOptions, NextUrl } from '@/types/router';
import { useRouter } from 'next/router';
import * as React from 'react';

export function RedirectTo(props: { url?: NextUrl; as?: NextUrl; options?: NextTransitionOptions; replace?: boolean }) {
  const router = useRouter();

  React.useEffect(() => {
    if (!props.url) {
      return;
    }

    if (props.replace) {
      router.replace(props.url, props.as, props.options);
    } else {
      router.push(props.url, props.as, props.options);
    }
  }, [props.url, props.as, props.options, props.replace, router]);

  return null;
}
