import { Drawer } from '@/components/drawers';
import {
  ActionTypes,
  PlanTransferAccountType,
  TriggerTypes,
  useCreatePlanMutation,
  useCreateRoutineMutation,
  useInitiatePlanTransferMutation,
} from '@/generated/graphql';
import { toAmountInCents } from '@/helpers/currency';
import { CongratulationsStep } from '@/routes/account/components/create-plan-drawer/components/congratulations-step';
import { FundPlanStep } from '@/routes/account/components/create-plan-drawer/components/fund-plan-step';
import { PlanGoalStep } from '@/routes/account/components/create-plan-drawer/components/plan-goal-step';
import { PlanNameStep } from '@/routes/account/components/create-plan-drawer/components/plan-name-step';
import { PlanTypeStep } from '@/routes/account/components/create-plan-drawer/components/plan-type-step';
import { CreatePlanSteps, IPlanGoal } from '@/routes/account/components/create-plan-drawer/helpers';
import { useCreatePlanDrawerContext } from '@/routes/account/components/create-plan-drawer/hooks/use-create-plan-drawer-context';
import { formToCron } from '@/routes/account/routes/routines/helpers/helpers';
import { FrequencyTypes } from '@/routes/account/routes/routines/types';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import React from 'react';

function useCreatePlanDrawer() {
  const controller = useCreatePlanDrawerContext();

  const [createPlanError, setCreatePlanError] = React.useState<string | undefined>();
  const [fundPlanError, setFundPlanError] = React.useState<string | undefined>();
  const [planId, setPlanId] = React.useState<string | undefined>();
  const createPlanMutation = useCreatePlanMutation();
  const initiatePlanTransferMutation = useInitiatePlanTransferMutation();
  const createRoutineMutation = useCreateRoutineMutation();

  React.useEffect(() => {
    setCreatePlanError(undefined);
    setFundPlanError(undefined);
  }, [controller.step]);

  async function onCreatePlan(values?: IPlanGoal) {
    try {
      setCreatePlanError(undefined);

      const planGoal = values || controller.planGoal;

      const goalAmountInCents = toAmountInCents(
        parseFloat((planGoal.goalAmount || '0').replace('$', '').replaceAll(',', '')),
      );
      const initialContributionAmountInCents = toAmountInCents(
        parseFloat((planGoal.initialContribution || '0').replace('$', '').replaceAll(',', '')),
      );
      const monthlyContributionGoalAmountInCents = toAmountInCents(
        parseFloat((planGoal.monthlyContribution || '0').replace('$', '').replaceAll(',', '')),
      );

      const hasInitialContribution = initialContributionAmountInCents !== 0;
      const hasMonthlyContribution = monthlyContributionGoalAmountInCents !== 0;

      const response = await createPlanMutation.mutateAsync({
        title: controller.planInfo.name,
        color: controller.planInfo.color,
        goalAmountInCents: goalAmountInCents === 0 ? undefined : goalAmountInCents,
        description: controller.planInfo.description,
        depositAccountId: controller.accountId,
        initialContributionAmountInCents: !hasInitialContribution ? undefined : initialContributionAmountInCents,
        monthlyContributionGoalAmountInCents: !hasMonthlyContribution
          ? undefined
          : monthlyContributionGoalAmountInCents,
        goalDate: DateTime.fromISO(new Date(planGoal.achieveGoalBy).toISOString()).toFormat('yyyy-MM-dd'),
        assignAccountNumber: controller.planInfo.assignAccountNumber,
      });

      setPlanId(response.createPlan.planId);

      if (controller.withFundPlanSteps) {
        if (hasInitialContribution || hasMonthlyContribution) {
          controller.setStep(CreatePlanSteps.FundPlan);
        } else {
          controller.onSuccess?.(response.createPlan.planId);
          controller.setStep(CreatePlanSteps.Congratulations);
        }
      } else {
        controller.onSuccess?.(response.createPlan.planId);
        controller.closeCreatePlanDrawer();
      }
    } catch (error) {
      setCreatePlanError('Something went wrong. Please try later');
    }
  }

  async function onFundPlan() {
    try {
      setFundPlanError(undefined);
      const planGoal = controller.planGoal;

      const initialContributionAmountInCents = toAmountInCents(
        parseFloat((planGoal.initialContribution || '0').replace('$', '').replaceAll(',', '')),
      );
      const monthlyContributionGoalAmountInCents = toAmountInCents(
        parseFloat((planGoal.monthlyContribution || '0').replace('$', '').replaceAll(',', '')),
      );

      if (initialContributionAmountInCents !== 0) {
        await initiatePlanTransferMutation.mutateAsync({
          amountInCents: initialContributionAmountInCents,
          depositAccountId: controller.accountId,
          fromAccountId: controller.accountId,
          fromAccountType: PlanTransferAccountType.Deposit,
          toAccountId: planId,
          toAccountType: PlanTransferAccountType.Plan,
        });
      }

      if (monthlyContributionGoalAmountInCents !== 0) {
        await createRoutineMutation.mutateAsync({
          name: `Monthly contribution to Plan: ${controller.planInfo.name}`,
          description: '',
          trigger: {
            type: TriggerTypes.Date,
            dateTrigger: {
              version: '0.01',
              type: TriggerTypes.Date,
              options: {
                pattern: formToCron({
                  frequency: FrequencyTypes.Monthly,
                  day: [controller.day],
                }),
              },
            },
          },
          action: {
            type: ActionTypes.PlanTransfer,
            planTransferAction: {
              version: '0.01',
              type: ActionTypes.PlanTransfer,
              options: {
                depositAccountId: controller.accountId,
                fromAccountId: controller.accountId,
                fromAccountType: PlanTransferAccountType.Deposit,
                toAccountId: planId,
                toAccountType: PlanTransferAccountType.Plan,
                amountInCents: monthlyContributionGoalAmountInCents,
              },
            },
          },
          consents: [],
        });
      }

      controller.onSuccess?.(planId);
      controller.setStep(CreatePlanSteps.Congratulations);
    } catch (error) {
      setFundPlanError('Something went wrong. Please try later');
    }
  }

  return {
    step: controller.step,
    createPlanError,
    fundPlanError,
    createPlanLoading: createPlanMutation.isLoading,
    fundPlanLoading: initiatePlanTransferMutation.isLoading || createRoutineMutation.isLoading,
    onFundPlan,
    onCreatePlan,
  };
}

export function CreatePlanDrawer(props: { onClose: () => void; isOpen: boolean }) {
  const controller = useCreatePlanDrawer();

  function getTransformValue() {
    if (controller.step === CreatePlanSteps.PlanType) {
      return 0;
    }
    if (controller.step === CreatePlanSteps.PlanName) {
      return 100;
    }
    if (controller.step === CreatePlanSteps.PlanGoal) {
      return 200;
    }
    if (controller.step === CreatePlanSteps.FundPlan) {
      return 300;
    }
    if (controller.step === CreatePlanSteps.Congratulations) {
      return 400;
    }
  }

  return (
    <Drawer onDrawerClose={props.onClose} open={props.isOpen} anchor="right">
      <Dialog.Panel className="sm:m-2 flex flex-col sm:rounded-lg w-screen sm:w-full h-screen sm:max-w-md transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all duration-300">
        <Dialog.Title
          as="div"
          className="flex justify-between font-medium leading-6 text-sm text-grey bg-white-dark p-lg uppercase"
        >
          <p>Create Plan</p>
          <XMarkIcon className="w-lg cursor-pointer" onClick={props.onClose} />
        </Dialog.Title>
        <div
          className="relative flex duration-500"
          style={{ transform: `translateX(-${getTransformValue()}%)`, height: 'calc(100% - 72px)' }}
        >
          <PlanTypeStep onClose={props.onClose} />
          <PlanNameStep />
          <PlanGoalStep
            onCreatePlan={controller.onCreatePlan}
            createPlanLoading={controller.createPlanLoading}
            createPlanError={controller.createPlanError}
          />
          <FundPlanStep
            onFundPlan={controller.onFundPlan}
            fundPlanLoading={controller.fundPlanLoading}
            fundPlanError={controller.fundPlanError}
          />
          <CongratulationsStep />
        </div>
      </Dialog.Panel>
    </Drawer>
  );
}
