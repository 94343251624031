import React from 'react';
import { use2faCode } from './hooks/use-2fa-code';

type TwoFAProps = ReturnType<typeof use2faCode>;

const TwoFAContext = React.createContext<TwoFAProps | undefined>(undefined);

export const TwoFAProvider = ({ children }) => {
  const controller = use2faCode();

  return <TwoFAContext.Provider value={controller}>{children}</TwoFAContext.Provider>;
};
