import { NEXT_PUBLIC_CIO_ORG_ID, NEXT_PUBLIC_CIO_SITE_ID } from '@/constants';
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import * as React from 'react';

export type UseSegmentAnalyticsProps = {
  writeKey?: string;
  cdnUrl?: string;
};

export function useSegmentAnalytics(props: UseSegmentAnalyticsProps) {
  const [analytics, setAnalytics] = React.useState<Analytics | undefined>(setupAnalytics);

  React.useEffect(() => {
    let id = setInterval(() => {
      const cio = document.getElementById('cio-tracker');
      if (cio) {
        cio?.setAttribute('data-in-app-org-id', NEXT_PUBLIC_CIO_ORG_ID);
        cio?.setAttribute('data-site-id', NEXT_PUBLIC_CIO_SITE_ID);
        cio?.setAttribute('data-use-in-app', 'true');
        window._cio?.load?.();
        clearInterval(id);
        id = undefined;
      }
    }, 500);

    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, []);

  React.useEffect(() => {
    if (!props.writeKey) {
      return;
    }

    async function loadAnalytics() {
      window.analytics._cdn = props.cdnUrl;
      const [response] = await AnalyticsBrowser.load({ writeKey: props.writeKey });
      setAnalytics(response);
    }

    loadAnalytics();
  }, [props.writeKey, props.cdnUrl]);

  return {
    isReady: !!analytics,
    analytics: analytics,
  };
}

function setupAnalytics(): Analytics {
  const analytics = (window.analytics = window?.analytics ?? ([] as any));

  if (analytics.initialize) {
    return analytics;
  }

  if (analytics.invoked) {
    return analytics;
  }

  analytics.invoked = !0;
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
  ];

  analytics.factory = function (methodName: string) {
    return function (...args: any[]) {
      analytics.push([methodName, ...args]);
      return analytics;
    };
  };

  for (const methodName of analytics.methods) {
    analytics[methodName] = analytics.factory(methodName);
  }

  return analytics;
}
