import cx from 'clsx';
import * as React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { FormFieldError } from '../form-field-error';
import { mergeRefs } from '../helpers/merge-refs';
import { Input } from '../input';

export type NumberFormatFieldProps = {
  label?: string;
  error?: string;
  inputClassName?: string;
  labelClassName?: string;
  prefixDisabled?: boolean;
  isOptional?: boolean;
  slot?: {
    before?: React.ReactNode;
    after?: React.ReactNode;
  };
} & Omit<NumberFormatProps, 'slot'>;

export const NumberFormatField = React.forwardRef<HTMLInputElement, NumberFormatFieldProps>(function NumberFormatField(
  props: NumberFormatFieldProps,
  ref,
) {
  const {
    label,
    className,
    name,
    slot,
    placeholder,
    inputClassName,
    labelClassName,
    prefixDisabled,
    isOptional,
    ...inputProps
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);

  function onFocus(e) {
    props.onFocus && props.onFocus(e);
  }

  function onBlur(e) {
    props.onBlur && props.onBlur(e);
  }

  return (
    <div className={cx('relative', className)}>
      <div className="flex justify-between items-center">
        {props.label && (
          <label
            className={cx(labelClassName, 'text-xs font-medium', {
              'text-red-800': !!props.error,
              'border-black-inactive text-black-inactive': props.disabled,
            })}
            onClick={() => inputRef.current.focus()}
            htmlFor={props.id ?? name}
          >
            {props.label}
          </label>
        )}
        {isOptional && <span className="text-tiny rounded px-1 bg-gray-200">Optional</span>}
      </div>
      <div className="relative my-1">
        {slot?.before && <div className="absolute flex left-0 bottom-0 top-0">{slot?.before}</div>}
        <NumberFormat
          {...inputProps}
          customInput={Input}
          placeholder={placeholder}
          name={name}
          id={props.id ?? name}
          onFocus={onFocus}
          onBlur={onBlur}
          hasError={!!props.error}
          className={cx(inputClassName, {
            'pl-11': slot?.before,
            'pr-10': slot?.after,
          })}
          getInputRef={mergeRefs<HTMLInputElement>(ref, inputRef)}
        />
        {slot?.after && <div className="absolute flex right-0 bottom-0 top-0">{slot?.after}</div>}
      </div>
      {props.error && <FormFieldError>{props.error}</FormFieldError>}
    </div>
  );
});
