import { httpClient } from '@/transports/http';
import { TwoFactorMethod } from '@/types/user';
import { PostRegistrarV2TwoFaSecretResponse } from '@core/mainframe-js-sdk/dist/operations/post-registrar-v2-two-fa-secret';
import {
  usePostRegistrarV2TwoFaSecret,
  usePostRegistrarV2_2faEnable,
  useRemove_2faRegistrarV1,
} from '@core/mainframe-react-query';
import React from 'react';

export function use2faCode() {
  const [twoFaSecret, setTwoFaSecret] = React.useState<PostRegistrarV2TwoFaSecretResponse>();
  const postRegistrarV2TwoFaSecret = usePostRegistrarV2TwoFaSecret(httpClient, {
    options: {
      onSuccess(data) {
        setTwoFaSecret(data);
      },
    },
  });

  const remove2faRegistrarMutation = useRemove_2faRegistrarV1(httpClient, {});
  const setup2faRegistrarMutation = usePostRegistrarV2_2faEnable(httpClient, {});
  /*
    TODO: add recovery codes view for user to save recovery codes
  */

  function refresh2faSecret(method: Exclude<TwoFactorMethod, TwoFactorMethod.Disabled>) {
    postRegistrarV2TwoFaSecret.mutate({ body: { method: method } });
  }

  async function setupTwoFactor(args: {
    code: string;
    method: Exclude<TwoFactorMethod, TwoFactorMethod.Disabled>;
    secret: string;
  }) {
    await setup2faRegistrarMutation.mutateAsync({
      body: {
        code: args.code,
        method: args.method,
        secret: args.secret,
      },
    });
  }

  return {
    remove2faRegistrarMutation,
    refresh2faSecret,
    setupTwoFactor,
    twoFaSecret,
    isLoading:
      setup2faRegistrarMutation.isLoading ||
      remove2faRegistrarMutation.isLoading ||
      postRegistrarV2TwoFaSecret.isLoading,
  };
}
