import { ActivityTimer } from '@/components/activity-timer';
import { HMBCommandPalette } from '@/components/command-palette';
import { Drawer } from '@/components/drawers';
import { FeatureFlag } from '@/components/feature-flag';
import { UserMenuItemList } from '@/components/header/components/authenticated-header';
import { HMBradleyLogo } from '@/components/hmbradley-logo';
import { PageTitle } from '@/components/page-title';
import { FULLSTORY_UNMASK } from '@/constants';
import { COMMAND_PALETTE_FEATURE } from '@/constants/features';
import { Menu } from '@headlessui/react';
import { Bars3Icon, UserCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import * as React from 'react';
import { Sidebar } from './sidebar';

function useDefaultMultiColumnLayout() {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  function onClose() {
    return setSidebarOpen(false);
  }

  function onOpen() {
    return setSidebarOpen(true);
  }

  return {
    onOpen,
    sidebarOpen,
    setSidebarOpen,
    onClose,
  };
}

export function DefaultMultiColumnLayout(props: React.PropsWithChildren<{}>) {
  const controller = useDefaultMultiColumnLayout();

  return (
    <>
      <PageTitle title="Dashboard" />
      <ActivityTimer />
      <FeatureFlag flag={COMMAND_PALETTE_FEATURE}>
        <HMBCommandPalette />
      </FeatureFlag>

      <div
        className={clsx('flex h-screen overflow-y-auto', FULLSTORY_UNMASK)}
        style={{
          scrollbarGutter: 'stable',
        }}
      >
        <Drawer open={controller.sidebarOpen} onDrawerClose={controller.onClose} title="Main Menu">
          <Sidebar className="flex-1" onClose={controller.onClose} />
        </Drawer>
        <div className="fixed z-4000">
          <Sidebar className="hidden lg:flex h-screen" />
        </div>
        <div className="flex flex-col flex-1 relative lg:pl-60">
          <Header onOpen={controller.onOpen} />
          {props.children}
        </div>
      </div>
    </>
  );
}

function Header(props: { onOpen: () => void }) {
  return (
    <header className="flex lg:hidden items-center justify-between bg-white border-b border-gray-200 px-4 py-2">
      <div className="flex flex-row items-center gap-3">
        <button
          type="button"
          className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md hover:text-gray-500 text-gray-900"
          onClick={props.onOpen}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <HMBradleyLogo className="h-8 w-auto" />
      </div>
      <div>
        <Menu as="div" className="text-left lg:hidden flex">
          {() => (
            <>
              <Menu.Button className="h-12 w-12 inline-flex items-center justify-center rounded-md hover:text-gray-500 text-gray-900">
                <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
              </Menu.Button>
              <UserMenuItemList />
            </>
          )}
        </Menu>
      </div>
    </header>
  );
}
