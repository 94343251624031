import {
  CREDIT_ACCOUNT_TYPE,
  DEPOSIT_ACCOUNT_TYPE,
  HELP_DESK,
  INDIVIDUAL_ACCOUNT_TYPE,
  JOINT_ACCOUNT_TYPE,
} from '@/constants/app';
import { DepositAccount, DepositAccountStatus } from '@/generated/graphql';
import { ExternalAccount } from '@/hooks/use-external-accounts';
import { AccountV4, CardV1, LedgerV2 } from '@core/mainframe-js-sdk';

export function helpDeskSupportEmailLink(args: { tag: string }) {
  const url = new URL(`${HELP_DESK}/support-email`);
  url.searchParams.append('tag', args.tag);
  return url.toString();
}

export function uspsTrackingLink(args: { tLabels: string }) {
  const url = new URL('https://tools.usps.com/go/TrackConfirmAction.action');
  url.searchParams.append('tLabels', args.tLabels);
  return url.toString();
}

export function createClientId(args: { environment: string; name: string; release: string }) {
  return `${args.name}/${args.release} (${args.environment})`;
}

export function isIndividualAccount(account: LedgerV2 | AccountV4) {
  return account.accountType === INDIVIDUAL_ACCOUNT_TYPE;
}

export function compose(...funcs) {
  return funcs.reduce(
    function (a, b) {
      return (...args) => a(b(...args));
    },
    (arg) => arg,
  );
}

export function removeUndefinedValues<T>(obj: T): T {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key as keyof T] !== undefined) {
      acc[key as keyof T] = obj[key as keyof T];
    }
    return acc;
  }, {} as T);
}

export function isDepositAccount(account: AccountV4) {
  return [INDIVIDUAL_ACCOUNT_TYPE, JOINT_ACCOUNT_TYPE, DEPOSIT_ACCOUNT_TYPE].includes(account.accountType);
}

export function isNotAuthUserCard(account: CardV1) {
  return account.isAuthorizedUserCard === false;
}

export function isCreditAccount(account: CardV1) {
  return account.cardType === CREDIT_ACCOUNT_TYPE;
}

export function isPlaidAccount(account: ExternalAccount) {
  return account.providerType === 'PLAID';
}

export function isNotPlaidAccount(account: ExternalAccount) {
  return account.providerType !== 'PLAID';
}

type Account = CardV1 | AccountV4;

export function byAccountId(id: string) {
  return function (account: Account) {
    return account.id === id;
  };
}

export function byNycbId(id: string) {
  return function (account: DepositAccount) {
    return account.id === id;
  };
}

export function byActiveCreditCard(creditCard: CardV1) {
  return creditCard.isActive === true;
}

export function byActiveDepositAccountId(depositAccountId: string) {
  return function (depositAccount: DepositAccount) {
    return depositAccount.id === depositAccountId;
  };
}

export function isHatchClosedByHmb(account: LedgerV2) {
  return account.hasNYCBTransition === false;
}

export function isOpenNycbAccount(account: DepositAccount) {
  return account.accountStatus === DepositAccountStatus.Opened;
}

function isVerified(option: { label: string; value: string; isVerified: boolean }) {
  return option.isVerified;
}

export function groupWithoutUnverifiedAccountOption(group: {
  label: string;
  options: Array<{ label: string; value: string; isVerified?: boolean }>;
}) {
  return {
    ...group,
    options: group.options.filter(isVerified),
  };
}
