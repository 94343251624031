import cx from 'clsx';
import * as React from 'react';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

export function FormFieldError({ children, className }: Props) {
  return <p className={cx(className, 'mt-1 mb-0 text-xs text-red-800 font-normal')}>{children}</p>;
}
