import { HMBradleyTimeZones } from '@/constants';
import { UTCDateLongFormatter } from '@/i18n/datetime';
import clsx from 'clsx';
import { DateTime } from 'luxon';

export function DayPicker(props: { onChange: (value: number) => void; value?: number }) {
  return (
    <div className="flex flex-col items-start gap-1">
      <p className="text-xs text-gray-800 mb-0.5">Please select a day</p>
      <div className="grid grid-cols-7 gap-2 bg-white rounded-lg px-2 py-3 w-full">
        {Array.from({ length: 28 }, (element, index) => index + 1).map((day) => (
          <p
            onClick={() => props.onChange(day)}
            className={clsx(
              'flex items-center justify-center select-none px-1 py-1.5 cursor-pointer text-sm font-medium rounded-lg hover:bg-blue-700 hover:text-white',
              props.value === day ? 'bg-blue-800 text-white' : 'text-gray-800',
            )}
            key={day}
          >
            {day}
          </p>
        ))}
      </div>
      {props.value != undefined && (
        <p className="font-normal text-xs text-gray-800 mt-0.5">
          Your monthly contribution routine will run each month beginning{' '}
          {UTCDateLongFormatter.format(
            DateTime.now()
              .setZone(HMBradleyTimeZones.DepositLedger)
              .plus(DateTime.now().day <= props.value ? {} : { months: 1 })
              .set({ day: props.value })
              .toJSDate(),
          )}
          , at 9:00AM PST on the date selected.
        </p>
      )}
    </div>
  );
}
