import { DashboardProvider } from '@/routes/account/components/dashboard-provider';
import { DefaultMultiColumnLayout } from '@/routes/account/components/default-multi-column-layout';
import * as React from 'react';

export function getDashboardPageLayout(page: React.ReactNode) {
  return (
    <DashboardProvider>
      <DefaultMultiColumnLayout>{page}</DefaultMultiColumnLayout>
    </DashboardProvider>
  );
}

export function getNoopLayout(page: React.ReactNode) {
  return page;
}
