import { useGetDepositAccountsQuery } from '@/generated/graphql';

export function useHasDepositAccount() {
  const depositAccountsNycb = useGetDepositAccountsQuery(
    {},
    {
      select(data) {
        return data.depositAccounts;
      },
    },
  );

  return {
    isLoading: depositAccountsNycb.isLoading,
    isError: depositAccountsNycb.isError,
    hasDepositAccount: (depositAccountsNycb.data?.length ?? 0) > 0,
  };
}
