import { Modal } from '@/components/modal';
import React from 'react';

const EXCLUDED_DOMAINS = ['hmbradley.com', 'hmb.to'];

function includesWord(text: string, searchWords: string[] = []): boolean {
  return searchWords.some((el) => {
    return text.match(new RegExp(el, 'i'));
  });
}

export function ExternalWebsitePopup() {
  let [isOpen, setIsOpen] = React.useState(false);
  let [externalLink, setExternalLink] = React.useState('');

  React.useEffect(() => {
    const handleClick = (e) => {
      const target = e.target as HTMLElement;
      if (target.tagName === 'A') {
        const href = target.getAttribute('href');
        if (href && href.startsWith('http') && !includesWord(href, EXCLUDED_DOMAINS)) {
          e.preventDefault();
          setExternalLink(href);
          setIsOpen(true);
        }
      }
    };
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  function onCancel() {
    setIsOpen(false);
  }

  function onContinue() {
    window.open(externalLink, '_blank');
    setIsOpen(false);
  }

  return (
    <Modal isOpen={isOpen} overlayClassName="modal-overlay" closeModal={onCancel}>
      <div className="bg-white max-w-lg h-full w-full p-lg pb-lg box-border overflow-hidden rounded shadow">
        <h3 className="text-lg font-medium mb-2">You are now leaving HMBradley’s website</h3>
        <p className="text-base font-normal">
          HMBradley's website and/or mobile terms, privacy, and security policies don't apply to the site or app you're
          about to visit. Please review its terms, privacy, and security policies to see how they apply to you.
          HMBradley isn’t responsible for (and doesn't provide) any products, services or content at this third-party
          site or app, except for products and services that explicitly carry the HMBradley name.
        </p>
        <div className="flex gap-2 mt-3 justify-end w-full">
          <button className="flex px-3 py-2 border-gray border-2 rounded-lg" onClick={onCancel}>
            Cancel
          </button>
          <button
            className="flex px-3 py-2 border-gray-900 border-2 rounded-lg text-white bg-gray-900"
            onClick={onContinue}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
}
