import { LARGE, Spinner } from '@/components/loaders/spinner';
import COLORS from '@/styles/colors';

export function RouterSpinner() {
  return (
    <div
      role="progressbar"
      aria-busy="true"
      aria-live="polite"
      aria-valuetext="Loading page"
      className="fixed inset-0 flex items-center justify-center"
    >
      <Spinner color={COLORS.brand_teal} size={LARGE} />
    </div>
  );
}
