import { DepositAccount, DepositAccountType } from '@/generated/graphql';
import { toAmountInDollarsFromCents } from '@/helpers/currency';
import { firstCharCapitalize } from '@/helpers/strings';

export function getLastFour(accountNumber: string): string {
  /**
   * TODO: If the account number is less than 4 characters, it means that it is the last four of the account number
   *  ***MOST LIKELY***.
   *
   * The proper fix is to remove `getLastFour` usage from `createHMBradleyAccountNickname` because what we are not
   * taking into consideration is "WHEN" the account number is the full account number or not and
   * `createHMBradleyAccountNickname` is being used everywhere so the proper fix will take longer.
   *
   * What to do then?
   *
   * Dont two strings as input in `createHMBradleyAccountNickname` instead, take a data structure that allows you to
   * understand much better the data we are dealing with.
   */
  if (accountNumber.length <= 4) {
    return accountNumber;
  }

  return accountNumber.slice(accountNumber.length - 4);
}

export function createHMBradleyAccountNickname(accountType: string, accountNumber: string): string {
  return `HMBradley ${firstCharCapitalize(accountType)} Account ${getLastFour(accountNumber)}`;
}

export function createClosedAccountNickname(accountType: string, accountNumber: string): string {
  return `Closed ${firstCharCapitalize(accountType)} Account ${getLastFour(accountNumber)}`;
}

export function accountNameWithBalanceCents(accountType: string, accountNumber: string, balance: number): string {
  return `${firstCharCapitalize(accountType)} Account ${getLastFour(accountNumber)} (${toAmountInDollarsFromCents(
    balance,
  )})`;
}

export function accountName(accountType: string, accountNumber: string): string {
  return `${firstCharCapitalize(accountType)} Account ${getLastFour(accountNumber)}`;
}

export function getAccountLabel(account?: DepositAccount) {
  return `${
    account?.isPendingJointAccount || account?.accountType === DepositAccountType.Joint ? 'Joint ' : ''
  }Deposit Account ${account?.depositAccountNumber?.lastFour || ''}`;
}

export function accountToOption(account?: DepositAccount) {
  return {
    label: getAccountLabel(account),
    value: account?.id,
  };
}
