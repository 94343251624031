import { RedirectTo } from '@/components/redirect-to';
import {
  PATH_500,
  PATH_ONBOARDING_INFO,
  PATH_ONBOARDING_KYC_IDV,
  PATH_ONBOARDING_KYC_IDV_COMPLETED,
  PATH_ONBOARDING_KYC_PROCESSING,
  PATH_ONBOARDING_KYC_SORRY,
  PATH_ONBOARDING_ONE_CLICK_CREDIT,
  PATH_ONBOARDING_PHONE_VERIFICATION,
  PATH_ONBOARDING_TERMS,
  PATH_ONBOARDING_VERIFY,
} from '@/constants/routes';
import { useAuthenticatedSessionContext } from '@/hooks/use-session-context';
import { OnboardingStatus } from '@/types/mainframe';
import * as React from 'react';

export function OnboardingApprovedStatus(props: React.PropsWithChildren<any>) {
  const sessionContext = useAuthenticatedSessionContext();

  switch (sessionContext.user.onboardingStatus) {
    case OnboardingStatus.UserUnverified: {
      return <RedirectTo url={PATH_ONBOARDING_VERIFY} />;
    }
    case OnboardingStatus.UserVerified: {
      return <RedirectTo url={PATH_ONBOARDING_INFO} />;
    }
    case OnboardingStatus.PersonCreated: {
      return <RedirectTo url={PATH_ONBOARDING_PHONE_VERIFICATION} />;
    }
    case OnboardingStatus.PhoneVerified: {
      return <RedirectTo url={PATH_ONBOARDING_TERMS} />;
    }
    case OnboardingStatus.LegalSigned: {
      return <RedirectTo url={PATH_ONBOARDING_ONE_CLICK_CREDIT} />;
    }
    case OnboardingStatus.OccRecorded: {
      return <RedirectTo url={PATH_ONBOARDING_KYC_PROCESSING} />;
    }
    case OnboardingStatus.Denied: {
      return <RedirectTo url={PATH_ONBOARDING_KYC_SORRY} />;
    }
    case OnboardingStatus.ManualReview: {
      return <RedirectTo url={PATH_ONBOARDING_KYC_IDV} />;
    }
    case OnboardingStatus.ManualReviewPending: {
      return <RedirectTo url={PATH_ONBOARDING_KYC_IDV_COMPLETED} />;
    }
    case OnboardingStatus.Approved: {
      return props.children;
    }
    default: {
      return <RedirectTo url={PATH_500} />;
    }
  }
}
