import * as React from 'react';
import { TemplatesDrawerContext } from '../contexts/templates-drawer-context';

export function useTemplatesDrawerContext() {
  const value = React.useContext(TemplatesDrawerContext);

  if (value === undefined) {
    throw new Error('Templates Context is undefined');
  }

  return value;
}
