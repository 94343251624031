import {
  ActionTypes,
  ComparisonTypes,
  CreditCardPaymentBalanceTypes,
  Plan,
  TemplateCategories,
  TransferDirection,
} from '@/generated/graphql';
import { toAmountInDollars } from '@/helpers/currency';
import { isNil } from '@/helpers/is-nil';
import { firstCharCapitalize } from '@/helpers/strings';
import { formatPercent } from '@/helpers/to-percent';
import { formatOrdinalNumber } from '@/i18n/plural';
import { DateTime } from 'luxon';
import {
  ActionFormState,
  AmountTypes,
  DepositTransactionConditionsState,
  FrequencyTypes,
  TransactionType,
} from '../types';
import { queryBy } from './helpers';

export function formatMultiAction(actions: ActionFormState[]) {
  return actions.reduce(groupActions, []).join(', ');
}

export function formatConditions(conditions: DepositTransactionConditionsState) {
  const conditionText = [];
  if (!isNil(conditions.lessThan) || !isNil(conditions.greaterThan)) {
    conditionText.push('amount');
  }
  if (!isNil(conditions.transactionType)) {
    conditionText.push('type');
  }
  if (!isNil(conditions.category)) {
    conditionText.push('category');
  }
  if (!isNil(conditions.exactMatch) || !isNil(conditions.partialMatch)) {
    conditionText.push('description');
  }

  return conditionText.join(', ');
}

export function formatCondition(condition: string) {
  return queryBy(condition, {
    greaterThan: 'Greater than',
    lessThan: 'Less than',
    exactMatch: 'Exact Match',
    partialMatch: 'Partial Match',
    transactionType: 'Type',
  });
}

export function formatTransactionType(transactionType: TransactionType) {
  return queryBy(transactionType, {
    [TransactionType.DirectDeposit]: 'Direct Deposit',
    [TransactionType.Deposit]: 'Deposit',
    [TransactionType.Withdrawal]: 'Withdrawal',
  });
}

function groupActions(acc: string[], action: ActionFormState) {
  const actionName = formatActionName(action);
  if (acc.includes(actionName)) {
    return acc;
  }
  return [...acc, actionName];
}

function formatActionName(action: ActionFormState) {
  if (action && 'action' in action) {
    return queryBy(action.action, {
      [ActionTypes.Categorize]: 'categorize',
      [ActionTypes.Notification]: 'notify',
      [ActionTypes.PlanTransfer]: 'transfer',
      [ActionTypes.MultiPlanTransfer]: 'transfer',
      [ActionTypes.InternalTransfer]: 'transfer',
      [ActionTypes.ExternalTransfer]: 'transfer',
      [ActionTypes.CashbackRedemption]: 'redeem cashback',
      [ActionTypes.CreditCardPayment]: 'payment',
      [ActionTypes.Webhook]: 'webhook',
    });
  } else {
    return 'unknown';
  }
}

export function formatCategory(category: string) {
  return category.replace('AND', '&').split('_').map(firstCharCapitalize).join(' ');
}

export function formatTemplateCategory(category: TemplateCategories) {
  return queryBy(category, {
    [TemplateCategories.Featured]: 'Featured',
    [TemplateCategories.ManageSpending]: 'Manage spending',
    [TemplateCategories.SaveForTheFuture]: 'Save for the future',
    [TemplateCategories.SaveForToday]: 'Save for today',
    [TemplateCategories.BudgetTogether]: 'Budget together',
  });
}

export function formatAmount(amountType: AmountTypes) {
  return function (amount: number) {
    return amountType == AmountTypes.Amount ? toAmountInDollars(amount) : formatPercent(amount);
  };
}

export function formatPaymentBalanceType(balanceType: CreditCardPaymentBalanceTypes) {
  if (balanceType == CreditCardPaymentBalanceTypes.CurrentBalance) {
    return 'Current Balance';
  }

  if (balanceType == CreditCardPaymentBalanceTypes.MinimumDue) {
    return 'Minimum due';
  }

  if (balanceType == CreditCardPaymentBalanceTypes.RemainingStatementBalance) {
    return 'Remaining statement balance';
  }

  return '';
}

export function formatDirection(direction: TransferDirection) {
  return queryBy(direction, {
    [TransferDirection.Pull]: 'From',
    [TransferDirection.Push]: 'To',
  });
}

export function formatDirectionInvert(direction: TransferDirection) {
  return queryBy(direction, {
    [TransferDirection.Push]: 'From',
    [TransferDirection.Pull]: 'To',
  });
}

export function formatPlanName(plan: Plan) {
  return `Plan: ${plan.title}`;
}

export function formatFrequencyText(frequency: FrequencyTypes) {
  return queryBy(frequency, {
    [FrequencyTypes.Monthly]: 'on the',
    [FrequencyTypes.Weekly]: 'on',
  });
}

export function formatComparison(inequality: ComparisonTypes) {
  return queryBy(inequality, {
    [ComparisonTypes.Gt]: 'Greater than',
    [ComparisonTypes.Lt]: 'Less than',
  });
}

export function formatFrequency(frequency: FrequencyTypes) {
  return queryBy(frequency, {
    [FrequencyTypes.Monthly]: 'Month',
    [FrequencyTypes.Weekly]: 'Week',
  });
}

export function formatDateRoutine(opts: { frequency: FrequencyTypes; day: number[] }) {
  return queryBy(opts.frequency, {
    [FrequencyTypes.Weekly]: opts.day.map((day) => DateTime.now().set({ weekday: day }).weekdayLong).join(', '),
    [FrequencyTypes.Monthly]: opts.day.map((day) => formatOrdinalNumber(day)).join(', '),
  });
}
