import { UrlObject, UrlQuery } from '@/types/router';
import { removeUndefinedValues } from '.';

/** @public */
export function replacePathParams(urlPath: string, pathParams: Record<string, any> = {}) {
  return Object.entries(pathParams).reduce(
    (theUrlPath, [name, value]) => theUrlPath.replaceAll(`:${name}`, value),
    urlPath,
  );
}

function addQueryParams(urlPath: string, queryParams: Record<any, any>) {
  const searchParams = new URLSearchParams(queryParams);
  return `${urlPath}?${searchParams.toString()}`;
}

export function routeFor(
  routePath: string,
  params?: {
    path?: Record<string, any>;
    query?: Record<any, any>;
  },
) {
  const normalizedPath = replacePathParams(routePath, params?.path);

  if (params?.query) {
    return addQueryParams(normalizedPath, params.query);
  }

  return normalizedPath;
}

/**
 * Create a URL creator, adding strong typing and removing the needs to
 * memorize the pathname.
 *
 * @param pathname the pathname from the URL.
 * @public
 */
export function makeUrlFor<TPath = unknown, TQuery extends UrlQuery = undefined>(pathname: string) {
  return function (args: { path: TPath; query: TQuery }): UrlObject<TQuery> {
    return {
      query: removeUndefinedValues(args.query ?? ({} as TQuery)),
      pathname: replacePathParams(pathname, args.path),
    };
  };
}
