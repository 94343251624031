import { PATH_DEPOSIT_APPLICATION_INDIVIDUAL, PATH_DEPOSIT_APPLICATION_JOINT_INVITE } from '@/constants/routes';
import {
  ActionTypes,
  CreditCardFromAccountTypes,
  DepositAccount,
  DepositAccountType,
  RoutineFragment,
  TriggerTypes,
} from '@/generated/graphql';
import { byAccountId } from '@/helpers';
import { styledBy } from '@/helpers/csskit';
import { toAmountInDollars } from '@/helpers/currency';
import { isNil } from '@/helpers/is-nil';
import { ExternalAccount } from '@/hooks/use-external-accounts';
import { useCreatePlanDrawerContext } from '@/routes/account/components/create-plan-drawer/hooks/use-create-plan-drawer-context';
import { accountName } from '@/routes/account/helpers';
import { CardAccountV2 } from '@core/mainframe-js-sdk';
import {
  InformationCircleIcon,
  PauseCircleIcon,
  PencilIcon,
  PlayCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import { useQueryClient } from 'react-query';
import {
  formatAmount,
  formatCategory,
  formatComparison,
  formatConditions,
  formatDateRoutine,
  formatDirection,
  formatDirectionInvert,
  formatFrequency,
  formatFrequencyText,
  formatMultiAction,
  formatPaymentBalanceType,
  formatPlanName,
  formatTransactionType,
} from '../helpers/routine-formatters';
import {
  AccountType,
  ActionFormState,
  ActionOptionsState,
  DepositTransactionConditionsState,
  FrequencyTypes,
  RoutineColors,
  TriggerFormState,
  TriggerOptionsState,
} from '../types';
import { ArrowAltIcon } from './icons';

export function PauseListItem(props: { routine: RoutineFragment }) {
  return (
    <div className="w-28 ml-6 mr-6 flex items-center justify-start gap-4">
      {props.routine.isEnabled ? (
        <PauseCircleIcon className="w-6 h-6 text-gray-950" />
      ) : (
        <PlayCircleIcon className="w-6 h-6 text-gray-950" />
      )}
      <p className="text-md font-medium">{props.routine.isEnabled ? 'Disable' : 'Enable'}</p>
    </div>
  );
}

export function DeleteListItem() {
  return (
    <div className="w-28 ml-6 mr-6 flex items-center justify-start gap-4">
      <TrashIcon className="text-red-800 w-6 h-6" />
      <p className="text-md font-medium text-red-800">Delete</p>
    </div>
  );
}

export function EditListItem() {
  return (
    <div className="w-28 ml-6 mr-6 flex items-center justify-start gap-4">
      <PencilIcon className="text-gray-950 w-6 h-6" />
      <p className="text-md font-medium text-gray-950">Edit</p>
    </div>
  );
}

function ButtonWrapper(props: React.PropsWithChildren<{ href?: string; className: string; onClick?: () => void }>) {
  if (props.href) {
    return (
      <Link href={props.href} className={props.className}>
        {props.children}
      </Link>
    );
  }
  if (props.onClick) {
    return (
      <div onClick={props.onClick} role="button" className={props.className}>
        {props.children}
      </div>
    );
  }
  return <div className={props.className}>{props.children}</div>;
}

function Bubble<Tprops>(props: {
  text?: Tprops;
  fallback?: string;
  color: RoutineColors;
  formatter?: (text: Tprops) => string;
  onClick?: () => void;
  href?: string;
}) {
  let text = props.text as string;
  let color = props.color;
  if (props.text || props.text === 0) {
    if (props.formatter) {
      text = props.formatter(props.text);
    }
  } else {
    text = props.fallback;
    color = RoutineColors.Incomplete;
  }
  return (
    <ButtonWrapper
      href={props.href}
      onClick={props.onClick}
      className={clsx(
        styledBy(color, {
          [RoutineColors.Purple]: 'text-purple-800 bg-purple-50',
          [RoutineColors.Blue]: 'text-blue-800 bg-blue-50',
          [RoutineColors.Incomplete]: 'text-gray-400 border-1 border-gray-400',
          [RoutineColors.Draft]: 'bg-gray-100 text-gray-400',
        }),
        'cursor-pointer hover:opacity-80 p-2 rounded-lg px-3 flex',
      )}
    >
      <p className="text-sm font-medium whitespace-nowrap">{text}</p>
    </ButtonWrapper>
  );
}

function BubbleText(props: { text: string }) {
  return (
    <div className="text-gray-500 py-2 rounded-lg">
      <p className="text-sm font-medium">{props.text}</p>
    </div>
  );
}

function OpenAccountCTA(props: { href?: string; accountType: string; text: string; onClick?: () => void }) {
  return (
    <ButtonWrapper href={props.href} className="bg-yellow-200 flex flex-row gap-1 rounded-lg items-center pr-2">
      <div className="text-gray-400 bg-white border-1 border-gray-400 cursor-pointer p-2 rounded-lg px-3">
        <p className="text-sm font-medium">{props.accountType}</p>
      </div>
      <div className="flex flex-row gap-1 items-center">
        <InformationCircleIcon className="h-5 w-5 text-yellow-800" />
        <p className="text-sm font-medium text-yellow-800">{props.text}</p>
      </div>
    </ButtonWrapper>
  );
}

export function TriggerOverview(props: {
  href?: string;
  triggerType: TriggerTypes;
  depositAccounts: DepositAccount[];
  options: TriggerOptionsState;
  fallbackAccountName?: string;
  onEdit?: () => void;
  draft?: boolean;
  verbose?: boolean;
}) {
  function getAccountName(id: string) {
    const account = props.depositAccounts.find(byAccountId(id));
    return account
      ? accountName(
          account.accountType === DepositAccountType.Joint ? 'Joint' : 'Deposit',
          account.depositAccountNumber.lastFour,
        )
      : props.fallbackAccountName;
  }
  const numberOfDepositAccounts = props.depositAccounts.length;

  const triggerColor = props.draft ? RoutineColors.Draft : RoutineColors.Purple;
  if (props.triggerType == TriggerTypes.Category && props.options?.type == TriggerTypes.Category) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.category}
          fallback="Type"
          formatter={formatCategory}
          color={triggerColor}
        />
        <BubbleText text="In" />
        {numberOfDepositAccounts == 0 ? (
          <OpenAccountCTA accountType="Account" text="Open account" href={PATH_DEPOSIT_APPLICATION_INDIVIDUAL} />
        ) : (
          <Bubble
            href={props.href}
            onClick={props.onEdit}
            text={props.options.account}
            formatter={getAccountName}
            fallback="Account"
            color={triggerColor}
          />
        )}
      </div>
    );
  }

  if (props.triggerType == TriggerTypes.DepositTransaction && props.options?.type == TriggerTypes.DepositTransaction) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <BubbleText text="Transaction matches" />
        <ButtonWrapper
          href={props.href}
          onClick={props.onEdit}
          className={clsx(
            styledBy(triggerColor, {
              [RoutineColors.Purple]: 'text-purple-800 bg-purple-50',
              [RoutineColors.Blue]: 'text-blue-800 bg-blue-50',
              [RoutineColors.Incomplete]: 'text-gray-400 border-1 border-gray-400',
              [RoutineColors.Draft]: 'bg-gray-100 text-gray-400',
            }),
            'cursor-pointer hover:opacity-80 p-2 rounded-lg px-3 flex flex-row gap-2 items-center flex-wrap',
          )}
        >
          <svg width="17" height="12" viewBox="0 0 17 12" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.1 7C10.1 8.98822 8.48822 10.6 6.5 10.6C4.51177 10.6 2.9 8.98822 2.9 7C2.9 5.01177 4.51177 3.4 6.5 3.4C8.48822 3.4 10.1 5.01177 10.1 7ZM11 7C11 9.48528 8.98528 11.5 6.5 11.5C4.01472 11.5 2 9.48528 2 7C2 4.51472 4.01472 2.5 6.5 2.5C8.98528 2.5 11 4.51472 11 7ZM9.53772 11.4652C9.91136 11.2105 10.2514 10.9102 10.5497 10.5722C12.3257 10.3509 13.7 8.83593 13.7 7C13.7 5.16407 12.3257 3.64914 10.5497 3.42782C10.2514 3.08982 9.91136 2.78947 9.53772 2.53479C9.72193 2.51183 9.90959 2.5 10.1 2.5C12.5853 2.5 14.6 4.51472 14.6 7C14.6 9.48528 12.5853 11.5 10.1 11.5C9.90959 11.5 9.72193 11.4882 9.53772 11.4652Z"
              fill="#3321A6"
            />
          </svg>
          <p className="text-sm font-medium">{formatConditions(props.options.conditions)}</p>
        </ButtonWrapper>
        <BubbleText text="In" />
        {numberOfDepositAccounts == 0 ? (
          <OpenAccountCTA accountType="Account" text="Open account" href={PATH_DEPOSIT_APPLICATION_INDIVIDUAL} />
        ) : (
          <Bubble
            href={props.href}
            onClick={props.onEdit}
            text={props.options.depositAccount}
            fallback="Account"
            formatter={getAccountName}
            color={triggerColor}
          />
        )}
      </div>
    );
  }

  if (props.triggerType == TriggerTypes.Balance && props.options?.type == TriggerTypes.Balance) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.comparison}
          fallback="Comparison"
          formatter={formatComparison}
          color={triggerColor}
        />
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.amount}
          fallback="Amount"
          formatter={toAmountInDollars}
          color={triggerColor}
        />
        <BubbleText text="In" />
        {numberOfDepositAccounts == 0 ? (
          <OpenAccountCTA accountType="Account" text="Open account" href={PATH_DEPOSIT_APPLICATION_INDIVIDUAL} />
        ) : (
          <Bubble
            href={props.href}
            onClick={props.onEdit}
            text={props.options.account}
            fallback="Account"
            formatter={getAccountName}
            color={triggerColor}
          />
        )}
      </div>
    );
  }

  if (props.triggerType == TriggerTypes.Balance && props.options?.type == TriggerTypes.CashbackRedemption) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.comparison}
          fallback="Comparison"
          formatter={formatComparison}
          color={triggerColor}
        />
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.amount}
          fallback="Amount"
          formatter={toAmountInDollars}
          color={triggerColor}
        />
        <BubbleText text="In" />
        <Bubble href={props.href} onClick={props.onEdit} text="HMBradley Cashback Account" color={triggerColor} />
      </div>
    );
  }

  if (props.triggerType == TriggerTypes.Date && props.options?.type == TriggerTypes.Date) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <BubbleText text="Every" />
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.frequency}
          fallback="Frequency"
          formatter={formatFrequency}
          color={triggerColor}
        />
        <BubbleText text={formatFrequencyText(props.options.frequency)} />
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.day != undefined && props.options.frequency ? props.options : undefined}
          fallback="Day"
          formatter={formatDateRoutine}
          color={triggerColor}
        />
      </div>
    );
  }

  if (props.triggerType == TriggerTypes.UserCategory && props.options?.type == TriggerTypes.UserCategory) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.userCategory}
          fallback="Category"
          formatter={formatCategory}
          color={triggerColor}
        />
        <BubbleText text="In" />
        {numberOfDepositAccounts == 0 ? (
          <OpenAccountCTA accountType="Account" text="Open account" href={PATH_DEPOSIT_APPLICATION_INDIVIDUAL} />
        ) : (
          <Bubble
            href={props.href}
            onClick={props.onEdit}
            text={props.options.account}
            fallback="Account"
            formatter={getAccountName}
            color={triggerColor}
          />
        )}
      </div>
    );
  }

  if (props.triggerType == TriggerTypes.Description && props.options?.type == TriggerTypes.Description) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.description}
          fallback="Description"
          color={triggerColor}
        />
        <BubbleText text="In" />
        {numberOfDepositAccounts == 0 ? (
          <OpenAccountCTA accountType="Account" text="Open account" href={PATH_DEPOSIT_APPLICATION_INDIVIDUAL} />
        ) : (
          <Bubble
            href={props.href}
            onClick={props.onEdit}
            text={props.options.account}
            fallback="Account"
            formatter={getAccountName}
            color={triggerColor}
          />
        )}
      </div>
    );
  }
}

export function ActionOverview(props: {
  trigger: TriggerFormState;
  actionType: ActionTypes;
  depositAccounts: DepositAccount[];
  externalAccounts: ExternalAccount[];
  creditAccount: CardAccountV2 | undefined;
  options: ActionOptionsState;
  fallbackAccountName?: string;
  fallbackExternalAccountName?: string;
  onEdit?: () => void;
  href?: string;
  draft?: boolean;
  verbose?: boolean;
}) {
  const queryClient = useQueryClient();
  const createPlanDrawerContext = useCreatePlanDrawerContext();
  function onCreatePlanClick(accountId: string) {
    createPlanDrawerContext.openCreatePlanDrawer({
      accountId,
      onSuccessCallback: () => {
        queryClient.invalidateQueries('GetAllAccountsWithTransferLimits');
      },
    });
  }

  function getAccountName(id: string) {
    const account = props.depositAccounts.find(byAccountId(id));
    return account
      ? accountName(
          account.accountType === DepositAccountType.Joint ? 'Joint' : 'Deposit',
          account.depositAccountNumber.lastFour,
        )
      : props.fallbackAccountName;
  }
  function getExternalAccountName(id: string) {
    const account = props.externalAccounts.find(byAccountId(id));
    return account ? account.externalAccountNickname : props.fallbackExternalAccountName;
  }
  function getCreditAccountName() {
    const lastFour = props.creditAccount?.cards.find((card) => !card.isAuthorizedUserCard && card.isActive)?.lastFour;
    return lastFour ? `HMBradley Credit Card ${lastFour}` : '';
  }
  function sumAccountPlans(acc: number, account: DepositAccount) {
    return account.plans.length + acc;
  }

  const numberOfPlans = props.depositAccounts.reduce(sumAccountPlans, 0);
  const numberOfDepositAccounts = props.depositAccounts.length;

  const actionColor = props.draft ? RoutineColors.Draft : RoutineColors.Blue;
  if (props.actionType == ActionTypes.Categorize && props.options?.type == ActionTypes.Categorize) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        {props.verbose && <BubbleText text="Categorize as" />}
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.category}
          fallback="Category"
          formatter={formatCategory}
          color={actionColor}
        />
      </div>
    );
  }
  if (props.actionType == ActionTypes.Webhook && props.options?.type == ActionTypes.Webhook) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        {props.verbose && <BubbleText text="Notify" />}
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.webhookName}
          fallback="Name"
          color={actionColor}
        />
      </div>
    );
  }
  if (props.actionType == ActionTypes.Notification && props.options?.type == ActionTypes.Notification) {
    const notificationTypesArray = [
      props.options.email && 'Email',
      props.options.push && 'Push',
      props.options.sms && 'Text message',
    ].filter((i) => !!i);
    const text = notificationTypesArray.reduce(
      (acc, item, index) => acc + item + (index === notificationTypesArray.length - 1 ? '' : ', '),
      '',
    );

    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <BubbleText text={props.verbose ? 'Notify me via' : 'Via'} />
        <Bubble href={props.href} onClick={props.onEdit} text={text} color={actionColor} />
      </div>
    );
  }

  if (props.actionType == ActionTypes.PlanTransfer && props.options?.type == ActionTypes.PlanTransfer) {
    const depositAccount = props.options.depositAccount;
    const validPlan = props.depositAccounts
      .find(byAccountId(props.options.depositAccount))
      ?.plans.find(byAccountId(props.options.plan));
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <BubbleText text="Transfer" />
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.amount}
          fallback="Amount"
          formatter={formatAmount(props.options.amountType)}
          color={actionColor}
        />
        {[TriggerTypes.Date, TriggerTypes.CashbackRedemption].includes(props.trigger.options.type) && (
          <>
            <BubbleText text={formatDirectionInvert(props.options.direction)} />
            {numberOfDepositAccounts == 0 ? (
              <OpenAccountCTA accountType="Account" text="Open account" href={PATH_DEPOSIT_APPLICATION_INDIVIDUAL} />
            ) : (
              <Bubble
                href={props.href}
                onClick={props.onEdit}
                text={props.options.depositAccount}
                fallback="Account"
                formatter={getAccountName}
                color={actionColor}
              />
            )}
          </>
        )}
        <BubbleText text={formatDirection(props.options.direction)} />
        {numberOfPlans == 0 && numberOfDepositAccounts > 0 ? (
          <OpenAccountCTA accountType="Plan" text="Create Plan" onClick={() => onCreatePlanClick(depositAccount)} />
        ) : (
          <Bubble
            href={props.href}
            onClick={props.onEdit}
            text={validPlan}
            fallback="Plan"
            formatter={formatPlanName}
            color={actionColor}
          />
        )}
      </div>
    );
  }

  if (props.actionType == ActionTypes.InternalTransfer && props.options?.type == ActionTypes.InternalTransfer) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <BubbleText text="Transfer" />
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.amount}
          fallback="Amount"
          formatter={formatAmount(props.options.amountType)}
          color={actionColor}
        />
        <BubbleText text="From" />
        {numberOfDepositAccounts == 0 ? (
          <OpenAccountCTA accountType="Account" text="Open account" href={PATH_DEPOSIT_APPLICATION_INDIVIDUAL} />
        ) : (
          <Bubble
            href={props.href}
            onClick={props.onEdit}
            text={props.options.fromDepositAccount}
            fallback="Account"
            formatter={getAccountName}
            color={actionColor}
          />
        )}
        <BubbleText text="To" />
        {numberOfDepositAccounts < 2 ? (
          <OpenAccountCTA
            accountType="Joint Account"
            text="Open joint account"
            href={PATH_DEPOSIT_APPLICATION_JOINT_INVITE}
          />
        ) : (
          <Bubble
            href={props.href}
            onClick={props.onEdit}
            text={props.options.toDepositAccount}
            fallback="Account"
            formatter={getAccountName}
            color={actionColor}
          />
        )}
      </div>
    );
  }

  if (props.actionType == ActionTypes.ExternalTransfer && props.options?.type == ActionTypes.ExternalTransfer) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <BubbleText text="Transfer" />
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.amount}
          fallback="Amount"
          formatter={formatAmount(props.options.amountType)}
          color={actionColor}
        />
        <BubbleText text={formatDirectionInvert(props.options.direction)} />
        {numberOfDepositAccounts == 0 ? (
          <OpenAccountCTA accountType="Account" text="Open account" href={PATH_DEPOSIT_APPLICATION_INDIVIDUAL} />
        ) : (
          <Bubble
            href={props.href}
            onClick={props.onEdit}
            text={props.options.depositAccount}
            fallback="Account"
            formatter={getAccountName}
            color={actionColor}
          />
        )}
        <BubbleText text={formatDirection(props.options.direction)} />
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.externalAccount}
          fallback="External Account"
          formatter={getExternalAccountName}
          color={actionColor}
        />
      </div>
    );
  }

  if (props.actionType == ActionTypes.MultiAction && props.options?.type == ActionTypes.MultiAction) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <ButtonWrapper
          href={props.href}
          onClick={props.onEdit}
          className={clsx(
            styledBy(actionColor, {
              [RoutineColors.Purple]: 'text-purple-800 bg-purple-50',
              [RoutineColors.Blue]: 'text-blue-800 bg-blue-50',
              [RoutineColors.Incomplete]: 'text-gray-400 border-1 border-gray-400',
              [RoutineColors.Draft]: 'bg-gray-100 text-gray-400',
            }),
            'cursor-pointer hover:opacity-80 p-2 rounded-lg px-3 flex flex-row gap-2 items-center flex-wrap',
          )}
        >
          <svg width="18" height="14" viewBox="0 0 18 14" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.104 12.5775L9 10.6817L10.896 12.5775C11.1014 12.7829 11.4344 12.7829 11.6397 12.5775L16.846 7.37165C17.0513 7.16629 17.0513 6.83332 16.846 6.62796L11.6397 1.42209C11.4344 1.21672 11.1014 1.21673 10.896 1.42209L9 3.31796L7.104 1.42209C6.89862 1.21672 6.56564 1.21673 6.36026 1.42209L1.15403 6.62796C0.948655 6.83332 0.948655 7.16629 1.15404 7.37165L6.36026 12.5775C6.56564 12.7829 6.89862 12.7829 7.104 12.5775ZM2.26965 6.99981L6.73213 11.462L6.7333 11.462L11.1957 6.99988L6.7333 2.53777L6.73213 2.53763L2.26965 6.99981ZM11.2679 2.53763L9.74375 4.06165L12.3102 6.62796C12.5156 6.83332 12.5156 7.16629 12.3102 7.37165L9.74375 9.93796L11.2679 11.462L15.7303 6.99981L11.2679 2.53763Z"
              fill="currentColor"
            />
          </svg>
          <p className="text-sm font-medium">{formatMultiAction(props.options.actions)}</p>
        </ButtonWrapper>
      </div>
    );
  }
  if (props.actionType == ActionTypes.CashbackRedemption && props.options?.type == ActionTypes.CashbackRedemption) {
    const isDeposit = props.options.accountType === AccountType.DepositAccount;
    const nameFormatter = isDeposit ? getAccountName : getExternalAccountName;
    const fallback = isDeposit ? 'Account' : 'External Account';

    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <BubbleText text="Transfer" />
        <p className="p-2 rounded-lg px-3 text-blue-800 bg-blue-50 text-sm w-fit">Full cashback balance</p>
        <BubbleText text="to" />
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.accountId}
          fallback={fallback}
          formatter={nameFormatter}
          color={actionColor}
        />
      </div>
    );
  }
  if (props.actionType == ActionTypes.CreditCardPayment && props.options?.type == ActionTypes.CreditCardPayment) {
    const isDeposit = props.options.fromAccountType === CreditCardFromAccountTypes.Deposit;
    const nameFormatter = isDeposit ? getAccountName : getExternalAccountName;
    const fallback = isDeposit ? 'Account' : 'External Account';

    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <BubbleText text="Pay" />
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.paymentBalanceType}
          fallback="Amount"
          formatter={formatPaymentBalanceType}
          color={actionColor}
        />

        <BubbleText text="to" />
        <Bubble
          href={props.href}
          onClick={props.onEdit}
          text={props.options.creditCardAccountId}
          fallback="HMBradley Credit Card"
          formatter={getCreditAccountName}
          color={actionColor}
        />

        <BubbleText text="from" />
        {numberOfDepositAccounts == 0 ? (
          <OpenAccountCTA accountType="Account" text="Open account" href={PATH_DEPOSIT_APPLICATION_INDIVIDUAL} />
        ) : (
          <Bubble
            href={props.href}
            onClick={props.onEdit}
            text={props.options.fromAccountId}
            fallback={fallback}
            formatter={nameFormatter}
            color={actionColor}
          />
        )}
      </div>
    );
  }
}

export function ConditionsOverview(props: {
  conditions: DepositTransactionConditionsState;
  actionColor: RoutineColors;
}) {
  const elements = [];
  if (!isNil(props.conditions.greaterThan)) {
    elements.push({ text: 'Amount is greater than', val: toAmountInDollars(props.conditions.greaterThan) });
  }
  if (!isNil(props.conditions.lessThan)) {
    elements.push({ text: 'Amount is less than', val: toAmountInDollars(props.conditions.lessThan) });
  }
  if (!isNil(props.conditions.transactionType)) {
    elements.push({ text: 'Transaction type is', val: formatTransactionType(props.conditions.transactionType) });
  }
  if (!isNil(props.conditions.category)) {
    elements.push({ text: 'Category is', val: props.conditions.category });
  }
  if (!isNil(props.conditions.exactMatch)) {
    elements.push({ text: 'Description matches', val: props.conditions.exactMatch });
  }
  if (!isNil(props.conditions.partialMatch)) {
    elements.push({ text: 'Description contains', val: props.conditions.partialMatch });
  }
  return (
    <div className="flex flex-col gap-2">
      {elements.map((item) => (
        <div key={`overview-${item.text}`} className="flex flex-row gap-2 items-center flex-wrap">
          <BubbleText text={item.text} />
          <Bubble text={item.val} color={props.actionColor} />
        </div>
      ))}
    </div>
  );
}

function MinimalTriggerOverview(props: {
  href?: string;
  triggerType: TriggerTypes;
  depositAccounts: DepositAccount[];
  options: TriggerOptionsState;
}) {
  const numberOfDepositAccounts = props.depositAccounts.length;

  if (props.triggerType == TriggerTypes.DepositTransaction && props.options?.type == TriggerTypes.DepositTransaction) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <ButtonWrapper
          href={props.href}
          className={clsx(
            styledBy(RoutineColors.Purple, {
              [RoutineColors.Purple]: 'text-purple-800 bg-purple-50',
              [RoutineColors.Blue]: 'text-blue-800 bg-blue-50',
              [RoutineColors.Incomplete]: 'text-gray-400 border-1 border-gray-400',
              [RoutineColors.Draft]: 'bg-gray-100 text-gray-400',
            }),
            'cursor-pointer hover:opacity-80 p-2 rounded-lg px-3 flex flex-row gap-2 items-center flex-wrap',
          )}
        >
          <svg width="17" height="12" viewBox="0 0 17 12" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.1 7C10.1 8.98822 8.48822 10.6 6.5 10.6C4.51177 10.6 2.9 8.98822 2.9 7C2.9 5.01177 4.51177 3.4 6.5 3.4C8.48822 3.4 10.1 5.01177 10.1 7ZM11 7C11 9.48528 8.98528 11.5 6.5 11.5C4.01472 11.5 2 9.48528 2 7C2 4.51472 4.01472 2.5 6.5 2.5C8.98528 2.5 11 4.51472 11 7ZM9.53772 11.4652C9.91136 11.2105 10.2514 10.9102 10.5497 10.5722C12.3257 10.3509 13.7 8.83593 13.7 7C13.7 5.16407 12.3257 3.64914 10.5497 3.42782C10.2514 3.08982 9.91136 2.78947 9.53772 2.53479C9.72193 2.51183 9.90959 2.5 10.1 2.5C12.5853 2.5 14.6 4.51472 14.6 7C14.6 9.48528 12.5853 11.5 10.1 11.5C9.90959 11.5 9.72193 11.4882 9.53772 11.4652Z"
              fill="#3321A6"
            />
          </svg>
          <p className="text-sm font-medium">{formatConditions(props.options.conditions)}</p>
        </ButtonWrapper>
      </div>
    );
  }
  if (props.triggerType == TriggerTypes.Category && props.options?.type == TriggerTypes.Category) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <Bubble
          href={props.href}
          text={props.options.category}
          fallback="Type"
          formatter={formatCategory}
          color={RoutineColors.Purple}
        />
      </div>
    );
  }

  if (props.triggerType == TriggerTypes.Balance && props.options?.type == TriggerTypes.Balance) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <Bubble
          href={props.href}
          text={props.options.comparison}
          fallback="Comparison"
          formatter={formatComparison}
          color={RoutineColors.Purple}
        />
        <Bubble
          href={props.href}
          text={props.options.amount}
          fallback="Amount"
          formatter={toAmountInDollars}
          color={RoutineColors.Purple}
        />
        <BubbleText text="In" />
        {numberOfDepositAccounts == 0 ? (
          <OpenAccountCTA accountType="Account" text="Open account" href={PATH_DEPOSIT_APPLICATION_INDIVIDUAL} />
        ) : (
          <Bubble href={props.href} text={props.options.account} fallback="Account" color={RoutineColors.Purple} />
        )}
      </div>
    );
  }

  if (props.triggerType == TriggerTypes.Date && props.options?.type == TriggerTypes.Date) {
    if (props.options.frequency == FrequencyTypes.Monthly) {
      return (
        <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
          <Bubble
            href={props.href}
            text={props.options.day != undefined && props.options.frequency ? props.options : undefined}
            fallback="Day"
            formatter={formatDateRoutine}
            color={RoutineColors.Purple}
          />
          <BubbleText text="of" />
          <Bubble href={props.href} text="Month" color={RoutineColors.Purple} />
        </div>
      );
    } else {
      return (
        <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
          <Bubble
            href={props.href}
            text={props.options.day != undefined && props.options.frequency ? props.options : undefined}
            fallback="Day"
            formatter={formatDateRoutine}
            color={RoutineColors.Purple}
          />
        </div>
      );
    }
  }

  if (props.triggerType == TriggerTypes.UserCategory && props.options?.type == TriggerTypes.UserCategory) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <Bubble
          href={props.href}
          text={props.options.userCategory}
          fallback="Category"
          formatter={formatCategory}
          color={RoutineColors.Purple}
        />
      </div>
    );
  }

  if (props.triggerType == TriggerTypes.Description && props.options?.type == TriggerTypes.Description) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <Bubble
          href={props.href}
          text={props.options.description}
          fallback="Description"
          color={RoutineColors.Purple}
        />
      </div>
    );
  }

  if (props.triggerType == TriggerTypes.Balance && props.options?.type == TriggerTypes.CashbackRedemption) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <Bubble
          href={props.href}
          text={props.options.amount}
          fallback="Amount"
          formatter={toAmountInDollars}
          color={RoutineColors.Purple}
        />
        <BubbleText text="In" />
        <Bubble href={props.href} text="HMBradley Cashback Account" color={RoutineColors.Purple} />
      </div>
    );
  }
}

function MinimalActionOverview(props: {
  trigger: TriggerFormState;
  actionType: ActionTypes;
  depositAccounts: DepositAccount[];
  externalAccounts: ExternalAccount[];
  options: ActionOptionsState;
  href?: string;
}) {
  const createPlanDrawerContext = useCreatePlanDrawerContext();
  function onCreatePlanClick(accountId: string) {
    createPlanDrawerContext.openCreatePlanDrawer({ accountId });
  }
  function sumAccountPlans(acc: number, account: DepositAccount) {
    return account.plans.length + acc;
  }

  const numberOfPlans = props.depositAccounts.reduce(sumAccountPlans, 0);
  const numberOfDepositAccounts = props.depositAccounts.length;
  if (props.actionType == ActionTypes.Categorize && props.options?.type == ActionTypes.Categorize) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <Bubble
          href={props.href}
          text={props.options.category}
          fallback="Category"
          formatter={formatCategory}
          color={RoutineColors.Blue}
        />
      </div>
    );
  }
  if (props.actionType == ActionTypes.Notification && props.options?.type == ActionTypes.Notification) {
    return (
      <div className="flex flex-row gap-2 items-center  flex-wrap">
        <BubbleText text="Notify me via" />
        {props.options.email && <Bubble href={props.href} text="Email" color={RoutineColors.Blue} />}
        {props.options.push && <Bubble href={props.href} text="Push" color={RoutineColors.Blue} />}
        {props.options.sms && <Bubble href={props.href} text="Text message" color={RoutineColors.Blue} />}
      </div>
    );
  }
  if (props.actionType == ActionTypes.Webhook && props.options?.type == ActionTypes.Webhook) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <BubbleText text="Notify" />
        <Bubble href={props.href} text={props.options.webhookName} fallback="Name" color={RoutineColors.Blue} />
      </div>
    );
  }
  if (props.actionType == ActionTypes.PlanTransfer && props.options?.type == ActionTypes.PlanTransfer) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <Bubble
          href={props.href}
          text={props.options.amount}
          fallback="Amount"
          formatter={formatAmount(props.options.amountType)}
          color={RoutineColors.Blue}
        />
        {[TriggerTypes.Date, TriggerTypes.CashbackRedemption].includes(props.trigger.options.type) && (
          <>
            <BubbleText text={formatDirectionInvert(props.options.direction)} />
            {numberOfDepositAccounts == 0 ? (
              <OpenAccountCTA accountType="Account" text="Open account" href={PATH_DEPOSIT_APPLICATION_INDIVIDUAL} />
            ) : (
              <Bubble
                href={props.href}
                text={props.options.depositAccount}
                fallback="Account"
                color={RoutineColors.Blue}
              />
            )}
          </>
        )}
        <BubbleText text={formatDirection(props.options.direction)} />
        {numberOfPlans == 0 && numberOfDepositAccounts > 0 ? (
          <OpenAccountCTA
            accountType="Plan"
            text="Create Plan"
            onClick={() => onCreatePlanClick(props.depositAccounts[0].id)}
          />
        ) : (
          <Bubble href={props.href} fallback="Plan" formatter={formatPlanName} color={RoutineColors.Blue} />
        )}
      </div>
    );
  }

  if (props.actionType == ActionTypes.InternalTransfer && props.options?.type == ActionTypes.InternalTransfer) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <Bubble
          href={props.href}
          text={props.options.amount}
          fallback="Amount"
          formatter={formatAmount(props.options.amountType)}
          color={RoutineColors.Blue}
        />
        <BubbleText text="From" />
        {numberOfDepositAccounts == 0 ? (
          <OpenAccountCTA accountType="Account" text="Open account" href={PATH_DEPOSIT_APPLICATION_INDIVIDUAL} />
        ) : (
          <Bubble href={props.href} fallback="Account" color={RoutineColors.Blue} />
        )}
        <BubbleText text="To" />
        {numberOfDepositAccounts < 2 ? (
          <OpenAccountCTA
            accountType="Joint Account"
            text="Open joint account"
            href={PATH_DEPOSIT_APPLICATION_JOINT_INVITE}
          />
        ) : (
          <Bubble href={props.href} fallback="Joint Account" color={RoutineColors.Blue} />
        )}
      </div>
    );
  }

  if (props.actionType == ActionTypes.ExternalTransfer && props.options?.type == ActionTypes.ExternalTransfer) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <Bubble
          href={props.href}
          text={props.options.amount}
          fallback="Amount"
          formatter={formatAmount(props.options.amountType)}
          color={RoutineColors.Blue}
        />
        <BubbleText text={formatDirectionInvert(props.options.direction)} />
        {numberOfDepositAccounts == 0 ? (
          <OpenAccountCTA accountType="Account" text="Open account" href={PATH_DEPOSIT_APPLICATION_INDIVIDUAL} />
        ) : (
          <Bubble href={props.href} text={props.options.depositAccount} fallback="Account" color={RoutineColors.Blue} />
        )}
        <BubbleText text={formatDirection(props.options.direction)} />
        <Bubble
          href={props.href}
          text={props.options.externalAccount}
          fallback="External Account"
          color={RoutineColors.Blue}
        />
      </div>
    );
  }

  if (props.actionType == ActionTypes.MultiAction && props.options?.type == ActionTypes.MultiAction) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <ButtonWrapper
          href={props.href}
          className="text-blue-800 bg-blue-50 cursor-pointer hover:opacity-80 p-2 rounded-lg px-3 flex flex-row gap-2 items-center flex-wrap"
        >
          <svg width="18" height="14" viewBox="0 0 18 14" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.104 12.5775L9 10.6817L10.896 12.5775C11.1014 12.7829 11.4344 12.7829 11.6397 12.5775L16.846 7.37165C17.0513 7.16629 17.0513 6.83332 16.846 6.62796L11.6397 1.42209C11.4344 1.21672 11.1014 1.21673 10.896 1.42209L9 3.31796L7.104 1.42209C6.89862 1.21672 6.56564 1.21673 6.36026 1.42209L1.15403 6.62796C0.948655 6.83332 0.948655 7.16629 1.15404 7.37165L6.36026 12.5775C6.56564 12.7829 6.89862 12.7829 7.104 12.5775ZM2.26965 6.99981L6.73213 11.462L6.7333 11.462L11.1957 6.99988L6.7333 2.53777L6.73213 2.53763L2.26965 6.99981ZM11.2679 2.53763L9.74375 4.06165L12.3102 6.62796C12.5156 6.83332 12.5156 7.16629 12.3102 7.37165L9.74375 9.93796L11.2679 11.462L15.7303 6.99981L11.2679 2.53763Z"
              fill="currentColor"
            />
          </svg>
          <p className="text-sm font-medium">{formatMultiAction(props.options.actions)}</p>
        </ButtonWrapper>
      </div>
    );
  }
  if (props.actionType == ActionTypes.CashbackRedemption && props.options?.type == ActionTypes.CashbackRedemption) {
    return (
      <div className="flex flex-row gap-y-2 gap-x-4 items-center flex-wrap">
        <BubbleText text="Transfer" />
        <p className="p-2 rounded-lg px-3 text-blue-800 bg-blue-50 text-sm w-fit">Full cashback balance</p>
      </div>
    );
  }
}

export function MinimalOverview(props: {
  href?: string;
  trigger: TriggerFormState;
  action: ActionFormState;
  depositAccounts: DepositAccount[];
  externalAccounts: ExternalAccount[];
  fallbackAccountName?: string;
  fallbackExternalAccountName?: string;
  draft?: boolean;
}) {
  return (
    <div className="flex flex-row flex-wrap items-center gap-2 whitespace-nowrap">
      <MinimalTriggerOverview
        href={props.href}
        triggerType={props.trigger.trigger}
        options={props.trigger.options}
        depositAccounts={props.depositAccounts}
      />
      <ArrowAltIcon className="py-4" />
      <MinimalActionOverview
        href={props.href}
        trigger={props.trigger}
        actionType={props.action?.action}
        options={props.action?.options}
        depositAccounts={props.depositAccounts}
        externalAccounts={props.externalAccounts}
      />
    </div>
  );
}
