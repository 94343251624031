import * as yup from 'yup';

export const PLAN_TYPE_OPTIONS = [
  {
    name: 'Emergency',
    color: '#EB5F2D',
  },
  {
    name: 'House',
    color: '#1FC052',
  },
  {
    name: 'Car',
    color: '#1FC052',
  },
  {
    name: 'Taxes',
    color: '#1EA1C1',
  },
  {
    name: 'Education',
    color: '#7561E2',
  },
  {
    name: 'Vacation',
    color: '#1EA1C1',
  },
];
export const CUSTOM_PLAN_TYPE_NAME = 'custom';

export const planInfoSchema = yup.object({
  name: yup.string().required('Please enter your first name'),
  color: yup.string().required('Please enter your first name'),
  description: yup.string(),
  assignAccountNumber: yup.bool(),
});
export interface IPlanInfo extends yup.Asserts<typeof planInfoSchema> {}

export const defaultPlanInfo = {
  name: '',
  color: '#EB5F2D',
  description: '',
  assignAccountNumber: false,
};

export enum CreatePlanSteps {
  PlanType = 'PlanType',
  PlanName = 'PlanName',
  PlanGoal = 'PlanGoal',
  ProjectedPlan = 'ProjectedPlan',
  FundPlan = 'FundPlan',
  Congratulations = 'Congratulations',
}
