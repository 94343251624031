import * as React from 'react';

export function useTemplatesDrawer() {
  const [isOpen, setIsOpen] = React.useState(false);

  function openTemplatesDrawer() {
    setIsOpen(true);
  }

  function closeTemplatesDrawer() {
    setIsOpen(false);
  }

  return { isOpen, openTemplatesDrawer, closeTemplatesDrawer };
}
