import {
  CreatePlanSteps,
  defaultPlanInfo,
  IPlanGoal,
  IPlanInfo,
} from '@/routes/account/components/create-plan-drawer/helpers';
import React from 'react';

export function useCreatePlanDrawer() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [withFundPlanSteps, setWithFundPlanSteps] = React.useState(false);
  const [accountId, setAccountId] = React.useState<string | undefined>();
  const [onSuccess, setOnSuccess] = React.useState<(planId?: string) => void>();

  const [step, setStep] = React.useState<CreatePlanSteps>(CreatePlanSteps.PlanType);
  const [planTypeName, setPlanTypeName] = React.useState<string | undefined>();
  const [planInfo, setPlanInfo] = React.useState<IPlanInfo>(defaultPlanInfo);
  const [planGoal, setPlanGoal] = React.useState<IPlanGoal | undefined>();
  const [day, setDay] = React.useState<number | undefined>();

  function openCreatePlanDrawer(params: {
    accountId: string;
    onSuccessCallback?: (planId: string) => void;
    withFundPlanSteps?: boolean;
  }) {
    setIsOpen(true);
    setAccountId(params.accountId);
    if (!!params.onSuccessCallback) {
      setOnSuccess(() => params.onSuccessCallback);
    }
    if (!!params.withFundPlanSteps) {
      setWithFundPlanSteps(true);
    }
  }

  function closeCreatePlanDrawer() {
    setIsOpen(false);
    setWithFundPlanSteps(false);
    setAccountId(undefined);
    setStep(CreatePlanSteps.PlanType);
    setPlanTypeName(undefined);
    setPlanInfo(undefined);
    setPlanGoal(undefined);
    setDay(undefined);
  }

  function getStepNumber() {
    if (step === CreatePlanSteps.PlanType) {
      return 1;
    }

    if (step === CreatePlanSteps.PlanName) {
      return 2;
    }

    if (step === CreatePlanSteps.PlanGoal || step === CreatePlanSteps.ProjectedPlan) {
      return 3;
    }

    return 4;
  }

  return {
    isOpen,
    accountId,
    openCreatePlanDrawer,
    closeCreatePlanDrawer,
    planTypeName,
    setPlanTypeName,
    planInfo,
    setPlanInfo,
    planGoal,
    setPlanGoal,
    step,
    stepNumber: getStepNumber(),
    setStep,
    onSuccess,
    withFundPlanSteps,
    day,
    setDay,
  };
}
