import { useFeatureWithUserStrategies } from '@/components/feature-flag/hooks/use-feature-with-user-strategies';
import * as React from 'react';

type FeatureFlagProps = {
  fallback?: React.FunctionComponent<{}>;
  renderFallback?: () => JSX.Element;
  flag: string;
  invert?: boolean;
};

export function FeatureFlag(props: React.PropsWithChildren<FeatureFlagProps>) {
  const { fallback: Fallback = null, renderFallback = () => null, children = null, invert = false } = props;
  const { isEnabled } = useFeatureWithUserStrategies(props.flag);
  const fallback = Fallback ? <Fallback /> : renderFallback();

  if (invert) {
    return isEnabled ? fallback : children;
  }

  return isEnabled ? children : fallback;
}
