import { httpClient } from '@/transports/http';
import { useGetCardV2Accounts } from '@core/mainframe-react-query';

export function useHasOpenedCreditAccount() {
  const cardV2Accounts = useGetCardV2Accounts(httpClient, {
    options: {
      select(data) {
        const account = data?.data?.find((account) => !account?.isAccountClosed && account.accountType === 'Credit');
        return {
          account,
        };
      },
    },
  });
  return {
    isLoading: cardV2Accounts.isLoading,
    isError: cardV2Accounts.isError,
    hasOpenedCreditAccount: Boolean(cardV2Accounts.data?.account?.accountID),
    accountId: cardV2Accounts.data?.account?.accountID,
    account: cardV2Accounts.data?.account,
  };
}
