import { BaseError } from '@/components/error-page';
import { ApiStatusContext } from '@/contexts/api-status-context';
import { useApiStatus } from '@/hooks/use-api-status';
import * as React from 'react';

type APIStatusProviderProps = React.PropsWithChildren<{}>;

export function ApiStatusProvider(props: APIStatusProviderProps) {
  const value = useApiStatus();
  return (
    <ApiStatusContext.Provider value={value}>
      {value.isError ? <BaseError type="500" /> : props.children}
    </ApiStatusContext.Provider>
  );
}
