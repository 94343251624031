import { FAQ, HELP_DESK } from '@/constants/app';
import {
  ACCOUNT_DETAILS_PATH,
  ACCOUNT_MANAGE_APP_CREDENTIALS_PATH,
  ACCOUNT_MANAGE_CARD_PATH,
  ACCOUNT_MANAGE_LINKED_ACCOUNTS_CONNECT_MANUALLY_PATH,
  ACCOUNT_MANAGE_LINKED_ACCOUNTS_CONNECT_PATH,
  ACCOUNT_MANAGE_LINKED_ACCOUNTS_PATH,
  ACCOUNT_REFERRALS_PATH,
  CREDIT_PAYMENT_PATH,
  CREDIT_REWARDS_PATH,
  FINANCIAL_INSIGHTS_PATH,
  NYCB_DEPOSIT_STATEMENTS_PATH,
  NYCB_TRANSFER_PATH,
} from '@/constants/routes';
import { routeFor } from '@/helpers/router';
import { useActiveCreditAccount } from '@/hooks/use-active-account';
import { useHasDepositAccount } from '@/hooks/use-has-deposit_account';
import { useHasOpenedCreditAccount } from '@/hooks/use-has-opened-credit-account';
import { useLogout } from '@/hooks/use-session';
import { useRouter } from 'next/router';
import { CommandScope, filterScope, PaletteCommand } from '../helpers';

export function useCommandPalette() {
  const router = useRouter();
  const activeCreditAccount = useActiveCreditAccount();
  const depositAcc = useHasDepositAccount();
  const creditAcc = useHasOpenedCreditAccount();

  const logout = useLogout();

  const commands: PaletteCommand[] = [
    {
      name: 'Transfer',
      scopes: [CommandScope.Deposit],
      command() {
        router.push(NYCB_TRANSFER_PATH);
      },
    },
    {
      name: 'Credit Card Payments',
      scopes: [CommandScope.Credit],
      command() {
        activeCreditAccount?.id
          ? router.push(
              routeFor(CREDIT_PAYMENT_PATH, {
                path: {
                  cardId: activeCreditAccount.id,
                },
              }),
            )
          : router.push(CREDIT_PAYMENT_PATH);
      },
    },
    {
      name: 'Cashback Rewards',
      scopes: [CommandScope.Credit],
      command() {
        activeCreditAccount?.id
          ? router.push(
              routeFor(CREDIT_REWARDS_PATH, {
                path: {
                  cardId: activeCreditAccount.id,
                },
              }),
            )
          : router.push(CREDIT_REWARDS_PATH);
      },
    },
    {
      name: 'Statements',
      scopes: [CommandScope.Deposit],
      command() {
        router.push(NYCB_DEPOSIT_STATEMENTS_PATH);
      },
    },
    {
      name: 'Manage Cards',
      scopes: [],
      command() {
        router.push(ACCOUNT_MANAGE_CARD_PATH);
      },
    },
    {
      name: 'External Accounts',
      scopes: [],
      command() {
        router.push(ACCOUNT_MANAGE_LINKED_ACCOUNTS_PATH);
      },
    },
    {
      name: 'App credentials',
      scopes: [],
      command() {
        router.push(ACCOUNT_MANAGE_APP_CREDENTIALS_PATH);
      },
    },
    {
      name: 'Connect bank account manually',
      scopes: [],
      command() {
        router.push(ACCOUNT_MANAGE_LINKED_ACCOUNTS_CONNECT_MANUALLY_PATH);
      },
    },
    {
      name: 'Link bank account',
      scopes: [],
      command() {
        router.push(ACCOUNT_MANAGE_LINKED_ACCOUNTS_CONNECT_PATH);
      },
    },
    {
      name: 'Credit',
      scopes: [],
      command() {
        router.push(FINANCIAL_INSIGHTS_PATH);
      },
    },
    {
      name: 'Referral Code',
      scopes: [],
      command() {
        router.push(ACCOUNT_REFERRALS_PATH);
      },
    },
    {
      name: 'Personal Information',
      scopes: [],
      command() {
        router.push({ pathname: ACCOUNT_DETAILS_PATH, query: { nestedRoute: 'personal' } });
      },
    },
    {
      name: 'Account',
      scopes: [],
      command() {
        router.push({ pathname: ACCOUNT_DETAILS_PATH, query: { nestedRoute: 'accounts' } });
      },
    },
    {
      name: 'Logout',
      scopes: [],
      command() {
        return logout.onLogout();
      },
    },
    {
      name: 'Security',
      scopes: [],
      command() {
        router.push({ pathname: ACCOUNT_DETAILS_PATH, query: { nestedRoute: 'security' } });
      },
    },
    {
      name: 'FAQs',
      scopes: [],
      command() {
        window.open(FAQ, '_blank');
      },
    },
    {
      name: 'Support',
      scopes: [],
      command() {
        window.open(HELP_DESK, '_blank');
      },
    },
    {
      name: 'Report a bug',
      scopes: [],
      command() {
        window.open(`${HELP_DESK}/support-email?tag=bugreport`, '_blank');
      },
    },
  ];

  return {
    commands: commands.filter(
      filterScope({
        hasCreditAccount: creditAcc.hasOpenedCreditAccount,
        hasDepositAccount: depositAcc.hasDepositAccount,
      }),
    ),
  };
}
