import {
  ActionTypes,
  CategoryTypes,
  ComparisonTypes,
  Consents,
  CreditCardFromAccountTypes,
  CreditCardPaymentBalanceTypes,
  TransactionCategories,
  TransferDirection,
  TriggerTypes,
} from '@/generated/graphql';

export enum FrequencyTypes {
  Weekly = 'weekly',
  Monthly = 'monthly',
}
export enum RoutineColors {
  Purple = 'purple',
  Gray = 'gray',
  Blue = 'blue',
  Incomplete = 'incomplete',
  Draft = 'draft',
}
export enum AmountTypes {
  Percentage = 'percentage',
  Amount = 'amount',
}

export enum AccountType {
  DepositAccount = 'deposit_account',
  ExternalAccount = 'external_account',
}

export type NameFormState = {
  title: string;
  description: string;
};

export type TriggerFormState = {
  trigger: TriggerTypes;
  options: TriggerOptionsState;
};

export type TriggerOptionsState =
  | DepositTransactionTriggerState
  | CategoryTriggerState
  | DescriptionTriggerState
  | UserCategoryTriggerState
  | DateTriggerState
  | BalanceTriggerState
  | CashbackTriggerState;

export type DepositTransactionTriggerState = {
  type: TriggerTypes.DepositTransaction;
  depositAccount: string;
  conditions: DepositTransactionConditionsState;
};

export enum TransactionType {
  DirectDeposit = 'direct_deposit',
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
}

export type DepositTransactionConditionsState = {
  category?: TransactionCategories;
  transactionType?: TransactionType;
  exactMatch?: string;
  greaterThan?: number;
  lessThan?: number;
  partialMatch?: string;
};

export type CategoryTriggerState = {
  type: TriggerTypes.Category;
  category: CategoryTypes;
  account: string;
};

export type BalanceTriggerState = {
  type: TriggerTypes.Balance;
  amount: number;
  comparison: ComparisonTypes;
  account: string;
};

export type CashbackTriggerState = {
  type: TriggerTypes.CashbackRedemption;
  amount: number;
  comparison: ComparisonTypes;
  account: string;
};

export type UserCategoryTriggerState = {
  type: TriggerTypes.UserCategory;
  userCategory: TransactionCategories;
  account: string;
};

export type DateTriggerState = {
  type: TriggerTypes.Date;
  frequency: FrequencyTypes;
  day: number[];
};

export type DescriptionTriggerState = {
  type: TriggerTypes.Description;
  description: string;
  account: string;
};

export type ActionFormState = {
  action: ActionTypes;
  options: ActionOptionsState;
};

export type ActionOptionsState =
  | PlanTransferActionState
  | InternalTransferActionState
  | CategorizeActionState
  | ExternalTransferActionState
  | NotificationActionState
  | WebhookActionState
  | MultiActionState
  | CreditCardCashbackTransferActionState
  | CreditCardPaymentActionState;

export type MultiActionState = {
  type: ActionTypes.MultiAction;
  actions: ActionFormState[];
};

export type ActionOption = {
  label: string;
  value: ActionTypes;
};

export type PlanTransferActionState = {
  type: ActionTypes.PlanTransfer;
  direction: TransferDirection;
  depositAccount: string;
  amount: number;
  amountType: AmountTypes;
  plan: string;
};

export type CategorizeActionState = {
  type: ActionTypes.Categorize;
  depositAccount: string;
  category: TransactionCategories;
};

export type InternalTransferActionState = {
  type: ActionTypes.InternalTransfer;
  amount: number;
  amountType: AmountTypes;
  toDepositAccount: string;
  fromDepositAccount: string;
};

export type ExternalTransferActionState = {
  type: ActionTypes.ExternalTransfer;
  direction: TransferDirection;
  amount: number;
  amountType: AmountTypes;
  depositAccount: string;
  externalAccount: string;
  consents: Consents[];
};

export type NotificationActionState = {
  type: ActionTypes.Notification;
  sms: boolean;
  email: boolean;
  push: boolean;
  message: string;
};

export type WebhookActionState = {
  type: ActionTypes.Webhook;
  url: string;
  webhookName: string;
  description: string;
  headers: { key: string; value: string }[];
};

export type RoutineFormState = {
  name: NameFormState;
  trigger: TriggerFormState;
  action: ActionFormState;
};

export type CreditCardCashbackTransferActionState = {
  type: ActionTypes.CashbackRedemption;
  accountId: string;
  accountType: AccountType;
};

export type CreditCardPaymentActionState = {
  type: ActionTypes.CreditCardPayment;
  paymentBalanceType: CreditCardPaymentBalanceTypes;
  fromAccountType: CreditCardFromAccountTypes;
  fromAccountId: string;
  creditCardAccountId: string;
  consents: Consents[];
};
