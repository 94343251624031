/**
 * Returns styles based on the `attr` value from the props.
 *
 * @param attr the attributes selector of the props.
 * @param mapping defines the styles matching the key of the object to the
 * possible values of `attr` from props.
 */
export function styledBy<TProps = any>(attr: string | number, mapping: Record<any, string>) {
  if (process.env.NODE_ENV !== 'production') {
    if (!mapping.hasOwnProperty(attr)) {
      throw new Error(`[styledBy] The attribute "${attr}" is not defined in the mapping.`);
    }
  }

  return mapping[attr];
}
