import { LARGE, Spinner } from '@/components/loaders/spinner';
import COLORS from '@/styles/colors';

export function Loading() {
  return (
    <div data-testid="loading-view" className="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center">
      <Spinner color={COLORS.brand_teal} size={LARGE} />
    </div>
  );
}
