import { ColorPicker } from '@/components/create-plan/components/plan-info-form/components/color-picker';
import { TextField } from '@/components/form/components/form-inputs/text-field';
import { InfoButtonToolTip } from '@/components/info-button-tool-tip';
import { SwitchContainer } from '@/components/switch';
import { URL_HMB_TO_NYCB_DEPOSIT_AGREEMENT } from '@/constants/app';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

export function PlanInfoForm(props: {
  form: UseFormReturn<{ name: string; color: string; description: string; assignAccountNumber: boolean }>;
  title?: string;
  withAddAccountNumber?: boolean;
}) {
  return (
    <form>
      <p className="text-lg text-gray-950 mb-3">{props.title || 'Name your Savings Plan'}</p>
      <div className="flex w-full items-start mb-2">
        <TextField
          {...props.form.register('name')}
          label="Plan name"
          placeholder="Plan name"
          className="flex-1 mr-3"
          error={props.form.formState.errors?.name?.message}
          required
        />
        <Controller
          control={props.form.control}
          name="color"
          render={({ field }) => (
            <ColorPicker value={props.form.getValues('color')} onChange={field.onChange} label="Plan color" />
          )}
        />
      </div>
      <TextField
        isOptional
        className="flex-1"
        {...props.form.register('description')}
        label="Description"
        placeholder="Add note here..."
        error={props.form.formState.errors?.description?.message}
        required
      />
      {props.withAddAccountNumber && (
        <div className="mt-6">
          <p className="text-lg text-gray-950 mb-4">Add an account number</p>
          <div className="flex items-center p-4 rounded-md border-1 border-gray-900 justify-between mb-4">
            <div className="flex items-center">
              <p className="text-gray-900 text-base">Pay from this Plan</p>
              <InfoButtonToolTip
                className="mt-px ml-1.5"
                icon={<QuestionMarkCircleIcon className="w-5 h-5 text-gray-500" />}
              >
                <div className="bg-gray-700 rounded p-4">
                  <p className="text-white font-medium text-sm mb-3">
                    Want to spend directly from this Plan? You can do so by adding an account number.
                  </p>
                  <p className="text-white font-medium text-sm">
                    See{' '}
                    <Link
                      target="_blank"
                      href={URL_HMB_TO_NYCB_DEPOSIT_AGREEMENT}
                      className="underline"
                      rel="noreferrer"
                    >
                      Deposit Account Agreement and Disclosures
                    </Link>{' '}
                    for details.
                  </p>
                </div>
              </InfoButtonToolTip>
            </div>
            <SwitchContainer
              onChange={(value) => {
                props.form.setValue('assignAccountNumber', value, {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                });
              }}
              value={props.form.watch().assignAccountNumber}
              labels={['', '']}
            />
          </div>
          <p className="text-gray-800 font-normal text-sm">
            By adding an account number, you can transact directly from this Plan. Whether you want to send your
            paycheck to or pay recurring bills from a Plan, you can bypass your main account when making transactions or
            deposits.
          </p>
        </div>
      )}
    </form>
  );
}
