import { CurrencyFormatter } from '@/i18n/numbers';
import * as yup from 'yup';

function moreThan0(value: string) {
  return parseFloat(value.replaceAll('$', '').replaceAll(',', '') || '0') >= 0;
}

export function getPlanGoalSchema(availableBalance: number) {
  return yup.object({
    goalAmount: yup.string().test({
      name: 'goalAmountMoreThan0',
      message: `Please enter valid amount`,
      test: moreThan0,
    }),
    monthlyContribution: yup.string().test({
      name: 'monthlyContributionMoreThan0',
      message: `Please enter valid amount`,
      test: moreThan0,
    }),
    initialContribution: yup
      .string()
      .test({
        name: 'initialContributionMoreThan0',
        message: `Please enter valid amount`,
        test: moreThan0,
      })
      .test({
        name: 'availableBalance',
        message: `Available balance: ${CurrencyFormatter.format(availableBalance)}`,
        test: (value) => {
          return parseFloat(value.replaceAll('$', '').replaceAll(',', '') || '0') <= availableBalance;
        },
      }),
    achieveGoalBy: yup.string().required('Please enter achieve goal by'),
  });
}

const planGoalSchema = getPlanGoalSchema(0);

export interface IPlanGoal extends yup.Asserts<typeof planGoalSchema> {}

export * from '@/components/create-plan/helpers';
