import { useTrackAnalyticsPage } from '@/hooks/analytics';
import { useRouter } from 'next/router';
import * as React from 'react';

export function AnalyticsPageTrack() {
  const router = useRouter();
  const trackPage = useTrackAnalyticsPage();

  React.useEffect(() => {
    function onRouteChangeComplete(url: string) {
      return trackPage(url);
    }

    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router, trackPage]);

  return null;
}
