import cx from 'clsx';
import React from 'react';

function CalculatorIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 7H15M15 17V14M12 17H12.01M9 17H9.01M9 14H9.01M12 14H12.01M15 11H15.01M12 11H12.01M9 11H9.01M7 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21Z"
        stroke="#EAEDF0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function RunProjectionButton(props: { onClick: () => void; isDisabled: boolean }) {
  return (
    <div className="flex justify-end mt-12">
      <button
        disabled={props.isDisabled}
        type="button"
        className={cx('outline-none flex items-center px-6 py-2.5 rounded-md', {
          'bg-gray-700': !props.isDisabled,
          'bg-gray-400': props.isDisabled,
        })}
        onClick={props.onClick}
      >
        <span className="text-white mr-2 ml-2 text-base">Run projection</span>
        <CalculatorIcon />
      </button>
    </div>
  );
}
