import { onBrowser } from '@straw-hat/is-ssr';
import cx from 'clsx';
import ReactModal from 'react-modal';

if (process.env.NODE_ENV !== 'test') {
  onBrowser(() => ReactModal.setAppElement('#modal-root'));
}

type ModalProps = {
  closeModal?: () => void;
  className?: string;
  overlayClassName?: string;
  children: React.ReactNode;
  isOpen: boolean;
  style?: object;
};

export function Modal(props: ModalProps) {
  const { closeModal = () => {}, children = [], isOpen, style = {} } = props;
  return (
    //@ts-ignore
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={style}
      contentLabel="Example Modal"
      className={cx(props?.className, 'relative outline-none')}
      overlayClassName={cx(
        props?.overlayClassName,
        'fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-4000',
      )}
    >
      {children}
    </ReactModal>
  );
}
