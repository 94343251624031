import { PlanTypeForm } from '@/components/create-plan/components/plan-type-form';
import { StepIndicator } from '@/components/create-plan/components/step-indicator';
import { Cta } from '@/routes/account/components/create-plan-drawer/components/cta';
import {
  CreatePlanSteps,
  CUSTOM_PLAN_TYPE_NAME,
  defaultPlanInfo,
  PLAN_TYPE_OPTIONS,
} from '@/routes/account/components/create-plan-drawer/helpers';
import { useCreatePlanDrawerContext } from '@/routes/account/components/create-plan-drawer/hooks/use-create-plan-drawer-context';
import React from 'react';

export function PlanTypeStep(props: { onClose: () => void }) {
  const controller = useCreatePlanDrawerContext();

  function onClickNext() {
    if (controller.planTypeName === CUSTOM_PLAN_TYPE_NAME) {
      controller.setPlanInfo(defaultPlanInfo);
    } else {
      const option = PLAN_TYPE_OPTIONS.find((option) => option.name === controller.planTypeName);

      controller.setPlanInfo({
        name: option.name,
        color: option.color,
        description: '',
        assignAccountNumber: false,
      });
    }
    controller.setStep(CreatePlanSteps.PlanName);
  }

  return (
    <div className="h-full min-w-full flex flex-col justify-between p-6 overflow-y-auto">
      <div>
        <PlanTypeForm
          selectedPlanType={controller.planTypeName}
          options={PLAN_TYPE_OPTIONS}
          customPlanTypeName={CUSTOM_PLAN_TYPE_NAME}
          onSelectPlanType={controller.setPlanTypeName}
          optionsContainerClassName="sm:w-full"
        >
          <p className="text-gray-600 text-sm leading-4 font-normal mb-6">
            Plans are sub-accounts of your main deposit account that allow you to allocate money towards your chosen
            goals. Any money you put into a Plan earns the same APY as your deposit account — but the funds in Plans are
            kept separate from your available balance (which means you won&apos;t accidentally eat into your savings).
          </p>
        </PlanTypeForm>
      </div>
      <div className="mt-4">
        <StepIndicator
          withFundPlanSteps={controller.withFundPlanSteps}
          step={controller.stepNumber}
          itemClassName="mx-1 w-2 h-2"
        />
        <Cta
          onClickBack={props.onClose}
          onClickNext={onClickNext}
          isNextDisabled={!controller.planTypeName}
          backButtonLabel="Cancel"
          nextButtonLabel="Next"
        />
      </div>
    </div>
  );
}
