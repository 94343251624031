import { isNotPlaidAccount, isPlaidAccount } from '@/helpers';
import { ExternalAccount, useExternalAccounts } from '@/hooks/use-external-accounts';

export function useLinkedAccounts() {
  const externalAccounts = useExternalAccounts();

  const plaidAccounts = externalAccounts.externalAccounts.filter(isPlaidAccount);
  const notPlaidExternalAccounts = externalAccounts.externalAccounts.filter(isNotPlaidAccount);

  const plaidByInstitution =
    plaidAccounts.reduce((institutionMap: { [key: string]: ExternalAccount[] }, account) => {
      if (account.plaidInstitutionName in institutionMap) {
        institutionMap[account.plaidInstitutionName].push(account);
      } else {
        institutionMap[account.plaidInstitutionName] = [account];
      }
      return institutionMap;
    }, {}) ?? {};

  return {
    isEmpty: externalAccounts.externalAccounts.length === 0,
    isLoading: externalAccounts.isLoading,
    externalAccounts: notPlaidExternalAccounts,
    plaidAccounts,
    plaidByInstitution,
  };
}
