import { TemplatesDrawer } from '..';
import { TemplatesDrawerContext } from '../contexts/templates-drawer-context';
import { useTemplatesDrawer } from '../hooks/use-templates-drawer';

export function TemplatesDrawerProvider(props: React.PropsWithChildren<{}>) {
  const controller = useTemplatesDrawer();

  return (
    <TemplatesDrawerContext.Provider value={controller}>
      <TemplatesDrawer isOpen={controller.isOpen} onDrawerClose={controller.closeTemplatesDrawer} />
      {props.children}
    </TemplatesDrawerContext.Provider>
  );
}
