import * as React from 'react';
import { NumberFormatField, NumberFormatFieldProps } from '../number-format-field';

export const CurrencyField = React.forwardRef<HTMLInputElement, NumberFormatFieldProps>(function CurrencyField(
  props: NumberFormatFieldProps,
  ref,
) {
  return (
    <NumberFormatField
      {...props}
      ref={ref}
      prefix={props.prefixDisabled ? '' : '$'}
      thousandSeparator
      fixedDecimalScale
      decimalScale={2}
    />
  );
});
