export const COMMAND_PALETTE_FEATURE = 'command-palette';
export const FEATURE_FLAG_LOGIN_DOWN = 'login-down';
export const FEATURE_FLAG_RENEW_EXPIRED_OCC_BANNER = 'renew-expired-occ-banner';
export const FEATURE_FLAG_GATED_SIGNUPS = 'gated-signups';
export const FEATURE_TIER_CHANGES_BANNER = 'tier-changes-banner';
export const FEATURE_FLAG_TAX_DOCUMENTS = 'tax-documents';
export const FEATURE_FLAG_GATED_REFERRALS = 'gated-referrals';
export const FEATURE_DIGITAL_CARDS = 'digital-cards';
export const FEATURE_FLAG_PLAID_INTEGRATION = 'plaid-integration';
export const FEATURE_FLAG_ONE_TIME_ADDITION_PAYMENT = 'one-time-addition-payment';
export const FEATURE_FLAG_OCC_NEXT_RUN_DATE = 'occ-next-run-date';
export const FEATURE_FLAG_CONNECT_MANUALLY = 'flag-connect-manually';
export const FEATURE_FLAG_DEPOSIT_ONBOARDING = 'deposit-onboarding';
export const FEATURE_FLAG_ROUTINES = 'routines';
export const FEATURE_FLAG_ROUTINES_BETA = 'routines-beta';
export const FEATURE_FLAG_ROUTINES_DEMO = 'routines-demo';
export const FEATURE_APY_REQUIREMENTS = 'apy-requirements';
export const FEATURE_CASHFLOW = 'cashflow';
export const FEATURE_EMPLOYEE_ONLY_CREDIT_CARD_AUTH_USER = 'employee-only-credit-card-auth-user';
export const FEATURE_FLAG_JOINT_ACCOUNTS = 'joint-accounts';
export const FEATURE_FLAG_ACCEPTING_CREDIT_INVITATION_MAINTENANCE = 'accepting-credit-invitation-maintenance';
export const FEATURE_FLAG_ACCOUNT_ACTIVATION = 'account-activation';
export const FEATURE_FLAG_DISABLED_REDEMPTIONS_TO_EXTERNAL_ACCOUNTS = 'disable-redemptions-to-external-accounts';
export const FEATURE_FLAG_DISABLED_REDEMPTIONS_TO_CREDIT_CARD_BALANCE =
  'disable-cashback-redemption-to-credit-card-balance';
export const FEATURE_FLAG_SHOW_CREDIT_INSIGHTS_CARD = 'show-credit-insights-card';
export const FEATURE_FLAG_DANGER_ZONE_MAINTENANCE = 'danger-zone-maintenance';
export const FEATURE_FLAG_PLANS_V2 = 'plans-v2';
export const FEATURE_FLAG_ONBOARDING_SURVEY = 'onboarding-survey';
export const FEATURE_FLAG_PLAID_UBMRELLA_ODFI_TRAMSFER = 'plaid-umbrella-odfi-transfer';
export const FEATURE_FLAG_ACCOUNTS_PAGE_V3 = 'accounts-page-v3';
export const FEATURE_FLAG_ENABLED_MRV_CARD = 'mrv-card-enable';
export const FEATURE_PLAID_UPDATE_MODE_TESTING = 'plaid-update-mode-testing';
export const FEATURE_WEB_AUTHN = 'fushionauth-web-authn';
export const FEATURE_FLAG_PLANS_WITH_ACCOUNT_NUMBERS = 'plans-with-account-numbers';
export const FEATURE_FLAG_UPDATE_ADDRESS_ENABLED = 'update-address-enabled';
export const FEATURE_FLAG_UPDATE_EMAIL_ENABLED = 'update-email-enabled';
export const FEATURE_FLAG_UPDATE_PHONE_ENABLED = 'update-phone-enabled';
export const FEATURE_FLAG_UPDATE_NAME_ENABLED = 'update-name-enabled';
export const FEATURE_FLAG_OUTGOING_WIRE_TRANSFER = 'outgoing-wire-transfer-enabled';
export const FEATURE_FLAG_TEMPORARY_MRV_REISSUE = 'temporary_mrv_reissue';
export const FEATURE_FLAG_CLOSING_DEPOSIT_ACCOUNT_ENABLED = 'closing-deposit-account-enabled';
export const FEATURE_FLAG_WIND_DOWN = 'wind-down';
export const FEATURE_FLAG_WIND_DOWN_BANNERS = 'wind-down-banners';
export const FEATURE_FLAG_WIND_DOWN_CREDIT_INVITES = 'wind-down-credit-invites';
