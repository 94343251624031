import { PlanInfoForm } from '@/components/create-plan/components/plan-info-form';
import { StepIndicator } from '@/components/create-plan/components/step-indicator';
import { useFeatureWithUserStrategies } from '@/components/feature-flag/hooks/use-feature-with-user-strategies';
import { FEATURE_FLAG_PLANS_WITH_ACCOUNT_NUMBERS } from '@/constants/features';
import { Cta } from '@/routes/account/components/create-plan-drawer/components/cta';
import {
  CreatePlanSteps,
  defaultPlanInfo,
  planInfoSchema,
} from '@/routes/account/components/create-plan-drawer/helpers';
import { useCreatePlanDrawerContext } from '@/routes/account/components/create-plan-drawer/hooks/use-create-plan-drawer-context';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';

export function PlanNameStep() {
  const controller = useCreatePlanDrawerContext();
  const featureFlag = useFeatureWithUserStrategies(FEATURE_FLAG_PLANS_WITH_ACCOUNT_NUMBERS);

  const form = useForm({
    defaultValues: controller.planInfo || defaultPlanInfo,
    mode: 'onChange',
    resolver: yupResolver(planInfoSchema),
  });

  React.useEffect(() => {
    form.reset(controller.planInfo);
  }, [controller.planInfo]);

  function onClickNext() {
    controller.setPlanInfo(form.getValues());
    controller.setStep(CreatePlanSteps.PlanGoal);
  }

  function onClickBack() {
    controller.setStep(CreatePlanSteps.PlanType);
  }

  return (
    <div className="h-full min-w-full flex flex-col justify-between p-6 overflow-y-auto">
      <div>
        <PlanInfoForm withAddAccountNumber={featureFlag.isEnabled} title="Name your Plan" form={form} />
      </div>
      <div className="mt-4">
        <StepIndicator
          withFundPlanSteps={controller.withFundPlanSteps}
          step={controller.stepNumber}
          itemClassName="mx-1 w-2 h-2"
        />
        <Cta
          onClickBack={onClickBack}
          onClickNext={onClickNext}
          backButtonLabel="Back"
          nextButtonLabel="Next"
          isNextDisabled={!form.formState.isValid}
        />
      </div>
    </div>
  );
}
