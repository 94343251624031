import { HMBradleyTimeZones } from '@/constants';
import { PATH_ROUTINES_LIST } from '@/constants/routes';
import { getPercentValue } from '@/helpers/percentage';
import { UTCDateLongFormatter } from '@/i18n/datetime';
import { formatOrdinalNumber } from '@/i18n/plural';
import { Cta } from '@/routes/account/components/create-plan-drawer/components/cta';
import { useCreatePlanDrawerContext } from '@/routes/account/components/create-plan-drawer/hooks/use-create-plan-drawer-context';
import { DateTime } from 'luxon';
import Link from 'next/link';
import React from 'react';

function InitialContributionIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289L7 8.58579L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z"
        fill="#3F4E56"
      />
    </svg>
  );
}

function MonthlyContributionIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11 14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14C9 13.4477 9.44772 13 10 13C10.5523 13 11 13.4477 11 14ZM10 5C9.44772 5 9 5.44772 9 6V10C9 10.5523 9.44772 11 10 11C10.5523 11 11 10.5523 11 10V6C11 5.44772 10.5523 5 10 5Z"
        fill="#3F4E56"
      />
    </svg>
  );
}

function RoutineArrowIcon() {
  return (
    <svg className="w-[20px] h-[20px]" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3333 5.33331L14 7.99998M14 7.99998L11.3333 10.6666M14 7.99998H2"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function LinkArrowIcon() {
  return (
    <svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.5L8 8.5L1 15.5" stroke="#11181B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function CongratulationsStep() {
  const controller = useCreatePlanDrawerContext();

  function onClickNext() {
    controller.closeCreatePlanDrawer();
  }

  const goal = controller.planGoal?.goalAmount || '$0.00';
  const initialContribution = controller.planGoal?.initialContribution || '$0.00';
  const monthlyContribution = controller.planGoal?.monthlyContribution || '$0.00';

  const hasInitialContribution = initialContribution !== '$0.00';
  const hasMonthlyContribution = monthlyContribution !== '$0.00';
  const hasGoal = goal !== '$0.00';

  const completedPercent = hasGoal
    ? getPercentValue(
        parseFloat(initialContribution.replaceAll('$', '').replaceAll(',', '')),
        parseFloat(goal.replaceAll('$', '').replaceAll(',', '')),
      )
    : 0;

  return (
    <div className="h-full min-w-full flex flex-col justify-between p-6 overflow-y-auto">
      <div>
        <p className="text-lg text-gray-950 mb-3">Congrats! You are all set</p>
        <p className="font-normal uppercase text-gray-600 text-sm mt-4 mb-3">Plan</p>
        <div className="rounded-lg border-1 border-gray-200 p-5">
          <div className="flex justify-between items-center">
            <span className="text-gray-900">{controller.planInfo?.name}</span>
            {hasGoal && <span className="text-gray-900">{completedPercent}% complete</span>}
          </div>
          <div className="h-2 w-full rounded-full bg-gray-100 mt-3 mb-1">
            <div
              className="h-2 rounded-full mr-1.5"
              style={{ backgroundColor: controller.planInfo?.color, width: `${completedPercent}%` }}
            />
          </div>
          <div className="flex justify-between">
            <span className="font-normal text-base text-blue">
              {controller.planGoal?.initialContribution || '$0.00'}
            </span>
            <span className="font-normal text-base text-black">{controller.planGoal?.goalAmount}</span>
          </div>
        </div>
        <div className="flex items-center mt-3">
          <div className="w-4 h-4">
            <InitialContributionIcon />
          </div>
          <p className="text-sm text-gray-600 font-normal ml-2">
            {hasInitialContribution
              ? `Your Plan is created and initial contribution of ${controller.planGoal?.initialContribution} has been successfully added!`
              : 'Your Plan is created!'}
          </p>
        </div>
        {hasMonthlyContribution && (
          <>
            <div className="w-full h-px bg-gray-200 mt-8 mb-7"></div>
            <p className="font-normal uppercase text-gray-600 text-sm mb-3">Routine</p>
            <Link href={PATH_ROUTINES_LIST} onClick={controller.closeCreatePlanDrawer}>
              <div className="rounded-lg border-1 border-gray-200 p-5">
                <div className="flex items-center justify-between mb-3">
                  <div>
                    <span className="text-gray-900">Monthly contribution to Plan: {controller.planInfo?.name}</span>
                    <div className="flex items-center mt-1.5">
                      <div className="w-4 h-4 rounded-full bg-green-50 flex items-center justify-center mr-2">
                        <div className="w-2 h-2 rounded-full bg-green-700" />
                      </div>
                      <span className="text-green-700 font-normal text-sm mr-2">Active</span>
                      <span className="text-gray-500 font-normal text-sm">Hasn’t run yet</span>
                    </div>
                  </div>
                  <LinkArrowIcon />
                </div>
                <div className="flex flex-row gap-2 items-center flex-wrap">
                  <div className="text-sm font-normal rounded-lg text-gray-500">Every</div>
                  <div className="p-2 text-sm rounded-lg text-purple-800 bg-purple-50">Month</div>
                  <div className="text-sm font-normal rounded-lg text-gray-500">on</div>
                  <div className="p-2 text-sm rounded-lg text-purple-800 bg-purple-50">
                    the {formatOrdinalNumber(controller.day)}
                  </div>
                  <div className="p-2">
                    <RoutineArrowIcon />
                  </div>
                  <div className="text-sm font-normal rounded-lg text-gray-500">Transfer</div>
                  <div className="p-2 text-sm rounded-lg text-blue-800 bg-blue-50">
                    {controller.planGoal?.monthlyContribution}
                  </div>
                  <div className="text-sm font-normal rounded-lg text-gray-500">To</div>
                  <div className="p-2 text-sm rounded-lg text-blue-800 bg-blue-50">
                    Plan: {controller.planInfo?.name}
                  </div>
                </div>
              </div>
            </Link>
            <div className="flex items-center mt-3">
              <div className="w-5 h-5">
                <MonthlyContributionIcon />
              </div>
              <p className="text-sm text-gray-600 font-normal ml-2">
                This routine will run automatically at 8AM PST on the date you set. Your next scheduled monthly
                contribution is on{' '}
                {UTCDateLongFormatter.format(
                  DateTime.now()
                    .setZone(HMBradleyTimeZones.DepositLedger)
                    .plus(DateTime.now().day <= controller.day ? {} : { months: 1 })
                    .set({ day: controller.day })
                    .toJSDate(),
                )}
                .
              </p>
            </div>
          </>
        )}
      </div>
      <div className="mt-4">
        <Cta onClickNext={onClickNext} withoutBackButton nextButtonLabel="Done" />
      </div>
    </div>
  );
}
