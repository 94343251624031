/** @public */
export const XS_MOBILE_WIDTH = 320;
/** @public */
export const S_MOBILE_WIDTH = 375;
/** @public */
export const MOBILE_WIDTH = 414;
/** @public */
export const XL_MOBILE_WIDTH = 600;
/** @public */
export const IPAD_WIDTH = 768;
/** @public */
export const TAB_WIDTH = 800;
/** @public */
export const DESKTOP_SMALL = 968;
/** @public */
export const DESKTOP_WIDTH = 1024;
/** @public */
export const MAX_WIDTH = 1200;
/** @public */
export const DESKTOP_WIDE = 1440;
/** @public */
export const MEDIUM_HEIGHT = 667;
/** @public */
export const TALL_MOBILE_HEIGHT = 812;
