import { DateTime } from 'luxon';

/**
 * Example: April 12, 2022
 * @public
 */
export const UTCDateLongFormatter = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

/**
 * Example: Apr 12, 2022
 * @public
 */
export const UTCDateShortFormatter = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

/**
 * Example: 4/12/2022
 * @public
 */
export const UTCDateNumericFormatter = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
});

/**
 * Example: April 2022
 * @public
 */
export const UTCMonthYearLongFormatter = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  year: 'numeric',
  month: 'long',
});

/**
 * Example: Apr 20
 * @public
 */
export const UTCMonthShortDayFormatter = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  day: 'numeric',
  month: 'short',
});

/**
 * Example: April 20
 * @public
 */
export const UTCMonthLongDayFormatter = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  day: 'numeric',
  month: 'long',
});

/**
 * Example: 20
 * @public
 */
export const UTCDayFormatter = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  day: 'numeric',
});

/**
 * Example: April
 * @public
 */
export const UTCMonthLongFormatter = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  month: 'long',
});

/**
 * Example: Nov 10, 2022
 * @public
 */
export function formatDate(date: string, zone: string = 'system') {
  return DateTime.fromISO(date, { zone: zone }).toFormat('LLL dd, yyyy');
}

/**
 * Example: 2022-04-12
 * @public
 */
export function toPlainDateString(dob: { month: number | string; day: number | string; year: number | string }) {
  return `${dob.year}-${dob.month}-${dob.day}`;
}
