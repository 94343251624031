import { SessionContext } from '@/contexts/session-context';
import { useContext } from 'react';

export function useSessionContext() {
  return useContext(SessionContext);
}

export function useAuthenticatedSessionContext() {
  const sessionContext = useSessionContext();

  if (!sessionContext.isAuthenticated) {
    throw new Error('Session is not authenticated');
  }

  return sessionContext;
}
