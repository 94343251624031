import { StepIndicator } from '@/components/create-plan/components/step-indicator';
import { InfoButtonToolTip } from '@/components/info-button-tool-tip';
import { DepositAccountType, useGetDepositAccountsQuery } from '@/generated/graphql';
import { Cta } from '@/routes/account/components/create-plan-drawer/components/cta';
import { DayPicker } from '@/routes/account/components/create-plan-drawer/components/day-picker';
import { useCreatePlanDrawerContext } from '@/routes/account/components/create-plan-drawer/hooks/use-create-plan-drawer-context';
import clsx from 'clsx';
import React from 'react';

export function FundPlanStep(props: { onFundPlan: () => void; fundPlanLoading: boolean; fundPlanError?: string }) {
  const controller = useCreatePlanDrawerContext();
  const getDepositAccountsQuery = useGetDepositAccountsQuery();

  function onClickBack() {
    controller.onSuccess?.();
    controller.closeCreatePlanDrawer();
  }

  const account = (getDepositAccountsQuery.data?.depositAccounts || []).find(
    (account) => account.id === controller.accountId,
  );
  const initialContribution = controller.planGoal?.initialContribution || '$0.00';
  const monthlyContribution = controller.planGoal?.monthlyContribution || '$0.00';

  const hasInitialContribution = initialContribution !== '$0.00';
  const hasMonthlyContribution = monthlyContribution !== '$0.00';

  const accountLabel = `${
    account?.accountType === DepositAccountType.Individual ? 'Deposit' : 'Joint Deposit'
  } Account ${account?.depositAccountNumber?.lastFour}`;

  return (
    <div className="h-full min-w-full flex flex-col justify-between p-6 overflow-y-auto">
      <div>
        <div className="flex items-center mb-3">
          <p className="text-lg text-gray-950 mr-1.5">Next: fund your Plan</p>
          <InfoButtonToolTip className="mt-1">
            <div className="bg-gray-700 rounded p-4">
              <p className="text-white font-medium text-sm">
                Available balance does not include balances in any Plans and the other items listed in the definition of
                Available Balance in the HMBradley Deposit Account Agreement and Disclosures.
              </p>
            </div>
          </InfoButtonToolTip>
        </div>
        <p className="text-sm text-gray-600 leading-4 font-normal mb-2">
          This moves funds from your deposit account to your new Plan. Funds in Plans earn the same interest rate as
          funds in your main deposit account.
        </p>
        <div className="rounded-lg mt-4 border-1 border-gray-200 pt-4 overflow-hidden">
          <p
            className={clsx('mb-1 px-5', {
              'text-gray-400': !hasInitialContribution,
              'text-gray-950': hasInitialContribution,
            })}
          >
            {initialContribution}
          </p>
          <p
            className={clsx('px-5', {
              'text-gray-400': !hasInitialContribution,
              'text-gray-950': hasInitialContribution,
            })}
          >
            Initial contribution
          </p>
          <p
            className={clsx('text-sm leading-4 font-normal px-5 mb-4', {
              'text-gray-400': !hasInitialContribution,
              'text-gray-600': hasInitialContribution,
            })}
          >
            One-time transfer from {accountLabel} to your newly created Plan: {controller.planInfo?.name}.
          </p>
        </div>
        <div className="bg-gray-50 rounded-lg">
          <div className="rounded-lg mt-4 border-1 border-gray-200 py-4 px-5 bg-white">
            <div className="flex items-center justify-center px-2 py-px rounded-md bg-gray-100 mb-2 w-fit">
              <span
                className={clsx('text-tiny mb-px', {
                  'text-gray-400': !hasMonthlyContribution,
                  'text-gray-600': hasMonthlyContribution,
                })}
              >
                Routine
              </span>
            </div>
            <p
              className={clsx('mb-1', {
                'text-gray-400': !hasMonthlyContribution,
                'text-gray-950': hasMonthlyContribution,
              })}
            >
              {monthlyContribution}
            </p>
            <p className={clsx({ 'text-gray-400': !hasMonthlyContribution, 'text-gray-950': hasMonthlyContribution })}>
              {' '}
              Create a Routine to add funds monthly
            </p>
            <p
              className={clsx('text-sm leading-4 font-normal', {
                'text-gray-400': !hasMonthlyContribution,
                'text-gray-600': hasMonthlyContribution,
              })}
            >
              Use a Routine to make recurring transfers into your {controller.planInfo?.name} Plan from {accountLabel}{' '}
              on a particular day of the month.
            </p>
          </div>
          {hasMonthlyContribution && (
            <div className="p-5">
              <DayPicker value={controller.day} onChange={controller.setDay} />
            </div>
          )}
        </div>
        <p className="text-sm text-gray-600 leading-4 font-normal mt-4">
          By selecting confirm you are agreeing to initiate the one-time transfer in the amount above and to create this
          Routine including the transfers on the date selected above.
        </p>
      </div>
      <div className="mt-4">
        {!!props.fundPlanError && (
          <p className="mb-6 text-red text-center leading-5 font-normal text-base">{props.fundPlanError}</p>
        )}
        <StepIndicator
          withFundPlanSteps={controller.withFundPlanSteps}
          step={controller.stepNumber}
          itemClassName="mx-1 w-2 h-2"
        />
        <Cta
          onClickBack={onClickBack}
          onClickNext={props.onFundPlan}
          backButtonLabel="Skip for now"
          nextButtonLabel="Confirm"
          isNextDisabled={hasMonthlyContribution && !controller.day}
          isLoading={props.fundPlanLoading}
        />
      </div>
    </div>
  );
}
