const COLORS = {
  black: '#000000',
  white: '#ffffff',
  whiteBackground: '#f6f7f9',
  brand_teal: '#006982',
  brandTealLight: '#40A6BE',
  bondiBlue: '#0095BA',
  pink: '#FFC5D2',
  purple_heart: '#5A46D7',
  pastel_blue: '#96E6F0',
  fountain_blue: '#4ba7b9',
  pastel_green: '#50EB82',
  pastel_purple: '#c8b9ff',
  passwordWrapper: '#6A6A6A',
  brandTeal: '#006982',
  lightBg: '#F5F5F5',
  fortune: '#EB3327',
  forbes: '#3E6296',
  techcrunch: '#0AA868',
  money: '#296695',
  darkGrey: '#323232',
  redOrange: '#EB5F2D',
  lightRedOrange: '#F49471',
  fontPlaceholderGrey: '#9f9f9f',
  fontInputGrey: '#666',
  emperor: '#545454',
  evenLighterGrey: '#eee',
  lightLightGrey: '#aaa',
  lightGrey: '#989898',
  blueGrey: '#B9DCE0',
  mercury: '#E5E5E5',
  flamingo: '#EB5F2D',
  scooter: '#39B8D5',
  violet_red: '#F12989',
  cupid: '#FAC8D2',
  salomie: '#FFDC8C',
  lightCoolGrey: '#EBECF1',
  silverChalice: '#A8A8A8',
  creditGreen: '#96fab5',
  creditPink: '#fac8d2',
  mintGreen: '#96fab5',
  tuplipTree: '#e8c83b',
  mineShaft: '#2f2f2f',
  alto: '#D8D8D8',

  //Red
  red800: '#B3370A',
  //Grey
  grey50: '#F5F6F8',
  grey500: '#67767F',

  // Figma black grayscale
  black0: '#000000',
  black1: '#1f1f1f',
  black2: '#545454',
  black3: '#A8A8A8',
  black4: '#d8d8d8',
  //blue
  blue: { 600: '#1EA1C1' },
};

export const SWATCH_COLORS_BY_NUMBER = {
  1: COLORS.flamingo,
  2: COLORS.brand_teal,
  3: COLORS.scooter,
  4: COLORS.pastel_green,
  5: COLORS.emperor,
  6: COLORS.violet_red,
  7: COLORS.cupid,
  8: COLORS.purple_heart,
  9: COLORS.pastel_purple,
  10: COLORS.salomie,
};

export default COLORS;
