import { useTemplatesDrawerContext } from '@/components/drawers/templates/hooks/use-templates-drawer-context';
import { FeatureFlag } from '@/components/feature-flag';
import { useFeatureWithUserStrategies } from '@/components/feature-flag/hooks/use-feature-with-user-strategies';
import { Anchor, UserMenuDropdown, UserMenuItemList } from '@/components/header/components/authenticated-header';
import { Image } from '@/components/image';
import { FULLSTORY_UNMASK } from '@/constants';
import {
  FEATURE_FLAG_ACCOUNTS_PAGE_V3,
  FEATURE_FLAG_PLANS_WITH_ACCOUNT_NUMBERS,
  FEATURE_FLAG_ROUTINES,
  FEATURE_FLAG_WIND_DOWN,
  FEATURE_FLAG_WIND_DOWN_CREDIT_INVITES,
} from '@/constants/features';
import {
  ACCOUNT_ACTIVITY,
  CREDIT_PAYMENT_PATH,
  CREDIT_REWARDS_PATH,
  CREDIT_TRANSACTIONS_PATH,
  FINANCIAL_INSIGHTS_PATH,
  NYCB_PLANS_PATH,
  NYCB_TRANSACTIONS_PATH,
  NYCB_TRANSFER_PATH,
  PATH_ACCOUNT_ACCOUNTS,
  PATH_ACCOUNT_HOME,
  PATH_ROUTINES_LIST,
} from '@/constants/routes';
import { useGetDepositAccountsQuery, useListRoutinesQuery } from '@/generated/graphql';
import { byAccountId, isCreditAccount, removeUndefinedValues } from '@/helpers';
import { assetUrl } from '@/helpers/asset-url';
import { routeFor } from '@/helpers/router';
import { useHasOpenedCreditAccount } from '@/hooks/use-has-opened-credit-account';
import { httpClient } from '@/transports/http';
import { useGetCardV1Cards, useGetCardV1CardsBalances } from '@core/mainframe-react-query';
import { Menu } from '@headlessui/react';
import {
  ArrowsRightLeftIcon,
  BanknotesIcon,
  BoltIcon,
  ChartPieIcon,
  CurrencyDollarIcon,
  HomeIcon,
  LifebuoyIcon,
  QueueListIcon,
  Squares2X2Icon,
} from '@heroicons/react/24/outline';
import { default as clx, default as cx } from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';

type SidebarProps = {
  className: string;
  onClose?: () => void;
};

function svgColor(props: { enabled?: boolean; ready?: boolean }) {
  return !props.ready ? 'text-grey' : props.enabled ? 'text-purple-900' : 'text-black-pure';
}

function borderColor(props: { enabled?: boolean; ready?: boolean }) {
  return !props.ready ? 'border-grey' : props.enabled ? 'border-purple-900' : 'border-black-pure';
}

function LightBulbIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.663 15H12.336M10 1V2M16.364 3.636L15.657 4.343M19 10H18M2 10H1M4.343 4.343L3.636 3.636M6.464 13.536C5.76487 12.8367 5.2888 11.9458 5.09598 10.9759C4.90316 10.006 5.00225 9.00076 5.38073 8.08721C5.75921 7.17366 6.40007 6.39284 7.22229 5.84349C8.0445 5.29414 9.01115 5.00093 10 5.00093C10.9889 5.00093 11.9555 5.29414 12.7777 5.84349C13.5999 6.39284 14.2408 7.17366 14.6193 8.08721C14.9977 9.00076 15.0968 10.006 14.904 10.9759C14.7112 11.9458 14.2351 12.8367 13.536 13.536L12.988 14.083C12.6747 14.3963 12.4262 14.7683 12.2567 15.1777C12.0872 15.5871 11.9999 16.0259 12 16.469V17C12 17.5304 11.7893 18.0391 11.4142 18.4142C11.0391 18.7893 10.5304 19 10 19C9.46957 19 8.96086 18.7893 8.58579 18.4142C8.21071 18.0391 8 17.5304 8 17V16.469C8 15.574 7.644 14.715 7.012 14.083L6.464 13.536Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function NavItemIcon(props: { ready?: boolean; enabled: boolean; icon: React.ElementType<{ className: string }> }) {
  return <props.icon className={clx('h-6 w-6', svgColor(props))} />;
}

function InsightsNavItemIcon(props: { ready?: boolean; enabled: boolean }) {
  return <NavItemIcon {...props} icon={LightBulbIcon} />;
}

function RewardsNavItemIcon(props: { ready?: boolean; enabled: boolean }) {
  return <NavItemIcon {...props} icon={BanknotesIcon} />;
}

function PaymentsNavItemIcon(props: { ready?: boolean; enabled: boolean }) {
  return <NavItemIcon {...props} icon={CurrencyDollarIcon} />;
}

function ActivityNavItemIcon(props: { ready?: boolean; enabled: boolean }) {
  return <NavItemIcon {...props} icon={QueueListIcon} />;
}

function BuoyNavItemIcon(props: { ready?: boolean; enabled: boolean }) {
  return <NavItemIcon {...props} icon={LifebuoyIcon} />;
}

function TransfersNavItemIcon(props: { ready?: boolean; enabled: boolean }) {
  return <NavItemIcon {...props} icon={ArrowsRightLeftIcon} />;
}

function PlansNavItemIcon(props: { ready?: boolean; enabled: boolean }) {
  return <NavItemIcon {...props} icon={ChartPieIcon} />;
}

function AccountsNavItemIcon(props: { ready?: boolean; enabled: boolean }) {
  return <NavItemIcon {...props} icon={Squares2X2Icon} />;
}

function RoutinesNavItemIcon(props: { ready?: boolean; enabled: boolean }) {
  return <NavItemIcon {...props} icon={BoltIcon} />;
}

function AccountsOverviewNavItemIcon(props: { ready?: boolean; enabled: boolean }) {
  return <NavItemIcon {...props} icon={HomeIcon} />;
}

function NavItem(props: {
  beta?: boolean;
  enabled?: boolean;
  ready?: boolean;
  to: string;
  query?: Record<string, string>;
  path?: Record<string, string>;
  label: string;
  close?: () => void;
  icon: React.ElementType<{ enabled: boolean; ready: boolean }>;
}) {
  return (
    <>
      {props.ready ? (
        <Link
          passHref
          href={routeFor(
            props.to,
            removeUndefinedValues({
              query: props.query,
              path: props.path,
            }),
          )}
          onClick={props.close}
          className={clx(
            'rounded-lg p-2 flex flex-row items-center gap-2 hover:bg-gray-100',
            props.enabled && 'bg-purple-50',
          )}
        >
          <div className="flex flow-row items-center gap-2 ">
            <props.icon enabled={props.enabled} ready={props.ready} />
            <p className={clx(svgColor(props))}>{props.label}</p>
            {props.beta && (
              <p
                className={clx(svgColor(props), borderColor(props), 'text-tiny font-medium px-2 border-1 rounded-full')}
              >
                BETA
              </p>
            )}
          </div>
        </Link>
      ) : (
        <div
          className={clx(
            'rounded-lg p-2 flex flex-row items-center gap-2 hover:bg-gray-100',
            props.enabled && 'bg-purple-50',
          )}
        >
          <props.icon enabled={props.enabled} ready={props.ready} />
          <p className={clx(svgColor(props), 'text-grey')}>{props.label}</p>
          {props.beta && (
            <p className={clx(svgColor(props), borderColor(props), 'text-tiny font-medium px-2 border-1 rounded-full')}>
              BETA
            </p>
          )}
        </div>
      )}
    </>
  );
}

function useDepositAccounts() {
  const router = useRouter();

  const depositAccountsNycb = useGetDepositAccountsQuery(
    {},
    {
      select(data) {
        return data.depositAccounts;
      },
    },
  );

  const [firstDeposit, setFirstDeposit] = React.useState('');

  React.useEffect(() => {
    const routerId = router.query.depositAccountId as string;

    const nycbDeposit = depositAccountsNycb.data?.find(byAccountId(routerId));
    if (nycbDeposit) {
      setFirstDeposit(nycbDeposit.id);
    } else {
      if (depositAccountsNycb.data?.[0]?.id) {
        setFirstDeposit(depositAccountsNycb.data[0].id);
      } else {
        setFirstDeposit('');
      }
    }
  }, [router.query.depositAccountId, depositAccountsNycb.data]);

  return {
    firstDeposit,
    isLoading: depositAccountsNycb.isLoading,
    numberOfAccounts: depositAccountsNycb.data?.length ?? 0,
  };
}

function useCreditAccounts() {
  const router = useRouter();
  const creditAccounts = useGetCardV1Cards(httpClient, {
    options: {
      select(data) {
        return data.data?.filter(isCreditAccount);
      },
    },
  });
  const [firstCredit, setFirstCredit] = React.useState('');

  React.useEffect(() => {
    const routerId = router.query.cardId as string;

    const credit = creditAccounts.data?.find(byAccountId(routerId));

    if (credit) {
      setFirstCredit(credit.id);
    } else {
      setFirstCredit(creditAccounts.data?.[0]?.id ?? '');
    }
  }, [router.query?.cardId, creditAccounts.data]);

  return {
    firstCredit,
    isLoading: creditAccounts.isLoading,
    numberOfAccounts: creditAccounts.data?.length ?? 0,
  };
}

function useSidebar() {
  const creditAcc = useHasOpenedCreditAccount();
  const depositAccounts = useDepositAccounts();
  const creditAccounts = useCreditAccounts();
  const getCardV1CardsQuery = useGetCardV1Cards(httpClient, {
    options: {
      enabled: Boolean(creditAccounts.firstCredit),
      select(data) {
        const creditCard = data.data?.find((card) => card?.id === creditAccounts.firstCredit);
        return {
          creditCard,
        };
      },
    },
  });

  const getCardV1CardsBalances = useGetCardV1CardsBalances(httpClient, {
    params: {
      path: { card_id: creditAccounts.firstCredit },
    },
    options: {
      enabled: Boolean(creditAccounts.firstCredit),
      select(data) {
        return {
          remainingStatementBalance: data.remainingStatementBalance,
        };
      },
    },
  });

  const routinesQuery = useListRoutinesQuery();

  return {
    isLoading:
      depositAccounts.isLoading ||
      creditAccounts.isLoading ||
      creditAcc.isLoading ||
      getCardV1CardsBalances.isLoading ||
      getCardV1CardsQuery.isLoading,
    isClosedCardHasBalance:
      getCardV1CardsQuery.data?.creditCard?.isAccountClosed &&
      Number(getCardV1CardsBalances.data?.remainingStatementBalance) > 0,
    firstDeposit: depositAccounts.firstDeposit,
    hasDepositAccount: depositAccounts.numberOfAccounts > 0,
    hasOpenedCreditAccount: creditAcc.hasOpenedCreditAccount,
    firstCredit: creditAccounts.firstCredit,
    hasMultipleAccounts: depositAccounts.numberOfAccounts + creditAccounts.numberOfAccounts >= 2,
    routinesLoading: routinesQuery.isLoading,
    routines: routinesQuery.data?.routines ?? [],
  };
}

export function Sidebar(props: SidebarProps) {
  const controller = useSidebar();
  const router = useRouter();
  const accountsV3FeatureFlag = useFeatureWithUserStrategies(FEATURE_FLAG_ACCOUNTS_PAGE_V3);

  const templatesDrawer = useTemplatesDrawerContext();
  return (
    <div
      className={cx(
        'z-0 flex-shrink-0 justify-between w-60 flex flex-col border-r border-transparent lg:border-gray-200 bg-white overflow-y-auto flex-nowrap',
        FULLSTORY_UNMASK,
        props.className,
      )}
    >
      <div className="flex justify-center items-center h-24">
        <Link href="/">
          <Image src={assetUrl('shared/logo.svg')} className="w-28 h-28" alt="hmbradley logo" fill />
        </Link>
      </div>
      <div className="flex flex-col justify-between h-full">
        <div className="p-2 mb-lg flex flex-col gap-2 text-sm font-medium">
          <NavItem
            label={accountsV3FeatureFlag.isEnabled ? 'Overview' : 'Accounts'}
            to={PATH_ACCOUNT_HOME}
            enabled={router.pathname?.includes('home')}
            icon={AccountsOverviewNavItemIcon}
            close={props.onClose}
            ready
          />
          {accountsV3FeatureFlag.isEnabled && (
            <NavItem
              label="Accounts"
              to={PATH_ACCOUNT_ACCOUNTS}
              enabled={router.pathname?.includes('accounts')}
              icon={AccountsNavItemIcon}
              close={props.onClose}
              ready
            />
          )}
          <FeatureFlag flag={FEATURE_FLAG_ROUTINES}>
            <NavItem
              label="Routines"
              to={PATH_ROUTINES_LIST}
              enabled={router.pathname?.includes('routines')}
              icon={RoutinesNavItemIcon}
              close={props.onClose}
              ready={!controller.routinesLoading}
              beta
            />
          </FeatureFlag>
          {!controller.hasDepositAccount ? (
            <NavItem
              label="Activity"
              to={CREDIT_TRANSACTIONS_PATH}
              icon={ActivityNavItemIcon}
              enabled={router.pathname?.includes('activity') || router.pathname?.includes('transactions')}
              close={props.onClose}
              path={{ cardId: controller.firstCredit }}
              ready={!controller.isLoading && Boolean(controller.firstCredit)}
            />
          ) : controller.hasMultipleAccounts ? (
            <NavItem
              label="Activity"
              to={ACCOUNT_ACTIVITY}
              enabled={router.pathname?.includes('activity') || router.pathname?.includes('transactions')}
              icon={ActivityNavItemIcon}
              close={props.onClose}
              ready
            />
          ) : (
            <NavItem
              label="Activity"
              to={NYCB_TRANSACTIONS_PATH}
              icon={ActivityNavItemIcon}
              enabled={router.pathname?.includes('activity') || router.pathname?.includes('transactions')}
              close={props.onClose}
              path={{ depositAccountId: controller.firstDeposit }}
              ready={!controller.isLoading}
            />
          )}
          {controller.hasDepositAccount && (
            <>
              {/* <NavItem
                label="Transfers"
                to={NYCB_TRANSFER_PATH}
                icon={TransfersNavItemIcon}
                enabled={router.pathname?.includes('transfers')}
                close={props.onClose}
                ready={!controller.isLoading}
              /> */}
              <FeatureFlag flag={FEATURE_FLAG_PLANS_WITH_ACCOUNT_NUMBERS}>
                <NavItem
                  label="Plans"
                  to={routeFor(NYCB_PLANS_PATH, {
                    path: {
                      depositAccountId: controller.firstDeposit,
                    },
                  })}
                  icon={PlansNavItemIcon}
                  enabled={router.pathname?.includes('plans')}
                  close={props.onClose}
                  ready={!controller.isLoading}
                />
              </FeatureFlag>
            </>
          )}
          {controller.hasOpenedCreditAccount && (
            <NavItem
              label="Cashback"
              to={CREDIT_REWARDS_PATH}
              icon={RewardsNavItemIcon}
              enabled={router.pathname?.includes('rewards')}
              close={props.onClose}
              path={{ cardId: controller.firstCredit }}
              ready={!controller.isLoading && Boolean(controller.firstCredit)}
            />
          )}
          {Boolean(controller.hasOpenedCreditAccount || controller.isClosedCardHasBalance) && (
            <NavItem
              label="Payments"
              to={CREDIT_PAYMENT_PATH}
              icon={PaymentsNavItemIcon}
              enabled={router.pathname?.includes('payment')}
              close={props.onClose}
              path={{ cardId: controller.firstCredit }}
              ready={!controller.isLoading && Boolean(controller.firstCredit)}
            />
          )}
          <FeatureFlag invert flag={FEATURE_FLAG_WIND_DOWN_CREDIT_INVITES}>
            <NavItem
              label="Insights"
              to={FINANCIAL_INSIGHTS_PATH}
              enabled={router.pathname?.includes('financial-insights')}
              icon={InsightsNavItemIcon}
              close={props.onClose}
              ready
            />
          </FeatureFlag>
        </div>
        <div>
          <div className="flex flex-col justify-between text-sm font-medium text-black-pure px-2">
            <FeatureFlag invert flag={FEATURE_FLAG_WIND_DOWN}>
              <TemplateButton openTemplateModal={templatesDrawer.openTemplatesDrawer} />
            </FeatureFlag>
            <NavItem
              label="Help and Support"
              to="https://hmb.to/support-topics"
              enabled={false}
              icon={BuoyNavItemIcon}
              close={props.onClose}
              ready
            />
          </div>
          <Menu as="div" className="text-left hidden lg:block">
            {(props) => (
              <>
                <UserMenuDropdown open={props.open} />
                <UserMenuItemList anchor={Anchor.LEFT} />
              </>
            )}
          </Menu>
          <div className="h-xl flex items-center pl-md text-grey-lighter text-xs font-light">
            &copy;&nbsp;{new Date().getFullYear()} HMBradley
          </div>
        </div>
      </div>
    </div>
  );
}

function TemplateButton(props: { openTemplateModal: () => void }) {
  return (
    <div
      role="button"
      onClick={props.openTemplateModal}
      className="rounded-lg p-2 flex flex-row items-center gap-2 hover:bg-gray-100"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M19 11H5M19 11C20.1046 11 21 11.8954 21 13V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V13C3 11.8954 3.89543 11 5 11M19 11V9C19 7.89543 18.1046 7 17 7M5 11V9C5 7.89543 5.89543 7 7 7M7 7V5C7 3.89543 7.89543 3 9 3H15C16.1046 3 17 3.89543 17 5V7M7 7H17"
          stroke="#111827"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <p>Templates</p>
    </div>
  );
}
