import COLORS from '@/styles/colors';
import { TextFieldProps } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import cx from 'clsx';
import { DateTime } from 'luxon';
import React from 'react';
import { TextFieldDatePicker } from './components/text-field-date-picker';

const theme = createTheme({
  zIndex: {
    modal: 4000,
  },
  palette: {
    primary: {
      main: COLORS.blue['600'],
    },
    secondary: {
      main: COLORS.brand_teal,
    },
  },
});

export type DatePickTextFieldProps = Omit<
  DatePickerProps<TextFieldProps, DateTime>,
  'TextFieldComponent' | 'renderInput' | 'error'
> &
  Omit<TextFieldProps, 'error'> & { error?: string; isOptional?: boolean };

export const DatePickerComponent = React.forwardRef<HTMLInputElement, DatePickTextFieldProps>(
  function DatePickerComponent(props, ref) {
    const [isOpen, setIsOpen] = React.useState(false);

    function onOpen() {
      setIsOpen(true);
    }

    function onClose() {
      setIsOpen(false);
    }

    return (
      <div className={cx('relative', props.className, { 'opacity-50 pointer-events-none': props.disabled })}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <ThemeProvider theme={theme}>
            <DatePicker
              inputFormat="MM/dd/yyyy"
              onChange={props.onChange}
              open={isOpen}
              onClose={onClose}
              value={props.value}
              minDate={props.minDate as DateTime | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>}
              renderInput={(params) => (
                <TextFieldDatePicker
                  {...params}
                  name={props.name}
                  isOptional={props.isOptional}
                  placeholder={props.placeholder}
                  error={props.error}
                  inputRef={params.inputRef}
                  label={props.label}
                  isOpen={isOpen}
                  onClose={onClose}
                  onOpen={onOpen}
                />
              )}
            />
          </ThemeProvider>
        </LocalizationProvider>
      </div>
    );
  },
);
