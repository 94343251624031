import COLORS from '../../../styles/colors';

/** @public */
export const LARGE = 'large';
/** @public */
export const MEDIUM = 'medium';
/** @public */
export const SMALL = 'small';

type SpinnerProps = {
  size?: keyof typeof SIZE_TO_PARAMETERS;
  color?: string;
};

const SIZE_TO_PARAMETERS = {
  [LARGE]: { tailwind: 'animate-spin-large origin-center stroke-[4px]', diameter: 36 },
  [MEDIUM]: { tailwind: 'animate-spin-medium origin-center stroke-[3px]', diameter: 24 },
  [SMALL]: { tailwind: 'animate-spin-small origin-center stroke-2', diameter: 16 },
};

export function Spinner(props: SpinnerProps) {
  const { size = MEDIUM, color = COLORS.brand_teal } = props;
  const diameter = size ? SIZE_TO_PARAMETERS[size].diameter : 24;

  return (
    <svg
      data-testid="loading-spinner"
      width={diameter}
      height={diameter}
      x="0"
      y="0"
      viewBox={`0 0 ${diameter} ${diameter}`}
    >
      <circle
        className={SIZE_TO_PARAMETERS[size].tailwind}
        cx={diameter / 2}
        cy={diameter / 2}
        r={diameter / 2 - 4}
        stroke={color}
        fill="none"
      />
    </svg>
  );
}
