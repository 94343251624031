import { Popover, Transition } from '@headlessui/react';
import cx from 'clsx';
import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

function Arrow(props: { className: string }) {
  return (
    <svg
      className={props.className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 5H2L8 11L14 5Z" fill="#11181B" />
    </svg>
  );
}

const options = [
  '#1FC052',
  '#1EA1C1',
  '#006982',
  '#FFDC8C',
  '#EB5F2D',
  '#FAC8D2',
  '#F12989',
  '#C8B9FF',
  '#7561E2',
  '#545454',
];

function PopoverButton(props: { open: boolean; isHideArrow?: boolean; value: string }) {
  return (
    <div
      className={cx('bg-white hover:bg-gray-50 rounded-lg flex justify-between items-center px-3 h-[3.2rem]', {
        'border-2 border-gray-900': !props.open,
        'border-2 border-blue': props.open,
      })}
    >
      <div className={'w-6 h-6 rounded-full'} style={{ backgroundColor: props.value }} />
      {!props.isHideArrow && <Arrow className={cx('ml-2', props.open ? 'rotate-180' : '')} />}
    </div>
  );
}

type ColorPickerProps = {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  isHideArrow?: boolean;
  isRightAlignment?: boolean;
  button?: (props: { open: boolean; isHideArrow?: boolean; value: string }) => JSX.Element;
};

export function ColorPicker(props: ColorPickerProps) {
  const Button = props.button ?? PopoverButton;

  return (
    <div className="flex flex-col justify-start">
      {props.label && <span className="text-xs font-medium mb-0.5">{props.label}</span>}
      <Popover className="relative">
        {(popoverProps) => (
          <>
            <Popover.Button>
              <Button open={popoverProps.open} isHideArrow={props.isHideArrow} value={props.value} />
            </Popover.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={cx('absolute z-50 w-48 mt-1', props.isRightAlignment ? 'right-auto' : 'right-0')}
              >
                {(popoverPanelProps) => (
                  <div className="bg-white px-3 pt-5 pb-1 rounded-md flex items-center flex-wrap border-2 border-blue">
                    {options.map((option) => (
                      <div
                        key={option}
                        role="button"
                        onClick={() => {
                          props.onChange(option);
                          popoverPanelProps.close();
                        }}
                        className={cx('mx-2 mb-4 rounded-full cursor-pointer', {
                          'border-2 border-blue w-5 h-5': option === props.value,
                          'w-4 h-4': option !== props.value,
                        })}
                        style={{ backgroundColor: option }}
                      ></div>
                    ))}
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

type ControlledColorPickerProp<T> = Omit<ControllerProps<T>, 'render'> & Omit<ColorPickerProps, 'onChange' | 'value'>;

export function ControlledColorPicker({ control, ...props }: ControlledColorPickerProp<typeof control>) {
  return (
    <Controller
      control={control}
      name={props.name}
      defaultValue={props.defaultValue}
      render={({ field }) => (
        <ColorPicker
          value={field.value}
          onChange={field.onChange}
          label={props.label}
          isHideArrow={props.isHideArrow}
          isRightAlignment={props.isRightAlignment}
          button={props.button}
        />
      )}
    />
  );
}
