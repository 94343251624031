import { DateTime } from 'luxon';

function generateMonthLabels(monthsCount: number) {
  return Array.from(Array(monthsCount).keys()).reduce(
    (acc) => ({
      date: acc.date.plus({ months: 1 }),
      labels: [...acc.labels, acc.date.toFormat('MMM yyyy')],
    }),
    { date: DateTime.now(), labels: [] } as { date: DateTime; labels: string[] },
  ).labels;
}

function generateContributionWithInterestData(
  monthLabels: string[],
  initialContribution: number,
  monthlyContribution: number,
  apyPercentage: number,
) {
  return monthLabels.reduce(
    (acc, _, index) => {
      const newToDateValue =
        acc.toDateValue + (index === 0 ? 0 : monthlyContribution + (acc.toDateValue * apyPercentage) / 12);
      const newData = [...acc.data, newToDateValue];
      return {
        data: newData,
        toDateValue: newToDateValue,
      };
    },
    {
      toDateValue: initialContribution,
      data: [],
    } as { toDateValue: number; data: number[] },
  ).data;
}

interface IUseChartDataProps {
  achieveYears: number;
  achieveMonths: number;
  initialContribution: number;
  monthlyContribution: number;
  goal: number;
  apyValue: number;
  coef?: number;
}

export type ChartDataItem = {
  name: string;
  contribution: number;
  contributionWithInterest: number;
};

export function useChartData(props: IUseChartDataProps) {
  function prepareGraphValue(value: number, initialContribution: number) {
    return value - initialContribution + initialContribution * (props.coef || 0.05);
  }

  function prepareTooltipValue(value: number, initialContribution: number) {
    return value + initialContribution - initialContribution * (props.coef || 0.05);
  }

  const monthsCount = props.achieveYears * 12 + props.achieveMonths + 1;
  const monthLabels = generateMonthLabels(monthsCount);

  const initialContribution = props.initialContribution;
  const goal = prepareGraphValue(props.goal, initialContribution);
  const monthlyContribution = props.monthlyContribution;

  const contributionValues = monthLabels.map((_, index) => initialContribution + index * monthlyContribution);
  const contributionWithInterestValues = generateContributionWithInterestData(
    monthLabels,
    initialContribution,
    monthlyContribution,
    props.apyValue,
  );

  const data: ChartDataItem[] = monthLabels.map((name, index) => ({
    name: name,
    contribution: prepareGraphValue(contributionValues[index], initialContribution),
    contributionWithInterest: prepareGraphValue(contributionWithInterestValues[index], initialContribution),
  }));

  const totalContributionWithInterest = data[data.length - 1].contributionWithInterest;
  const wouldAchieve = totalContributionWithInterest >= goal;
  const yMax = !wouldAchieve ? goal * 1.05 : totalContributionWithInterest * 1.05;

  return {
    yMax: yMax,
    data: data,
    goal: goal,
    wouldAchieve: wouldAchieve,
    monthLabels: monthLabels,
    goalPositionTop: 1 - goal / yMax > 1 ? 1 : 1 - goal / yMax,
    prepareTooltipValue: prepareTooltipValue,
  };
}
