import * as React from 'react';
import { CommandPaletteContext } from '../contexts/command-palette';
import { useCommandPalette } from './use-command-palette';

export function useCommandPaletteContext(): ReturnType<typeof useCommandPalette> {
  const controller = React.useContext(CommandPaletteContext);

  if (controller === undefined) {
    throw new Error('Context failed to connect: missing CommandPaletteProvider');
  }

  return controller;
}
