import { Loading } from '@/components/loaders/pages-loader';
import { RedirectTo } from '@/components/redirect-to';
import { PATH_LOGIN } from '@/constants/routes';
import { useSessionContext } from '@/hooks/use-session-context';
import { useRouter } from 'next/router';
import * as React from 'react';

export function AuthenticatedUser(props: React.PropsWithChildren<any>) {
  const router = useRouter();
  const sessionContext = useSessionContext();

  if (sessionContext.isLoading) {
    return <Loading />;
  }

  if (!sessionContext.isAuthenticated) {
    return (
      <RedirectTo
        replace
        url={{
          pathname: PATH_LOGIN,
          query: {
            redirectTo: router.asPath,
          },
        }}
      />
    );
  }

  return props.children;
}
