import { httpClient } from '@/transports/http';
import { useGetLedgerStatus } from '@core/mainframe-react-query';
import * as React from 'react';

export function useApiStatus() {
  const [shown, setShown] = React.useState<string[]>([]);
  // TODO: Remove statuses state
  // We should return all the information from the backend service.
  const [statuses, setStatuses] = React.useState({});
  const ledgerStatusQuery = useGetLedgerStatus(httpClient, {
    options: {
      // TODO: do we want this?
      // refetchInterval: 5000,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setStatuses({
          ledger: data,
          card: {
            status: 'OK',
            statusMessage: '',
          },
          registrar: {
            status: 'OK',
            statusMessage: '',
          },
          compliance: {
            status: 'OK',
            statusMessage: '',
          },
          decider: {
            status: 'OK',
            statusMessage: '',
          },
        });
      },
      onSettled() {
        setShown([]);
      },
    },
  });
  const lastChecked = Math.max(ledgerStatusQuery.dataUpdatedAt, ledgerStatusQuery.errorUpdatedAt);
  const isReady = !ledgerStatusQuery.isLoading && !ledgerStatusQuery.isError;

  return {
    markAsShown(servicePath: string) {
      setShown([...shown, servicePath]);
    },
    isShown(servicePath: string) {
      return shown.includes(servicePath);
    },
    getServiceStatus(servicePath: string) {
      return servicePath in statuses ? statuses[servicePath] : undefined;
    },
    isReady,
    lastChecked,
    isLoading: ledgerStatusQuery.isLoading,
    isError: ledgerStatusQuery.isError,
  };
}
