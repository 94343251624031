import { ProjectedPlanGraph } from '@/components/create-plan/components/projected-plan-graph';
import { useCreatePlanDrawerContext } from '@/routes/account/components/create-plan-drawer/hooks/use-create-plan-drawer-context';
import { XL_MOBILE_WIDTH } from '@/styles/media_queries';
import { DateTime } from 'luxon';
import React from 'react';

export function ProjectedPlan(props: { apyValue: number }) {
  const controller = useCreatePlanDrawerContext();

  const dateDifference = DateTime.fromISO(controller.planGoal?.achieveGoalBy || '11/11/2022').diff(
    DateTime.now(),
    'months',
  );

  const achieveYears = Math.trunc(dateDifference.months / 12);
  const achieveMonths = Math.trunc(dateDifference.months % 12);

  const chartWidth = window.innerWidth > XL_MOBILE_WIDTH ? 400 : window.innerWidth - 48;

  return (
    <div>
      <p className="text-lg text-gray-950 mb-3">Projected growth*</p>
      {!!controller.planGoal && (
        <ProjectedPlanGraph
          applyColumnLayout
          width={chartWidth}
          height={chartWidth * 0.6}
          chartMargin={{ top: 0, right: 5, bottom: 5, left: 5 }}
          planGoal={{
            achieveYears,
            achieveMonths,
            goalAmount: parseFloat((controller.planGoal.goalAmount || '0').replace('$', '').replaceAll(',', '')),
            initialContribution: parseFloat(
              (controller.planGoal.initialContribution || '0').replace('$', '').replaceAll(',', ''),
            ),
            monthlyContribution: parseFloat(
              (controller.planGoal.monthlyContribution || '0').replace('$', '').replaceAll(',', ''),
            ),
          }}
          apyValue={props.apyValue}
        />
      )}
      <div className="w-full mt-6 mb-8 h-px bg-gray-200" />
    </div>
  );
}
