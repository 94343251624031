import { CommandPaletteProvider } from '@/components/command-palette/components/command-palette-provider';
import { TemplatesDrawerProvider } from '@/components/drawers/templates/providers/templates-drawer-provider';
import { ToastContainerWrapper } from '@/components/toast';
import { withAuthenticatedUser } from '@/components/with-authenticated-user';
import { withOnboardingApprovedStatus } from '@/components/with-onboarding-approved-status';
import { compose } from '@/helpers';
import { TwoFAProvider } from '@/modules/providers/two-fa';
import { CreatePlanDrawerProvider } from '@/routes/account/components/create-plan-drawer/providers/create-plan-drawer-provider';
import * as React from 'react';

export const DashboardProvider = compose(
  withAuthenticatedUser,
  withOnboardingApprovedStatus,
)(function DashboardProvider(props: React.PropsWithChildren<{}>) {
  return (
    <ToastContainerWrapper containerId="Banner">
      <CreatePlanDrawerProvider>
        <TemplatesDrawerProvider>
          <CommandPaletteProvider>
            <TwoFAProvider>{props.children}</TwoFAProvider>
          </CommandPaletteProvider>
        </TemplatesDrawerProvider>
      </CreatePlanDrawerProvider>
    </ToastContainerWrapper>
  );
});
