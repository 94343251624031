const USER_STRATEGY_NAME = 'userWithId';

type Strategy = {
  name: string;
  parameters: {
    userIds: string;
    [key: string]: string | number | boolean;
  };
};

export type Strategies = Array<Strategy>;

export function isUserStrategy({ name }: { name: string }) {
  return name === USER_STRATEGY_NAME;
}

function getUserIds(strategy: Strategy) {
  return strategy.parameters.userIds.split(',');
}

export function getUsersIdFromStrategies(strategies: Strategies) {
  return strategies.filter(isUserStrategy).map(getUserIds).flat();
}
