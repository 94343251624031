import { FULLSTORY_MASK } from '@/constants';
import cx from 'clsx';
import * as React from 'react';
import { FormFieldError } from '../form-field-error';
import { mergeRefs } from '../helpers/merge-refs';
import { Input, InputProps } from '../input';

export type TextFieldProps = {
  autoComplete?: 'one-time-code' | 'email' | 'username' | 'current-password' | 'new-password' | 'off';
  label?: string;
  error?: string;
  inputClassName?: string;
  labelClassName?: string;
  slot?: {
    before?: React.ReactNode;
    after?: React.ReactNode;
  };
  isOptional?: boolean;
  isFullStoryMasked?: boolean;
} & Omit<InputProps, 'slot'>;

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(function TextField(
  props: TextFieldProps,
  ref,
) {
  const {
    label,
    className,
    inputClassName,
    name,
    slot,
    placeholder,
    isFullStoryMasked,
    labelClassName,
    isOptional,
    ...inputProps
  } = props;

  const initialLabelState = !!props.defaultValue || !!props.value || !!placeholder || props.autoFocus;

  const [hasValue, setHasValue] = React.useState(initialLabelState);
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (inputRef.current?.value || (typeof inputRef.current?.value === 'number' && inputRef.current?.value > 0))
      setHasValue(true);
    else setHasValue(false);
  }, [inputRef.current?.value]);

  const onFocus = (e) => {
    props.onFocus && props.onFocus(e);
  };

  const onBlur = (e) => {
    props.onBlur && props.onBlur(e);
  };

  const onChange = (e) => {
    props.onChange && props.onChange(e);
    setHasValue(!!e.target.value);
  };

  return (
    <div className={cx('relative leading-none', className)}>
      <div className="flex justify-between items-center">
        {props.label && (
          <label
            className={cx(labelClassName, 'text-xs font-medium', {
              'text-red-800': !!props.error,
              'border-black-inactive text-black-inactive': props.disabled,
            })}
            onClick={() => inputRef.current.focus()}
            htmlFor={props.id ?? name}
          >
            {props.label}
          </label>
        )}
        {isOptional && <span className="text-tiny rounded py-0.5 px-1 bg-gray-200">Optional</span>}
      </div>
      <div className="relative my-1">
        {slot?.before && <div className="absolute top-0 bottom-0 left-0 flex">{slot?.before}</div>}
        <Input
          {...inputProps}
          name={name}
          placeholder={placeholder}
          id={props.id ?? name}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          hasValue={hasValue}
          hasError={!!props.error}
          className={cx(inputClassName, {
            [FULLSTORY_MASK]: isFullStoryMasked,
            'pl-10': slot?.before,
            'pr-10': slot?.after,
          })}
          ref={mergeRefs<HTMLInputElement>(ref, inputRef)}
        />
        {slot?.after && <div className="absolute top-0 bottom-0 right-0 flex">{slot?.after}</div>}
      </div>
      {props.error && <FormFieldError>{props.error}</FormFieldError>}
    </div>
  );
});
