import { httpClient } from '@/transports/http';
import { usePostRegistrarV1Oauth2Refresh } from '@core/mainframe-react-query';
import * as React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import IdleTimer, { EVENTS } from 'react-idle-timer';

import COLORS from '@/styles/colors';

import { useLogout } from '@/hooks/use-session';
import 'react-circular-progressbar/dist/styles.css';
import { Button, BUTTON_COLORS, BUTTON_DISPLAY } from '../buttons-main/button';
import { Modal } from '../modal';

const events = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
] as EVENTS[];

const ACTIVITY_TIMER_DURATION = 1000 * 60 * 9;
const DEFAULT_LOGOUT_DURATION = 60;
const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 5;

let countdownInterval: NodeJS.Timeout | null = null;
let authRefreshInterval: NodeJS.Timeout | null = null;

function cleanupIntervals() {
  clearInterval(countdownInterval);
  clearInterval(authRefreshInterval);
}

export function ActivityTimer() {
  const logout = useLogout();
  const registrarV1Oauth2Refresh = usePostRegistrarV1Oauth2Refresh(httpClient, {});
  const [isIdle, setIdle] = React.useState<boolean>(false);
  const [countdown, setCountdown] = React.useState<number>(DEFAULT_LOGOUT_DURATION);

  function logoutUser() {
    cleanupIntervals();
    setIdle(false);
    return logout.onLogout();
  }

  function handleContinueSession() {
    setIdle(false);
    setCountdown(DEFAULT_LOGOUT_DURATION);
    cleanupIntervals();
    registrarV1Oauth2Refresh.mutate({});
  }

  function onIdle() {
    if (isIdle) return;

    setIdle(true);
    clearInterval(authRefreshInterval);

    const endDate = DEFAULT_LOGOUT_DURATION * 1000 + Date.now();
    countdownInterval = setInterval(() => {
      const nextCount = Math.round((endDate - Date.now()) / 1000);
      if (nextCount >= 0) {
        setCountdown(nextCount);
      } else {
        logoutUser();
      }
    }, 1000);
  }

  // Updates the user's token on an interval when they're not idle
  React.useEffect(() => {
    authRefreshInterval = setInterval(() => {
      if (!isIdle) {
        registrarV1Oauth2Refresh.mutate({});
      }
    }, REFRESH_TOKEN_INTERVAL);
  }, [isIdle]);

  // cleanup when unmounting
  React.useEffect(() => () => cleanupIntervals(), []);

  return (
    <>
      <IdleTimer element={document} onIdle={onIdle} events={events} debounce={250} timeout={ACTIVITY_TIMER_DURATION} />
      <Modal isOpen={isIdle} overlayClassName="modal-overlay">
        <div className="bg-white max-w-[394px] max-h-[300px] h-full w-full p-xl pb-lg box-border overflow-hidden rounded shadow">
          <h2 className="text-xl font-semibold text-black text-left p-0 m-0 mb-md sm:mb-lg">
            Looks like you&apos;ve left us...
          </h2>
          <div className="flex justify-between items-center mb-md">
            <div className="flex relative float-right w-20 h-20 items-center justify-center mr-sm sm:mr-0">
              <div className="absolute flex items-center w-full h-full">
                <CircularProgressbar
                  styles={buildStyles({
                    pathColor: COLORS.brandTeal,
                  })}
                  value={countdown}
                  maxValue={DEFAULT_LOGOUT_DURATION}
                />
              </div>
              <p className="font-light text-grey-dark text-2xl text-center pt-xs sm:text-4xl">{countdown}</p>
            </div>
            <p className="text-sm text-left font-light p-0 ml-lg max-w-[222px] sm:text-base">
              For your security, we’ll log you out in just a moment. Click to stay logged in or log out now below.
            </p>
          </div>

          <Button color={BUTTON_COLORS.black} display={BUTTON_DISPLAY.block} onClick={handleContinueSession}>
            Keep me logged in
          </Button>
          <Button color={BUTTON_COLORS.white} display={BUTTON_DISPLAY.block} onClick={logoutUser}>
            Log out
          </Button>
        </div>
      </Modal>
    </>
  );
}
