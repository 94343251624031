import cx from 'clsx';
import React from 'react';

function CustomizeImage() {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="42" height="42" rx="21" fill="#D7DDE2" />
      <path
        d="M19.992 12.936C19.4353 12.936 18.984 13.3873 18.984 13.944C18.984 14.5007 19.4353 14.952 19.992 14.952H22.008C22.5647 14.952 23.016 14.5007 23.016 13.944C23.016 13.3873 22.5647 12.936 22.008 12.936H19.992Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.952 15.96C14.952 14.8466 15.8546 13.944 16.968 13.944C16.968 15.6141 18.3219 16.968 19.992 16.968H22.008C23.6781 16.968 25.032 15.6141 25.032 13.944C26.1454 13.944 27.048 14.8466 27.048 15.96V27.048C27.048 28.1614 26.1454 29.064 25.032 29.064H16.968C15.8546 29.064 14.952 28.1614 14.952 27.048V15.96ZM17.976 19.992C17.4193 19.992 16.968 20.4433 16.968 21C16.968 21.5567 17.4193 22.008 17.976 22.008H17.9861C18.5428 22.008 18.9941 21.5567 18.9941 21C18.9941 20.4433 18.5428 19.992 17.9861 19.992H17.976ZM21 19.992C20.4433 19.992 19.992 20.4433 19.992 21C19.992 21.5567 20.4433 22.008 21 22.008H24.024C24.5807 22.008 25.032 21.5567 25.032 21C25.032 20.4433 24.5807 19.992 24.024 19.992H21ZM17.976 24.024C17.4193 24.024 16.968 24.4753 16.968 25.032C16.968 25.5887 17.4193 26.04 17.976 26.04H17.9861C18.5428 26.04 18.9941 25.5887 18.9941 25.032C18.9941 24.4753 18.5428 24.024 17.9861 24.024H17.976ZM21 24.024C20.4433 24.024 19.992 24.4753 19.992 25.032C19.992 25.5887 20.4433 26.04 21 26.04H24.024C24.5807 26.04 25.032 25.5887 25.032 25.032C25.032 24.4753 24.5807 24.024 24.024 24.024H21Z"
        fill="white"
      />
    </svg>
  );
}

function CustomizeImageActive() {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="42" height="42" rx="21" fill="white" />
      <path
        d="M19.992 12.936C19.4353 12.936 18.984 13.3873 18.984 13.944C18.984 14.5007 19.4353 14.952 19.992 14.952H22.008C22.5647 14.952 23.016 14.5007 23.016 13.944C23.016 13.3873 22.5647 12.936 22.008 12.936H19.992Z"
        fill="#29353B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.952 15.96C14.952 14.8466 15.8546 13.944 16.968 13.944C16.968 15.6141 18.3219 16.968 19.992 16.968H22.008C23.6781 16.968 25.032 15.6141 25.032 13.944C26.1454 13.944 27.048 14.8466 27.048 15.96V27.048C27.048 28.1614 26.1454 29.064 25.032 29.064H16.968C15.8546 29.064 14.952 28.1614 14.952 27.048V15.96ZM17.976 19.992C17.4193 19.992 16.968 20.4433 16.968 21C16.968 21.5567 17.4193 22.008 17.976 22.008H17.9861C18.5428 22.008 18.9941 21.5567 18.9941 21C18.9941 20.4433 18.5428 19.992 17.9861 19.992H17.976ZM21 19.992C20.4433 19.992 19.992 20.4433 19.992 21C19.992 21.5567 20.4433 22.008 21 22.008H24.024C24.5807 22.008 25.032 21.5567 25.032 21C25.032 20.4433 24.5807 19.992 24.024 19.992H21ZM17.976 24.024C17.4193 24.024 16.968 24.4753 16.968 25.032C16.968 25.5887 17.4193 26.04 17.976 26.04H17.9861C18.5428 26.04 18.9941 25.5887 18.9941 25.032C18.9941 24.4753 18.5428 24.024 17.9861 24.024H17.976ZM21 24.024C20.4433 24.024 19.992 24.4753 19.992 25.032C19.992 25.5887 20.4433 26.04 21 26.04H24.024C24.5807 26.04 25.032 25.5887 25.032 25.032C25.032 24.4753 24.5807 24.024 24.024 24.024H21Z"
        fill="#29353B"
      />
    </svg>
  );
}

export function CustomPlanOption(props: { isSelected: boolean; onClick: () => void }) {
  const [isHover, setIsHover] = React.useState(false);

  function onMouseEnter() {
    setIsHover(true);
  }

  function onMouseLeave() {
    setIsHover(false);
  }

  const isActive = isHover || props.isSelected;

  return (
    <div
      role="button"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cx(
        'cursor-pointer border-1 border-gray-200 flex px-4 py-5 items-center rounded-lg duration-300 mt-2',
        {
          'bg-gray-700': isActive,
        },
      )}
      onClick={props.onClick}
    >
      {isActive ? <CustomizeImageActive /> : <CustomizeImage />}
      <div className="pl-4">
        <p className={cx('text-md mb-1', { 'text-gray-950': !isActive, 'text-white': isActive })}>
          Customize your own Plan
        </p>
        <p className={cx('font-normal text-sm', { 'text-gray-600': !isActive, 'text-white': isActive })}>
          Set aside money for a specific goal that has meaning to you
        </p>
      </div>
    </div>
  );
}
