const OrdinalPluralFormatter = new Intl.PluralRules('en-US', { type: 'ordinal' });

const ordinalSuffixes = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
};

export function formatOrdinalNumber(day: number) {
  const suffix = ordinalSuffixes[OrdinalPluralFormatter.select(day)];
  return `${day}${suffix}`;
}
