import { CalendarIcon } from '@heroicons/react/24/outline';
import { TextFieldProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { TextField } from '../../text-field';

type TextFieldDatePickerProps = {
  name: string;
  label?: React.ReactNode | string;
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
  inputProps?: TextFieldProps['inputProps'];
  inputRef: React.Ref<HTMLInputElement>;
  error?: any | string;
  isOptional?: boolean;
  placeholder?: string;
};

export function TextFieldDatePicker(props: TextFieldDatePickerProps) {
  const ref = React.useRef(null);

  function handleFocus() {
    ref.current.blur();
  }

  return (
    <div ref={props.inputRef} onClick={props.isOpen ? props.onClose : props.onOpen}>
      <TextField
        name={props.name}
        type="text"
        onFocus={handleFocus}
        ref={ref}
        label={props.label as string}
        error={props.error}
        isOptional={props.isOptional}
        placeholder={props.placeholder}
        className="cursor-pointer"
        value={props.inputProps.value}
        onChange={props.inputProps.onChange}
        slot={{
          after: <CalendarButton error={props.error} />,
        }}
      />
    </div>
  );
}

function CalendarButton(props: { error?: string }) {
  return <CalendarIcon width={26} className={clsx('mr-2', !!props.error ? 'text-red-800' : 'text-grey-dark')} />;
}
