import {
  ChartDataItem,
  useChartData,
} from '@/components/create-plan/components/projected-plan-graph/hooks/use-chart-data';
import { CurrencyFormatter } from '@/i18n/numbers';
import cx from 'clsx';
import React from 'react';
import { Area, AreaChart, Tooltip, YAxis } from 'recharts';
import { Margin } from 'recharts/types/util/types';

function CustomTooltip(props: { active; payload; label }) {
  if (props.active && props.payload && props.payload.length) {
    return <div className="rounded bg-gray-950 text-sm text-white py-0.5 px-2">{props.payload[0].payload.name}</div>;
  }

  return null;
}

function DashedLine(props: { className: string }) {
  return (
    <svg className={props.className} height="1" viewBox="0 0 435 1" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0.5H435" stroke="#D7DDE2" strokeDasharray="2 6" />
    </svg>
  );
}
function Grid(props: { wouldAchieve: boolean; goalPositionTop: number; noGoal: boolean }) {
  return (
    <div className="absolute z-40 top-0 bottom-0 left-2 right-2">
      <DashedLine className="absolute z-30 top-0 w-full" />
      <DashedLine className="absolute z-30 top-1/4 w-full" />
      <DashedLine className="absolute z-30 top-1/2 w-full" />
      <DashedLine className="absolute z-30 top-3/4 w-full" />
      <DashedLine className="absolute z-30 bottom-0 w-full" />
      <DashedLine className="absolute z-30 bottom-0 w-full" />
      {!props.noGoal && (
        <div className="absolute z-40 w-full -translate-y-1/2" style={{ top: `${props.goalPositionTop}%` }}>
          <div
            className={cx('bg-white ml-[23px] w-fit relative z-40', {
              'px-[5px]': !props.wouldAchieve,
              'px-[1px]': props.wouldAchieve,
            })}
          >
            <div
              className={cx('w-fit rounded px-2 text-sm pt-0.5 pb-1 leading-none', {
                'bg-white text-gray-950': props.wouldAchieve,
                'bg-red-700 text-white': !props.wouldAchieve,
              })}
            >
              Goal
            </div>
          </div>
          <div
            className={cx('w-full top-[8px] z-30 absolute border-t-4 border-dotted', {
              'border-gray-950': props.wouldAchieve,
              'border-red-700': !props.wouldAchieve,
            })}
          />
        </div>
      )}
    </div>
  );
}

const COLOR_BLUE = '#0297BC';

export function ProjectedPlanGraph(props: {
  planGoal: {
    goalAmount: number;
    monthlyContribution: number;
    initialContribution: number;
    achieveYears: number;
    achieveMonths: number;
  };
  apyValue: number;
  width?: number;
  height?: number;
  applyColumnLayout?: boolean;
  chartMargin?: Margin;
}) {
  const [activePayload, setActivePayload] = React.useState<ChartDataItem | undefined>();

  const noGoal = props.planGoal.goalAmount === 0;

  const chartData = useChartData({
    goal: noGoal ? 0 : props.planGoal.goalAmount,
    initialContribution: props.planGoal.initialContribution,
    monthlyContribution: props.planGoal.monthlyContribution,
    achieveYears: props.planGoal.achieveYears,
    achieveMonths: props.planGoal.achieveMonths,
    apyValue: props.apyValue,
  });

  const goalPositionTop =
    ((chartData.yMax - chartData.goal) / chartData.yMax) * 100 > 100
      ? 100
      : ((chartData.yMax - chartData.goal) / chartData.yMax) * 100;

  function onMouseMove(data) {
    if (data.activePayload && data.activePayload.length) {
      setActivePayload(data.activePayload[0].payload);
    }
  }

  function onMouseLeave() {
    setActivePayload(chartData.data[chartData.data.length - 1]);
  }

  React.useEffect(() => {
    if (chartData.data.length) {
      setActivePayload(chartData.data[chartData.data.length - 1]);
    }
  }, [chartData.data.length]);

  return (
    <div className={cx('flex', { 'flex-col': props.applyColumnLayout })}>
      {props.applyColumnLayout && !!activePayload && (
        <div className="flex justify-between">
          <div>
            <p className="text-sm font-normal" style={{ color: COLOR_BLUE }}>
              Contribution + interest
            </p>
            <p className="text-lg mb-4" style={{ color: COLOR_BLUE }}>
              {CurrencyFormatter.format(
                chartData.prepareTooltipValue(
                  activePayload.contributionWithInterest,
                  props.planGoal.initialContribution,
                ),
              )}
            </p>
          </div>
          <div>
            <p className="text-sm font-normal text-gray-900">Total contribution</p>
            <p className="text-lg text-gray-900 mb-12">
              {CurrencyFormatter.format(
                chartData.prepareTooltipValue(activePayload.contribution, props.planGoal.initialContribution),
              )}
            </p>
          </div>
        </div>
      )}
      <div className={cx('relative w-fit', { 'mx-auto': props.applyColumnLayout })}>
        <Grid wouldAchieve={chartData.wouldAchieve} goalPositionTop={goalPositionTop} noGoal={noGoal} />
        <AreaChart
          className="z-50"
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
          width={props.width || 440}
          height={props.height || 258}
          data={chartData.data}
          margin={props.chartMargin || { top: 5, right: 5, bottom: 5, left: 5 }}
        >
          <defs>
            <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#85E8FF" stopOpacity={1} />
              <stop offset="100%" stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorBlack" x1="0" y1="0" x2="0" y2="1">
              <stop offset="10%" stopColor="#fff" stopOpacity={0.6} />
              <stop offset="90%" stopColor="#fff" stopOpacity={0} />
            </linearGradient>
          </defs>
          <YAxis type="number" hide domain={[0, chartData.yMax]} />
          <Area
            type="monotone"
            dataKey="contributionWithInterest"
            stroke={COLOR_BLUE}
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#colorBlue)"
          />
          <Area
            type="monotone"
            dataKey="contribution"
            stroke="#0B1012"
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#colorBlack)"
          />
          <Tooltip
            active
            allowEscapeViewBox={{ y: false, x: false }}
            offset={-10}
            position={{ y: 0 }}
            wrapperStyle={{ bottom: 0, top: 'unset', outline: 'none' }}
            cursor={{ stroke: '#0B1012', strokeWidth: 1 }}
            content={CustomTooltip}
          />
        </AreaChart>
      </div>
      {!!activePayload && (
        <div className={cx({ 'pl-6': !props.applyColumnLayout, 'mt-4': props.applyColumnLayout })}>
          {!props.applyColumnLayout && (
            <>
              <p className="text-sm font-normal" style={{ color: COLOR_BLUE }}>
                Contribution + interest
              </p>
              <p className="text-lg mb-4" style={{ color: COLOR_BLUE }}>
                {CurrencyFormatter.format(
                  chartData.prepareTooltipValue(
                    activePayload.contributionWithInterest,
                    props.planGoal.initialContribution,
                  ),
                )}
              </p>
              <p className="text-sm font-normal text-gray-900">Total contribution</p>
              <p className="text-lg text-gray-900 mb-12">
                {CurrencyFormatter.format(
                  chartData.prepareTooltipValue(activePayload.contribution, props.planGoal.initialContribution),
                )}
              </p>
            </>
          )}
          {!noGoal && (
            <>
              {chartData.wouldAchieve ? (
                <p className="text-sm font-normal text-gray-950">
                  You would hit your goal ahead of your target date based on the contribution amounts input.
                </p>
              ) : (
                <p className="text-sm font-normal text-red-700">
                  You would not hit your goal by your target date based on the contribution amounts input.
                </p>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
