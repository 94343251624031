import Switch from 'react-switch';

type SwitchContainerProps = {
  onChange?: (value: boolean) => void;
  value?: boolean;
  labels?: Array<string>;
};

export function SwitchContainer({ onChange = () => {}, value = false, labels = ['On', 'Off'] }: SwitchContainerProps) {
  return (
    <div className="flex item-center">
      <Switch
        className="mr-sm"
        width={48}
        height={23}
        onChange={onChange}
        checked={value}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor="#50EB82"
        offColor="#dcdcdc"
        name="switch"
      />
      <label htmlFor="switch" className="text-grey-dark text-base font-light">
        {value ? labels[0] : labels[1]}
      </label>
    </div>
  );
}
